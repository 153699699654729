<template>

<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- section -->
        <section class="section bg-cover bg-no-repeat effect-section" style="background-image: url('/static/img/banner-bg-5.jpg');">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-8 col-lg-6 col-xl-5 order-lg-2">
                        <div class="card box-shadow-lg">
                            <div class="card-body">
                                <h3 class="font-w-600 dark-color">Change your password?</h3>
                                <p>Change your password to be able to login to your account.</p>
                                <div>
                                  <div class="" v-for="errors in validationErrors" :key="errors.id">
                                    <div class="text-danger text-center">
                                      {{ errors }}
                                    </div>
                                  </div>
                                </div>
                                <form @submit.prevent="changePassword">
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">New Password</label>
                                        <input type="password" v-model="password" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Confirm New Password</label>
                                        <input type="password" v-model="password_confirmation" class="form-control">
                                    </div>
                                    <div class="p-5px-t">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <button v-if="loading == false" type="submit" class="m-btn m-btn-radius m-btn-theme">Change Password</button>
                                            <button v-if="loading == true" disabled class="m-btn m-btn-radius m-btn-theme">
                                              <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Processing</span>
                                              </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End section -->
    </main>
    <!-- main end -->

    <Footer />

</div>

</template>

<script>
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { ref } from "@vue/reactivity";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

export default {
  components: {
        Navbar,
        Footer,
    },

  setup() {
    const password = ref("");
    const password_confirmation = ref("");
    const router = useRouter();
    const validationErrors = ref("");
    const toast = useToast();
    const loading = ref(false);
    const showPassword = ref(true);
    const showConfirmPassword = ref(true);

    const togglePasswordField = () => {
      showPassword.value = !showPassword.value;
    };

    const togglePasswordConfirmField = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const route = useRoute();

    const changePassword = async () => {
      loading.value = true;
      await axios
        .post("/reset-password", {
          password: password.value,
          resetToken: route.params.token,
          password_confirmation: password_confirmation.value,
        })
        .then(() => {
          loading.value = false;
          toast.success("Password changed successfully");
          router.push("/auth/login");
          
        })
        .catch((error) => {
          if (error.response.status == 422) {
            toast.error("There are errors in your inputs");
            validationErrors.value = error.response.data.message;
          }

          if (error.response.status == 400) {
            toast.error(error?.response?.data?.message);
          }
          if (error.response.status == 500) {
            toast.error("Please recheck your login details");
          }
          loading.value = false;
        });
    };
    return {
      changePassword,
      password_confirmation,
      password,
      router,
      toast,
      loading,
      showPassword,
      showConfirmPassword,
      togglePasswordField,
      togglePasswordConfirmField,
      validationErrors,
    };
  },
};
</script>
<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>