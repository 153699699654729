<template>
  <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar />
    
    <!-- Main -->
    <main>
        <!-- Page Title -->
        <section class="section bg-center bg-cover effect-section" style="background-image: url(static/img/bg-1.jpg);">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">Our Pricing</h2>
                        <ol class="breadcrumb white justify-content-center">
                          <li><router-link to="/home">Home</router-link></li>
                            <li class="active">Our Pricing</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->
        
        <!-- Section -->
        <section class="section gray-bg">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-600 dark-color m-20px-b p-20px-b theme-after after-50px">Our Pricing Plans</h3>
                        <p class="m-0px font-1">Let’s Get Started with a fully integrated management system. We have the ideal Package for your Business</p>
                    </div>
                </div>
                <div class="tab-style-1">
                    <ul class="nav nav-fill nav-tabs">
                        <li class="nav-item">
                            <a href="#tab3_sec1" data-toggle="tab" class="active">
                                <div class="icon"><i class="icon-chat"></i></div>
                                <span>Monthly</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec2" data-toggle="tab">
                                <div class="icon"><i class="icon-tools"></i></div>
                                <span>Quarterly</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec3" data-toggle="tab">
                                <div class="icon"><i class="icon-megaphone"></i></div>
                                <span>Half Year</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec4" data-toggle="tab">
                                <div class="icon"><i class="icon-heart"></i></div>
                                <span>Yearly</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="tab3_sec1" class="tab-pane fade in active show">
                          <div class="row align-items-center">
                              <div v-for="monthly in monthly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec2" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in quarterly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec3" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in halfyear_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec4" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in yearly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->

    </main>
    <!-- End Main -->

    <Footer />

  </div>

    
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import { reusableMixin } from '../lib/reusableMixin';
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
// import paystack from "vue3-paystack";
import { useFlutterwave } from "flutterwave-vue3"


export default {
    components: {
      Navbar,
      Footer,
      // paystack
    },
  mixins: [reusableMixin],
    data() {
    const router = useRouter();
    const toast = useToast();
      return{
        web: true,
        monthly_plans: [],
        quarterly_plans: [],
        halfyear_plans: [],
        yearly_plans: [],
        basicAmount: '7,500',
        standardAmount: '15,000',
        plans: [],
        categories: [],
        types: [],
        details: [],
        loading: false,
        router: router,
        toast: toast,
        amount: 0,
        token: localStorage?.token,
        payment: false,
        publicKey:'pk_live_7505355c505d780e487e23eb2ae2911b8a42f09e',
        user: localStorage.vuex !== undefined && JSON.parse(localStorage?.vuex),
        planData: {
          type: 'Monthly',
          category: 'IMS',
          plan: null,
          plan_details_id: null,
          amount: 0,
          user_sub_id: null
        }
      }
        
    },
    computed: {
      reference(){
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for( let i=0; i < 10; i++ )
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
      }
    },
    methods: {
      getPricingPlans() {
        axios.get("/subscription/pricing-plans").then((response) => {
          this.monthly_plans = response.data.data.monthly
          this.quarterly_plans = response.data.data.quarterly
          this.halfyear_plans = response.data.data.halfyear
          this.yearly_plans = response.data.data.yearly
        })
      },

      onSuccessfulPayment(res) {
        this.$refs.closeModalRef.click();
        axios.post("/subscription/buy", {
          amount: this.amount/100,
          reference_number: res.reference,
          plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
          type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
          payment_type: 'paystack'

        }).then((response) => {
          this.toast.success(response.data.message);
          this.$refs.closeModalRef.click();
          setTimeout(() => {
            window.location.replace("/subscription-plan");
          }, 1000)
        })
        .catch ((err)=> {
          this.toast.error('error');
          console.log(err)
        })
      },

      onCancelledPayment() {
        console.log('cancel')
      },

      setAmount (amount) {
        if(this.token === undefined) {
          this.toast.error('signin to buy plan');
          this.router.push('/auth/login')
        }else {
          this.checkIfEligible(amount);
        }
        this.amount = parseInt(amount.replace(',', '')) * 100;
        this.payment = false;
      },

      flutterPayment() {
        useFlutterwave({
            amount: this.amount/100,
            callback(data) {
              let toast = useToast();
              let payment_data = {
                plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
                type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
                customer: data.customer,
                reference_number:data.flw_ref,
                meta:data,
                payment_type: 'Flutterwave'
              }
              this.$refs.closeModalRef.click();

              axios.post('/subscription/buy', payment_data).then((response) => {
                toast.success(response.data.message);
                setTimeout(() => {
                  window.location.replace("/subscription-plan");
                }, 1000)
              }).catch((error) => {
                this.toast.error(error.response.data.message);
              })

            },
            country: "NG",
            currency: this.user.getLoggedInUser?.code,
            customer: { email: this.user.getLoggedInUser.email, name: this.user.getLoggedInUser.fullname, phone_number: this.user.getLoggedInUser.company.phone },
            customizations: { description: "", logo: "", title: "BMAC" },
            meta: {
              user_id: this.user.getLoggedInUser.id,
              // token: 'FLW-' + this.reference
            },
            onclose() {
              console.log('close it here')
            },
            payment_options: "card",
            public_key: "FLWPUBK-9dbf5b16745932868693fead47ef7100-X",
            redirect_url: undefined,
            tx_ref: "tx_ref_" + Math.random().toString(36).substring(1, 100)
          })
      },

      checkIfEligible(amount) {
          this.payment = true;
        axios.post('/subscription/check-subscription', {
          plan_details_id: this.plans.filter(plan=> plan.amount == parseInt(amount.replace(',', '')))[0].id,
        }).then((res) => {
          this.$refs.payRef.click();
          console.log(res);
        }).catch((error) => {
          this.payment = false;
          console.log(error)
          this.toast.error(error.response.data.message);
        })
      },


      scrollTop(){
          window.scroll({
              top: 0,
              behavior: 'smooth',
          })
      }

    },

    
    mounted() {
      this.superUser()
      this.getPricingPlans()
      this.scrollTop();
    },
    mountBefore() {

    }
    
}
</script>
<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>