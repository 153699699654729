<template>
  <div>
  <div class="page-breadcrumb" id="content_wrapper">
    <div class="align-items-center">
      <div class="my-0 d-flex"></div>
        <h4 class="page-title text-capitalize">
          Welcome {{ user?.first_name }} 
          <img src="../private-assets/images/lf20_qamcb5gv.json].png" style="height: 31px"/> <br>
          <span style="font-size: 15px; right: inherit;">Referral ID: <span style="color: #004AAD">{{user?.my_referal_code}}</span></span>
        </h4>
        
      </div>
    </div>

  <div style="overflow-x: hidden">
    <div class="d-flex justify-content-between gridtop">
      
      <a
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid" style="color: white">Wallet ID<br>
             <span style="color: green"> {{ wallet.wallet_no }}</span>
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/wallet.png" style="max-height: 40px;" />
          </div>
        </div>
      </a>

      <a
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div class="pl-2">
            <div class="hometopfontgrid" style="color: white">Total Referral</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ total_referral }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/referral.png" style="max-height: 40px;" />
            <!-- <font-awesome-icon class="dashboardicon mr-2" icon="sticky-note" style="color: #DAE7FC !important; background:#004AAD" /> -->
          </div>
        </div>
      </a>

      <router-link
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        to="/all-products"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid" style="color: white">Total Products</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ total_products }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <font-awesome-icon class="dashboardicon mr-2" icon="shopping-cart" style="color: #DAE7FC !important; background:#004AAD" />
          </div>
        </div>
      </router-link>     

      <router-link
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        to="/manage-user"
        style="text-decoration: none; background-color: #051B35"
      >
        <div
          class="d-flex justify-content-between remove-margin"
          style="border-radius: 1rem"
        >
          <div class="pl-2">
            <div class="hometopfontgrid" style="color: white">Total Users</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ total_users }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/users.png" style="max-height: 30px;" />
          </div>
        </div>
      </router-link>
    </div><br>
    

    <div class="d-flex justify-content-between gridtop">
      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/manage-store"
        style="text-decoration: none;"
      >
        <div class="d-flex justify-content-between">
          <div class="pl-2">
            <div class="hometopfontgrid">Total Stores</div>
            <div class="mt-1" style="font-size: 19px;">
              {{ total_stores }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <font-awesome-icon class="dashboardicon mr-2" icon="sticky-note" style="color: #DAE7FC !important; background:#004AAD" />
          </div>
        </div>
      </router-link>
     <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/all-product-location?outofstock"
        style="text-decoration: none;"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid">Out of Stock</div>
            <div class="mt-1" style="font-size: 19px">
              {{ out_of_stock }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/low-stock.png" style="max-height: 40px;" />
            <!-- <font-awesome-icon class="dashboardicon mr-2" icon="long-arrow-alt-down" style="color: #DAE7FC !important; background:#051B35" /> -->
          </div>
        </div>
      </router-link>
      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/all-product-location?totallowstock"
        style="text-decoration: none;"
      >
        <div class="d-flex justify-content-between">
          <div class="pl-2">
            <div class="hometopfontgrid">Total Low Stock</div>
            <div class="mt-1" style="font-size: 19px">
              {{ low_stock }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/low-stock2.png" style="max-height: 40px;" />
          </div>
        </div>
      </router-link>

      <router-link
        class="makeGreen bg-white my-1 w-100 py-3 px-3 remove-margin"
        to="/expired-products?period=0"
        style="text-decoration: none;"
      >
        <div
          class="d-flex justify-content-between remove-margin"
          style="border-radius: 1rem"
        >
          <div class="pl-2">
            <div class="hometopfontgrid">Exp. products</div>
            <div class="mt-1" style="font-size: 19px">
              {{ expired_products }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/expired.png" style="max-height: 40px;" />
          </div>
        </div>
      </router-link>
    </div><br>



    <div class="d-flex justify-content-between gridtop">
      <a
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid" style="color: white">Wallet Balance</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ $filters.formatMoney(wallet_balance, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/wallet.png" style="max-height: 40px;" />
          </div>
        </div>
      </a>
      <router-link
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        :to="
          '/all-sales?todaysdate=' +
          new Date()
            .toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .split('/')
            .reverse()
            .join('-')
        "
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between" style="border-radius: 1rem">
          <div class="pl-2">
            <div class="hometopfontgrid" style="color: white">Total Revenue Today</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ $filters.formatMoney(revenue, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/revenue.png" style="max-height: 30px;" />
          </div>
        </div>
      </router-link>

      <router-link
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        to="/debtor"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div cl4ass="pl-2">
            <div class="hometopfontgrid" style="color: white">Debtors</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ $filters.formatMoney(debtors, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/debt.png" style="max-height: 35px;" />
          </div>
        </div>
      </router-link>
      <router-link
        class="makeGreen my-1 w-100 py-3 px-3 remove-margin"
        to="/creditor"
        style="text-decoration: none; background-color: #051B35"
      >
        <div class="d-flex justify-content-between">
          <div class="pl-2">
            <div class="hometopfontgrid" style="color: white">Creditors</div>
            <div class="mt-1" style="font-size: 19px; color: white">
              {{ $filters.formatMoney(creditors, company?.currency?.symbol) }}
            </div>
          </div>
          <div class="dashboardiconcontainer">
            <img src="/static/img/credit.png" style="max-height: 40px;" />
          </div>
        </div>
      </router-link>
    </div><br>

    <div v-if="sales_matrix" class="row">
      <div v-if="sales_matrix.length > 1" class="col-md-12">        
        <GChart type="ColumnChart" :data="sales_matrix" :options="sales_matrix_options"/>
      </div>
    </div><br>

    <div v-if="loading == false" class="row">
      <div v-if="sales_perstore_chart" class="col-md-9">
        <GChart v-if="sales_perstore_chart.length > 1" type="LineChart" :data="sales_perstore_chart" :options="sales_perstore_options" />
      </div>
      <div class="col-md-3">
          <router-link class="makeGreen bg-white" to="s" style="text-decoration: none;">
            <div class="bg-white besidetable px-3 d-flex justify-content-between sidegrid">
              <div class="py-3 pl-2">
                <div class="" style="font-weight: 500">Total Expenses</div>
                <div class="h3 mt-2"> {{ $filters.formatMoney(total_expenses, company?.currency?.symbol) }}</div>
              </div>
              <div class="dashboardiconcontainer py-2 mt-3 pr-3">
                <img src="/static/img/expense.png" style="max-height: 40px;" />
              </div>
            </div>
          </router-link>
          <router-link class="makeGreen bg-white my-2" to="" style="text-decoration: none;">
            <div class="bg-white besidetable px-3 rounded-lg d-flex justify-content-between sidegrid outstockmargin">
              <div class="py-3 pl-2">
                <div class="" style="font-weight: 500">Total Invoices</div>
                <div class="h3 mt-2"> {{ $filters.formatMoney(total_invoices, company?.currency?.symbol) }}</div>
              </div>
              <div class="dashboardiconcontainer py-2 mt-3 pr-3">
                <img src="/static/img/invoice.png" style="max-height: 40px;" />
              </div>
            </div>
          </router-link>
          <router-link class="makeGreen bg-white" to="" style="text-decoration: none;">
            <div class="bg-white besidetable px-3 mt-3 rounded-lg d-flex justify-content-between sidegrid">
              <div class="py-3 pl-2">
                <div class="" style="font-weight: 500">Paid Invoices</div>
                <div class="h3 mt-2">{{ $filters.formatMoney(total_paid_invoices, company?.currency?.symbol) }}</div>
              </div>
              <div class="dashboardiconcontainer py-2 mt-3 pr-3">
                <img src="/static/img/payment.png" style="max-height: 40px;" />
              </div>
            </div>
          </router-link>
        </div>
    </div><br>

    <div class="row">
      <div v-if="top_customer_chart" class="col-md-6">
        <GChart v-if="top_customer_chart.length > 1" type="AreaChart" :data="top_customer_chart" :options="top_customer_options" />
      </div>
      <div v-if="top_supplier_chart" class="col-md-6">
        <GChart v-if="top_supplier_chart.length > 1" type="AreaChart" :data="top_supplier_chart" :options="top_supplier_options" />
      </div>
    </div><br>

    <div v-if="top_selling_chart" class="row">
      <div class="col-md-12">
        <GChart v-if="top_selling_chart.length > 1" type="ComboChart" :data="top_selling_chart" :options="top_selling_options" />        
      </div>
    </div>  

    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card pr-4">
          <div class="card-body">
            <div class="d-md-flex">
              <div>
                <h6 class="card-title" style="font-weight: 600">
                  Top 10 Selling Products
                </h6>
                
              </div>
              <div class="ms-auto d-flex rounded-2">
                <select
                  class="rounded-1"
                  style="border: none; color: #004AAD"
                  @change="topProducts()"
                  v-model="period"
                >
                  <option selected style="font-size: 15px; color: #004AAD" value="daily">
                    Daily
                  </option>
                  <option style="font-size: 15px; color: #004AAD" value="weekly">
                    Weekly
                  </option>
                  <option selected style="font-size: 15px; color: #004AAD" value="lastweek">
                    Last Week
                  </option>
                  <option style="font-size: 15px; color: #004AAD" value="monthly">
                    Monthly
                  </option>
                  <option style="font-size: 15px; color: #004AAD" value="yearly">
                    Yearly
                  </option>
                </select>
                
              </div>
            </div>
          </div>
          <div v-if="loading == true" class="text-center py-5">
            <div
              class="spinner-border text-primary"
              role="status"
              style="width: 5rem; height: 5rem"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div
            class="text-center py-5"
            v-if="topproducts?.length == 0 && loading == false"
          >
            <div>
              <img src="../private-assets/images/emptypng.png" alt="" />
            </div>
            <div class="fw-bold mt-3">Your sales list is empty!</div>
          </div>
          <div v-if="loading == false && topproducts?.length > 0">
            <table class="table table-responsive px-4">
              <thead>
                <tr class="bg-light">
                  <th class="" style="width:40%">Product Name</th>
                  <th class="border-top-0" style="width:20%">Cost Price({{ company?.currency?.symbol }})</th>
                  <th class="border-top-0" style="width:20%">Selling Price({{ company?.currency?.symbol }})</th>
                  <th class="border-top-0">Quantity</th>
                  <th class="border-top-0">Created</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="product in topproducts" :key="product.id">
                  <td class="">
                    <div class="d-flex align-items-center">
                      <div class="me-2" v-if="product.image">
                        <img
                          class="tableimg"
                          :src="host.hostName + product.image"
                          alt=""
                        />
                      </div>
                      <div class="tabletext">
                        <div class="m-b-0 font-16">
                          {{ product.product_name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="tablepadding">
                    {{ $filters.formatMoney(product.cost_price) }}
                  </td>
                  <td class="tablepadding">
                    {{ $filters.formatMoney(product.selling_price) }}
                  </td>
                  <td class="tablepadding">{{ product.quantity }}</td>
                  <td class="tablepadding">{{ formatDate(product.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>

  </div>
</template>

<script>
import axios from "axios";

// import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
// import { onClickOutside } from "@vueuse/core";
import { useToast } from "vue-toastification";

// import { onMounted } from "@vue/runtime-core";
import host from "../../main";
import { reusableMixin } from '../../lib/reusableMixin';
import moment from 'moment-timezone';

import { GChart } from 'vue-google-charts'

export default {
  mixins: [reusableMixin],

  components: {
    GChart
  },

  mounted(){
    this.fetchChart();
    this.getCompany();
    this.sumInfo();
    this.countInfo();
    this.topProducts();    
  },
    
  methods: {
    async sumInfo() {
      this.period = 'monthly';
      await axios.get("/dashboard/suminfo")
        .then((response) => {
          this.revenue = response.data.data?.total_revenue_today;
          this.debtors = response.data.data.total_debtors ?? 0;
          this.creditors = response.data.data.total_creditor;
        })
        .catch(() => {
          this.toast.error("Something went wrong");
        });
    },

    async countInfo() {
      await axios.get("/dashboard/countinfo")
        .then((response) => {
          this.expired_products = response.data.data.expired_products;
          this.total_products = response.data.data.total_products;
          this.out_of_stock = response.data.data.out_of_stock;
          this.low_stock = response.data.data.low_stock;
          this.total_users = response.data.data.total_users;
          this.total_stores = response.data.data.total_stores;
          this.wallet = response.data.data.wallet;
          this.wallet_balance = response.data.data.wallet.balance;
          this.total_referral = response.data.data.total_referral;
          this.total_expenses = response.data.data.total_expenses;
          this.total_invoices = response.data.data.total_invoices;
          this.total_paid_invoices = response.data.data.total_paid_invoices;
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },

    async topProducts() {      
      this.loading = true;
      this.showToggle = !this.showToggle;
      await axios.get(`/dashboard/topproducts?period=${this.period}`)
        .then((response) => {
          this.loading = false;
          this.topproducts = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.toast.error(error.response.data.message);
        });
    },

    async fetchChart() {
      this.loading = true;
      await axios.get("/dashboard/chart")
        .then((response) => {
          this.sales_matrix = response.data.data.sales_matrix.map(Object.values);
          this.sales_perstore_chart = response.data.data.sales_per_store.map(Object.values);
          this.top_customer_chart = response.data.data.top_customers.map(Object.values);
          this.top_supplier_chart = response.data.data.top_suppliers.map(Object.values);
          this.top_selling_chart = response.data.data.top_selling_products.map(Object.values); 

          this.loading = false;
        }).catch((error) => {
          this.toast.error(error.response.data.message);
          this.loading = false;
        });
    },

    toggleBar() {
      this.showToggle = !this.showToggle;
    },

    onClickOutside() {
      this.targetToggle = true,
      this.showToggle = false;
    },

    formatDate(date) {
        return moment(date).format('MMM DD, YYYY');
    },

  },

  computed: {
        user() {
            return this.store.state.getLoggedInUser
        }

    },

    data() {
      return {
        showToggle: true,
        targetToggle: false,
        revenue: 0,
        debtors: 0,
        creditors: 0,
        low_stock: 0,
        total_expenses: 0,
        total_invoices: 0,
        total_paid_invoices: 0,
        wallet:{},
        wallet_balance: 0,
        total_referral: 0,
        total_products: 0,
        expired_products: 0,
        topproducts: [],
        period: '',
        out_of_stock: 0,
        host: host,
        loading: false,
        total_users: 0,
        total_stores: 0,
        toast: useToast(),
        store: useStore(),


        sales_matrix: [],
        sales_matrix_options: {
          title: 'Sales Performance',
            subtitle: 'Sales, Expenses, and Profit',
            // width: 800,
          height: 500,
        },

        chartData2: [
          ['City', '2010 Population', '2000 Population'],
          ['New York City, NY', 8175000, 8008000],
          ['Los Angeles, CA', 3792000, 3694000],
          ['Chicago, IL', 2695000, 2896000],
          ['Houston, TX', 2099000, 1953000],
          ['Philadelphia, PA', 1526000, 1517000],
        ],
        chartOptions2: {
          title: 'Population of Largest U.S. Cities',
          chartArea: { width: '50%' },
          hAxis: {
            title: 'Total Population',
            minValue: 0,
          },
          vAxis: {
            title: 'City',
          },
          // width: 800,
          height: 600,
        },

        sales_perstore_chart: [],
        sales_perstore_options: {
            title: 'Sales Performance Per Location',
            curveType: 'function',
            legend: { position: 'bottom' },
            // width: 800,
            height: 350,
        },

        chartData4: [
          ['Task', 'Hours per Day'],
          ['Work', 11],
          ['Eat', 2],
          ['Commute', 2],
          ['Watch TV', 2],
          ['Sleep', 7],
        ],
        chartOptions4: {
            title: 'My Daily Activities',
            // width: 800,
            height: 600,
        },

        top_customer_chart: [],
        top_customer_options: {
            title: 'Top Buying Customers',
            hAxis: { title: 'Customers', titleTextStyle: { color: '#333' } },
            vAxis: { minValue: 0 },
            chartArea: { width: '50%', height: '70%' },
            // width: 800,
            height: 400,
        },

        top_supplier_chart: [],
        top_supplier_options: {
            title: 'Top Suppliers',
            hAxis: { title: 'Suppliers', titleTextStyle: { color: '#333' } },
            vAxis: { minValue: 0 },
            chartArea: { width: '50%', height: '70%' },
            // width: 800,
            height: 400,
        },


        top_selling_chart: [],
        top_selling_options: {
          title: 'Top Selling Products',
          vAxis: { title: 'Sales' },
          hAxis: { title: 'Products' },
          seriesType: 'bars',
          series: { 5: { type: 'line' } },
          // width: 800,
          height: 600,
        },

      };
    },
};
</script>

<style scoped>
.hometopfontgrid {
  font-size: 15px;
  font-weight: 500;
}

.remove-margin {
  margin-left: 1rem;
  margin-right: 1rem;
}

.gridtop {
  overflow: auto;
  margin-left: -15px;
  width: 102.5%;
}

.sidegrid {
  /* width: 241px; */
  width: 100%;
 
}

.table-hamburger {
  cursor: pointer;
}

.toggle {
  position: absolute;
}

.innertoggle {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border-bottom: 1px solid gray;
  width: 100% !important;
}

.innertoggle:hover {
  background: rgb(216, 213, 213);
  width: 100%;
}

.table thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.tabletext {
  font-size: 18px;
  font-weight: 600;
}

.tableimg {
  height: 36px;
  border-radius: 999px;
}

.tablepadding {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.hometabletoggle {
  background: white;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  /* padding-left: 20px;
    padding-right: 20px; */
  padding-top: 3px;
  /* padding-bottom: 3px; */
  width: 114px;
}

@media (max-width: 500px) {
  .sidegrid {
    /* width: 150px !important; */
    width: 100% !important;
  }
}

@media (max-width: 1000px) {
  .gridtop {
    margin-left: -9px;
    width: 104.5%;
  }

  .sidegrid {
    width: 140px;
  }
}

/* @media (min-width: 1280px) {
  .sidegrid {
    width: 230px;
  }
}  */

@media (min-width: 1024px) {
  .outstockmargin {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1024px) {
  .sidegrid {
    width: 159px;
  }
}

@media (max-width: 767px) {
  .remove-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sidegridcontainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .sidegrid {
    width: 100%;
    /* width: 280px; */
  }

  .gridtop {
    margin-left: -3px;
    width: 100%;
  }

  .sidegrid {
    /* width: 223px; */
    width: 100%;
  }

  .outstockmargin {
    margin-left: 4px !important;
  }
}
</style>
