<template>
    <InventoryHeaderTab />

    <div class="card thecard rounded-lg mt-5 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
        <h4>Create Stock Adjustment</h4>
        <div class="text-muted" style="font-size: 10px">
            Make Stock Adjustment to your stocks
        </div>
        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                <li class="text-danger" style="list-style: none">
                    <small>{{ errors }}</small>
                </li>
            </ul>
        </div>
        <form class="mt-4">
            <div class="row ps-2">
                <div class="form-group col-md-4">
                    <label for="inputEmail4" style="">Date</label>
                    <Datepicker v-model="date" :enableTimePicker="true" class=" "
                        style="background: none; border: none"></Datepicker>
                </div>
                <div class="form-group col-md-4">
                    <label>Select Store</label>
                    <Multiselect v-model="store_id" label="name" :options="getStore" placeholder="Select store"
                        :searchable="true" :create-option="false" :close-on-select="true"
                        @select="getProductInStore(store_id)" />

                </div>
            </div>
            <div class="mt-3 overflow-auto" style="max-height:300px" id ="overflow">
                <table class="table table-sm-responsive mt-4">
                    <thead class="thead-light">
                        <tr class="pb-5">
                            <th class="font-weight-bold" style="width:20%">Product</th>
                            <th class="font-weight-bold" style="width:2%">Available Qty</th>
                            <!-- <th class="font-weight-bold">Variant</th> -->
                            <th class="font-weight-bold" style="width:8%">Variant/Unit of Measurement</th>
                            <th class="font-weight-bold" style="width:12%">Cost Price</th>

                            <th class="font-weight-bold" style="width:8%">Qty</th>
                            <th class="font-weight-bold" style="width:20%">Reason</th>
                            <th class="font-weight-bold" style="width:15%">Adjustment Type</th>
                            <th class="font-weight-bold" style="width:12%">Total</th>
                            <th class="font-weight-bold"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in product" :key="index.id">
                            <td>
                                <Multiselect v-model="p.product_id" label="name" :options="getProducts"
                                    :canClear="false" :searchable="true" :create-option="false" :close-on-select="true"
                                    @select="handleChange(p.product_id, index)" />
                            </td>
                            <td>
                                <input disabled type="text" class="form-control" v-model="p.available_qty" />
                            </td>
                            <td v-if="p.variant.length > 0">
                                <select class="form-select" style="width: 150px" v-model="p.product_variant_id"
                                    @change="getVariantCost(p)">
                                    <option disabled>Select variant</option>
                                    <option v-for="variant in p.variant" :key="variant.id" :value="variant.id">
                                        {{ variant.variant_name }}
                                    </option>
                                </select>
                            </td>
                            <td v-else>
                                <input type="text" class="form-control" min=0 :value="p.unit_of_measurement" disabled />
                            </td>
                            <td><input type="number" class="form-control" v-model="p.cost_price"
                                    @keyup="calculateAmount(p)" /></td>

                            <td><input type="text" min=0 class="form-control" v-model="p.quantity"
                                    @keyup="calculateAmount(p)" onkeypress="return /^[0-9]+$/i.test(event.key)"/></td>
                            <td>
                                <input type="text" class="form-control" v-model="p.reason">
                            </td>
                            <td>
                                <select id="inputState" class="form-select" v-model="p.adjustment_type"
                                    @change="adjustmentType(p)">
                                    <option disabled selected>Select</option>
                                    <option value="increment">Increment</option>
                                    <option value="decrement">Decrement</option>
                                </select>
                            </td>
                            <td>
                                <input disabled type="text" class="form-control" v-model="p.total_price" />
                            </td>

                            <!-- <td><span class="iconify mr-3" data-icon="ant-design:delete-outlined" style="font-size: 28px;"></span></td> -->
                            <td>
                                <button class="btn btn-danger text-white fw-bold" @click.prevent="removeProduct(index)"
                                    type="button">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div class="d-flex justify-content-end mt-2">
                    <button class="btn" style="background-color: #DAE7FC" type="button" @click="addProduct">Add Product</button>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <div class="text-primary fs-4">
                    <span class="fw-bold me-2" style="font-size: 0.8em">Grand Total:
                        {{ $filters.formatMoney(grandTotal(), currentUserCurrency?.symbol) }}
                    </span>

                </div>
            </div>


            <div class="mt-5 d-flex justify-content-end">
                <button type="submit" class="btn px-5 me-5 btn-primary text-white" v-if="crudLoading == false"
                    @click.prevent="saveAdjustment">Save</button>
                <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white"
                    disabled>
                    <span class="visually-">Processing</span>
                </button>
                <button type="button" @click.prevent="$router.go(-1)" class="btn px-5 exportbtn">Cancel</button>
            </div>
        </form>
    </div>
</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import {
    ref
} from "@vue/reactivity";
import {
    onMounted
} from "@vue/runtime-core";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";
import { useReusable } from "../../../lib/reusable";
import Multiselect from '@vueform/multiselect'
import { reusableMixin } from '../../../lib/reusableMixin';

export default {
    components: {
        InventoryHeaderTab,
        Multiselect
    },
    mixins: [reusableMixin],
    setup() {
        const crudLoading = ref(false)
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);
        const getVariants = ref("");
        const date = ref(new Date());
        const store_id = ref(null)
        const getProducts = ref([]);

        const product = ref([{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            reason: "",
            adjustment_type: "",
            total_price: (0).toFixed(2)
        },
        {
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            reason: "",
            adjustment_type: "",
            total_price: (0).toFixed(2)
        },
        {
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            reason: "",
            adjustment_type: "",
            total_price: (0).toFixed(2)
        },
        {
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            reason: "",
            adjustment_type: "",
            total_price: (0).toFixed(2)
        },
        {
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            reason: "",
            adjustment_type: "",
            total_price: (0).toFixed(2)
        }
        ]);

        const { removeProduct, validateInput, getStore, allStore } = useReusable(product)

        const addProduct = () => {
            product.value.push({
                product_id: "",
                available_qty: 0,
                cost_price: "",
                new_cost_price: "",
                selling_price: "",
                variant: [],
                product_variant_id: "",
                quantity: 1,
                reason: "",
                adjustment_type: "",
                total_price: (0).toFixed(2)
            });
        };

        const clearFields = (product) => {
            product.product_id = "",
                product.available_qty = 0,
                product.cost_price = "",
                product.new_cost_price = "",
                product.selling_price = "",
                product.variant = [],
                product.product_variant_id = "",
                product.quantity = "",
                product.reason = "",
                product.adjustment_type = "",
                product.total_price = (0).toFixed(2)
        }

        const getProductInStore = async (storeId) => {
            const getProduct = await axios.get(`${'/admin/store/product-in-store/' + storeId}`)
            let products = getProduct?.data?.data?.data

            // clear old  products
            getProducts.value = []
            products.forEach((product) => {
                getProducts.value.push({
                    value: product.id,
                    name: product.bar_code ? product.product_name + '<span style="visibility: hidden; "> - ' + product?.bar_code + '</span>' : product.product_name
                })
            })
            // Set avalaible quantity value back to zero
            product.value.forEach((p) => {
                p.available_qty = 0
                clearFields(p)
            })
        }
        const adjustmentType = (product) => {
            if (product.adjustment_type == 'decrement') {
                product.total_price = parseFloat(-Math.abs(product.total_price)).toFixed(2)
            } else {
                product.total_price = parseFloat(Math.abs(product.total_price)).toFixed(2)
            }
            return product.total_price
        }
        const saveAdjustment = async () => {
            crudLoading.value = true
            let valid_products = removeEmptyObjects(product.value)

            await axios.post("/inventory/store-adjustment", {
                store_id: store_id.value,
                time: date.value.toLocaleTimeString(),
                date: date.value.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }).split("/").reverse().join("/"),
                adjustment_item: valid_products
            })
                .then((res) => {
                    crudLoading.value = false
                    router.push("/all-stock-adjustment");
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 403) {
                        toast.error(error.response.data.message);
                    }
                    if (error.response.status == 400) {
                        validationErrors.value = error.response.data.message;
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                });
        }


        const removeEmptyObjects = (array) => {
            const newArray = [];
            array.forEach(element => {
                if (element.product_id !== "") {
                    newArray.push(element);
                }
            });

            return newArray;
        }

        const handleChange = async (id, index) => {
            // let id = evt.target.value
            const getProduct = await axios.get(`/inventory/product/${id}?store_id=${store_id.value}`)
            let single_product = getProduct?.data?.data

            // set variant value
            let variant = single_product.variants

            let cost;
            let total_cost;

            // set cost value
            cost = single_product?.cost_price
            // set total cost value
            total_cost = single_product?.cost_price

            let selling_price = single_product?.selling_price

            // Check if product already selected
            let occurences = product.value.filter((v) => (v.product_id == single_product.id))

            if (occurences.length > 1) {
                product.value[index].product_id = ""
                product.value[index].cost_price = parseFloat(0).toFixed(2)
                product.value[index].new_cost_price = 0
                product.value[index].selling_price = (0).toFixed(2)
                product.value[index].available_qty = 0
                product.value[index].total_price = parseFloat(0).toFixed(2)
                product.value[index].product_variant_id = ''
                product.value[index].quantity = 0
                toast.warning("Product already selected");

                return
            }

            let empty_product = product.value.filter((v) => (v.product_id == ""))
            if(empty_product.length == 0){
                addProduct()
            }

            // set available quantity value
            let available_qty = single_product?.store_qty
            product.value[index].cost_price = parseFloat(cost).toFixed(2)
            product.value[index].new_cost_price = cost
            product.value[index].selling_price = selling_price
            product.value[index].available_qty = available_qty
            product.value[index].total_price = parseFloat(total_cost).toFixed(2)
            product.value[index].variant = variant
            product.value[index].unit_of_measurement = single_product?.unit_of_measurement
            product.value[index].product_variant_id = ''
            product.value[index].quantity = 1

        }

        const getVariantCost = (p) => {
            let variant = p.variant.find(({
                id
            }) => id == p.product_variant_id)

            let qty = p.quantity == "" ? 0 : p.quantity
            let variant_qty = variant.variant_quantity
            p.cost_price = (p.new_cost_price * parseFloat(variant_qty)).toFixed(2)//cost_prices[0]//parseFloat(variant_selling_price)//parseInt(variant_selling_price) + parseInt(p.total_price)
            p.total_price = (p.cost_price * parseFloat(qty)).toFixed(2)//parseInt(variant_selling_price) + parseInt(p.total_price)
            adjustmentType(p)
        }

        const calculateAmount = (p) => {
            if (p.variant.length > 0) {
                p.total_price = (parseFloat(p.cost_price) * parseFloat(p.quantity)).toFixed(2)
            } else {
                p.total_price = (parseFloat(p.cost_price) * parseFloat(p.quantity)).toFixed(2)
            }

            if (isNaN(p.total_price)) {
                p.total_price = 0
            } else {
                adjustmentType(p)
            }
        }
        const grandTotal = () => {
            return product.value.reduce((a, b) => {
                return (parseFloat(a) + parseFloat(b.total_price)).toFixed(2);
            }, 0);
        }

        onMounted(async () => {
            allStore()

            var element = document.getElementById("overflow");
            element.scrollTop = element.scrollHeight;
        });

        return {
            grandTotal,
            crudLoading,
            getStore,
            getProducts,
            getVariants,
            date,
            product,
            addProduct,
            removeProduct,
            saveAdjustment,
            handleChange,
            validationErrors,
            store_id,
            getVariantCost,
            getProductInStore,
            validateInput,
            adjustmentType,
            calculateAmount
        };
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .thebtn {
        margin-right: 5px !important;
    }
}
</style>
