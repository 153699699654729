<template>

<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- section -->
        <section class="section bg-cover bg-no-repeat effect-section" style="background-image: url('/static/img/banner-bg-5.jpg');">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center justify-content-lg-between align-items-center">
                    <div class="col-sm-8 col-lg-6 col-xl-5 order-lg-2">
                        <div class="card box-shadow-lg">
                            <div class="card-body">
                                <h3 class="font-w-600 dark-color">Login</h3>
                                <p>Sign in to your account to continue.</p>
                                <div>
                                  <div class="" v-for="errors in validationErrors" :key="errors.id">
                                    <div class="text-danger text-center">
                                      {{ errors }}
                                    </div>
                                  </div>
                                </div>
                                <form @submit.prevent="loginUser">
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Email Address</label>
                                        <input type="email" class="form-control" v-model="email" placeholder="Email Address">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Password</label>
                                        <input type="password" class="form-control" v-model="password" placeholder="********">
                                    </div>
                                    <div class="p-5px-t">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <button  v-if="loading == false" type="submit" class="m-btn m-btn-radius m-btn-theme">Sign in</button>
                                            <button  v-if="loading == true"  disabled class="m-btn m-btn-radius m-btn-theme">
                                              <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Processing</span>
                                              </div>
                                            </button>
                                            <router-link to="/auth/reset-password" class="small font-w-600" href="#">Forgot Password?</router-link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer"><small>Not registered?</small>
                                <router-link to="/auth/register" class="small font-w-600">Create account</router-link></div>
                        </div>
                    </div>
                    <div class="col-lg-5 order-lg-1 d-none d-lg-block">
                        <blockquote class="blockquote">
                            <h3 class="white-color font-w-500 h2 m-15px-b">Automate inventory processes, receive reports at every stage, eliminate spreadsheets, and save both time and money.</h3><br>
                            <footer class="blockquote-footer white-color-light" style="color: #fff">Track, Control and Optimize with <cite title="Source Title">InventFlux</cite></footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </section>
        <!-- end section -->
    </main>
    <!-- main end -->

    <Footer />

</div>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ref } from "@vue/reactivity";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";


export default {
  components: {
        Navbar,
        Footer,
    },

  setup() {
    const email = ref("");
    const password = ref("");
    const validationErrors = ref("");
    const router = useRouter();
    const toast = useToast();
    const loading = ref(false);
    const showPassword = ref(true);


    const toggleShowHide = () => {
      showPassword.value = !showPassword.value;
    };

    const loginUser = async () => {
      if(email.value.length == 0 || password.value.length == 0) {
        return
      }else {
          loading.value = true;
          await axios
            .post("/login-web", {
              email: email.value,
              password: password.value,
            })
            .then((response) => {
              loading.value = false;
              localStorage.setItem(
                "token",
                response.data.data.original.accessToken
              );


              axios.defaults.headers[
                "Authorization"
              ] = `Bearer ${response.data.data.original.accessToken}`;
              router.push("/");
              toast.success("Login successful");
            })
            .catch((error) => {
              loading.value = false;
              if (error.response.status == 400) {
                toast.error("There are errors in your input");
                validationErrors.value = error.response.data.message;
              }
              if (error.response.status == 401) {
                toast.error(error?.response?.data?.message)
              }
              if (error.response.status == 402) {
                router.push('send-email-verifcation')
                toast.error(error?.response?.data?.message)
              }
              if (error.response.status == 500) {
                toast.error("Please recheck your login details");
              }
            });
      
      }

    };

    return {
      loginUser,
      email,
      password,
      router,
      toast,
      loading,
      toggleShowHide,
      showPassword,
      validationErrors,
    };
  },

};
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>