<template>
    <div>
        <div class="col main_col">
            <div class="pos_main">
                <div class="main_display px-2 py-4">
                    <h5>Subscription Plan</h5>
                    <div class="d-flex justify-content-center">
                        <div class="filedisplay_cont">
                            <div class="file_display">
                                <button @click="printInvoice('A4', '21px', 'subscription-invoice')" >Print</button>
                                <button @click="createPDF('subscription-invoice')">Download as PDF</button>
                                <!-- <button>Send as Email</button> -->
                            </div>
                            <div v-if="loading == true" class="text-center py-5">
                                <div class="spinner-border text-primary" role="status"
                                    style="width: 5rem; height: 5rem">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div class="file_content pt-5 d-flex justify-content-center"
                                v-if="loading == false">
                                <div class="file_width" id="printMe">
                                    <div class="d-flex file_logo justify-content-between align-items-center">
                                        <!-- <img src="./assets/bmac.png" alt="" width="99px" height="41px" /> -->
                                        <div class="text-end">
                                            <h6>Invoice</h6>
                                            <h6>{{ subscriptions?.invoice_number }}</h6>
                                        </div>
                                    </div>
                                    <div class="row file_address justify-content-evenly">
                                        <div class="col">
                                            <div>
                                                <div class="text-start">
                                                    <h6><b>{{ currentUser?.business?.company_name }}</b></h6>
                                                    <h6>{{ currentUser?.business?.address }},</h6>
                                                </div>
                                                <div class="text-start mt-3">
                                                    <h6><b>Telephone:</b></h6>
                                                    <h6>{{currentUser?.business?.phone}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col add_center d-flex justify-content-end">
                                            <div>
                                                <div class="text-start">
                                                    <h6><b>Bill To:</b></h6>
                                                    <h6> {{ currentUser?.business?.company_name }}</h6>
                                                    <h6>{{ currentUser?.business?.address }},</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col add_center d-flex justify-content-end">
                                            <div>
                                                <div class="text-start">
                                                    <h6>Issue Date</h6>
                                                    <h6>Due Date</h6>
                                                    <h6>Amount Due</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div>
                                                <div class="text-end">
                                                    <h6>{{ dateTime(subscriptions?.payment_date) }} </h6>
                                                    <h6>{{ dateTime(subscriptions?.expiry_date) }}</h6>
                                                    <h6>{{ $filters.formatMoney(subscriptions?.plan_detail?.amount, currentUserCurrency?.symbol) }}</h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="summary">
                                        <div class="d-flex justify-content-between align-items-center pt-5 pb-3">
                                            <h2>Summary</h2>
                                            <h2>Amount</h2>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center py-5">
                                            <h3 style="text-transform:capitalize" v-if="!subscriptions?.user?.trial_subscription">{{ subscriptions?.user?.trial_subscription == '14 days trial' ? subscriptions?.user?.trial_subscription : subscriptions?.plan_detail?.category?.name}} ({{ subscriptions?.plan_detail?.type == 'Monthly' ? 'monthly' : subscriptions?.plan_detail?.type == 'Quarterly' ? '3 months' : subscriptions?.plan_detail?.type == 'Half Year' ? 'six months' : subscriptions?.plan_detail?.type == 'Yearly' ? 'year' : '' }})</h3>
                                            <h3 v-else> {{ subscriptions?.user?.trial_subscription }} </h3>
                                            <h3>
                                                {{ $filters.formatMoney(subscriptions?.plan_detail?.amount, currentUserCurrency?.symbol) }}
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="d-flex total justify-content-end">
                                        <div class="total_width">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="text-start">Amount</h6>
                                                <h6 class="text-end">{{ $filters.formatMoney(subscriptions?.plan_detail?.amount, currentUserCurrency?.symbol) }}</h6>
                                            </div>
                                            <!-- <div class="d-flex my-4 align-items-center justify-content-between">
                                                <h6 class="text-start">VAT</h6>
                                                <h6 class="text-end">N500.00</h6>
                                            </div> -->
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="text-start"><b>Total</b></h6>
                                                <h6 class="text-end"><b>{{ $filters.formatMoney(subscriptions?.plan_detail?.amount, currentUserCurrency?.symbol) }}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="margin-top: 150px;">
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from '@vue/reactivity';
import { onMounted } from "@vue/runtime-core";
import { reusableMixin } from '../../../lib/reusableMixin';
// import { useToast } from "vue-toastification";
import axios from "axios";
import {
     useRoute
} from "vue-router";

export default {
    mixins: [reusableMixin],
    setup() {
        const subscriptions = ref()
        // const toast = useToast();
        const loading = ref(false)
        const route = useRoute()

        const printInvoice =(width, fontsize = "", title = "") =>{
            var name = `${title}`
            var mywindow = window.open('', 'PRINT', 'height=500,width=700');

            mywindow.document.write(`<html><head> <style>
            
                @page { size: ${width}, }
                @media print {
                    body {
                        font-size: ${fontsize};
                        margin: 20mm 8mm 20mm 8mm;  
                    }
                    
                }
                
                .text-center{
                    text-align:center;
                }
                .text-start{text-align:left!important}
                .pt-5{padding-top:3rem!important}
                .mt-1 {
                    margin-top: .25rem !important
                }
                .row{--bs-gutter-x:1.5rem;--bs-gutter-y:0;display:flex;flex-wrap:wrap;margin-top:calc(var(--bs-gutter-y) * -1);margin-right:calc(var(--bs-gutter-x) * -.5);margin-left:calc(var(--bs-gutter-x) * -.5)}

                .justify-content-evenly{justify-content:space-evenly!important}
                .align-items-center{align-items:center!important}
                    .justify-content-center{justify-content:center!important}
                
                    .justify-content-between {
                        justify-content: space-between !important
                    }
                    .d-flex {
                        display: flex !important
                    }
                    .justify-content-end {
                        justify-content: flex-end !important
                    }
              
                    .mt-3 {
                        margin-top: 1rem !important
                    }
                    .py-5{padding-top:3rem!important;padding-bottom:3rem!important}
                    .pb-3{padding-bottom:1rem!important}
                    .pt-5{padding-top:3rem!important}
                    .col{flex:1 0 0%}

.filedisplay_cont {
    width: 80%;
}

.file_display {
    margin: 30px 0;
}

.file_display button {
    background: #FFFFFF;
    border: 1px solid #CEFAE4;
    border-radius: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #393A3D;
    width: 150px;
    padding: 8px 0;
    margin-right: 10px;
}

.file_display button:hover {
    background: #037B40;
    color: white;
}

.file_content {
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    margin-left: 40px;
    /* font-family: 'Manrope'; */
}

.file_width {
    width: 90%;
}

.file_logo h6 {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.file_address {
    margin: 50px 0 !important;
}

.file_address .add_center div {
    width: 80%;
}

.summary div {
    border-top: 1px solid#C6C6C6;
    border-bottom: 1px solid #C6C6C6;
}

.summary div:nth-child(even) {
    border-top: none !important;
}

.summary h2 {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.summary h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #626262;
    margin-bottom: 0 !important;
}

.total {
    margin: 30px 0;
}

.total h6 {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.total_width {
    width: 200px;
}
            </style><title>${name}</title>`);
            mywindow.document.write('</head><body>');
            mywindow.document.write(document.getElementById('printMe').innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true
        }
        const activeSubscription = () => {
            loading.value = true
            axios.get("/subscription/user/"+route.params.id)
                .then((response) => {
                    loading.value = false
                    subscriptions.value = response.data.data
                }).catch(() => {
                    loading.value = false
                })
        }



        onMounted(() => activeSubscription());


        return { subscriptions, loading, printInvoice}
    }
}
</script>

<style scoped>
/* design2 */
.filedisplay_cont {
    width: 80%;
}

.file_display {
    margin: 30px 0;
}

.file_display button {
    background: #FFFFFF;
    border: 1px solid #CEFAE4;
    border-radius: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #393A3D;
    width: 150px;
    padding: 8px 0;
    margin-right: 10px;
}

.file_display button:hover {
    background: #004AAD;
    color: white;
}

.file_content {
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    margin-left: 40px;
    /* font-family: 'Manrope'; */
}

.file_width {
    width: 90%;
}

.file_logo h6 {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.file_address {
    margin: 50px 0 !important;
}

.file_address .add_center div {
    width: 80%;
}

.summary div {
    border-top: 1px solid#C6C6C6;
    border-bottom: 1px solid #C6C6C6;
}

.summary div:nth-child(even) {
    border-top: none !important;
}

.summary h2 {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.summary h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #626262;
    margin-bottom: 0 !important;
}

.total {
    margin: 30px 0;
}

.total h6 {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.total_width {
    width: 200px;
}
</style>