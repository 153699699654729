<template>
    <InventoryHeaderTab />
    
    <div class="mt-3 gridtop">
        <!-- <div class="mt-2 px-5" style="font-size: 14px;">Stock Adjustment</div> -->
        <div class="d-flex justify-content-between  my-3">
            <div class="mt-2 fw-bold" style="font-size:20px">
                <span @click="$router.go(-1)" style="cursor:pointer">
                    <font-awesome-icon class="arrowradius ms-1" prefix="far" icon="angle-left" />
                </span>
                {{store_adjustment?.batch_code}}
            </div>
            <PrintButtonHeader :title="'Invoice-'+store_adjustment?.batch_code" :urlA4="'/view-stock-adjustment/'" :url58mm="'/print58mm-stock-Adjustment/'" :url80mm="'/print80mm-stock-Adjustment/'" :currentUrl="'Print 80mm Stock Adjustment'" :data="store_adjustment" :type="'Stock Adjustment'"/>
    

        </div>
        
        
        <div class="bg-white rounded-lg mt-4 mb-3 px-4 py-3" id="printMe">
            <div v-if="loading == true" class="text-center py-5">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            
            <div v-if="!loading">
                <div class="d-flex justify-content-between my-5 headercreate" style="font-size: 15px;">
                    <div class="text-secondary">DATE: <span class=" text-dark fw-bold">{{dateTime(store_adjustment?.date)}}</span></div>
                    <div class="text-secondary">STOCK ADJUSTMENT NUMBER: <span class="text-dark fw-bold">{{store_adjustment?.batch_code}}</span></div>
                    <div class="text-secondary">STORE: <span class="text-dark fw-bold">{{store_adjustment?.store?.store_name}}</span></div>
                    <div class="text-secondary">ADJUSTED BY: <span class="text-dark fw-bold">{{store_adjustment?.user?.fullname}}</span></div>
                </div>
                <div class="">
                    <table class="table table-sm-responsive">
                        <thead class="thead-light" style="border-style: hidden !important;">
                            <tr class="pb-5 text-center">
                                <th class="fw-bold" style="font-size: 14px;">S/N</th>
                                <th class="fw-bold" style="font-size: 14px;">Products</th>
                                <th class="fw-bold" style="font-size: 14px;">Cost Price({{ currentUserCurrency?.symbol }})</th>
                                <th class="fw-bold" style="font-size: 14px;">Quantity</th>
                                <th class="fw-bold" style="font-size: 14px;">Variant</th>
                                <th class="fw-bold" style="font-size: 14px;">Reason</th>
                                <th class="fw-bold" style="font-size: 14px;">Adjustment Type</th>
                                <th class="fw-bold" style="font-size: 14px;">Total({{ currentUserCurrency?.symbol }})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center" v-for="(sa, index) in adjustment_items" :key="sa.id">    
                                <th class="text-secondary" style="font-size: 16px;"> {{index+1}} </th>
                                <td class="text-secondary" style="font-size: 16px;">{{sa?.product?.product_name}}</td>
                                <td class="text-secondary" style="font-size: 16px;"> {{$filters.formatMoney(sa?.cost_price)}}</td>
                                <td class="text-secondary" style="font-size: 16px;">{{sa?.quantity}}</td>
                                <td class="text-secondary" style="font-size: 16px;">{{sa?.variant?.variant_name ?? sa?.product?.unit_of_measurement}}</td>
                                <td class="text-secondary" style="font-size: 16px;">{{sa?.reason}}</td>
                                <td class="text-secondary" style="font-size: 16px;">{{sa?.adjustment_type == 1 ? 'Increase' : 'Decrease'}}</td>
                                <td class="text-secondary" style="font-size: 16px;"> 
                                    <span :class="sa?.adjustment_type == 0 ? 'text-danger' : ''">{{$filters.formatMoney(sa?.total_price)}}
                                    </span>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div class="line"></div>
                <div class="mt-4 d-flex justify-content-end">
                    <div class="me-3 fw-bold" style="font-size:19px">Grand Total:</div>
                    <span style="font-size:19px"  :class="sa?.adjustment_type == 0 ? 'text-danger' : ''">
                        {{$filters.formatMoney(totalPrice)}}
                    </span>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    
    <script>
    import InventoryHeaderTab from './component/inventoryheadertab.vue'
    import {
        useToast
    } from "vue-toastification";
    import axios from "axios";
    import jsPDF from "jspdf";
    import html2canvas from "html2canvas"
    import {reusableMixin} from "../../../lib/reusableMixin";
    
    export default {
        components: {
            InventoryHeaderTab
        },
        mixins: [reusableMixin],
    
        data() {
            return {
                toast: useToast(),
                store_adjustment: null,
                adjustment_items: [],
                loading:false
            }
        },
        methods: {
            createPDF(batch_code) {
                var pdf = new jsPDF({
                    unit: 'pt',
                    orientation: 'l',
                    lineHeight: 1.2
                });
    
                var element = document.getElementById('pdf');
                var width = element.style.width;
                var height = element.style.height;
                html2canvas(element).then(canvas => {
                    var image = canvas.toDataURL('image/png');
                    pdf.addImage(image, 'JPEG', 0, 0, width, height);
                    pdf.save(batch_code + '.pdf');
                });
            },
    
            getStockAdjustment() {
                this.loading = true
                axios.get(`/inventory/store-adjustment/${this.$route.params.id}`).then((response) => {
                    this.loading = false
                    this.store_adjustment = response.data.data
                    this.adjustment_items = response.data.data.adjustment_item
    
                }).catch(() => {
                    this.loading = false
                    this.toast.error("Oops, Something went wrong")
                })
            }
        },
        computed: {
            totalPrice() {
                let total = this.adjustment_items.reduce((a, b) => {
                    return a + parseFloat(b.total_price);
                }, 0);
                return (total).toFixed(2);
            }
        },
        created() {
            this.getStockAdjustment()
        }
    
    }
    </script>
    
    <style scoped>
    .active-button{
        background-color: green;
    }
    </style>
    