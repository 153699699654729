<template>

<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- section -->
        <section class="section bg-cover bg-no-repeat effect-section" style="background-image: url('/static/img/banner-bg-5.jpg');">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-8 col-lg-6 col-xl-5 order-lg-2">
                        <div class="card box-shadow-lg">
                            <div class="card-body">
                                <h3 class="font-w-600 dark-color">Email Verification</h3>
                                <p>We will send you a verification link.</p>
                                <div class="d-flex justify-content-center">
                                  <div class="">
                                    <div
                                      class="col-md-6 w-100"
                                      v-for="errors in validationErrors"
                                      :key="errors.id"
                                    >
                                      <div class="text-danger">{{ errors }}</div>
                                    </div>
                                  </div>
                                </div>
                                <form @submit.prevent="resetPasswordMail">
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Email Address</label>
                                        <input type="email" v-model="email" class="form-control">
                                    </div>
                                    <div class="p-5px-t">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <button v-if="loading == false" type="submit" class="m-btn m-btn-radius m-btn-theme">Send Verification Link</button>
                                            <button v-if="loading == true" disabled class="m-btn m-btn-radius m-btn-theme">
                                              <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Processing</span>
                                              </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer"><small>Back to </small>
                              <router-link to="/auth/login" class="small font-w-600"> Login?</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End section -->
    </main>
    <!-- main end -->

    <Footer />

</div>

</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

export default {
  components: {
        Navbar,
        Footer,
    },

  setup() {
    const email = ref("");
    const validationErrors = ref("");
    const toast = useToast();
    const loading = ref(false);
    const router = useRouter();

    const resetPasswordMail = async () => {
      loading.value = true;

      await axios
        .post("/send-verification-email", {
          email: email.value,
          type:"web"
        })
        .then(() => {
          loading.value = false;
          toast.success("Email verification link has been sent to your mail");
          router.push("/auth/login");
          
        })
        .catch((error) => {
          if (error.response.status == 422) {
            toast.error("There is error in your input");
            validationErrors.value = error.response.data.message;
          }
          // if (error.response.status == 403) {
            toast.error(error.response.data.message);
          // }
          // if (error.response.status == 403) {
          //   toast.error("Email not found!");
          // }
          // if (error.response.status == 500) {
          //   toast.error("An error occured, please try again!");
          // }
          loading.value = false;
        });
    };

    return { email, resetPasswordMail, loading, validationErrors };
  },
};
</script>
<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>