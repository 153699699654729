<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

   <!-- Main -->
   <main>
        <!-- Page Title -->
        <section class="section bg-center bg-cover bg-fixed effect-section" style="background-image: url(static/img/banner-bg-5.jpg);">
            <div class="mask theme-bg opacity-9"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">Our Blog Posts</h2>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li class="active">Our Blog Posts</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- En Page Title -->
        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div v-for="blog in details" :key="blog.id" class="col-md-6 col-lg-4 m-30px-b">
                        <div class="hover-top card box-shadow-only-hover overflow-hidden">
                            <div>
                                <router-link :to="'/blog/'+blog.slug">
                                    <img :src="url.hostName+'images/blog/'+blog.image" title="" alt="">
                                </router-link>
                            </div>
                            <div class="p-20px">
                                <label class="font-small">{{blog.category?blog.category.name:''}}</label>
                                <h5 class="m-10px-b font-w-600"><router-link class="dark-color" :to="'/blog/'+blog.slug">{{blog.title}}</router-link></h5>
                                <p>{{strip(blog.description).substring(1, 70) + "..."}}</p>
                                <div class="nav font-small border-top-1 border-color-dark-gray p-15px-t">
                                    <router-link class="m-15px-r body-color font-w-500" :to="'/blog/'+blog.slug"><i class="fas fa-calendar-alt "></i> {{formatDate(blog.created_at)}}</router-link>
                                    <router-link class="body-color font-w-500" :to="'/blog/'+blog.slug"><i class="fas fa-comments"></i> {{blog.comments.length}}</router-link>
                                    <router-link class="body-color font-w-500 ml-auto" :to="'/blog/'+blog.slug">Read More <i class="fas fa-chevron-right"></i></router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <pagination :pagination="pagination"
                        @prev="pageData(pagination.prevPageUrl)"
                        @next="pageData(pagination.nextPageUrl)" v-if="details.length > 0">
                    </pagination>

                    <!-- <pagination style="float: right" :pagination="pagination" @prev="pageData(pagination.prevPageUrl)"
                      @next="pageData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination> -->
                    <!-- <div class="col-12 p-30px-t">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1"><i class="fas fa-chevron-left"></i></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#"><i class="fas fa-chevron-right"></i></a>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </section>
        <!-- End Section -->
    </main>
    <!-- main end -->

    <Footer />

</div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import moment from 'moment-timezone';
import hostName from '../main';


export default{
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            details: [],
            loading: true,
            url: hostName,
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },

        async pageData() {
            await axios.get("/admin/active-blog").then((response) => {
                this.details = response.data.data.data;
                this.configPagination(response.data.data);
                this.loading = false;
            })
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        formatDate(date) {
            return moment(date).format('MMM DD, YYYY');
        },

        strip(html){
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    },

    watch: {
        query() {
            this.pageData()
        },
    },

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>