<template>
<InventoryHeaderTab />

<div class="mt-3 gridtop">
    <!-- <div class="mt-2 px-5" style="font-size: 14px;">Stock Adjustment</div> -->
    <div class="d-flex justify-content-between  my-3">
        <div class="mt-2 fw-bold" style="font-size:20px">
            <router-link to="/all-sales">
                <font-awesome-icon class="arrowradius ms-1" prefix="far" icon="angle-left" />
            </router-link>
            {{sales?.batch_code}}
        </div>

        <div class="text-center py-5" v-if="sales_items.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">Your sale list is empty!</div>
        </div>
        <div class="d-flex gridtop">
            <div class="me-2">
                <button class="btn px-5" style="background:white; border:1px solid gray;" @click="createPDF(sales?.batch_code)">Download as PDF</button>
            </div>
            <div class="d-flex">
                <router-link to="/print-stock-adjustment">
                    <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
                        Print 58mm
                    </button>
                </router-link>
            </div>
            <div class="d-flex">
                <router-link to="/print-stock-adjustment">
                    <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
                        Print 80mm
                    </button>
                </router-link>
            </div>
            <div class="d-flex">
                <router-link :to="'/sales-receipt/'+sales?.id">
                    <button class="btn px-5 mr-2" style="background:white; border:1px solid gray;">
                        Print A4
                    </button>
                </router-link>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-4 mb-3 px-4 py-3" id="pdf">
        <div class="d-flex justify-content-between my-5 headercreate" style="font-size: 15px;">
            <div class="text-secondary">Date: <span class=" text-dark fw-bold">{{dateTime(sales?.date)}}</span></div>
            <div class="text-secondary">SALES NUMBER: <span class="text-dark fw-bold">{{sales?.batch_code}}</span></div>
            <div class="text-secondary">STORE: <span class="text-dark fw-bold">{{sales?.store?.store_name}}</span></div>
            <div class="text-secondary">CUSTOMER: <span class="text-dark fw-bold">{{sales?.customer?.customer_name}}</span></div>
            <div class="text-secondary">SOLD BY: <span class="text-dark fw-bold">{{sales?.user?.fullname}}</span></div>
        </div>
        <div class="">
            <table class="table table-sm-responsive">
                <thead class="thead-light" style="border-style: hidden !important;">
                    <tr class="pb-5 text-center">
                        <th class="fw-bold" style="font-size: 14px;">S/N</th>
                        <th class="fw-bold" style="font-size: 14px;">Products</th>
                        <th class="fw-bold" style="font-size: 14px;">Selling Price</th>
                        <th class="fw-bold" style="font-size: 14px;">Variant</th>
                        <th class="fw-bold" style="font-size: 14px;">Quantity</th>
                        <th class="fw-bold" style="font-size: 14px;">Tax</th>
                                                <th class="fw-bold" style="font-size: 14px;">Discount</th>
                        <!-- <th class="fw-bold" style="font-size: 14px;">Reason</th> -->
                        <!-- <th class="fw-bold" style="font-size: 14px;">Adjustment Type</th> -->
                        <th class="fw-bold" style="font-size: 14px;">Total (₦)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(p, index) in sales_array" :key="p.id">
                        <th class="text-secondary" style="font-size: 16px;"> {{index+1}} </th>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.product_name}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.selling_price}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.variant_name ?? 'N/A'}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.quantity}}</td>
                        <!-- <td class="text-secondary" style="font-size: 16px;">{{totalTax(p?.product?.tax, p)}}</td> -->
                        <td class="text-secondary" style="font-size: 16px;">{{p?.tax}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.discount}}</td>

                        <td class="text-secondary" style="font-size: 16px;">{{p?.total}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <div class="me-3 fw-bold" style="font-size:19px">Grand Total:</div>
            <span style="font-size:19px">₦{{grandTotal}}</span>
        </div>
    </div>
</div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            sales: null,
            grand_total:0,
            tax_amount:0,
            sales_items: [],
            sales_array:[]
        }
    },
    methods: {
        
        createPDF(batch_code) {
            var pdf = new jsPDF({
                unit: 'pt',
                orientation: 'l',
                lineHeight: 1.2
            });

            var element = document.getElementById('pdf');
            var width = element.style.width;
            var height = element.style.height;
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'JPEG', 0, 0, width, height);
                pdf.save(batch_code + '.pdf');
            });
        },

        getSale() {
            axios.get(`/inventory/sales/${this.$route.params.id}`).then((response) => {
                this.sales = response.data.data
                this.sales_items = response.data.data.sales_item
                this.sales_items.forEach((s)=>{
                let tax = s?.product?.tax.reduce(function (sum, tax) {return parseFloat(sum) + (s?.selling_price * parseFloat(tax?.tax_percentage)/100)}, 0)
                this.sales_array.push({
                    "product_name":s?.product?.product_name,
                    "selling_price":s?.selling_price,
                    "variant_name":s?.variant?.variant_name ?? 'N/A',
                    "quantity":s?.quantity,
                    "tax":tax,
                    "discount":s?.discount,
                    "total":((s?.selling_price * s?.quantity)+tax) - s?.discount

                })

            })

            }).catch(() => {
                this.toast.error("Oops, Something went wrong")
            })
        },
        totalTax(taxes, sale){
            
            return taxes.reduce(function (sum, tax) {
                return parseFloat(sum) + (sale?.selling_price * parseFloat(tax.tax_percentage)/100)
            }, 0);
        },
  
    },
    computed:{
        grandTotal(){
            return this.sales_array.reduce((a, b) => {
                return a+b.total
            }, 0);
        }
    },

    created() {
        this.getSale()
    }

}
</script>

<style>

</style>
