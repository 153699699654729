<template>
  <SalesHeaderTab />
  
  <div class="mt-3 gridtop">
    <div class="bg-white rounded-lg mt-3 mx-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto;">
      <div>
        <h4>Make Sales</h4>
        <div class="text-muted" style="font-size: 10px;">Sell to your customer</div>
      </div>
      <form action="" class="mt-4">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="inputEmail4">Date</label>
            <input type="date" class="form-control">
          </div>
          <div class="form-group col-md-4">
            <label>Store</label>
            <select id="inputState" class="form-select">
              <option selected>Select store</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Customer</label>
            <select id="inputState" class="form-select">
              <option selected>Select Customer</option>
            </select>
          </div>
        </div>
      </form>
      <div class="card sales-receipt">
        <table class="table table-sm-responsive">
          <thead class="thead-light">
            <tr class="">
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Product</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Available Qty</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Variant</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Quantity</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Price</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Discount</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Apply Tax</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;">Amount</th>
              <th class="text-secondary font-weight-bold" style="font-size: 14px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select id="inputState" class="form-select">
                  <option selected>Select Product</option>
                </select>
              </td>
              <td><input disabled type="text" value="0" class="form-control"></td>
              <td>
                <select class="form-select">
                  <option selected>Select Variant Name</option>
                </select>
              </td>
              <td><input type="text" class="form-control"></td>
              <td><input type="text" class="form-control"></td>
              <td><input type="text" class="form-control"></td>
              <td><input type="text" class="form-control"></td>
              <td><input type="text" class="form-control"></td>
              <td><span class="iconify mr-3" data-icon="ant-design:delete-outlined" style="font-size: 28px;"></span></td>
            </tr>
          </tbody>
        </table>
        <div class="form-group d-flex justify-content-end px-3">
          <button class="btn variantproduct">Add Variant</button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <div class="d-flex justify-content-between gridtop">
          <div class="mr-3">
            <span class="font-weight-bold ms-3">Total Amount</span>
            <span>0.00</span>
          </div>
          <div class="mr-3">
            <span class="font-weight-bold ms-3">Total Discount</span>
            <span>0.00</span>
          </div>
          <div class="mr-3">
            <span class="fw-bold ms-3">Total VAT</span>
            <span>0.00</span>
          </div>
          <div class="text-primary">
            <span class="fw-bold ms-2">Grand Total</span>
            <span>0.00</span>
          </div>
        </div>
      </div>
      <form action="" class="mt-5">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="inputEmail4">Select Payment Type</label>
            <select id="inputState" class="form-select">
              <option selected>Select Payment Type</option>
              <option value="">Cash</option>
              <option value="">Card</option>
              <option value="">Transfer</option>
              <option value="">Part Payment</option>
              <option value="">Part Payment</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Paid Amount</label>
            <input type="text" class="form-control" disabled>
          </div>
          <!-- <div class="form-group col-md-4">
            <label>Change</label>
            <input type="text" class="form-control" disabled>
          </div> -->
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <button type="submit" class="btn px-5 me-5 btn-success text-white">Make Sales</button>
          <button type="submit" class="btn px-5 btn-secondary">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SalesHeaderTab from './component/salesheadertab.vue';
export default {
  components: { SalesHeaderTab }
}
</script>

<style>

</style>