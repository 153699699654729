<template>

<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- section -->
        <section class="section bg-cover bg-no-repeat effect-section" style="background-image: url('/static/img/banner-bg-5.jpg');">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-sm-8 col-lg-6 col-xl-5 order-lg-2">
                        <div class="card box-shadow-lg">
                            <div class="card-body">
                                <h3 class="font-w-600 dark-color">Welcome to InventFlux</h3>
                                <p>Complete the form to sign up with us.</p>
                                <div class="">
                                  <ul class="col-md-12" v-for="(errors, index) in validationErrors" :key="index">
                                    <li class="text-danger">{{ errors }}</li>
                                  </ul>
                                </div>
                                <form @submit.prevent="registerUser">
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">First Name <span style="color: brown">*</span></label>
                                        <input type="text" class="form-control" v-model="first_name" name="first_name" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Last Name <span style="color: brown">*</span></label>
                                        <input type="text" class="form-control" v-model="last_name" name="last_name" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Company Name <span style="color: brown">*</span></label>
                                        <input type="text" class="form-control" v-model="company_name" name="company_name" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Main Store Name <span style="color: brown">*</span></label>
                                        <input type="text" class="form-control" v-model="store_name" name="store_name" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Email Address <span style="color: brown">*</span></label>
                                        <input type="email" class="form-control" required v-model="email" name="email" placeholder="name@example.com">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Phone Number <span style="color: brown">*</span></label>
                                        <vue-tel-input :value="phone" @input="onInput" aria-required=""></vue-tel-input>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Referral Code</label>
                                        <input type="text" class="form-control" v-model="referred_by" name="referred_by" placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Password <span style="color: brown">*</span></label>
                                        <input type="password" class="form-control" v-model="password" name="password" placeholder="********" required>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label small font-w-600 dark-color m-0px">Confirm Password <span style="color: brown">*</span></label>
                                        <input type="password" class="form-control" v-model="password_confirmation" name="password_confirmation" placeholder="********" required>
                                    </div>
                                    <!-- Checkbox -->
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox d-flex align-items-center text-muted">
                                            <input type="checkbox" class="custom-control-input" id="termsCheckbox" checked required>
                                            <label class="custom-control-label" for="termsCheckbox">
                                                <small>
                                                    I agree to the
                                                    <a class="link-muted" href="/terms-and-conditions" rel="nofollow" onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;" target="_blank">Terms and Conditions</a>
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End Checkbox -->
                                    <div class="p-5px-t">
                                        <div class="d-flex justify-content-between align-items-center">
                                          <button v-if="loading == true" disabled class="m-btn m-btn-radius m-btn-theme"> 
                                              <div class="spinner-grow text-info" role="status">
                                              <span class="visually-hidden">Processing</span>
                                            </div>
                                          </button>
                                          <button v-if="loading == false" type="submit" class="m-btn m-btn-radius m-btn-theme">Get Started</button>                                          
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer"><small>Already have an account?</small>
                                <router-link to="/auth/login" class="small font-w-700">Login</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end section -->
    </main>
    <!-- main end -->

    <Footer />

</div>

</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
// import { onMounted } from "@vue/runtime-core";

export default {
  components: {
        Navbar,
        Footer,
    },

  setup() {
    const first_name = ref("");
    const last_name = ref("");
    const referred_by = ref("");
    const company_name = ref("");
    const store_name = ref("");
    const email = ref("");
    const phone = ref("");
    const password = ref("");
    const password_confirmation = ref("");
    const validationErrors = ref("");
    const toast = useToast();
    const router = useRouter();
    const loading = ref(false);
    const countries = ref([]);
    const showPassword = ref(true);
    const showConfirmPassword = ref(true);

    const togglePasswordField = () => {
      showPassword.value = !showPassword.value;
      console.log(showPassword.value);
    };

    const togglePasswordConfirmField = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const onInput = (_, phoneObject) => {
      if (phoneObject?.formatted) {
        phone.value = phoneObject.number;
      }
    };

    const registerUser = async () => {
      loading.value = true;
      await axios
        .post("/register-web", {
          first_name: first_name.value,
          last_name: last_name.value,
          company_name: company_name.value,
          email: email.value,
          phone_no: phone.value,
          password: password.value,
          store_name: store_name.value,
          password_confirmation: password_confirmation.value,
          referred_by: referred_by.value,
        })
        .then((res) => {
          loading.value = false;
          router.push("/auth/send-email-verifcation");
          toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response.status == 422) {
            toast.error("There are errors in your input");
            validationErrors.value = error?.response?.data?.message;
          }
          if (error.response.status == 500) {
            toast.error("An error occured please try again");
          }
          if (error.response.status == 400 ||  error.response.status == 401) {
            toast.error("An error occured please try again");
          }
          loading.value = false;
        });
    };
    // const getAllCountries = async () => {
    //   const response = await axios.get("countries");
    //   countries.value = response.data.data.data;
    //   loading.value = false;
    // };
    // onMounted(getAllCountries());

    return {
      first_name,
      last_name,
      company_name,
      store_name,
      email,
      phone,
      password,
      referred_by,
      password_confirmation,
      validationErrors,
      registerUser,
      loading,
      countries,
      togglePasswordField,
      togglePasswordConfirmField,
      showPassword,
      showConfirmPassword,
      onInput,
    };
  },
};
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>