<template>
    <div class="mt-3 px-3 gridtop">
        <div class="d-flex justify-content-between">
            <div class="mt-2 fw-bold" style="font-size:20px">
                <router-link :to="'/user-credit/'+purchase?.supplier?.id">
                    <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
                </router-link> {{ purchase?.batch_code }}
            </div>
            <PrintButtonHeader :title="'Creditor-Invoice-' + purchase?.batch_code" :urlA4="'/creditor-receipt/'"
                :url58mm="'/print58mm-creditor-receipt/'" :url80mm="'/print80mm-creditor-receipt/'"
                :currentUrl="'View Creditor Receipt'" :data="creditor_credits" :type="'Creditor Receipt'" :loading="loading"/>


        </div>
    </div>
    <div class="row pl-3 pr-3">
        <div class="bg-white rounded-lg mt-3 mx-3 mb-3 px-4 py-3 print-sales-shadow col-md-8" id="debitorcard"
            style="overflow-x: auto;">
            <Invoice :purchase_items="purchase_items" :purchase_array="purchase_array" :purchase="purchase" :creditor_credits="creditor_credits" :loading="loading"/>

        </div>
        <div class="col mt-3">
            <div class="card rounded-lg">
                <div class="mx-2 pb-5 pt-2">
                    <div class="fw-bold fs-4">Record Payment </div>
                    <div class="mt-3">
                        <label class="mb-2">Payment Type</label>
                        <select class="form-select" v-model="payment_type" :disabled="permission.create_record_payment != 1">
                            <option selected disabled>Select Payment Type</option>
                            <option value="cash">Cash</option>
                            <option value="card">Card</option>
                        </select>
                    </div>
                    <div class="form-group mt-3">
                        <label class="font-weight-bold mb-2">Payment Amount</label>
                        <input type="text" onkeypress="return /^[0-9.]+$/i.test(event.key)" class="form-control" v-model="amount" @keyup="handleAmount()" :disabled="permission.create_record_payment != 1">
                    </div>
                    <div class="form-group mt-3">
                        <label class="mb-2">Balance</label>
                        <input disabled type="number" class="form-control" v-model="balance">
                    </div>
                    <div class="mt-3">
                        <button class="btn allbuttonsclr" v-if="crudLoading == false"
                            @click.prevent="makePayment" :disabled="permission.create_record_payment != 1">Record Payment</button>
                        <button class="btn allbuttonsclr" v-else disabled>Processing</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import {
    useStore
} from "vuex";
import Invoice from '../purchase/components/invoice.vue'

export default {
    components: {
        Invoice
    },
    mixins: [reusableMixin],
    data() {
        return {
            creditor_credits:null,
            toast: useToast(),
            purchase: null,
            grand_total: 0,
            tax_amount: 0,
            creditor: [],
            purchase_items: [],
            purchase_array: [],
            store: useStore(),
            payment_type: null,
            amount: null,
            balance: "0.00",
            def_balance:"0.00",
            crudLoading: false,
            loading:true
        }
    },
    methods: {

        handleAmount() {
            let balance = this.creditor_credits?.total_amount - this.creditor_credits?.amount_paid
            this.balance = (balance - this.amount).toFixed(2);
            if (Math.sign(this.balance) == -1) {
                this.amount = this.creditor_credits?.total_balance;
                this.balance = parseFloat(balance).toFixed(2);
            }

            if(this.amount == this.balance){
                this.balance = (0).toFixed(2)
            }

        },
        getCreditor() {
            this.loading = true
            axios
                .get(`/creditors/id/${this.$route.params.id}`)
                .then((response) => {
                    this.loading = false
                    this.creditor_credits = response.data.data;
                    this.creditor = response.data.data.creditor;

                    this.purchase = this.creditor_credits?.purchase;
                    this.purchase_items = this.creditor_credits?.purchase?.purchase_item;
                    this.purchase_items.forEach((s) => {
                        this.purchase_array.push({
                            product_name: s?.product?.product_name,
                            cost_price: s?.cost_price,
                            variant_name:s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
                            quantity: s?.quantity,
                            total: parseFloat(s?.amount_paid)//(s?.cost_price * s?.quantity)
                        });
                    });
                    this.def_balance = this.creditor_credits?.total_amount - this.creditor_credits?.amount_paid;
                    this.balance = (this.def_balance).toFixed(2)
                })
                .catch(() => {
                    this.loading = true
                    this.toast.error("Oops,Something went wrong");
                });
        },

        makePayment() {
            if (this.payment_type == null) {
                this.toast.error("Select payment type first");
                return
            }
            this.crudLoading = true
            axios.post('/creditors/pay-credit', {
                amount: this.amount,
                supplier_id: this.purchase?.supplier_id,
                credit_id: this.creditor_credits?.id
            }).then((response) => {
                this.crudLoading = false
                this.$router.push({ name: "Creditor" });
                this.toast.success(response.data.message);
            }).catch((error) => {
                this.crudLoading = false
                this.toast.error(error.response.data.message[0] ?? error.response.data.message);
            })
        }


    },

    created() {
        this.getCreditor()
    }

}
</script>

<style scoped>
.subtotal {
    margin-right: 112px;
}

.taxmargin {
    margin-right: 162px;
}

.totalmargin {
    margin-right: 144px;
}

.salesbottommargin {
    margin-right: 74px !important;
}

.salesbottommarginpaid {
    margin-right: 32px !important;
}

.salesbottommarginbal {
    margin-right: 46px !important;
}

.salescardcontainer {
    justify-content: center;
}

@media (max-width: 767px) {
    .salescard {
        width: 100% !important;
    }

    .onmobile {
        flex-direction: column !important;
    }

    .salescardcontainer {
        display: block !important;
    }

    .invoicelogo {
        width: 80px;
        height: 60px;
    }

    #debitorcard {
        width: 93% !important;
    }

    #debittopbtns {
        margin-bottom: 5px;
    }
}
</style>
