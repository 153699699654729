<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

   <!-- Main -->
   <main>
        <!-- Page Title -->
        <section class="section bg-center bg-cover effect-section" style="background-image: url(static/img/bg-1.jpg);">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">About Us</h2>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li class="active">About Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->

        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-lg-6 m-15px-tb">
                        <div class="h3 dark-color font-w-600 m-15px-b"><span class="theme-color">InventFlux:</span> Revolutionizing Inventory Management</div>
                        <p class="font-1 m-40px-b" v-html="details.short_description"></p>
                        <ul class="list-type-03 m-20px-b">
                            <li><i class="fas fa-check"></i>Automated inventory management.</li>
                            <li><i class="fas fa-check"></i>Minimize handling costs</li>
                            <li><i class="fas fa-check"></i>Enhance cash flow optimization</li>
                        </ul>
                        <div class="btn-bar p-15px-t">
                            <router-link class="m-btn m-btn-radius m-btn-theme" to="/auth/register">Get Started</router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 text-center m-15px-tb">
                        <img src="/static/img/cat-v2-7.svg" title="" alt="">
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->

        <!-- Section -->
        <!-- <section class="p-50px-tb theme-bg">
            <div class="container">
                <div class="row counter">
                    <div class="col-6 col-md-6 col-lg-3 m-15px-tb text-center">
                        <div class="count white-color h1 font-w-600" data-to="650" data-speed="650">650</div>
                        <h6 class="white-color-light after-50px white-after p-20px-b m-0px">Happy Clients</h6>
                    </div>
                    <div class="col-6 col-md-6 col-lg-3 m-15px-tb text-center">
                        <div class="count white-color h1 font-w-600" data-to="987" data-speed="987">987</div>
                        <h6 class="white-color-light after-50px white-after p-20px-b m-0px">Work Completed</h6>
                    </div>
                    <div class="col-6 col-md-6 col-lg-3 m-15px-tb text-center">
                        <div class="count white-color h1 font-w-600" data-to="350" data-speed="350">350</div>
                        <h6 class="white-color-light after-50px white-after p-20px-b m-0px">Award Winning</h6>
                    </div>
                    <div class="col-6 col-md-6 col-lg-3 m-15px-tb text-center">
                        <div class="count white-color h1 font-w-600" data-to="850" data-speed="850">850</div>
                        <h6 class="white-color-light after-50px white-after p-20px-b m-0px">Telephonic Talk</h6>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- End Section -->
        




        <!-- Section -->
        <section v-if="features.length" class="section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 m-15px-tb">
                        <label class="theme2nd-bg p-5px-tb p-20px-lr border-radius-5 white-color m-15px-b">Key Features</label>
                        <h3 class="h1 font-w-400 dark-color m-10px-b">Do more with InventFlux</h3>
                        <!-- <p class="font-1 m-0px">Building a thriving business is hard enough without worrying about tracking your stocks. We are here to ease all your worries by providing the you with the best IMS System</p> -->
                        <div class="row p-10px-t">
                            <div v-for="data in features" :key="data.id" class="col-md-6 m-15px-tb">
                                <div class="media">
                                    <div class="icon-60 dots-icon border-radius-5 border-color-theme border-all-1 theme-color">
                                        <i class="icon-desktop"></i>
                                        <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                                    </div>
                                    <div class="media-body p-20px-l">
                                        <h6 class="dark-color font-w-600">{{data.title}}</h6>
                                        <p class="m-0px">{{data.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 m-15px-tb text-center">
                        <img src="static/img/mobile-1.png" title="" alt="">
                    </div> -->
                </div>
            </div>
        </section>
        <!-- End Section -->



        <!-- Section -->
        <section v-if="features.length" class="section gray-bg">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-400 dark-color m-10px-b">Key Benefits</h3>
                        <p class="font-1 m-0px">Built with knowledge collected from thousands of inventory client use cases. InventFlux helps keep track of processing, receiving, stock locations, moving product, selling as well as packing and shipping.</p>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div v-for="data in benefits" :key="data.id" class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">{{data.title}}</h6>
                                <p class="m-15px-b">{{data.description}}</p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->


        <!-- Section -->
        <section class="p-20px-tb theme-bg">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-8 col-lg-9 text-center text-md-left m-15px-tb">
                        <h3 class="white-color m-10px-b">Ready to get started? It's fast, free and very easy!</h3>
                        <p class="white-color-light m-0px">automate your inventory with one simple InventFlux</p>
                    </div>
                    <div class="col-md-4 col-lg-3 text-center text-md-right m-15px-tb">
                        <router-link class="m-btn m-btn-radius m-btn-t-white" to="/auth/register">Get Started Now</router-link>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->
    </main>
    <!-- End Main -->

    <Footer />

</div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";


export default{
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            details: {},
            loading: true,
            benefits: [],
            features: [],
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
        this.fetchFeatures();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        pageData() {
            axios.get("/admin/fetchProjectSetup").then((response) => {
                this.details = response.data.data;
                this.loading = false;
            })
        },

        fetchFeatures() {
            axios.get("/admin/active-features").then((response) => {
                this.features = response.data.data.features;
                this.benefits = response.data.data.benefits;
                this.loading = false;
            })
        }
    }

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>