<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- Page Title -->
        <section class="bg-center bg-cover bg-fiexd effect-section" style="background-image: url('/static/img/bg-1.jpg');">
            <div class="mask dark-g-bg opacity-9"></div>
            <div class="container">
                <div class="row screen-65 justify-content-center align-items-center p-100px-tb">
                    <div class="col-lg-10 text-center m-50px-t">
                        <h1 class="display-4 font-w-600 white-color m-25px-b">{{details.title}}</h1>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li><router-link to="/blog">Blog</router-link></li>
                            <li class="active">{{details.title}}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->
        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="nav p-25px-b">
                            <span class="dark-color font-w-600"><i class="fas fa-calendar-alt "></i> {{formatDate(details.created_at)}}</span>
                            <router-link v-if="details.category" class="dark-color font-w-600 m-15px-l" :to="'/blog/category/'+details.category.slug"><i class="far fa-folder-open"></i> {{details.category.name}}</router-link>
                        </div>
                        <figure v-if="details.image" class="figure"><img alt="" :src="url.hostName+'images/blog/'+details.image" class="img-fluid shadow rounded" width="720">
                            <figcaption class="m-15px-t dark-color">{{details.title}}</figcaption>
                        </figure>
                        <iframe v-if="details.video_id" width="720" height="520" src="https://www.youtube.com/embed/a7Cq50UMgFg"></iframe>
                        <h5 class="font-w-600 dark-color p-25px-t m-15px-b">{{details.title}}</h5>
                        <div v-html="details.description"/>
                        
                        <div class="nav tag-cloud p-25px-t">
                            <router-link v-if="details.category" :to="'/blog/category/'+details.category.slug">{{details.category.name}}</router-link>
                        </div>
                        <div class="p-25px-tb m-35px-tb border-top-1 border-bottom-1 border-color-gray">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 class="dark-color font-w-600 m-0px">Share Post</h5>
                                </div>
                                <div>
                                    <div class="nav justify-content-center justify-content-md-end social-icon si-30 gray">
                                        <a :href="'http://www.facebook.com/sharer.php?u='+baseurl+'/blog/'+details.slug" rel="nofollow" onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                        <a :href="'http://twitter.com/share?text='+details.title+'&url='+baseurl+'/blog/'+details.slug" rel="nofollow" onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;" target="_blank"><i class="fab fa-twitter"></i></a>
                                        <a :href="'https://plus.google.com/share?url='+baseurl+'/blog/'+details.slug" rel="nofollow" onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;"><i class="fab fa-google-plus-g"></i></a>
                                        <a :href="'http://www.linkedin.com/shareArticle?mini=true&url='+baseurl+'/blog/'+details.slug" rel="nofollow" onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;"><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="media gray-bg p-20px">
                            <div class="avatar-80 border-radius-50">
                                <img src="/static/img/avatra-2.jpg" title="" alt="">
                            </div>
                            <div class="media-body p-20px-l">
                                <h5 class="m-10px-b font-w-600 dark-color">Nancy Bayers</h5>
                                <p class="m-0px">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div> -->
                        <div class="comments-area m-40px-t m-50px-b">
                            <div class="border-bottom-1 border-color-gray p-10px-b m-25px-b">
                                <h4 class="dark-color font-w-600 m-0px"><span v-if="details.comments">{{details.comments.length}}</span> Comments</h4>
                            </div>
                            <ul v-if="details.comments" class="comment-list">
                                <li v-for="comment in details.comments" :key="comment.id" class="comment">
                                    <article class="comment-body">
                                        <div v-if="comment.user" class="comment-meta d-flex align-items-center">
                                            <div class="comment-author"><img src="/static/img/avatra-2.jpg" title="" alt=""></div>
                                            <div class="comment-metadata">
                                                <div class="c-name">{{comment.user? comment.user.first_name+' '+comment.user.last_name: ''}}</div>
                                                <span class="c-date">{{formatDate(comment.created_at)}} at {{formatTime(comment.created_at)}}</span>
                                            </div>
                                        </div>
                                        <div class="comment-content">
                                            <p>{{comment.comment}}</p>
                                        </div>
                                        <div class="comment-reply">
                                            <a v-if="isLogin" class="m-btn m-btn-t-theme m-btn-radius m-btn-sm" data-toggle="modal" @click="showReplyForm(comment.id)">Reply</a>
                                            <!-- <a v-if="show_reply == true && reply_id == 'reply-'+comment.id" class="m-btn m-btn-t-theme m-btn-radius m-btn-sm" @click="hideReplyForm(comment.id)" href="javascript:void(0)">Cancel</a> -->
                                        </div>
                                        <!-- <div v-if="show_reply && reply_id == 'reply-'+comment.id" :id="'reply-'+comment.id">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="dark-color font-w-600">Your Reply?</label>
                                                            <textarea class="form-control" rows="2" name="comment" v-model="replycomment" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <button v-if="loading == false" class="m-btn m-btn-radius m-btn-theme" @click="submitReplyComment" type="button">Submit</button>
                                                        <button v-if="loading == true" class="m-btn m-btn-radius m-btn-theme" disabled>
                                                            <div class="spinner-grow text-info" role="status">
                                                                <span class="visually-hidden">Processing</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> -->
                                    </article>
                                    <ul v-if="comment.sub" class="children">
                                        <li v-for="sub in comment.sub" :key="sub.id" class="comment">
                                            <article class="comment-body">
                                                <div class="comment-meta d-flex align-items-center">
                                                    <div class="comment-author"><img src="/static/img/avatra-2.jpg" title="" alt=""></div>
                                                    <div class="comment-metadata">
                                                        <div class="c-name">{{sub.user? sub.user.first_name+' '+sub.user.last_name: ''}}</div>
                                                        <span class="c-date">{{formatDate(sub.created_at)}} at {{formatTime(sub.created_at)}}</span>
                                                    </div>
                                                </div>
                                                <div class="comment-content">
                                                    <p>{{sub.comment}}</p>
                                                </div>
                                                <!-- <div class="comment-reply">
                                                    <a class="m-btn m-btn-t-theme m-btn-radius m-btn-sm" href="#">Reply</a>
                                                </div> -->
                                            </article>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="card gray-bg">
                            <div v-if="isLogin" class="card-body">
                                <h4 class="dark-color font-w-600 m-30px-b">Leave a Comment</h4>
                                <div>
                                  <div class="" v-for="errors in validationErrors" :key="errors.id">
                                    <div class="text-danger text-center">
                                      {{ errors }}
                                    </div>
                                  </div>
                                </div>
                                <form @submit.prevent="submitComment">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-600">Full Name</label>
                                                <input type="text" class="form-control" :value="user.first_name+' '+user.last_name" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-600">Your Email</label>
                                                <input type="text" class="form-control" :value="user.email" readonly>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="dark-color font-w-600">Your Comment?</label>
                                                <textarea class="form-control" rows="6" name="comment" v-model="comment" required></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button v-if="loading == false" class="m-btn m-btn-radius m-btn-theme" type="submit">Submit</button>
                                            <button v-if="loading == true" class="m-btn m-btn-radius m-btn-theme" disabled>
                                                <div class="spinner-grow text-info" role="status">
                                                    <span class="visually-hidden">Processing</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div v-else class="card-body">
                                <h4 class="dark-color font-w-600 m-30px-b">Login to Comment</h4>
                                <div>
                                  <div class="" v-for="errors in validationErrors" :key="errors.id">
                                    <div class="text-danger text-center">
                                      {{ errors }}
                                    </div>
                                  </div>
                                </div>
                                <form @submit.prevent="loginUser">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-600">Email Address</label>
                                                <input type="email" class="form-control" v-model="email" required>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-600">Password</label>
                                                <input type="password" class="form-control" v-model="password" required>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button v-if="loading == false" class="m-btn m-btn-radius m-btn-theme" type="submit">Submit</button>
                                            <button v-if="loading == true" class="m-btn m-btn-radius m-btn-theme" disabled>
                                                <div class="spinner-grow text-info" role="status">
                                                    <span class="visually-hidden">Processing</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 md-m-15px-tb">
                        <div class="card">
                            <div class="card-header bg-transparent">
                                <span class="h5 m-0px font-w-600 dark-color">Search</span>
                            </div>
                            <div class="p-20px">
                                <form class="d-flex flex-column flex-md-row m-5px-b input-group" action="/search/blog">
                                    <input type="text" class="form-control mr-sm-2 mb-2 mb-sm-0" name="search" placeholder="Search">
                                    <button class="m-btn m-btn-theme m-btn-radius flex-shrink-0" type="submit"><i class="fa fa-search"></i></button>
                                </form>
                            </div>
                        </div>
                        <div class="card m-35px-t">
                            <div class="card-header bg-transparent">
                                <span class="h5 m-0px font-w-600 dark-color">Categories</span>
                            </div>
                            <div class="list-group list-group-flush">
                                <router-link v-for="category in categories" :key="category.id" :to="'/blog/category/'+category.slug" class="list-group-item list-group-item-action d-flex justify-content-between p15px-tb">
                                    <div>
                                        <span>{{category.name}}</span>
                                    </div>
                                    <div>
                                        <i class="ti-angle-right"></i>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div v-if="latest" class="card m-35px-t">
                            <div class="card-header bg-transparent">
                                <span class="h5 m-0px font-w-600 dark-color">Recent Posts</span>
                            </div>
                            <div class="list-group list-group-flush">
                                <router-link v-for="blog in latest" :key="blog.id" :to="'/blog/'+blog.slug" class="list-group-item list-group-item-action d-flex p15px-tb">
                                    <div>
                                        <div class="avatar-50 border-radius-5">
                                            <img :src="url.hostName+'images/blog/'+blog.image" title="" alt="" />
                                        </div>
                                    </div>
                                    <div class="p-15px-l">
                                        <p class="m-0px">{{blog.title}}</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>

                        <div v-if="featured" class="card m-35px-t">
                            <div class="card-header bg-transparent">
                                <span class="h5 m-0px font-w-600 dark-color">Featured Posts</span>
                            </div>
                            <div class="list-group list-group-flush">
                                <router-link v-for="blog in featured" :key="blog.id" :to="'/blog/'+blog.slug" class="list-group-item list-group-item-action d-flex p15px-tb">
                                    <div>
                                        <div class="avatar-50 border-radius-5">
                                            <img :src="url.hostName+'images/blog/'+blog.image" title="" alt="" />
                                        </div>
                                    </div>
                                    <div class="p-15px-l">
                                        <p class="m-0px">{{blog.title}}</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->


        <!-- Modal -->
        <div class="modal fade" id="reply_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLabel">Leave a Reply</h5> -->
                        <button type="button" class="close" @click="hideReplyForm" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="isLogin" class="card-body">
                            <h4 class="dark-color font-w-600 m-30px-b">Leave a Reply</h4>
                            <div>
                                <div class="" v-for="errors in validationErrors" :key="errors.id">
                                <div class="text-danger text-center">
                                    {{ errors }}
                                </div>
                                </div>
                            </div>
                            <form>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="dark-color font-w-600">Your Reply?</label>
                                            <textarea class="form-control" rows="4" name="comment" v-model="replycomment" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <button v-if="loading == false" class="m-btn m-btn-radius m-btn-theme" @click="submitReplyComment" type="button">Submit</button>
                                        <button v-if="loading == true" class="m-btn m-btn-radius m-btn-theme" disabled>
                                            <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Processing</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="hideReplyForm" data-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>


    </main>
    <!-- main end -->

    <Footer />

</div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import moment from 'moment';
import hostName from '../main';
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
// import $ from "jquery";


export default{   

    components: {
        Navbar,
        Footer,
    },
    data() {
        const route = useRoute();
        const toast = useToast();
        const router = useRouter();
        return {
            details: {},
            categories: [],
            featured: [],
            latest: [],
            loading: false,
            url: hostName,
            route: route,
            router: router,
            toast: toast,
            email: '',
            password: '',
            validationErrors: [],
            baseurl: window.location.origin,
            isLogin: localStorage.getItem("token"),
            user: '',
            comment: '',
            replycomment: '',
            parent_id: '',
            show_reply: false,
            reply_id: '',
        }
    },
    mounted() {
        this.scrollTop();
        this.getCurrentUser();
        this.pageData();        
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        async pageData() {
            await axios.get("/admin/blog-detail/"+this.route.params.id).then((response) => {
                this.details = response.data.data.data;
                this.categories = response.data.data.category;
                this.featured = response.data.data.featured;
                this.latest = response.data.data.latest;
                this.loading = false;
                
            })
        },

        async loginUser(){
            if(this.email == '' || this.password == '') {
                return
            }else {
                this.loading = true;
                await axios.post("/login-web", {
                    email: this.email,
                    password: this.password
                }).then((response) => {
                    this.loading = false;
                    localStorage.setItem("token", response.data.data.original.accessToken);

                    axios.defaults.headers["Authorization"] = `Bearer ${response.data.data.original.accessToken}`;
                    this.toast.success("Login successful");
                    window.location.reload();
                    
                }).catch((error) => {
                    this.loading = false;
                    if (error.response.status === 400) {
                        this.toast.error("There are errors in your input");
                        this.validationErrors = error.response.data.message;
                    }
                    if (error.response.status === 401) {
                        this.toast.error(error?.response?.data?.message)
                    }
                    if (error.response.status === 402) {
                        this.router.push('send-email-verifcation')
                        this.toast.error(error?.response?.data?.message)
                    }
                    if (error.response.status === 500) {
                        this.toast.error("Please recheck your login details");
                    }
                });
            
            }

        },

        async submitComment(){
            if(this.comment == '') {
                return
            }else {
                this.loading = true;
                await axios.post("/admin/blog-comment", {
                    comment: this.comment,
                    blog_id: this.details.id,
                    user_id: this.user.id
                }).then((response) => {
                    this.loading = false;
                    if(response.data.status == 200){
                        this.comment = '';
                        this.pageData();
                        this.toast.success(response.data.message);
                    }
                    
                }).catch((error) => {
                    this.loading = false;
                    if (error.response.status === 400) {
                        this.toast.error("There are errors in your input");
                        this.validationErrors = error.response.data.message;
                    }
                    if (error.response.status === 422) {
                        this.toast.error("There are errors in your input");
                        this.validationErrors = error.response.data.message;
                    }
                    if (error.response.status === 401) {
                        this.toast.error(error?.response?.data?.message)
                    }

                    if (error.response.status === 500) {
                        this.toast.error("Please recheck your login details");
                    }
                });
            
            }

        },

        async submitReplyComment(){
            if(this.comment == '') {
                return
            }else {
                this.loading = true;
                await axios.post("/admin/blog-comment", {
                    comment: this.replycomment,
                    blog_id: this.details.id,
                    user_id: this.user.id,
                    parent_id: this.parent_id
                }).then((response) => {
                    this.loading = false;
                    if(response.data.status == 200){
                        this.replycomment = '';
                        this.parent_id = '';
                        this.pageData();
                        this.hideReplyForm();
                        this.toast.success(response.data.message);
                    }
                    
                }).catch((error) => {
                    this.loading = false;
                    if (error.response.status === 400) {
                        this.toast.error("There are errors in your input");
                        this.validationErrors = error.response.data.message;
                    }
                    if (error.response.status === 422) {
                        this.toast.error("There are errors in your input");
                        this.validationErrors = error.response.data.message;
                    }
                    if (error.response.status === 401) {
                        this.toast.error(error?.response?.data?.message)
                    }

                    if (error.response.status === 500) {
                        this.toast.error("Please recheck your login details");
                    }
                });
            
            }

        },

        getCurrentUser() {
            axios.get("/user").then((response) => {
                this.user = response.data.data.data;
                this.store.dispatch("getCurrentUser", this.user);
            }).catch((error)=>{
                if(error.response.status ==  403){
                    this.route.push('/auth/login')
                } else{
                    this.route.push('/auth/login')
                }

                this.toast.error(error?.response?.data?.message)
            })

        },

        showReplyForm(id){
            this.parent_id = id;
            // this.reply_id = "reply-"+id;            
            // this.show_reply = true;
            window.$('#reply_modal').modal('show');
        },

        hideReplyForm(){
            this.parent_id = '';
            window.$('#reply_modal').modal('hide');
        },
        
        formatDate(date) {
            return moment(date).format('MMM DD, YYYY');
        },

        formatTime(date) {
            return moment(date).format('h:mm a');
        },

        strip(html){
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    }

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>