<template>
  <div class="topallreports">
    <ReportHeaderTab />
    <div class="active">
      <div class="mt-3 fw-bold gridtop">
        <div class="mt-2" style="font-size: 20px">Daily Sales Report</div>
        <div class="
            d-flex
            justify-content-between
            gridtop
            salestop
            allformstop
            nexttopform
          ">
          <div class="d-flex allformstop">
            <div class="d-flex">
              <div class="reportDate" style="width: 160px">
                <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                  style="background: none; border: none" />
              </div>
              <div class="reportDate me-1" style="width: 160px">
                <Datepicker v-model="tableData.end_date" :enableTimePicker="false" class="form-control"
                  style="background: none; border: none" />
              </div>
            </div>
            <div class="d-flex selectstore">
              <div class="input-group mb-4 me-3 " id="stores" style="width: 149px;font-weight:400">
                <Multiselect v-model="tableData.store" label="name" :options="getStore" placeholder="--Stores--"
                  :searchable="true" :create-option="false" :close-on-select="true" />
              </div>
              <div class="input-group mb-4 me-1" id="stores" style="width: 149px">
                <select class="form-select inputsearchborder" name="" id="" v-model="tableData.payment_type">
                  <option :value="pay.value" v-for="pay in paymentTypes" :key="pay.id">
                    {{ pay.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="topreportbtn">
              <button type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white"
                v-if="crudLoading == false" @click="fetchData()">
                Submit
              </button>
              <button v-if="crudLoading == true" class="btn btn-primary text-white" type="button"
                @click="fetchData()" disabled>
                <span class="">Processing...</span>
              </button>
            </div>
          </div>
          <div class="d-flex searchexportrpts">
            <div>
              <div class="dropdown">
                <button class="btn exportbtn me-2 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                  Export
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a @click="
                      drawPdf(
                        print_content,
                        headerArrayColumn,
                        'l',
                        'daily-sales-report',
                        'Daily Sales Report',
                        '',
                        footerRowArray
                      )
                    " class="dropdown-item" href="#">PDF</a>
                  </li>
                  <li>
                    <a @click="reusableMixinToCSV('Daily-sales-report')" class="dropdown-item" href="#">CSV</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card makecardresponsive rounded-lg mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
        <div v-if="loading == true" class="text-center py-5">
          <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="text-center py-5" v-if="details.length == 0 && loading == false">
          <div>
            <img src="../../private-assets/images/emptypng.png" alt="" />
          </div>
          <div class="fw-bold mt-3">Your sales list is empty!</div>
        </div>
        <div class="card-toolbar" v-if="loading == false && details.length > 0">
          <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

            <div class="d-flex align-items-center py-1" style="margin-right: 10px">
              <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option :value="pagination.total">All</option>
              </select>
            </div>
            <!--begin::Actions-->

          </div>
        </div>
        <div v-if="loading == false && details.length > 0">
          <table class="table table-sm-responsive">
            <thead class="thead-white" style="border-style: hidden !important">
              <tr class="pb-5">
                <th class="fw-bold heading" style="font-size: 14px">Date</th>
                <th class="fw-bold heading" style="font-size: 14px">Transaction</th>
                <th class="fw-bold heading" style="font-size: 14px">Total
                  Amount({{ company?.currency?.symbol }})</th>
                <th class="fw-bold heading" style="font-size: 14px">Total
                  Cost({{ company?.currency?.symbol }})</th>
                <th class="fw-bold heading" style="font-size: 14px">Total
                  Profit({{ company?.currency?.symbol }})</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" v-for="sale in details" :key="sale.id">
                <td class="text-secondary row1">{{ sale.date }}</td>
                <td class="text-secondary row1">{{ sale.transactions }}</td>
                <td class="text-secondary row1">
                  {{ $filters.formatMoney(sale.total_amt)  }}
                </td>
                <td class="text-secondary row1">
                  {{ $filters.formatMoney(sale.total_cost)  }}

                </td>

                <td class="text-secondary row1" :class="Math.sign(sale.total_profit) == -1 ? 'text-danger' : ''">
                  {{ $filters.formatMoney(sale.total_amt - sale.total_cost)  }}

                </td>
              </tr>
              <tr class="fw-bold">
                <td class="row1">GRAND TOTAL</td>
                <td class="row1">
                  <v-num #="{ number }" :value="total_transaction">{{
                      number
                  }}</v-num>
                </td>
                <td class="row1">
                  {{ $filters.formatMoney(total_amount)  }}

                </td>
                <td class="row1">
                  {{ $filters.formatMoney(total_cost)  }}

                </td>
                <td class="row1" :class="Math.sign(total_profit) == -1 ? 'text-danger' : ''">
                  {{ $filters.formatMoney(total_profit)  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
      @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

import { reusableMixin } from "../../../lib/reusableMixin";

import Multiselect from "@vueform/multiselect";
import ReportHeaderTab from "./components/reportheadertab.vue";

export default {
  components: {
    ReportHeaderTab,
    Multiselect,
  },
  mixins: [reusableMixin],
  data() {
    let sortOrders = {};
    let columns = [
      {
        width: "16%",
        label: "Name",
        name: "name",
        sortable: false,
      },
      {
        width: "15%",
        label: "Sortcode",
        name: "sortcode",
        sortable: false,
      },
      {
        width: "15%",
        label: "Phonecode",
        name: "phonecode",
        sortable: false,
      },
      {
        width: "5%",
        label: "Status",
        name: "status",
        sortable: false,
      },
      {
        width: "5%",
        label: "Actions",
        name: "actions",
        sortable: false,
      },
    ];

    // Show date from the past 1 week
    var current_date = new Date();
    let current_date_to_date = new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate()-7)
    current_date_to_date = new Date(current_date_to_date);

    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      print_content: [],
      headerArrayColumn: [
        "S/N", "Date",
        "Transaction",
        "Total Amount",
        "Total Cost",
        "Total Profit",
      ],
      footerRowArray: [],
      details: [],
      paymentTypes: [],
      loading: false,
      query: null,

      selectedModel: [],
      allSelected: false,
      columns: columns,
      sortKey: "id",
      sortOrders: sortOrders,
      tableData: {
        store: "All",
        payment_type: "All",
        start_date: current_date_to_date,
        end_date: new Date(),
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "desc",
        id: "",
        status: [0, 1, 2, 3],
        query: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      toast: useToast(),
      currentID: null,
      crudLoading: false,
      total_transaction: 0,
      total_amount: 0,
      total_cost: 0,
      total_profit: 0,

    };
  },
  methods: {
    grandTotal(item) {
      this.total_transaction = item.reduce(function (sum, t) {
        return sum + t.transactions;
      }, 0);

      this.total_amount = item.reduce(function (sum, t) {
        return sum + t.total_amt;
      }, 0);

      this.total_cost = item.reduce(function (sum, t) {
        // return sum + t.total_cost
        return (parseInt(sum) + parseInt(t.total_cost)).toFixed(2);
      }, 0);

      this.total_profit = item.reduce(function (sum, t) {
        return (parseFloat(sum) + parseFloat(t.total_amt - t.total_cost)).toFixed(2);
      }, 0);

      this.footerRowArray = [];
      this.footerRowArray.push("", "GRAND TOTAL", this.total_transaction, this.$filters.formatMoney(this.total_amount), this.$filters.formatMoney(this.total_cost), this.$filters.formatMoney(this.total_profit))
    },

    dataToPrint(details) {
      details.map((sales, idx) => {
        let result = {
          sn: idx + 1,
          date: sales.date,
          transactions: sales.transactions,
          total_amt: this.$filters.formatMoney(sales.total_amt),
          total_cost: this.$filters.formatMoney(sales.total_cost),
          total_profit: this.$filters.formatMoney(sales.total_profit),
        };
        this.print_content.push(result);
      });
    },


    fetchData(url = "/report/daily-report") {
      this.loading = true;
      this.crudLoading = true;
      this.tableData.query = this.query;

      if (this.crudLoading && this.query) {
        this.crudLoading = false;
      }

      this.tableData.draw++;
      axios
        .get(url, {
          params: this.tableData,
        })
        .then((response) => {
          this.print_content = [];
          if (!response.data.status == 200) {
            this.loading = false;
            return (this.errorMessage = "Could not fetch data");
          }
          this.loading = false;
          this.crudLoading = false;
          let data = response.data;
          this.details = data.data.sales.data;
          this.grandTotal(data.data.sales2);
          this.dataToPrint(this.details);
          this.configPagination(data.data.sales);
        })
        .catch((error) => {
          this.crudLoading = false;
          this.errorMessagem = error;
          this.loading = false;
        });
    },




    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
  },
  computed: {
    loggedInUser() {
      return this.store.state.getCurrentUserPermission
    }
  },

  watch: {
    query() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.allStore();
    this.allPaymentTypes();
    this.getCompany();
  },
};
</script>


<style scoped>
.reportDate {
  margin-top: -6px;
}

.topallreports {
  overflow-x: hidden !important;
}

.salestop {
  margin-left: -12px;
}

@media (max-width: 767px) {
  .allformstop {
    flex-direction: column;
  }
  .makecardresponsive {
    width: 100% !important;
  }

  .salessubmitdate {
    margin-left: 16px;
  }

  .salescard {
    width: 83% !important;
  }

  .selectstore {
    margin-left: 12px;
    flex-direction: row;
  }

  #stores {
    width: 140px !important;
  }

  #right-store {
    margin-left: 4px;
  }

  .topreportbtn {
    margin-left: 13.5px;
    margin-top: -15px;
    margin-bottom: 8px;
  }

  .searchexportrpts {
    margin-left: 17px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .allformstop {
    flex-direction: column;
  }

  .salessubmitdate {
    margin-left: 10px;
  }

  .allformstop {
    flex-direction: row;
  }

  .nexttopform {
    flex-direction: column;
  }

  .searchexportrpts {
    margin-left: -33px;
  }

  .topreportbtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
