<template>
    <!-- 
  If there is no variant use unit of measurement value instead

 -->
    <InventoryHeaderTab />
    <div class="tab-pane fade show active" id="allproduct" role="tabpanel" aria-labelledby="allproduct-tab">
        <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
            <div class="mt-2" style="font-size: 20px">Product List</div>
            <div class="d-flex gridtop">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
                        <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                        <input type="text" class="form-control mt-1" placeholder="Search product"
                            style="padding-left: 38px" v-model="query" />
                    </div>
                    <div class="dropdown">
                        <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                            Export
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a @click="dataToPrint()" class="dropdown-item" href="#">PDF</a>
                            </li>
                            <li>
                                <a @click="reusableMixinToCSV('products.csv')" class="dropdown-item" href="#">CSV</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center" style="overflow: auto; width: 100%">
                    <div>
                        <router-link :to="'/bulk-product-upload'" class="btn btn-primary mt-1 me-2" :class="permission.create_product == 0 ? 'disabled':''">
                            Bulk Upload
                        </router-link>
                    </div>
                    <div class="me-2">
                        <router-link to="/create-product" class="btn btn-primary text-white mt-1" :class="permission.create_product == 0 ? 'disabled':''">
                                <!-- <font-awesome-icon icon="plus" /> -->
                                Create Product
                        </router-link>
                    </div>
                    <div class="">
                        <button class="btn btn-danger text-white mt-1" :data-bs-toggle="permission.delete_product == 1 ? 'modal' : ''" :class="permission.delete_product == 1 && selectedModel.length  > 0 ?  '' : 'disabled'"
                            data-bs-target="#bulkModal">
                            Bulk Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div v-if="loading == true" class="text-center py-5">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="text-center py-5" v-if="details.length == 0 && loading == false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your product list is empty!</div>
            </div>

            <div v-if="loading == false && details.length > 0">
                <div class="card-toolbar">
                    <!--begin::Toolbar-->
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                            <select v-model="tableData.length" @change="fetchData()"
                                class="form-select form-select-solid fw-bolder">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option :value="pagination.total">All</option>
                            </select>
                        </div>
                        <!--begin::Actions-->
                    </div>
                    <!--end::Toolbar-->
                </div>
                <div class="">
                    <table class="table table-sm-responsive">
                        <thead class="thead-white" style="border-style: hidden !important">
                            <tr class="pb-5 text-left">
                                <th scope="col">
                                    <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" />
                                </th>
                                <th class="font-weight-bold heading" style="width:30%;">Lists of Products</th>
                                <th class="font-weight-bold heading">Quantity</th>
                                <th class="font-weight-bold heading">Pcs Per Carton</th>
                                <th class="font-weight-bold heading">Cartons</th>
                                <th class="font-weight-bold heading">Pcs</th>
                                <th class="font-weight-bold heading">Cost Price({{ currentUserCurrency?.symbol }})</th>
                                <th class="font-weight-bold heading">Selling Price({{ currentUserCurrency?.symbol}})</th>
                                <th class="font-weight-bold">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(products, index) in details" :key="index" class="text-left">
                                <td>
                                    <input type="checkbox" name="" id="" v-model="selectedModel" :value="products.id" />
                                </td>
                                <td class="text-secondary py-3 row1">
                                    {{ products.product_name }} 
                                </td>
                                <td class="text-secondary py-3 row1">
                                    <!-- {{ products.stores?.reduce(function (sum, p) {return parseFloat(sum) + p.pivot.quantity}, 0) }} -->
                                    <!-- {{ products.store_qty ?? products?.quantity}} -->
                                    {{ products.store_qty ?? 0 }}

                                </td>
                                <td class="text-secondary py-3 row1">
                                    {{ products.variants.filter(v => v.variant_selling_price == Math.max.apply(Math,
                                    products.variants.map((variant) => { return variant.variant_selling_price;
                                    })))[0]?.variant_quantity ?? 'N/A' }}
                                </td>
                                <td class="text-secondary py-3 row1">
                                    {{ (carton[products.id] == undefined) ? "N/A" : carton[products.id] }}
                                    {{calculatePieces(products)}}
                                </td>
                                <td class="text-secondary py-3 row1">{{ (pieces[products.id] == undefined) ? "N/A" :
                                pieces[products.id] }}</td>
                                <td class="text-secondary py-3 row1">
                                    {{ $filters.formatMoney(products?.cost_price)  }}
                                </td>
                                <td class="text-secondary py-3 row1">
                                    {{ $filters.formatMoney(products?.selling_price)  }}
                                </td>
                                <td class="py-3">
                                    <router-link :to="permission.view_product==1 ? `/view-product/${products.id}` : '#'" class="text-dark">
                                        <font-awesome-icon icon="eye" style="font-size: 15px" :style="permission.view_product==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                    </router-link>
                                    <router-link :to="permission.edit_product==1 ? `/edit-product/${products.id}` : '#'" class="text-dark">
                                        <font-awesome-icon prefix="fal" icon="pen" class="mx-2" :style="permission.edit_product==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"
                                            style="font-size: 15px" />
                                    </router-link>
                                    <font-awesome-icon @click.prevent="getModalCurrentId(products.id)" type="button" :style="permission.delete_product==1 ? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"
                                        :data-bs-toggle="permission.delete_product==1 ? 'modal' : ''" data-bs-target="#singleModal" icon="trash"
                                        style="font-size: 15px" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="loading == true" class="text-center">
                        <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
            @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
    </div>

    <SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleProductDeleteButton"
        :modalText="'Are you sure you want to delete this product'" :action="'deleteSingle'"></SingleDeleteModal>

    <BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkDeleteProductButton"
        :modalText="'Are you sure you want to delete this products'"></BulkDeleteModal>
</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";

import axios from "axios";
import {
    useToast
} from "vue-toastification";
import SingleDeleteModal from "../../../components/ActionModal.vue";
import BulkDeleteModal from "../../../components/bulkDeleteModal.vue";

import jsPDF from "jspdf";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import "jspdf-autotable";
import {
    useStore
} from "vuex";

export default {
    components: {
        InventoryHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal,
    },
    mixins: [reusableMixin],
    data() {
        let sortOrders = {};
        let columns = [{
            width: "16%",
            label: "Name",
            name: "name",
            sortable: false,
        },
        {
            width: "15%",
            label: "Sortcode",
            name: "sortcode",
            sortable: false,
        },
        {
            width: "15%",
            label: "Phonecode",
            name: "phonecode",
            sortable: false,
        },
        {
            width: "5%",
            label: "Status",
            name: "status",
            sortable: false,
        },
        {
            width: "5%",
            label: "Actions",
            name: "actions",
            sortable: false,
        },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            headerArrayColumn: ['S/N', 'Product Name', 'Qty', 'Pcs Per Carton', 'Cartons', 'Pcs', 'Cost Price', 'Selling Price'],
            print_content: [],
            details: [],
            loading: false,
            query: null,

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            carton: [],
            pieces: [],
            details_: []

        }
    },
    methods: {
        dataToPrint() {
            this.print_content = []
            this.details.map((product, idx) => {
                this.print_content.push({
                    sn: idx + 1,
                    product_name: product.product_name,
                    quantity: product.store_qty ?? product.quantity,
                    pcs_per_carton: product.variants.length > 0 ? product.variants.filter(v => v.variant_selling_price == Math.max.apply(Math, product.variants.map((variant) => { return variant.variant_selling_price; })))[0]?.variant_quantity : 'N/A',
                    carton: product.variants.length > 0 ? this.carton[product.id] : 'N/A',
                    pcs: product.variants.length > 0 ? this.pieces[product.id] : 'N/A',
                    cost_price: product.cost_price,
                    selling_price: product.selling_price,
                })
            });
            this.drawPdf(this.print_content, this.headerArrayColumn, 'l', 'Product', 'Product')
        },

        calculatePieces(product) {
            console.log('-----', product.variants);
            let variant = product.variants

            if (variant.length > 0) {

                let product_quantity = parseInt(product.store_qty ?? product?.quantity)
                let quantity = product_quantity

                let variant_qty = variant.filter(v => v.variant_selling_price == Math.max.apply(Math, variant.map((variant) => {
                    return variant.variant_selling_price;
                })))[0]?.variant_quantity
                let carton = 0

                // console.log('quantity----', variant_qty);


                while (parseInt(variant_qty) < quantity) {                   
                    quantity -= variant_qty
                    carton += 1
                    if(quantity < variant_qty){break;}
                }
                this.pieces[product.id] = variant.length > 0 ? quantity : 'N/A'
                this.carton[product.id] = variant.length > 0 ? carton : 'N/A'
            }


        },

        exportToPdf() {
            const doc = new jsPDF();
            doc.autoTable({
                html: ".table"
            });
            doc.save("products.pdf");
        },

        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = '/inventory/product') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                // this.details.forEach((product)=>{
                //     this.calculatePieces(product, id) 
                // 
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;
            });
        },

        callSingleProductDeleteButton() {
            axios.delete(`/inventory/product/${this.currentID}`).then(() => {
                this.toast.success('Product deleted successfully')
                this.selectedModel = []
                this.fetchData()
            }).catch((error) => {
                this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
            })

        },

        callBulkDeleteProductButton() {
            axios.post(`/inventory/product/delete-bulk`, {
                products: this.selectedModel,
            }).then((response) => {
                this.fetchData();
                this.selectedModel = []
                this.toast.success(response.data.data);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } else if (error.response.status == 400) {
                    this.toast.error(error.response.data.message[0].id);
                } else {
                    this.toast.error(error.response.data.message);
                }
            });
        },

        getModalCurrentId(id) {
            this.currentID = id;
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        }
    },

    watch: {
        query() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        /* width: 21rem; */
        width: 100%;
    }
}
</style>


