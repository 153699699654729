<template>
<InventoryHeaderTab />

<div class="mt-3 gridtop">
    <!-- <div class="mt-2 px-5" style="font-size: 14px;">Stock Adjustment</div> -->
    <div class="d-flex justify-content-between  my-3">
        <div class="mt-2 fw-bold" style="font-size:20px">
            <router-link to="/all-purchases">
                <font-awesome-icon class="arrowradius ms-1" prefix="far" icon="angle-left" />
            </router-link>
            {{purchase?.batch_code}}
        </div>

        <div class="text-center py-5" v-if="purchase_items.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">Your purchase list is empty!</div>
        </div>
        <div class="d-flex gridtop">
            <div class="me-2">
                <button class="btn px-5" style="background:white; border:1px solid gray;" @click="createPDF(purchase?.batch_code)">Download as PDF</button>
            </div>
            <div class="d-flex">
                <router-link :to="'/purchase-receipt'">
                    <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
                        Print 58mm
                    </button>
                </router-link>
            </div>
            <div class="d-flex">
                <router-link to="/print-stock-adjustment">
                    <button class="btn px-5 me-2" style="background:white; border:1px solid gray;">
                        Print 80mm
                    </button>
                </router-link>
            </div>
            <div class="d-flex">
                <router-link to="/print-stock-adjustment">
                    <button class="btn px-5 mr-2" style="background:white; border:1px solid gray;">
                        Print A4
                    </button>
                </router-link>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-4 mb-3 px-4 py-3" id="pdf">
        <div class="d-flex justify-content-between my-5 headercreate" style="font-size: 15px;">
            <div class="text-secondary">Date: <span class=" text-dark fw-bold">{{dateTime(purchase?.date)}}</span></div>
            <div class="text-secondary">PURCHASE NUMBER: <span class="text-dark fw-bold">{{purchase?.batch_code}}</span></div>
            <div class="text-secondary">STORE: <span class="text-dark fw-bold">{{purchase?.store?.store_name}}</span></div>
            <div class="text-secondary">SUPPLIER: <span class="text-dark fw-bold">{{purchase?.supplier?.supplier_name}}</span></div>
            <div class="text-secondary">PURCHASED BY: <span class="text-dark fw-bold">{{purchase?.user?.fullname}}</span></div>
        </div>
        <div class="">
            <table class="table table-sm-responsive">
                <thead class="thead-light" style="border-style: hidden !important;">
                    <tr class="pb-5 text-center">
                        <th class="fw-bold" style="font-size: 14px;">S/N</th>
                        <th class="fw-bold" style="font-size: 14px;">Products</th>
                        <th class="fw-bold" style="font-size: 14px;">Cost Price</th>
                        <th class="fw-bold" style="font-size: 14px;">Variant</th>
                        <th class="fw-bold" style="font-size: 14px;">Quantity</th>
                        <!-- <th class="fw-bold" style="font-size: 14px;">Reason</th> -->
                        <!-- <th class="fw-bold" style="font-size: 14px;">Adjustment Type</th> -->
                        <th class="fw-bold" style="font-size: 14px;">Total (₦)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(p, index) in purchase_items" :key="p.id">
                        <th class="text-secondary" style="font-size: 16px;"> {{index+1}} </th>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.product?.product_name}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.product?.cost_price}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.variant?.variant_name ?? 'N/A'}}</td>
                        <td class="text-secondary" style="font-size: 16px;">{{p?.quantity}}</td>
                        <!-- <td class="text-secondary" style="font-size: 16px;">{{p?.reason}}</td> -->
                        <!-- <td class="text-secondary" style="font-size: 16px;">{{p?.reason}}</td> -->
                        <td class="text-secondary" style="font-size: 16px;">{{p?.cost_price * p?.quantity}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <div class="me-3 fw-bold" style="font-size:19px">Grand Total:</div>
            <span style="font-size:19px">₦{{totalPrice}}</span>
        </div>
    </div>
</div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            purchase: null,
            purchase_items: []
        }
    },
    methods: {
        
        createPDF(batch_code) {
            var pdf = new jsPDF({
                unit: 'pt',
                orientation: 'l',
                lineHeight: 1.2
            });

            var element = document.getElementById('pdf');
            var width = element.style.width;
            var height = element.style.height;
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'JPEG', 0, 0, width, height);
                pdf.save(batch_code + '.pdf');
            });
        },

        getPurchase() {
            axios.get(`/inventory/purchase/${this.$route.params.id}`).then((response) => {
                this.purchase = response.data.data
                this.purchase_items = response.data.data.purchase_item

            }).catch(() => {
                this.toast.error("Oops, Something went wrong")
            })
        }
    },
    computed: {
        totalPrice() {
            let total = this.purchase_items.reduce((a, b) => {
                return a + parseFloat(b.cost_price) * b.quantity;
            }, 0);
            return (total).toFixed(2);
        }
    },
    created() {
        this.getPurchase()
    }

}
</script>

<style>

</style>
