<template>
    <InventoryHeaderTab />

    <div class="tab-content mb-3" id="myTabContent">
        <div class="tab-pane mt-3 fade show active card thecard rounded-lg px-3 py-3" id="company" role="tabpanel"
            aria-labelledby="company-tab">
            <div class="d-flex justify-content-between">
                <div>
                    <h4>Edit Product</h4>
                    <div class="text-muted" style="font-size: 10px">
                        Edit your product
                    </div>
                </div>
            </div>
            <div v-if="loading == true" class="text-center py-3">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="row">
                <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                    <li class="text-danger" style="list-style: none">
                        <small>{{ errors }}</small>
                    </li>
                </ul>
            </div>
            <div v-if="loading == false">
                <form @submit.prevent="editProduct" class="mt-4">
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">Bar Code</label>
                            <input v-model="bar_code" type="text" class="form-control" placeholder="Input bar code" />
                            <font-awesome-icon icon="barcode" class="scan" />
                            <!-- <i class="mdi mdi-barcode-scan iconify scan"></i> -->
                        </div>
                        <div class="form-group col-md-4">
                            <label>Product Name <span class="text-danger">*</span> </label>
                            <input v-model="product_name" type="text" class="form-control"
                                placeholder="Input product name" />
                        </div>

                        <div class="form-group col-md-4">
                            <label>Unit of Measurement <span class="text-danger">*</span> </label>
                            <Multiselect v-model="unit_of_measurement" :options="UOM" label="name" placeholder="Select unit of measurement" :searchable="true" :create-option="false" :close-on-select="true" />
                        </div>
                    </div>
                    <div class="row mt-3">

                        <div class="form-group col-md-6">
                            <label>Cost Price</label>
                            <input v-model="cost_price" type="text" class="form-control" placeholder="Input cost price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                @keyup="handleInput" />
                        </div>

                        <div class="form-group col-md-6">
                            <label>Mark Up (%)</label>
                            <input type="number" min=0 class="form-control" v-model="markup" @keyup="handleInput"
                                onkeypress="return /^[0-9]+$/i.test(event.key)" />
                            <span class="text-danger"><small>{{ markup_error }}</small></span>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="form-group col-md-4">
                            <label for="inputState">Selling Price</label>
                            <input v-model="selling_price" type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                placeholder="Input selling price" :disabled="isDisable" />
                        </div>

                        <div class="form-group col-md-4">
                            <label for="">Category</label>
                            <select id="" class="form-select" v-model="category" @change="categorySub($event)">
                                <option value="" disabled>Choose category</option>
                                <option v-for="catego in allCategories" :key="catego.id" :value="catego.id">
                                    {{ catego.category_name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="">Subcategory</label>
                            <select id="" class="form-select" v-model="subcategory">
                                <option v-if="dropDownLoading" selected disabled>Loading...</option>
                                <option v-if="!dropDownLoading">--Choose--</option>
                                <option v-for="subcat in allSubcategories" :key="subcat.id" :value="subcat.id">
                                    {{ subcat.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>Quantity</label>
                            <input type="text" v-model="quantity" disabled class="form-control"
                                placeholder="input quantity" />
                            <div style="font-size: 13px">
                                You have to purchase product to update quantity
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Warning Quantity</label>
                            <input v-model="warning_quantity" type="number" class="form-control"
                                placeholder="input warning quantity" onkeypress="return /^[0-9]+$/i.test(event.key)" />
                            <span class="text-danger"><small>{{ warningQty_error }}</small></span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="fw-bold" style="font-size: 15px">
                            APPLY TAX TO THIS PRODUCT
                        </div>
                        <div class="mt-3">
                            <span v-for="(tax) in taxes" :key="tax.id">
                                <input class="form-check-input" type="checkbox" :id="tax.id" :true-value="tax_id"
                                    :false-value="null" v-model="tax_id" name="profileImg" v-bind:value="tax.id"
                                    :checked="check(tax.id)">
                                <label style="margin-right:20px !important;margin-left:5px" class="form-check-label"
                                    for="flexCheckChecked">
                                    {{ tax.tax_name }} ({{ tax.tax_percentage }}%)
                                </label>
                            </span>
                        </div>
                        <div class="mt-3 mb-2 fw-bold" style="font-size: 15px">
                            PRICE VARIANT
                        </div>
                        <div style="font-size: 15px">
                            Add variant if this product is sold in multiple versions e.g pieces,
                            dozens, cartons. If you want to make use of variant, ensure the
                            quantity is saved in pieces.
                        </div>
                        <div v-for="(vary, index) in variants" :key="index.id" class="row mt-3">
                            <div class="form-group col-md-3">
                                <label for="inputEmail4">Variant Name</label>
                                <input v-model="vary.variant_name" type="text" class="form-control"
                                    placeholder="Input variant code" />
                            </div>
                            <div class="form-group col-md-3">
                                <label>Variant Price</label>
                                <input v-model="vary.variant_selling_price" type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    placeholder="Input variant price" />
                            </div>
                            <div class="form-group col-md-3">
                                <label>Variant Quantity</label>
                                <input v-model="vary.variant_quantity" type="number" class="form-control" onkeypress="return /^[0-9]+$/i.test(event.key)"
                                    placeholder="Input variant quantity" />
                            </div>
                            <div class="col-md-3 deletebtn">
                                <button @click.prevent="removeVariant(index.id)"
                                    class="btn btn-danger text-white fw-bold px-4">
                                    X
                                </button>
                            </div>
                        </div>
                        <div class="form-group col-md-4 mt-3">
                            <button @click.prevent="addVariant" class="btn" style="background-color: #DAE7FC">
                                Add Variant
                            </button>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-end">
                        <button type="submit" class="btn px-5 me-5 btn-primary text-white" v-if="crudLoading == false">Save</button>
                        <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 allbuttonsclr text-white" disabled>
                                <span class="visually-">Processing</span>
                        </button>
                        <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style src="@vueform/multiselect/themes/default.css">

</style>

<script>
import {
    onMounted,
    ref
} from "@vue/runtime-core";
import {
    useRoute,
    useRouter
} from "vue-router";
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import host from "../../../main"
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        InventoryHeaderTab,
        Multiselect
    },
    setup() {
        const {
            params
        } = useRoute();
        const product = ref("")
        const product_name = ref("");
        const cost_price = ref("");
        const selling_price = ref("");
        const category = ref("");
        const subcategory = ref("");
        const bar_code = ref("");
        const unit_of_measurement = ref("");
        const markup = ref("");
        const quantity = ref("")
        const max_warning_quantity = ref("");
        const warning_quantity = ref("");
        const variant_name = ref("");
        const variant_selling_price = ref("");
        const variant_quantity = ref("");
        const UOM = ref([])
        const image = ref(null);
        const new_image = ref('');
        const variants = ref([{
            variant_name: "",
            variant_selling_price: "",
            variant_quantity: ""
        },]);
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);
        const allCategories = ref([]);
        const allSubcategories = ref([]);

        const dropDownLoading = ref(false)

        const loading = ref(false);
        const store = ref({})

        const stores = ref({})
        const store_arr = ref([]);
        const taxes = ref([])
        const tax_id = ref([])
        const isDisable = ref(false)
        const markup_error = ref("")
        const warningQty_error = ref("")
        const crudLoading = ref(false)

        const previousTax = ref([])

        const check = (tax_id) => {
            for (let i = 0; i < previousTax.value.length; i++) {
                if (previousTax.value[i].id == tax_id) {
                    return true
                }
                return false;
            }
        }

        const handleInput = () => {
            if (Math.sign(markup.value) == -1) {
                markup_error.value = "Markup value must not be lesser than zero"
            } else {
                markup_error.value = ""
            }
            if (Math.sign(warning_quantity.value) == -1) {
                warningQty_error.value = "Warning quantity value must not be lesser than zero"
            } else {
                warningQty_error.value = ""
            }
            if (cost_price.value && markup.value) {
                // isDisable.value = true
                selling_price.value = markup.value == 0 ? product.value.selling_price : ((parseInt(markup.value) / 100) * parseInt(cost_price.value)) + parseInt(cost_price.value)
            } else {
                isDisable.value = false
            }
        }

        const categorySub = async(event)=>{
            dropDownLoading.value = true
            let category_id = event.target.value
            await axios
                .post("/settings/category/subcategory", {
                    category_id: category_id,
                })
                .then((res) => {
                    allSubcategories.value = res.data.data
                    dropDownLoading.value = false
                })
                .catch(() => {
                    dropDownLoading.value = true
                    toast.error("An error occured please try again");  
                });
        };

        const getStores = async () => {
            const getStores = await axios.get('/admin/store')
            const loadedStore = getStores.data.data
            stores.value = loadedStore
            stores.value.forEach((store) => {
                store_arr.value.push({
                    "label": store.store_name,
                    "value": store.id
                })
            })
        }

        const addVariant = () => {
            variants.value.push({
                variant_name: "",
                variant_selling_price: "",
                variant_quantity: "",
            });
        };

        const removeVariant = (index) => {
            variants.value.splice(index, 1);
        };


        const getUnitofMeasurement = async () => {
            await axios
                .get("/inventory/product/uom/unit-of-measurement")
                .then((res) => {
                    let uom = res.data.data
                    uom.forEach((u) => {
                        UOM.value.push({value:u.id, name:u.name})
                    })
                })
                .catch(() => {
                    toast.error("An error occured please try again");  
                });
        }
        const onFileSelected = (event) => {
            let file = event.target.files[0];
            if (file.size > 5048770) {
                toast.warning("Image too large")
                return;
            } else {
                let reader = new FileReader();
                reader.onload = (event) => {
                    new_image.value = event.target.result;
                };
                reader.readAsDataURL(file);
            }
        }
        const editProduct = async () => {
            crudLoading.value = true
            await axios
                .put(`/inventory/product/web/${params.id}`, {
                    store: store.value.value,
                    tax_id: tax_id.value.filter(x => x !== null) ?? null,
                    image: new_image.value,
                    product_name: product_name.value,
                    cost_price: cost_price.value,
                    selling_price: selling_price.value,
                    category: category.value,
                    subcategory: subcategory.value,
                    bar_code: bar_code.value,
                    unit_of_measurement: unit_of_measurement.value,
                    markup: markup.value,
                    max_warning_quantity: max_warning_quantity.value,
                    warning_quantity: warning_quantity.value,
                    variant: variants.value,
                    variant_name: variant_name.value,
                    variant_selling_price: variant_selling_price.value,
                    variant_quantity: variant_quantity.value,
                })
                .then((res) => {
                    crudLoading.value = false
                    router.push("/all-products");
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message
                    }
                    if (error.response.status == 403) {
                        toast.error(error.response.data.message);
                    }
                    if (error.response.status == 400) {
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                });
        };

        const getProduct = async () => {
            await axios.get(`/inventory/product/${params.id}`).then((response) => {
                product.value = response.data.data;
                const theproduct = product.value
                loading.value = false;
                variants.value = theproduct.variants
                image.value = theproduct.image;
                bar_code.value = theproduct.bar_code;
                bar_code.value = theproduct.bar_code;
                product_name.value = theproduct.product_name;
                quantity.value = theproduct.quantity;
                cost_price.value = theproduct.cost_price;
                markup.value = theproduct.markup;
                selling_price.value = theproduct.selling_price;
                category.value = theproduct.category;
                subcategory.value = theproduct.subcategory;
                unit_of_measurement.value = theproduct?.uom?.id;
                warning_quantity.value = theproduct.warning_quantity;

                theproduct.tax.forEach((t) => {
                    previousTax.value.push(t);
                    tax_id.value.push(t.id)
                })
            }).catch(() => {
                loading.value = false;
                toast.error("An error occured please try again");
            })

        }

        const getAllSubcategories = async () => {
            await axios.get("settings/subcategory/all").then((response)=>{
                allSubcategories.value = response.data.data;
            }).catch((error)=>{
                toast.error(error.response.data.message, {
                    timeout: 10000,
                });
            })            
        };

        onMounted(async () => {
            loading.value = true
            getStores()
            getUnitofMeasurement()
            getAllSubcategories()
            const result = await axios.get('settings/tax')
            taxes.value = result.data.data

            const response = await axios.get("/settings/category/all");
            allCategories.value = response.data.data;

            

            getProduct()

            if (cost_price.value && markup.value) {
                isDisable.value = true
            }

        });

        return {
            crudLoading,
            categorySub,
            allSubcategories,
            subcategory,
            dropDownLoading,
            product,
            product_name,
            cost_price,
            selling_price,
            category,
            bar_code,
            unit_of_measurement,
            markup,
            max_warning_quantity,
            warning_quantity,
            variants,
            editProduct,
            addVariant,
            variant_name,
            variant_selling_price,
            variant_quantity,
            quantity,
            removeVariant,
            image,
            validationErrors,
            allCategories,
            loading,
            host,
            onFileSelected,
            new_image,
            store,
            stores,
            store_arr,
            taxes,
            handleInput,
            tax_id,
            check,
            previousTax,
            isDisable,
            warningQty_error,
            markup_error,
            UOM,
            getUnitofMeasurement
        };
    },
};
</script>

<style scoped>
.img-icon {
    position: absolute;
    z-index: 7;
    margin-top: 43px;
    margin-left: 11px;
    color: white;
}

.imgedit {
    position: relative;
}

@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .thebtn {
        margin-right: 5px !important;
    }
}
</style>
