<template>
<SettingHeaderTab />

<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
        <div class="mt-2" style="font-size:24px"> Customer List </div>
        <div class="d-flex gridtop">
            <div class="d-flex">
                <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
                    <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute px-2" />
                    <input type="text" class="form-control " placeholder="Search customers" style="padding-left: 38px;" v-model="query" />
                </div>

                <div class="dropdown">
                    <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../private-assets/images/Vector.png" class="me-2" alt=""> Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <a @click="drawPdf(print_content, headerArrayColumn,'p','Customers','Customer')" class="dropdown-item" href="#">PDF</a>
                        <li><a @click="reusableMixinToCSV('customers.csv')" class="dropdown-item" href="#">CSV</a></li>
                    </ul>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <router-link to="bulk-customers-upload" class="btn btn-primary mt-1 me-2" :class="permission.create_customers == 1 ? '' : 'disabled'">Bulk Upload</router-link>
                </div>
                <div>
                    <router-link to="create-customer" class="btn btn-primary text-white mt-1 me-2" :class="permission.create_customers == 1 ? '' : 'disabled'">
                        Create Customer
                    </router-link>
                </div>
                <div class="mt-1">
                    <button class="btn btn-danger text-white" data-bs-toggle="modal" data-bs-target="#bulkModal" :class="permission.delete_customers == 1 && selectedModel.length  > 0 ?  '' : 'disabled'">Bulk Delete</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3 cardpadding" style="overflow-x: auto;">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-else-if="details.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
            <div class="fw-bold mt-3">Your customer bucket is empty!</div>
        </div>

        <div class="mb-5 cardpadding" v-if="details.length > 0 && loading == false">
            <div class="card-toolbar">
                <!--begin::Toolbar-->
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
                <!--end::Toolbar-->
            </div>
            <div class="table-responsive">
                <table class="table align-middle table-row-dashed fs-6 gy-5" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                    <thead style="!important;">
                        <tr class="bg-white">
                            <th scope="col">
                                <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" />
                            </th>
                            <th scope="col" class="heading">Customer Name</th>
                            <th scope="col" class="heading">Email</th>
                            <th scope="col" class="heading">Phone Number</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="customers in details" :key="customers.id">
                            <td class="py-3"><input type="checkbox" name="" id="" v-model="selectedModel" :value="customers.id" /> </td>
                            <td class="py-3 row1">{{ customers.customer_name }}</td>
                            <td class="py-3 row1">{{customers.email}}</td>
                            <td class="py-3 row1" style='mso-number-format:"\@";'>{{ customers.phone_no}}</td>
                            <td class="py-3">
                                <div class="" style="font-size: 13px;">
                                    <router-link :to="permission.edit_customers==1 ? {name:'Edit Customer', params:{id:customers.id}} : '#'">
                                        <font-awesome-icon prefix="fal" icon="pen" class="me-2" style="font-size: 15px;" :style="permission.edit_customers==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                    </router-link>
                                    <font-awesome-icon type="button" :data-bs-toggle="permission.delete_customers==1 ? 'modal' : ''" data-bs-target="#singleModal" icon="trash" style="font-size: 15px;" @click="getOneModal(customers)" :style="permission.delete_customers==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <pagination style="float:right;" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
            </pagination>
        </div>
    </div>
</div>
<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleCustomerDeleteButton" :modalText="'Are you sure you want to delete this customer'" :action="'deleteSingle'"></SingleDeleteModal>

<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkCustomerDeleteButton" :modalText="'Are you sure you want to delete this customers'"></BulkDeleteModal>
</template>

<style>
.form-select.form-select-solid {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease, background-color .2s ease;
}
</style>

<script>
import SettingHeaderTab from './components/settingheadertab.vue';
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';
import {reusableMixin} from "../../../lib/reusableMixin";

import "jspdf-autotable";
import axios from 'axios';
import {
    useToast
} from 'vue-toastification';
import { useStore } from 'vuex';
export default {
    components: {
        SettingHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal,
    },
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn:['S/N','Customer Name', 'Email', 'Phone Number'],
            errorMessage: null,
            details: [],
            loading: false,
            query: null,
            customer_id: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                draw: 0,
                length: 5,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            permission_status:null,
            store: useStore()
            
        }
    },

    methods: {
        dataToPrint(details){
            this.print_content = []
            details.map((customer, idx) => {
                let result = {
                    sn: idx + 1,
                    customer_name: customer.customer_name,
                    email: customer.email,
                    phone_number: customer.phone_no,
                };
                this.print_content.push(result)
            });
        },
        getOneModal(customer) {
            this.customer_id = customer.id
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },
        fetchData(url = '/settings/customers') {
            this.loading = true
            this.tableData.query = this.query
            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData,
            }).then((response) => {
                let data = response.data;
                this.details = data.data.customers.data;
                this.dataToPrint(data.data.customers2)
                this.configPagination(data.data.customers);
                this.loading = false

            }).catch((error) => {
                this.loading = false
                if(error?.response?.status == 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }

            })
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        callSingleCustomerDeleteButton() {
            axios.delete(`/settings/customer/${this.customer_id}`).then(() => {
                this.toast.success('Customer deleted successfully')
                this.fetchData()
            }).catch((error) => {
                if(error?.response?.status == 400 || error?.response?.status == 500){
                    this.toast.error('Whoops, Something went wrong')
                }
                if(error?.response?.status == 403){
                    this.toast.error(error.response.data.message, {
                    timeout: 10000,
                });
                }
            })

        },

        callBulkCustomerDeleteButton() {
            axios.post(`/settings/customer/delete-bulk`, {
                customers: this.selectedModel,
            }).then((response) => {
                this.fetchData()
                this.toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } 

                if(error?.response?.status == 400 || error?.response?.status == 500 || error?.response?.status == 403){
                    this.toast.error(error.response.data.message);
                }
            })
        }

    },
    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        }
    },

    watch: {
        query() {
            this.fetchData()
        }
    },

    created() {
        this.fetchData();

    },

}
</script>

<style>
.row1{
    mso-number-format:"\@";
}
</style>