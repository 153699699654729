<template>

<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main><br><br>
      <div class="" v-if="!loading">
        <div class="container d-flex justify-content-center authtop">
          <div class="row justify-content-center align-self-center">
            <div class="w-100 text-center">
              <img v-if ="status == 200" src="../assets/images/checked.png" alt="" width="100" height="100"/>
              <img v-else src="../assets/images/close.png" alt="" width="100" height="100"/>

            </div>
            <div class="text-center mt-3 mb-3">
              <h1  v-if="status == 200">Success! </h1>
              <h1  v-else>Error! </h1>

            </div>
            <div class="w-100 text-center mb-4 text-success f-20" style="font-size:1.5;text-transform: capitalize;">{{ response }}</div>

            <div class="w-100 text-center my-3" v-if ="status != 200">Didn't receive the link? 
              <router-link to="/auth/send-email-verifcation" class="font-weight-bold text-success" style="text-decoration:none">Click to resend</router-link>
            </div>

            <div class="w-100 text-center mb-4 text-secondary">
              <router-link to="/auth/login" style="text-decoration:none">Back to Sign In</router-link>
            </div>
          </div>
        </div>
        <div class="text-secondary d-flex justify-content-between bottom-content-container thealtfooter"></div>
      </div>

    </main>
    <!-- End Main -->

    <Footer />

</div>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref } from "@vue/reactivity";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

export default {
  components: {
        Navbar,
        Footer,
    },

  setup() {
    const route = useRoute();
    const loading = ref(false)
    const response = ref('')
    const status =  ref(null)

    const verifyEmail = async () => {
      loading.value = true;
      await axios.post(`/verify-email`,{
        verification_code:route.params.token
      })
      .then((resp) => {
        response.value = resp.data.message;
        status.value = resp.status
        loading.value = false;
      }).catch((error) => {
        status.value = error
        response.value = error.response.data.message
        // toast.error(error.response.data.message, {
        //   timeout: 10000,
        // });
        loading.value = false;
      })
    };

    onMounted(async () => {
      verifyEmail()
    });
    return { route,loading,response,verifyEmail,status };
  },
}
</script>

<style>
.authbg {
  background: #F2FFF8;
  height: 100%;
}

.authtop {
  padding-top: 84px;
}

.inputborder {
  border-left: white;
}

.fontinput {
  font-size: 23px;
}

.footergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.bottom-content-container {
  padding-left: 113px;
  padding-right: 111px;
}

.bottom-footer-margin {
  margin-right: 14px;
}

.password-reset-success-icon {
  background: #9FFFCC;
  border-radius: 999px;
}

.thefooter {
  margin-top: 84px;
}

.thealtfooter {
  margin-top: 190px;
}

.buttongreen {
  background: green !important;
  color: white !important;
}

@media (max-width: 767px) {
  .bottom-content-container {
    margin-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
  }
}
</style>