<template>
<SettingHeaderTab />

<div>
    <div class="mt-3 px-2">
        <div class="mt-2 pl-3 font-weight-bold" style="font-size:24px"> Payment Type</div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-else-if="payment_types.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
            <div class="fw-bold mt-3">Your payment type bucket is empty!</div>
        </div>
        <div v-if="payment_types.length > 0 && loading == false">
            <div class="d-flex justify-content-between px-5 paymentpadding mb-3 py-3 inventoryborder" v-for="(payment_type, index) in payment_types" :key="payment_type.id">
                <div class="col">
                    <span style="text-transform: capitalize;">{{ payment_type.name }}</span>
                </div>
                <div class="text-secondary" style="font-size: 13px;">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 :value="payment_type.id" v-model="statuses[index]" @change="updatePaymentStatus(payment_type.id, $event)" :disabled="!permission.change_payment_type == 1">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
computed,
    ref
} from '@vue/reactivity';
import SettingHeaderTab from './components/settingheadertab.vue';
import axios from 'axios';
import {
    onMounted
} from '@vue/runtime-core';
import {
    watch
} from "vue";

import {
    useToast
} from 'vue-toastification';
import { useStore } from 'vuex';

export default {
    components: {
        SettingHeaderTab
    },

    setup() {
        const payment_types = ref([])
        const loading = ref(false)
        const statuses = ref([])
        const status = ref()
        const toast = useToast()
        const permission_status = ref(null)
        const store = useStore();

        const getPaymentTypes = async () => {
            loading.value = true
            await axios.get('/settings/payment_type')
                .then((response) => {
                    payment_types.value = response.data.data
                    payment_types.value.forEach((p, index) => {
                        statuses.value[index] = p.is_active
                    })
                    loading.value = false
                }).catch((error) => {
                    loading.value = false
                    if (error?.response?.status == 403) {
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                })

        }
        const updatePaymentStatus = async (id, evt) => {

            await axios.put('settings/payment_type/' + id, {
                status: evt.target.checked
            }).then((res) => {
                toast.success(res.data.message);
            }).catch((error) => {
                 axios.get('/settings/payment_type')
                .then((response) => {
                    payment_types.value = response.data.data
                    payment_types.value.forEach((p, index) => {
                        statuses.value[index] = p.is_active
                    })
                })
                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                }
                if (error.response.status == 403) {
                    toast.error(error?.response?.data?.message);
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
            })
        }
        onMounted(()=>{
            getPaymentTypes();
        })
        watch(() => {

        })
        const permission = computed(() => store.state.getCurrentUserPermission);


        return {
            permission,
            loading,
            payment_types,
            getPaymentTypes,
            updatePaymentStatus,
            status,
            statuses,
            permission_status
        }
    }
}
</script>

<style>
.form-switch .form-check-input {
    height: 24px !important;
    width: 48px !important;
    margin-top: -4px;
}

.form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}

.form-switch .form-check-input:checked {
    background-color: #004AAD;
    border-color: #004AAD;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

@media only screen and (max-width: 768px) {
    .paymentpadding {
        padding: 0px !important;
    }
}
</style>
