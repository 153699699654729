<template>
  <SettingHeaderTab />
  <div class="tab-pane mt-5 fade show active bg-white rounded-lg px-3 py-3"  v-if="loggedInUser.is_super == 1">
    <div class="d-flex justify-content-between">
      <div>
        <h4>Company Info</h4>
        <div class="text-muted" style="font-size: 10px">
          Set up your company profile
        </div>
      </div>
      <div class="editcompany">
        <router-link to="edit-company-info">
          <font-awesome-icon class="me-1" icon="edit" style="font-size: 20px" />
          <span>Edit Company Profile</span>
        </router-link>
      </div>
    </div>
    <div class="mt-3 d-flex">
      <div class="me-5 phototext">PHOTO</div>
      <div v-if="companyInfo.company_image">
        <img
          :src="host.hostName+companyInfo.company_image" alt=""
          class="logosindexnippet"
          style="height: 90px;width:90px"
        />
      </div>

      <div v-else>
        <img
          src="../../private-assets/images/user.png"
          alt=""
          class="logosindexnippet"
          style="height: 90px"
        />
      </div>
    </div>
    <div class="mt-4">
      <div class="row">
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            COMPANY NAME
          </div>
          <div class="mt-3">{{ companyInfo.company_name }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            EMAIL
          </div>
          <div class="mt-3">{{ companyInfo?.company_email }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            ADDRESS
          </div>
          <div class="mt-3">{{ companyInfo?.address }}</div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            COUNTRY
          </div>
          <div class="mt-3">{{ companyInfo?.country?.name }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            State
          </div>
          <div class="mt-3">{{ companyInfo?.state?.name }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            City
          </div>
          <div class="mt-3">{{ companyInfo?.city?.name }}</div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            PHONE NUMBER
          </div>
          <div class="mt-3">{{ companyInfo?.phone }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            POSTAL CODE
          </div>
          <div class="mt-3">{{ companyInfo?.postal_code }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            BUSINESS TYPE
          </div>
          <div class="mt-3">{{ companyInfo?.business_type }}</div>
        </div>


      </div>


      <div class="row mt-5">
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            RC NUMBER
          </div>
          <div class="mt-3">{{ companyInfo?.rc_number }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            NUMBER OF STAFF
          </div>
          <div class="mt-3">{{ companyInfo?.no_of_staff }}</div>
        </div>
        <div class="col">
          <div class="font-weight-bold text-muted" style="font-size: 14px">
            Currency
          </div>
          <div class="mt-3">{{ companyInfo?.currency?.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import SettingHeaderTab from "./components/settingheadertab.vue";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";
import host from "../../../main"
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: { SettingHeaderTab },
  setup() {
    const companyInfo = ref("");
    const profile = ref("");
    const store = useStore();

    const getCompanyInfo = async () => {
      const response = await axios.get("settings/company");
      companyInfo.value = response?.data?.data?.data?.company;

    };

    const loggedInUser = computed(() => store.state.getLoggedInUser);

    onMounted(()=>{
      getCompanyInfo();
    })


    return { companyInfo, profile, getCompanyInfo,host,loggedInUser };
  },
};
</script>

<style scoped>
.phototext {
  margin-top: 35px;
}
</style>
