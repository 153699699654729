<template>
<SettingHeaderTab />

<div class="mb-3">
    <div class="tab-pane mt-3 fade show active bg-white rounded-lg px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Create Customer</h4>
                <div class="text-muted" style="font-size: 10px;">Create customers here</div>
            </div>
        </div>

        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                <li class="text-danger" style="list-style: none">
                    <small>{{ errors }}</small>
                </li>
            </ul>
        </div>

        <form @submit.prevent="editCustomer" class="mt-4">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Customer Name</label>
                    <input v-model="customer_name" type="text" class="form-control" placeholder="Enter full name" onkeypress="return /[a-z, ]/i.test(event.key)"/>
                    <p class="form-text text-danger" v-if="validationErrors.customer_name">
                        {{ validationErrors.customer_name[0] }}
                    </p>
                </div>
                <div class="form-group col-md-4">
                    <label>Phone Number</label>
                    <input v-model="phone_no" type="text" class="form-control" placeholder="Enter phone number">
                </div>
                <div class="form-group col-md-4">
                    <label>Email Address</label>
                    <input type="text" class="form-control" placeholder="Enter email address" v-model="email">
                    <p class="form-text text-danger" v-if="validationErrors.email">
                        {{ validationErrors.email[0] }}
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4">
                    <label>Address</label>
                    <input type="text" class="form-control" placeholder="Enter customer's address" v-model="address">
                </div>
                <div class="form-group col-md-4">
                    <label>Country</label>
                    <select v-model="country_id" class="form-select" @change="getStates($event)">
                        <option>Choose Country</option>
                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4" v-if="Array.isArray(states)">
                    <label for="inputState">State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)" name="state_id">
                        <option value="">Choose state</option>
                        <option :value="state.id" v-for="state in states" :key="state?.id">{{ state?.name }}</option>
                    </select>
                </div>
                <div class="form-group col-md-4" v-else>
                    <label for="inputState">State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)" name="state_id">
                        <option value="">Choose state</option>
                        <option :value="default_state?.id" :key="default_state?.id">{{ default_state?.name }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-md-4" v-if="Array.isArray(cities)">
                    <label for="inputZip">City</label>
                    <select v-model="city_id" class="form-select" name="city_id">
                        <option value="">Choose city</option>
                        <option :value="city.id" v-for="city in cities" :key="city?.id">{{ city?.name }}</option>
                    </select>
                </div>

                <div class="form-group col-md-4" v-else>
                    <label for="inputZip">City</label>
                    <select v-model="city_id" class="form-select" name="city_id">
                        <option value="">Choose city</option>
                        <option :value="default_city?.id" :key="default_city?.id">{{ default_city?.name }}</option>
                    </select>
                </div>
                <div class="form-group col"></div>
                <div class="form-group col"></div>
            </div>
            <div class="mt-4 d-flex justify-content-end buttoncustomer">
                <button type="submit" class="btn px-5 me-5 btn-primary text-white" v-if="crudLoading == false">Update</button>
                <button type="submit" class="btn px-5 me-5 btn-primary text-white" v-if="crudLoading == true" disabled>
                    Processing
                </button>

                <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">Cancel</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    ref
} from '@vue/reactivity';
import SettingHeaderTab from './components/settingheadertab.vue';
import axios from 'axios';
import {
    useRouter
} from 'vue-router';
import {
    useToast
} from 'vue-toastification';
import {
    useRoute
} from 'vue-router';

import {
    onMounted
} from '@vue/runtime-core';
export default {
    components: {
        SettingHeaderTab
    },
    setup() {
        const customer_name = ref('')
        const phone_no = ref('')
        const country_id = ref('')
        const city_id = ref('')
        const state_id = ref('')
        const email = ref('')
        const address = ref('')
        const loading = ref(false)
        const crudLoading = ref(false)
        const router = useRouter()
        const validationErrors = ref('')
        const toast = useToast()
        const route = useRoute();
        const countries = ref([])
        const states = ref(null);
        const cities = ref(null)

        const default_state = ref("")
        const default_city = ref("")

        const singleCustomer = async () => {
            let id = route.params.id
            await axios.get('/settings/customer/' + id).then((response) => {
                customer_name.value = response.data.data.customer_name
                phone_no.value = response.data.data.phone_no
                email.value = response.data.data.email
                city_id.value = response.data.data.city_id
                country_id.value = response.data.data.country_id
                state_id.value = response.data.data.state_id
                address.value = response.data.data.address
                default_city.value = response.data.data.city
                default_state.value = response.data.data.state

                states.value = response.data.data.states;
                cities.value = response.data.data.cities;

            })
        }

        const editCustomer = async () => {
            let id = route.params.id
            crudLoading.value = true
            await axios.put('settings/customer/' + id, {
                customer_name: customer_name.value,
                phone_no: phone_no.value,
                country_id: country_id.value,
                city_id: city_id.value,
                state_id: state_id.value,
                email: email.value,
                address: address.value
            }).then((res) => {
                crudLoading.value = false
                router.push('/all-customers')
                toast.success(res.data.message);
            }).catch((error) => {
                if (error.response.status == 422) {
                    toast.warning("There is an error with your input");
                    validationErrors.value = error.response.data.message;
                }
                if (error.response.status == 403) {
                    toast.error(error.response.data.message);
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
                crudLoading.value = false
            })
        }

        const getAllCountries = async () => {
            const response = await axios.get('countries')
            countries.value = response.data.data.data
            loading.value = false
        }

        const getStates = async (event) => {
            let country_id = event.target.value
            const response = await axios.get('/states/' + country_id)
            states.value = response.data.data.data
            loading.value = false
        }

        const getCities = async (event) => {
            let state_id = event.target.value
            const response = await axios.get('/cities/' + state_id)
            cities.value = response.data.data.data
            loading.value = false
        }

        onMounted(singleCustomer(), getAllCountries())

        return {
            crudLoading,
            validationErrors,
            customer_name,
            phone_no,
            country_id,
            city_id,
            state_id,
            email,
            address,
            countries,
            states,
            cities,
            default_state,
            default_city,
            singleCustomer,
            editCustomer,
            getAllCountries,
            getStates,
            getCities

        }
    }
}
</script>

<style>
@media only screen and (max-width: 280px) {
    .buttoncustomer {
        flex-direction: column;
    }
}
</style>
