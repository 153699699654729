<template>

<div>
<AdminHeader />

<div class="mb-3">
    <div class="mt-5 active card thecard rounded-lg px-3 py-3">
        <div class="d-flex justify-content-between">
            <div>
                <h4>Edit Store</h4>
                <div class="text-muted" style="font-size: 10px">Edit store</div>
            </div>
        </div>
        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id" style="list-style:none">
                <li class="text-danger"><small>{{ errors }}</small></li>
            </ul>
        </div>
        <form @submit.prevent="editStore" class="mt-4">
            <div class="row">
                <div class="form-group col-md-4">
                    <label class="mb-2">Store Name</label>
                    <input v-model="store_name" type="text" class="form-control" placeholder="Enter store name" />
                </div>
                <div class="form-group col-md-4">
                    <label class="mb-2">Address</label>
                    <input v-model="address" type="text" class="form-control" placeholder="Enter store address" />
                </div>
                   <div class="form-group col-md-4">
                    <label class="mb-2">Country</label>
                    <select v-model="country_id" class="form-select" @change="getStates($event)">
                        <option>Choose Country</option>
                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-4">
                <div class="form-group col-md-4">
                    <label class="mb-2" for="inputState">State</label>
                    <select v-model="state_id" class="form-select" @change="getCities($event)" name="state_id">
                        <option value="">Choose state</option>
                        <option :value="state.id" v-for="state in states" :key="state?.id">{{ state?.name }}</option>
                    </select>
                </div>
     
                <div class="form-group col-md-4">
                    <label class="mb-2" for="inputZip">City</label>
                    <select v-model="city_id" class="form-select" name="city_id">
                        <option value="">Choose city</option>
                        <option :value="city.id" v-for="city in cities" :key="city?.id">{{ city?.name }}</option>
                    </select>
                </div>

                <div class="form-group col-md-4">
                    <label class="mb-2">Phone Number</label>
                    <input v-model="phone_no" type="text" placeholder="Enter phone number" class="form-control" />
                </div>
            </div>
            <div class="mt-4 d-flex justify-content-end">
                 <button type="submit" class="btn px-3 me-5 btn-primary text-white" v-if="crudLoading == false">Update Store</button>
                <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white" disabled>
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Processing</span>
                    </div>
                </button>
                <router-link to="/manage-store"><button type="submit" class="btn px-5 btn-secondary">
                        Cancel
                    </button></router-link>
            </div>
        </form>
    </div>
</div>

</div>
</template>

<script>
import {
    ref
} from "@vue/reactivity";
import AdminHeader from "./administrationheadertab.vue";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    onMounted
} from "@vue/runtime-core";
export default {
    components: {
        AdminHeader
    },
    setup() {
        const store_name = ref("");
        const phone_no = ref("");
        const address = ref("");
        
        const countries = ref([])
        const cities = ref(null)
        const states = ref(null)
        const country_id = ref('')
        const city_id = ref('')
        const state_id = ref('')
        const loading = ref(false)

        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref("");
        const permission_status =  ref(null)
        const crudLoading = ref(false)

        const {
            params
        } = useRoute();


        const singleStore = async () =>{
          const storeCall = await axios.get(`/admin/store/${params.id}`)
            const thestore = storeCall.data.data.store;
            const thestates = storeCall.data.data.states;
            const thecities = storeCall.data.data.cities;
            store_name.value = thestore.store_name;
            phone_no.value = thestore.phone_no;
            address.value = thestore.address;
            city_id.value = thestore.city_id;
            state_id.value = thestore.state_id;
            country_id.value = thestore.country_id;

            states.value = thestates
            cities.value = thecities

        }
        const editStore = async () => {
            crudLoading.value = true
            axios
                .put(`/admin/store/${params.id}`, {
                    store_name: store_name.value,
                    phone_no: phone_no.value,
                    address: address.value,
                    city_id: city_id.value,
                    state_id: state_id.value,
                    country_id: country_id.value,
                })
                .then(() => {
                    crudLoading.value = false
                    router.push("/manage-store");
                    toast.success("Store updated successfully");
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        toast.warning("Your inputs are not valid, kindly recheck");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                    crudLoading.value = false
                });
        };


        const getAllCountries = async () => {
            const response = await axios.get('countries')
            countries.value = response.data.data.data
            loading.value = false
        }

        const getStates = async (event) => {
            let country_id = event.target.value
            // clear cities
            cities.value = []
            const response = await axios.get('/states/' + country_id)
            states.value = response.data.data.data
            loading.value = false
        }

        const getCities = async (event) => {
            let state_id = event.target.value
            const response = await axios.get('/cities/' + state_id)
            cities.value = response.data.data.data
            loading.value = false
        }


         onMounted(singleStore(), getAllCountries())

        return {
            store_name,
            phone_no,
            address,
            countries,
            states,
            cities,
            country_id,
            city_id,
            state_id,
            validationErrors,
            editStore,
            getStates,
            getCities,
            permission_status,
            crudLoading
    
        };
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .createbtn {
        margin-right: 5px !important;
    }
}
</style>
