<template>
  <!-- Footer-->
  <footer class="theme3rd-bg footer">
        <div class="footer-top footer-border-light">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-4 m-15px-tb">
                        <div class="m-10px-b">
                          <img v-if="details.logo" :src="url.hostName+'images/logo/'+details.logo" style="max-height: 100px;" title="" alt="InventFlux"/>
                          <img v-else src="/static/img/logo.png" style="max-height: 100px;" title="" alt="InventFlux">
                        </div>
                        <p class="font-1" style="color: white;">InventFlux is an Inventory Management Software that captures all process automation.</p>
                        <form class="d-flex flex-row m-5px-b input-group" @submit.prevent="sendNewsletter">
                            <input type="email" v-model="newsletter_email" class="form-control border-radius-0 border-0" placeholder="you@yoursite.com" required>
                            <button v-if="loading ==false" class="m-btn m-btn-theme2nd flex-shrink-0" type="submit">Subscribe</button>
                            <button v-if="loading ==true" class="m-btn m-btn-theme2nd flex-shrink-0" disabled>
                              <div class="spinner-grow text-info" role="status">
                                <span class="visually-hidden">Processing</span>
                              </div>
                            </button>
                        </form>
                        
                    </div>
                    <div class="col-6 col-md-4 col-lg-2 m-15px-tb">
                        <h5 class="white-color font-w-600 h6 m-20px-b">
                            ABOUT
                        </h5>
                        <ul class="list-unstyled links-white footer-link-1">
                            <li>
                                <router-link to="/home">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/about">About InventFlux</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Contact Us</router-link>
                            </li>
                            <li>
                                <router-link to="/home">Documentation</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-4 col-lg-2 m-15px-tb">
                        <h5 class="white-color font-w-600 h6 m-20px-b">
                            USEFUL LINKS
                        </h5>
                        <ul class="list-unstyled links-white footer-link-1">
                            <li>
                                <router-link to="/blog">Blog</router-link>
                            </li>
                            <li>
                                <router-link to="/faq">FAQs</router-link>
                            </li>
                            <li>
                                <router-link to="/pricing">Pricing</router-link>
                            </li>
                            <li>
                                <router-link to="/auth/register">Get Started</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0 m-15px-tb">
                        <h5 class="white-color font-w-600 h6 m-20px-b">
                            LEGAL
                        </h5>
                        <ul class="list-unstyled links-white footer-link-1">
                            <li>
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                            </li>
                            <li>
                                <router-link to="terms-and-conditions">Terms & Condition</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-4 col-lg-2 m-15px-tb">
                        <h5 class="white-color font-w-600 h6 m-20px-b">
                            INFORMATION
                        </h5>
                        <address>
                            <p class="font-small white-color-light m-5px-b">{{details.address}}</p>
                            <p class="m-5px-b"><a class="theme2nd-color font-small border-bottom-1 border-color-theme2nd" :href="'mailto:'+details.email">{{details.email}}</a></p>
                            <p class="m-5px-b"><a class="theme2nd-color font-small border-bottom-1 border-color-theme2nd" :href="'tel:'+details.tel">{{details.tel}}</a></p>
                            <p class="m-5px-b"><a class="theme2nd-color font-small border-bottom-1 border-color-theme2nd" :href="'tel:'+details.tel2">{{details.tel2}}</a></p>
                        </address>
                         <div class="social-icon si-30 theme radius nav">
                            <a :href="details.facebook" target="_blank" style="cursor: pointer;"><i class="fab fa-facebook-f" style="color: white"></i></a>
                            <a :href="details.twitter" target="_blank" style="cursor: pointer;"><i class="fab fa-twitter" style="color: white"></i></a>
                            <a :href="details.linkedin" target="_blank" style="cursor: pointer;"><i class="fab fa-linkedin-in" style="color: white"></i></a>
                            <a :href="details.instagram" target="_blank" style="cursor: pointer;"><i class="fab fa-instagram" style="color: white"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom footer-border-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-center text-md-right">
                        <ul class="nav justify-content-center justify-content-md-start m-5px-tb links-white font-small footer-link-1">
                            <li><router-link to="/privacy-policy">Privace &amp; Policy</router-link></li>
                            <li><router-link to="/faq">Faq's</router-link></li>
                            <li><router-link to="/terms-and-conditions">Terms &amp; Condition</router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-6 text-center text-md-right">
                        <p class="m-0px font-small white-color-light">2024 © {{details.shortname}}. All rights reserved. Fully managed by <a href="https://www.essyp.com" target="_blank" style="color: #004aad;">Essyp</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer -->
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import hostName from '../main';

export default {
  data() {

    const toast = useToast();

    return {
        details: {},
        newsletter_email: '',
        loading: false,
        toast: toast,
        url: hostName,
    }
  },
  mounted() {
    this.pageData();
  },
  methods: {
    pageData() {
        axios.get("/admin/fetchProjectSetup").then((response) => {
            this.details = response.data.data;
        })
    },

    async sendNewsletter() {
      this.loading = true;
      await axios
        .post("/send-newsletter", {
          email: this.newsletter_email,
        })
        .then((res) => {
          this.loading = false;
          this.newsletter_email = '';
          this.toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response.status == 422 || error.response.status == 400) {
            this.toast.error(error.response.data.message);
          } else if (error.response.status == 500) {
            this.toast.error("An error occured please try again");
          } else if (error.response.status == 404) {
            this.toast.error("the API route does not exist!");
          } else {
            this.toast.error("An error occured please try again");
          }
          this.loading = false;
        });
    },
  }
  
}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>