<template>
<SettingHeaderTab />
<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between headercreate" style="font-size:24px">
        <div class=""> List of Taxes</div>
        <div class="d-flex">
            <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:2px">
                <i class="fa fa-search fa-fw text-muted position-absolute ps-2"></i>
                <input type="text" class="form-control " placeholder="Search taxes" style="padding-left: 38px;" v-model="query" />
            </div>
            <div>
                <button class="btn text-white btn-primary" data-bs-toggle="modal" data-bs-target="#createTaxModal" type="button" :class="permission.create_taxes == 1 ? '' : 'disabled'">Create Tax</button>
            </div>
        </div>
    </div>
    
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-else-if="taxes.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
            <div class="fw-bold mt-3">Your category bucket is empty!</div>
            <!-- <div>Create store</div> -->
        </div>

        <table class="table table-sm-responsive" v-if="loading == false && taxes.length > 0">
            <thead class="thead-white" style="border-style: hidden !important;">
                <tr class="pb-5">
                    <th class="fw-bold">Tax</th>
                    <th class="fw-bold">Value(%)</th>
                    <th class="fw-bold">Action</th>
                </tr>
            </thead>
            <tbody>

                <tr class="" v-for="tax in taxes" :key="tax.id">
                    <td>{{tax.tax_name}}</td>
                    <td>{{tax.tax_percentage}}</td>
                    <td>
                        <div class="" style="font-size: 13px;">
                            <font-awesome-icon @click="getOneModal(tax)" :data-bs-toggle="tax.can_delete && permission.delete_taxes ==1 ? 'modal' : ''" data-bs-target="#editTaxModal" type="button" prefix="fal" icon="pen" class="mx-2" :style="tax.can_delete && permission.delete_taxes ==1 ? {} : {'color':'#DCDCDC', 'cursor':'default'}" />
                            <font-awesome-icon @click="getOneModal(tax)" :data-bs-toggle="(tax.can_delete) && (permission.delete_taxes ==1) ? 'modal' : ''" data-bs-target="#deleteTaxModal" type="button" icon="trash" style="font-size: 15px;" :style="tax.can_delete && permission.delete_taxes ==1 ? {} : {'color':'#DCDCDC', 'cursor':'default'}" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    
    </div>
</div>

<div class="modal fade" id="editTaxModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editTaxModal">Edit Tax</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="updateTax">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Tax Name</label>
                            <input type="text" class="form-control" placeholder="Enter tax name" v-model="tax_name">
                        </div>
                        <div class="col-md-6">
                            <label>Tax percentage(%)</label>
                            <input type="text" class="form-control" placeholder="Enter percentage" v-model="tax_percentage"  onkeypress="return /^[0-9]+$/i.test(event.key)">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="tax_name < 1 && tax_percentage < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white" v-if="crudLoading == false">Update</button>
                        <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white">
                            <div class="spinner-grow text-info" role="status">
                                <span class="visually-hidden">Processing</span>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteTaxModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteTaxModal">Delete Tax </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img src="../../../assets/images/Frame.png" style="font-size: 70px;" alt="trash">
                </div>
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Delete
                </div>
                <div class="text-center">
                    Are you sure you want to delete this tax?
                </div>
            </div>
        
            <div class="mt-3 mb-5 text-center">
                <button @click.prevent="deleteTax" type="button" class="btn btn-success me-3" style="padding-left:2.5rem; padding-right:2.5rem" data-bs-dismiss="modal">Yes</button>
                <button type="button" class="btn" style="background: #DFEAFD; padding-left:2.5rem; padding-right:2.5rem" data-bs-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createTaxModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createTaxModal">Create Tax</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createTax">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Tax Name</label>
                            <input type="text" class="form-control" placeholder="Enter tax name" v-model="ctax_name">
                        </div>
                        <div class="col-md-6">
                            <label>Tax percentage(%)</label>
                            <input type="text" class="form-control" placeholder="Enter percentage" v-model="ctax_percentage"  onkeypress="return /^[0-9]+$/i.test(event.key)">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="ctax_name < 1 && ctax_percentage < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">Create</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import {
computed,
    ref
} from '@vue/reactivity'
import SettingHeaderTab from './components/settingheadertab.vue'
import axios from 'axios'
import {
    onMounted
} from '@vue/runtime-core'
import {
    useToast
} from 'vue-toastification'
import {
    watch
} from "vue";
import { useStore } from 'vuex'

// import { Modal } from 'bootstrap'
export default {
    components: {
        SettingHeaderTab
    },
    setup() {
        const taxes = ref('')
        const loading = ref(false)
        const toast = useToast()
        const tax_name = ref('')
        const tax_percentage = ref('')
        const ctax_name = ref('')
        const ctax_percentage = ref('')
        const validationErrors = ref('')
        const tax_id = ref('')
        const query = ref(null)
        const crudLoading = ref(false)
        const permission_status = ref(null)
        const store = useStore();

        const getOneModal = (tax) => {
            tax_id.value = tax.id
            tax_name.value = tax.tax_name
            tax_percentage.value = tax.tax_percentage
        }

        const deleteTax = async () => {
            await axios.delete(`/settings/tax/${tax_id.value}`).then((res) => {
                    getAllTaxes()
                    toast.success(res.data.message)
                })
                .catch((error) => {
                    if (error.response.status == 400) {
                        toast.error(error.response.data.message)
                    } else {
                        toast.error('Tax could not be deleted')

                    }

                })

        }
        const createTax = async () => {
            loading.value = true
            await axios.post('/settings/tax', {
                tax_name: ctax_name.value,
                tax_percentage: ctax_percentage.value
            }).then(() => {

                ctax_name.value = ''
                ctax_percentage.value = ''
                toast.success('Created tax successfull')
                getAllTaxes()
                loading.value = false

            }).catch((error) => {

                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                    validationErrors.value = error.response.data.message
                }
                if (error.response.status == 403) {
                    toast.warning(error.response.data.message);
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
                loading.value = false

            })
        }

        const updateTax = async () => {
            crudLoading.value = true
            await axios.put(`${'/settings/tax/' + tax_id.value}`, {
                tax_name: tax_name.value,
                tax_percentage: tax_percentage.value

            }).then(() => {
                toast.success('Created tax successfull')
                getAllTaxes()
                crudLoading.value = false

            }).catch((error) => {

                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                    validationErrors.value = error.response.data.message
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
                crudLoading.value = false

            })
        }

        const getAllTaxes = async () => {
            loading.value = true

            await axios.get('settings/tax', {
                params: {
                    query: query.value
                }
            }).then((response) => {
                taxes.value = response.data.data

                loading.value = false
            }).catch((error) => {
                loading.value = false
                if (error?.response?.status == 403) {
                    permission_status.value = error?.response?.status
                    toast.error(error.response.data.message);
                }
            })

        }
        const permission = computed(() => store.state.getCurrentUserPermission);

        watch(query, () => {
            getAllTaxes()
        });

        onMounted(() => getAllTaxes())

        return {
            permission,
            taxes,
            getAllTaxes,
            loading,
            crudLoading,
            createTax,
            updateTax,
            tax_name,
            tax_percentage,
            ctax_name,
            ctax_percentage,
            validationErrors,
            getOneModal,
            deleteTax,
            query,
            permission_status
        }
    }
}
</script>

<style>

</style>
