<template>
  <div class="">
    <div class="mt-2" style="font-size: 20px; color: #35413B; font-weight: 700">Subscription</div>
    <div id="overlay"></div>

    <h6 class="text-center header" style="margin-bottom:-40px">Choose the best Plan for your business</h6>
    <!-- <router-link to="/subscribe/create-plan">button</router-link> -->
    <div class="pos_pricing">
      <form action="">
        <div class="d-flex justify-content-center mt-0">
          <div class="package_padding">
            <!-- <div class="p-2 select_one"> -->
            <!-- <div class="d-flex justify-content-center align-items-center"> -->
            <label class="items labl" v-for="(cat) in categories" :key="cat.id">
              <input type="radio" name="" :id="cat.id" v-model="planData.category" :value="cat.name"
                style="visibility:hidden" @change="fetchData()">
              <!-- <div class="list">{{cat?.name}}</div> -->

            </label>
            <!-- </div> -->
            <!-- </div> -->
            <!-- {{ types }} -->
            <div class="radio_buttons d-flex justify-content-center">
              <div class="padd d-flex justify-content-evenly">
                <div class="radio-item" v-for="(t, index) in types" :key="index">
                  <input type="radio" :id="index" name="ritem" :value="t" v-model="planData.type" @change="fetchData()">
                  <label :for="index">{{ t }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row prices_container g-4 justify-content-center" v-if="!loading && plans.length > 0">

          <div class="col-lg-4 price col-md-6 col-sm-6 col" v-for="plan in plans" :key="plan.id" style="margin-top:0px">
            <input type="text" v-model="planData.amount" style="visibility:hidden;height:0px">
            <div class="content py-4">
              <div class="top_text text-center">
                <h2>{{ plan?.name }}</h2>
                <span v-if="plan?.price != 'Custom'">
                  <h1>{{ $filters.formatMoney(plan?.price, currentUserCurrency?.symbol) }}</h1>
                </span>
                <h1 v-else>{{ plan?.price }}</h1>
                <h3>PER {{ plan?.type == 'Monthly' ? 'MONTH' : plan?.type == 'Quarterly' ? 'THREE MONTHS' : plan?.type
                == 'Half Year' ? 'SIX MONTHS' : plan?.type == 'Yearly' ? 'YEAR' : '' }}</h3>

                <button type="button" style="padding:0px;border:0px" v-if="plan.name.toLowerCase() != 'premium'">
                  <label class="button labl">
                    <input type="radio" name="" :id="plan?.id" :value="plan?.id" v-model="planData.plan"
                      style="visibility:hidden" @click="checkIfEligible(plan)">
                    BUY NOW
                  </label>
                </button>
                <button v-else type="button" style="padding:0px;border:0px;" data-bs-toggle="modal"
                  data-bs-target="#modalId">
                  <label class="button labl">
                    <!-- <input type="radio" name="" :id="plan?.id" :value="plan?.id" v-model="planData.plan" style="visibility:hidden"> -->
                    CONTACT US
                  </label>
                </button>


                <h4><a href="https://inventflux.com">inventflux.com</a></h4>
                <div class="p-2">
                  <ul>
                    <li v-for="(description, index) in JSON.parse(plan.descriptions)" :key="index">{{ description }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading == true" class="text-center py-5">
          <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="text-center py-5" v-if="plans.length == 0 && loading == false">
          <div>
            <img src="../../private-assets/images/emptypng.png" alt="" />
          </div>
          <div class="fw-bold mt-3">
            No plan available yet!
          </div>
        </div>

      </form>
    </div>



    <!-- Modal -->
    <div class="modal fade" id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog mw-25 w-50 modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
                  <img src="../../../assets/images/contact2-.png" alt="trash">
                </div>
      
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Contact Us
                </div>
            <div class="text-center">
              <div>Call Customer Support: <span class="text-muted">{{ details?.tel  }}, {{ details?.tel2  }}</span></div>
              <div>Email: <span class="text-muted">{{ details?.email }} </span> </div>
            </div>
          </div>
          <div class="my-3 text-center">
            <button type="button" class="btn btn-default" style="border: 1px solid grey" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import { reusableMixin } from '../../../lib/reusableMixin';

export default {
  mixins: [reusableMixin],
  data() {
    return {
      plans: [],
      categories: [],
      types: [],
      details: [],
      loading: false,
      planData: {
        type: 'Monthly',
        category: 'IMS',
        plan: null,
        plan_details_id: null,
        amount: 0,
        user_sub_id: null,
        details: {},
      }

    }
  },
  methods: {
    checkIfEligible(plan) {
      this.planData.plan_details_id = plan.plan_detail_id
      this.planData.query = this.$route.query.query
      axios.post('/subscription/check-subscription', this.planData).then(() => {
       
        this.$route.query.query == 'upgrade' ? this.$router.push("/subscription-payment/" + plan.plan_detail_id + "?query=" + this.$route.query.query) : this.$router.push("/subscription-payment/" + plan.plan_detail_id);

      }).catch((error) => {
        this.loading = false
        this.show_payment = false
        this.toast.error(error.response.data.message);
      })
    },


    getActiveCategories() {
      axios.get("/subscription/category/active").then((response) => {
        this.categories = response.data.data
        this.planData.category = response?.data?.data[0]?.name //this is changed to

      })
    },

    pageData() {
        axios.get("/admin/fetchProjectSetup").then((response) => {
            this.details = response.data.data;
        })
    },

    getPlanTypes() {
      axios.get("/subscription/types").then((response) => {
        this.types = response.data.data
        this.planData.type = this.types[Object.keys(this.types)[0]]

      })
    },



    fetchData(url = "/subscription/change") {
      // document.getElementById("overlay").style.display = "block";
      this.loading = true;
      axios
        .get(url, {
          params: this.planData,
        })
        .then((response) => {
          document.getElementById("overlay").style.display = "none";
          this.loading = false;

          let data = response.data;
          this.plans = data.data.plans;

        })
        .catch((error) => {
          this.errorMessagem = error;
          this.loading = false;
        });
    },
  },

  mounted() {
    this.superUser()
    this.getActiveCategories()
    this.fetchData()
    this.getPlanTypes();
    this.pageData();
  },
  mountBefore() {

  }
}
</script>

<style scoped>
#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

@import url("https://fonts.googleapis.com/css?family=Inter");

.labl>input:checked+div {
  /* (RADIO CHECKED) DIV STYLES */
  background: #FCFFFD !important;
  border-radius: 10px !important;
  color: #037B40 !important;

  display: flex;
  width: 100.333%;
  justify-content: center;
  height: 60px;
  cursor: pointer;
  align-items: center;
}


.header {
  font-weight: 400;
  font-size: 24px;
  color: #18191F;
  margin-top: 30px;
}

.select_one {
  background: #DCFFEE;
  border-radius: 10px;
  /* margin-top: 100px; */
}

.select_one .items {
  display: flex;
  width: 33.333%;
  justify-content: center;
  height: 60px;
  cursor: pointer;
  align-items: center;
  /* padding:10px *remove */
}

.select_one .items .list {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0;

  /* 
  display: flex;
  width: 33.333%;
  justify-content: center;
  /* height: 60px; 
  align-items: center; */
}


.radio_buttons {
  margin: 30px 0;
}

.radio_buttons .padd {
  width: 90%;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #18191F;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #047B40;
  background-color: transparent;
}

.radio-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #047B40;
}

.package_padding {
  width: 70%;
}



.prices_container .col .content {
  background: #FFFFFF;
  border: 1px solid #CFD4D7;
  border-radius: 10px;
  min-height: 610px;
  max-height: auto;
}

.prices_container .col .content h2 {
  font-weight: 400;
  font-size: 24px;
  color: #5B5971;
  margin-bottom: 28px;
}

.prices_container .col .content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #3A3A3A;
}

.prices_container .col .content h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5B5971;
  margin: 20px 0;
}

.prices_container .col .content .button {
  padding: 1em 3em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5B5971;
  background: #FFFFFF;
  border: 1px solid #037B40;
  border-radius: 5px;
  cursor: pointer;
}

.prices_container .col .content h4 {
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0;
  color: #5B5971;
}

.prices_container .col .content h4 a {
  color: #037B40 !important;
}

.prices_container .col .content ul {
  list-style-image: url('../../../assets/images/down.png');
  text-align: start;
  list-style-position: outside;
}

.prices_container .col .content ul li {
  margin-bottom: 10px;
}

.prices_container .col .content .active {
  background: #037B40;
  color: white;
}

.pos_pricing {
  padding: 0 40px;
}

@media (min-width: 1700px) {
  .pos_pricing {
    width: 80%;
    margin: auto;
  }
}
</style>