<template>

        <div class="mt-3 gridtop">
            <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
                <div class="">
                    <div>
                        <h4>Make Purchases</h4>
                        <div class="text-muted" style="font-size: 10px">
                            Record Purchased goods from suppliers
                        </div>
                    </div>
                </div>
                <div class="row mx-2 mt-2">
                    <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                        <li class="text-danger">{{ errors }}</li>
                    </ul>
                </div>
                <form action="" class="mt-4">
                    <div class="row px-2">
                        <div class="mb-0 me-1 col-md-4 dateinputs salesdatetop form-group">
                            <label class="datelabel">Date <span class="text-danger">*</span> </label>
                            <Datepicker v-model="date" :enableTimePicker="true" class="form-control salesdate"
                                style="background: none; border: none"></Datepicker>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Store <span class="text-danger">*</span></label>
                            <!-- 
                    <select id="inputState" class="form-select" v-model="store_id" @change="getProductInStore($event)">
                        <option disabled selected>Select store</option>
                        <option v-for="stores in getStore" :key="stores.id" :value="stores.id">
                            {{ stores.store_name }}
                        </option>
                    </select> -->
                            <Multiselect v-model="store_id" label="name" :options="getStore" placeholder="Select store"
                                :searchable="true" :create-option="false" :close-on-select="true" />
                        </div>
                        <div class="form-group col-md-4">
                            <label>Supplier <span class="text-danger">*</span></label>
                            <Multiselect v-model="supplier" label="name" :options="supplier_arr"
                                placeholder="Select or create supplier" :searchable="true" :create-option="true"
                                :close-on-select="true" />
                            <small class="text-muted">Search or create a new supplier</small>
                        </div>
                    </div>
                </form>
                <div class="mt-3 overflow-auto" style="max-height:350px;">
                    <div class="text-danger">
                        <!-- <small> {{is_product_in_store_error}}</small> -->
                    </div>
                    <table class="table table-sm-responsive">
                        <thead class="thead-light" style="border-style: hidden !important">
                            <tr class="">
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:22%">
                                   <span> Product</span> 
                                   <button class="btn btn-sm btn-primary " @click="modalToggle()" style="padding:0px;width:20px;height:20px;margin-left:5%">+</button> 
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:5%">
                                    Available Qty
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:13%">
                                    Variant
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:13%">
                                    Cost Price
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:5%">
                                    Quantity
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:20%">
                                    Best Before
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px;width:13%">
                                    Amount
                                </th>
                                <th class="text-secondary font-weight-bold" style="font-size: 14px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(p, index) in product" :key="index">
                                <td style="width: 150px">
                                    <Multiselect v-model="p.product_id" label="name" :options="getProducts"
                                        :canClear="false" :searchable="true" :create-option="false"
                                        :close-on-select="true" @select="handleChange(p.product_id, index)" />
                                </td>

                                <td>
                                    <input disabled type="text" class="form-control" v-model="p.available_qty" />
                                </td>
                                <td v-if="p.variant.length > 0">
                                    <select class="form-select" v-model="p.product_variant_id"
                                        @change="handleVariant(p, index, p.product_variant_id)">
                                        <option disabled>Select variant</option>
                                        <option v-for="variant in p.variant" :key="variant.id" :value="variant.id">
                                            {{ variant.variant_name }}
                                        </option>
                                    </select>
                                </td>
                                <td v-else>
                                    <input type="text" class="form-control" min=0 :value="p.unit_of_measurement"
                                        disabled />
                                </td>
                                <td><input type="number" class="form-control" min=0 step="any" v-model="p.cost_price"
                                        :disabled="p.disable_field" @keyup="calculateAmount(p)" required /></td>
                                <td><input type="number" class="form-control" min=1 step="any" v-model="p.quantity"
                                        :disabled="p.disable_field" @keyup="calculateAmount(p)" onkeypress="return /^[0-9]+$/i.test(event.key)" required /></td>
                                <td>
                                    <Datepicker :disabled="p.disable_field" v-model="p.best_before"
                                        :enableTimePicker="false" :inputFormat="yyyy - MM - dd" class="form-control"
                                        style="background: none; border: none; margin-top: -6px"></Datepicker>
                                </td>

                                <td><input type="number" class="form-control" min=0 step="any" v-model="p.amount"
                                        @keyup="calculateTotal()" :disabled="p.disable_field" required /></td>
                                <td>
                                    <button class="btn btn-danger text-white fw-bold"
                                        @click.prevent="removeProduct(index, calculateTotal)" type="button">X</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group d-flex justify-content-end px-3 mb-2 mt-2">
                    <button class="btn" style="background-color: #DAE7FC" type="button" @click="addProduct">Add Product</button>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <div class="text-primary fs-4">
                        <span class="fw-bold me-2" style="font-size: 0.8em">Total Amount:</span>
                        <span style="font-size: 0.8em; color: #051B35">{{ $filters.formatMoney(form.total_cost, currentUserCurrency?.symbol)  }}</span>
                    </div>
                </div>
                <form @submit.prevent="makePurchase" class="mt-5">
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">Select Payment Type</label>
                            <!-- <Multiselect v-model="form.payment_type" label="name" :options="paymentTypes" :canClear="false" :searchable="true" :create-option="true" :close-on-select="true"  @select="handlePaymentType()"/> -->

                            <select v-model="form.payment_type" id="inputState" class="form-select"
                                @change="handlePaymentType()">
                                <option :value="pay.value" v-for="pay in paymentTypes" :key="pay.id">{{ pay.name }}
                                </option>
                            </select>
                            <small class="text-danger">{{ split_payment_error }}</small>
                        </div>
                        <div class="form-group col-md-4"
                            v-show="form.payment_type == 'cash' || form.payment_type == 'card' || form.payment_type == 'part'">
                            <label>Paid Amount</label>
                            <input type="number" min=0 step="any" class="form-control" v-model="form.paid_amount"
                                :disabled="paid_disabled" @keyup="handlePaidInput" />
                            <span class="text-danger"><small>{{ paid_amount_error }}</small></span>
                        </div>
                        <div class="form-group col-md-4"
                            v-show="form.payment_type == 'cash' || form.payment_type == 'card'">
                            <label>Change</label>
                            <input type="number" min=0 step="any" class="form-control" disabled v-model="form.change" />
                        </div>
                        <div class="form-group col-md-4"
                            v-show="form.payment_type == 'credit' || form.payment_type == 'part'">
                            <label>Balance</label>
                            <input type="text" class="form-control" v-model="form.balance" disabled />
                        </div>
                        <div class="form-group col-md-4" v-show="form.payment_type == 'split'">
                            <label>Cash</label>
                            <input type="number" class="form-control" name="cash" v-model="form.cash"
                                :disabled="cash_disabled" @keyup="handleSplitpayment($event)" />
                            <span class="text-danger"><small> {{ split_cash_error }}</small></span>
                        </div>
                        <div class="form-group col-md-4" min=0 step="any" v-show="form.payment_type == 'split'">
                            <label>Card</label>
                            <input type="number" min=0 step="any" class="form-control" name="card" v-model="form.card"
                                :disabled="card_disabled" @keyup="handleSplitpayment($event)" />
                            <span class="text-danger"><small> {{ split_card_error }}</small></span>

                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-end">
                        <button type="submit" class="btn px-5 me-5 btn-primary text-white"
                            v-if="crudLoading == false">Make Purchase</button>
                        <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white"
                            disabled>
                            <span class="visually-">Processing</span>
                        </button>
                        <button type="button" class="btn px-5 exportbtn" data-bs-toggle="modal"
                            data-bs-target="#singleModal">Cancel</button>
                    </div>
                </form>
            </div>

            <Alert :modalId="'singleModal'" @alertModal="handleAction()"
                :modalText="'Are you sure you want to cancel this purchase?'" :action="'alertModal'"
                title="Cancel Purchase"></Alert>


            <!-- Modal -->
            <div class="modal fade" id="create-product"  :class="{ show: modal_active, 'd-block': modal_active }" tabindex="-1" aria-labelledby="create-productLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Product</h5>
                            <button type="button" class="btn-close" @click="modalToggle()" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <createProductForm @saveProduct="callProductFunc" @modalToggle="modalToggle"></createProductForm>
                        </div>
                        <!-- <div class="modal-footer"> -->
                            <!-- <button type="button" class="btn px-5 exportbtn" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary">Save changes</button> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div v-if="modal_active" class="modal-backdrop fade show"></div>

        </div>

</template>

<script>
import {
    ref
} from "@vue/reactivity";
import {
    onMounted
} from "@vue/runtime-core";
import axios from "axios";
import {
    useReusable
} from "../../../lib/reusable";
import Multiselect from '@vueform/multiselect'
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";

// import jsPDF from "jspdf";
import { reusableMixin } from "../../../lib/reusableMixin";
import "jspdf-autotable";
import Alert from "../../../components/AlertModal.vue";
import createProductForm from "../inventorymgt/component/createProductForm";
export default {
    name: "MakePurchase",
    components: {
        Multiselect,
        Alert,
        createProductForm

    },
    mixins: [reusableMixin],
    setup() {
        const {
            paymentTypes,
            allPaymentTypes,
            getStore,
            allStore
        } = useReusable()


        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);

        const store_id = ref(null)
        const supplier_arr = ref([])
        const supplier = ref(null)
        const getProducts = ref([])
        const currentVariant = ref([])
        const product = ref([{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: 1,
            amount:(0).toFixed(2),
            best_before: null,
            disable_field: true

        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: 1,
            amount:(0).toFixed(2),
            best_before: null,
            disable_field: true
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: 1,
            amount:(0).toFixed(2),
            best_before: null,
            disable_field: true
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: 1,
            amount:(0).toFixed(2),
            best_before: null,
            disable_field: true
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            new_cost_price: "",
            selling_price: "",
            variant: [],
            product_variant_id: "",
            quantity: 1,
            amount:(0).toFixed(2),
            best_before: null,
            disable_field: true
        }])
        const date = ref(new Date())
        const form = ref({
            payment_type: 'cash',
            total_cost: '0.00',
            paid_amount: null,
            change: null,
            balance: null,
            cash: (0).toFixed(2),
            card: (0).toFixed(2),
        })

        const paid_disabled = ref(true)
        const cash_disabled = ref(true)
        const card_disabled = ref(true)
        const paid_amount_error = ref('')
        const split_payment_error = ref('')
        const split_cash_error = ref("")
        const split_card_error = ref("")
        const is_product_in_store_error = ref('')
        const crudLoading = ref(false)
        const product_result = ref([])

        const { getProductInStore, removeProduct } = useReusable(product)

        const modal_active = ref(false)


        const modalToggle = () => {
            let body='';
            body = document.getElementById("create-product")
            modal_active.value = !modal_active.value
            modal_active.value ? body.classList.add("modal-open") : body.classList.remove("modal-open")
        }

        const handleAction = () => {
            router.go(-1)
        }


        const handleSplitpayment = (event) => {
            let name = event.target.name
            let cash = form.value.cash
            let card = form.value.card
            if (cash == '') {
                cash = 0
            }
            if (card == '') {
                card = 0
            }

            if (Math.sign(cash) == -1) {
                cash = 0
                split_cash_error.value = "cash value must not be lesser than zero"
            } else {
                split_cash_error.value = ""
            }

            if (Math.sign(card) == -1) {
                split_card_error.value = "card value must not be lesser than zero"
                cash = 0
            } else {
                split_card_error.value = ""
            }


            // 
            if (name == 'cash') {
                form.value.card = parseFloat(form.value.total_cost - cash).toFixed(2)
                if (Math.sign(form.value.card) == -1) {
                    form.value.card  = parseFloat(0).toFixed(2)
                    form.value.cash  = parseFloat(form.value.total_cost).toFixed(2)
                }
            } else if (name == 'card') {
                form.value.cash = parseFloat(form.value.total_cost - card).toFixed(2)
                if (Math.sign(form.value.cash) == -1) {
                    form.value.cash  = parseFloat(0).toFixed(2)
                    form.value.card  = parseFloat(form.value.total_cost).toFixed(2)
                }
            }

        }

        const handlePaymentType = () => {
            let paymentType = form.value.payment_type
            if (paymentType == 'credit') {
                form.value.balance = form.value.total_cost
                form.value.paid_amount = null
                form.value.cash = null
                form.value.card = null
            } else if (paymentType == 'part') {
                form.value.change = null
                form.value.balance = form.value.total_cost
                form.value.paid_amount = (0).toFixed(2)
                form.value.cash = null
                form.value.card = null
                if (form.value.paid_amount < form.value.total_cost) {
                    form.value.balance = (form.value.total_cost - form.value.paid_amount).toFixed(2)
                }
            } else if (paymentType == 'split') {
                form.value.balance = null
            } else if (paymentType == 'part') {
                form.value.cash = null
                form.value.card = null
                form.value.paid_amount = null
                form.value.change = null
            }else if ((paymentType == 'cash') || (paymentType == 'card')) {
                form.value.cash = null
                form.value.card = null
                form.value.balance = null
                form.value.paid_amount = form.value.total_cost
                form.value.change = (0).toFixed(2)

            }

        }

        const calculateAmount = (p) => {
            if (p.variant.length > 0) {
                p.amount = (p.cost_price * p.quantity).toFixed(2)
            } else {
                p.amount = (parseFloat(p.cost_price) * parseFloat(p.quantity)).toFixed(2)
            }
            if (isNaN(p.amount)) {
                p.amount = '?'
            }
            if (p.quantity > 0) {
                calculateTotal()
            }

            if (Math.sign(p.quantity) == -1) {
                p.quantity = Math.abs(p.quantity)
            }

        }

        const calculateTotal = () => {
            let total = product.value.reduce((a, b) => {
                return a + parseFloat(b.amount);
            }, 0);
            if (isNaN(total) != true) {
                form.value.total_cost = (total).toFixed(2)
            } else {
                form.value.total_cost = 0
            }

            //get paid amount and enable if not empty
            form.value.paid_amount = form.value.total_cost
            if (form.value.paid_amount == '?') {
                paid_disabled.value = true
                card_disabled.value = true
                cash_disabled.value = true
            } else {
                paid_disabled.value = false
                card_disabled.value = false
                cash_disabled.value = false
            }

            // Get change value
            if (form.value.payment_type == 'cash' || form.value.payment_type == 'card') {
                form.value.change = (form.value.paid_amount - total).toFixed(2)
            }
            // Prefill Balance
            form.value.balance = form.value.total_cost
        }

        const handlePaidInput = async () => {
            // alert(form.value.payment_type)
            if (form.value.payment_type == 'part') {
                if (form.value.paid_amount > form.value.total_cost) {
                    form.value.paid_amount = parseFloat(form.value.total_cost).toFixed(2)
                    form.value.balance = '0.00'
                    paid_amount_error.value = "Paid amount must not be greater than total amount"
                    setTimeout(() => {
                        paid_amount_error.value = ""
                    }, 1000);
                } else {
                    form.value.balance = (form.value.total_cost - form.value.paid_amount).toFixed(2)
                }
            } else if (form.value.payment_type == 'cash' || form.value.payment_type == 'card') {
                // Get change value
                if (form.value.paid_amount > form.value.total_cost) {
                    form.value.change = (form.value.paid_amount - form.value.total_cost).toFixed(2)
                } else {
                    form.value.change = null
                }
            }
        }

        const removeEmptyObjects = (array) => {
            const newArray = [];
            array.forEach(element => {
                if (element.product_id !== "") {
                    newArray.push(element);
                }
            });

            return newArray;
        }

        const addProduct = () => {
            product.value.push({
                product_id: "",
                available_qty: 0,
                cost_price: "",
                new_cost_price: "",
                selling_price: "",
                variant: [],
                product_variant_id: "",
                quantity: 1,
                amount:(0).toFixed(2),
                best_before: null,
                disable_field: true

            });
        };

        const callProductFunc = async()=>{
            allProducts()
        }

        const allProducts = async () => {
            const result = await axios.get('inventory/product')
            product_result.value = result.data.data
            getProducts.value = result.data.data
            getProducts.value = [{
                value: 0, name: "<span class='addNewProduct' style='color:grey;'>---Products---</span>"}]
            product_result.value.forEach((p) => {
                getProducts.value.push({ 
                    value: p.id, 
                    name: p.bar_code ? p.product_name + '<span style="visibility: hidden; "> - ' + p?.bar_code + '</span>' :  p.product_name
                })
            })
        }

        const allSuppliers = async () => {
            await axios.get(`${'/settings/suppliers/all'}`)
                .then((response) => {
                    let suppliers = response?.data?.data
                    suppliers.forEach((supplier) => {
                        supplier_arr.value.push({ value: supplier.id, name: supplier.supplier_name })
                    })
                }).catch(() => {

                })
        }
        const handleChange = async (id, index) => {
            const getProduct = await axios.get(`/inventory/product/${id}?store_id=${store_id.value}`)
            let single_product = getProduct?.data?.data
            // set variant value
            let variant = single_product.variants
            // set cost value
            let cost = single_product?.cost_price
            // set selling price value
            let selling_price = single_product?.selling_price
            // set available quantity value
            let available_qty = 0


            if (single_product?.store_product != null) {
                available_qty = single_product?.store_qty
                cost = single_product?.store_product?.cost_price
            } else {
                is_product_in_store_error.value = `This product is not in the selected store`
                setTimeout(() => {
                    is_product_in_store_error.value = ""
                }, 2000);
            }


            // Check if product already selected
            let occurences = product.value.filter((v) => (v.product_id == single_product.id))

            if(occurences.length > 1){
                product.value[index].product_id = ""
                product.value[index].cost_price = parseFloat(0).toFixed(2)
                product.value[index].new_cost_price = 0
                product.value[index].selling_price = (0).toFixed(2)
                product.value[index].available_qty = 0
                product.value[index].total_price = parseFloat(0).toFixed(2)
                product.value[index].product_variant_id = ''
                product.value[index].quantity = 0
                product.value[index].amount = (0).toFixed(2)
                toast.warning("Product already selected");

                return
            }

            let empty_product = product.value.filter((v) => (v.product_id == ""))
            if(empty_product.length == 0){
                addProduct()
            }


            product.value[index].selling_price = parseFloat(selling_price).toFixed(2)
            product.value[index].cost_price = parseFloat(cost).toFixed(2)
            product.value[index].new_cost_price = cost
            product.value[index].available_qty = available_qty
            product.value[index].best_before = null//single_product?.store_product?.best_before
            product.value[index].total_price = cost * product.value[index].quantity

            product.value[index].amount = parseFloat(cost * product.value[index].quantity).toFixed(2)
            product.value[index].variant = variant
            product.value[index].disable_field = false
            product.value[index].unit_of_measurement = single_product?.unit_of_measurement
            product.value[index].product_variant_id = ''

            calculateTotal()
        }

        const makePurchase = async () => {
            crudLoading.value = true
            let cash = form.value.cash
            let card = form.value.card
            let sum_cash_card_value = parseFloat(cash) + parseFloat(card)
            if (form.value.paid_amount && Number.isInteger(form.value.paid_amount)) {

                form.value.paid_amount = form.value.paid_amount.toFixed(2)
            }
            if (form.value.payment_type == 'split') {
                if (sum_cash_card_value != parseFloat(form.value.total_cost)) {
                    crudLoading.value = false
                    toast.error("Cash and card value must be equal to grand total")
                    return
                }
            }
            let valid_products  = removeEmptyObjects(product.value)

            await axios.post("/inventory/purchase", {
                others: form.value,
                total_amount: form.value.total_cost,
                store_id: store_id.value,
                time: date.value.toLocaleTimeString(),
                date: date.value.toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").reverse().join("/"),
                supplier_id: supplier.value,
                purchase_item: valid_products
            })
                .then((res) => {
                    crudLoading.value = false
                    router.push({ name: "All Purchase" });
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 400) {
                        validationErrors.value = error.response.data.message;
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                });
        }

        const handleVariant = (p, index, vId) => {
            // alert(vId)
            if (p.variant.length > 0) {
                let variant = p.variant.find(({
                    id
                }) => id == vId)

                currentVariant.value = variant
                p.cost_price = (p.new_cost_price * parseFloat(variant.variant_quantity)).toFixed(2)
                product.value[index].amount = (p.cost_price * product.value[index].quantity).toFixed(2)
            }

            calculateTotal(vId)
        }



        onMounted(async () => {
            allSuppliers()
            allProducts()
            allStore()
            allPaymentTypes()
        });
        return {
            modal_active,
            modalToggle,
            callProductFunc,
            product_result,
            handleAction,
            allPaymentTypes,
            paymentTypes,
            getProductInStore,
            removeProduct,
            getStore,
            handleChange,
            product,
            store_id,
            getProducts,
            supplier_arr,
            supplier,
            allSuppliers,
            addProduct,
            calculateTotal,
            form,
            date,
            calculateAmount,
            paid_disabled,
            cash_disabled,
            card_disabled,
            paid_amount_error,
            split_payment_error,
            is_product_in_store_error,
            split_cash_error,
            split_card_error,
            validationErrors,
            handlePaidInput,
            handlePaymentType,
            handleSplitpayment,
            makePurchase,
            handleVariant,
            crudLoading

        };
    },
};
</script>
