<template>
    <div>
    <AdminHeader />   
    <div class="tab-content mb-3" id="myTabContent">
        <div class="tab-pane mt-5 fade show active card thecard rounded-lg px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
            <div class="d-flex justify-content-between">
                <div>
                    <h4>Create Store</h4>
                    <div class="text-muted" style="font-size: 10px">Set up stores</div>
                </div>
            </div>
            <div class="row">
                <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id" style="list-style:none">
                    <li class="text-danger"><small>{{ errors }}</small></li>
                </ul>
            </div>
            <form @submit.prevent="createStore" class="mt-4">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="mb-2">Store Name</label>
                        <input v-model="store_name" type="text" class="form-control" placeholder="Enter store name" />
                    </div>
                    <div class="form-group col-md-4">
                        <label class="mb-2">Address</label>
                        <input v-model="address" type="text" class="form-control" placeholder="Enter store address" />
                    </div>
                    <div class="form-group col-md-4">
                        <label  class="mt-2">Country</label>
                        <select v-model="country_id" class="form-select" @change="getStates($event)">
                            <option>Choose Country</option>
                            <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mt-4">
                        <div class="form-group col-md-4">
                        <label class="mt-2">State</label>
                        <select v-model="state_id" class="form-select" @change="getCities($event)">
                            <option value="">Choose state</option>
                            <option :value="state.id" v-for="state in states" :key="state.id">{{ state.name }}</option>

                        </select>
                    </div>
                <div class="form-group col-md-4">
                        <label class="mt-2">City</label>
                        <select v-model="city_id" class="form-select">
                            <option value="">Choose city</option>
                            <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="mb-2">Phone Number</label>
                        <input v-model="phone_no" type="text" placeholder="Enter phone number" class="form-control" />
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-end">
                    <button type="submit" class="btn px-3 me-5 btn-primary text-white" v-if="crudLoading == false">Create Store</button>
                    <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white" disabled>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Processing</span>
                        </div>
                    </button>
                    <button type="button" class="btn px-5 exportbtn" @click="$router.go(-1)">Cancel</button>
                </div>
            </form>
        </div>
    </div>

    </div>
</template>
<script>
import AdminHeader from "./administrationheadertab.vue";
import {
    useToast
} from "vue-toastification";
import axios from "axios";
import {
    onMounted
} from "@vue/runtime-core";
import {
    useRouter
} from "vue-router";
import {
    ref
} from "@vue/reactivity";

export default {
    components: {
        AdminHeader
    },
    
    setup() {
        const toast = useToast();
        const router = useRouter();
        const store_name = ref("");
        const phone_no = ref("");
        const address = ref("");
        const city_id = ref("");
        const state_id = ref("");
        const country_id = ref("");
        const validationErrors = ref("");

        const countries = ref([])
        const states = ref([])
        const cities = ref([])
        const loading = ref(false)
        const permission_status = ref(null)
        const crudLoading = ref(false)

        const createStore= async()=> {
            crudLoading.value = true
            // toast.success('success');
            axios
                .post("/admin/store", {
                    store_name: store_name.value,
                    phone_no: phone_no.value,
                    address: address.value,
                    city_id: city_id.value,
                    state_id: state_id.value,
                    country_id: country_id.value,
                })
                .then(() => {
                    crudLoading.value = false
                    router.push("/manage-store");
                    toast.success("Store created successfully");
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("Your inputs are not valid, kindly recheck");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500 || error.response.status ==400) {
                        toast.error("An error occured please try again");
                    }

                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                });
        }
        
        const getAllCountries = async () => {
            const response = await axios.get('countries')
            countries.value = response.data.data.data
            loading.value = false
        }
        const getStates = async (event) => {
            let country_id = event.target.value
            const response = await axios.get('/states/' + country_id)
            states.value = response.data.data.data
            loading.value = false
        }

        const getCities = async (event) => {
            let state_id = event.target.value
            const response = await axios.get('/cities/' + state_id)
            cities.value = response.data.data.data
            loading.value = false
        }
        
        onMounted(getAllCountries)
        
        
         
        return {
            createStore,
            store_name,
            phone_no,
            address,
            city_id,
            state_id,
            country_id,
            countries,
            states,
            cities,
            validationErrors,
            permission_status,
            crudLoading,
            loading,
            getAllCountries,
            getStates,
            getCities,
        }
        
    },
}
</script>
<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }

    .createbtn {
        margin-right: 5px !important;
    }
}
</style>