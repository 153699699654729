<template>

    <div class="mt-3 gridtop">
        <div class="d-flex justify-content-between">

            <div class="mt-1 fw-bold" style="font-size:20px">
                <span style="cursor:pointer" @click="$router.go(-1)"><font-awesome-icon class="arrowradius me-3"
                        prefix="far" icon="angle-left" /></span>
                {{ debtor?.batch_code }}
            </div>
            <PrintButtonHeader :title="'Invoice-' + debtor?.batch_code" :urlA4="'/debtor-receipt/'" v-if="!loading"
                :url58mm="'/print58mm-debtor-receipt/'" :url80mm="'/print80mm-debtor-receipt/'"
                :currentUrl="'View debtor Receipt'" :data="debtor_debts" :type="'Debtor Receipt'" />
        </div>
        <div class="bg-white d-flex justify-content-center rounded-lg mt-3 mb-3 px-4 py-3 cardpadding"
            style="overflow-x: auto;">
            <div v-if="loading == true" class="text-center" style="margin:200px">
                <div class="spinner-border text-success" role="status" style="width: 5rem; height: 5rem">
                </div>
            </div>
            <div class="printborder" v-if="!loading">
                <div class="p-3 sales_invoice" id="printMe">

                    <div>Date: {{ dateTime(sales?.date) }}, {{ timeConvert(sales.time) }}</div>
                    <div>Store: {{ sales?.store?.store_name }}</div>
                    <div>Customer: {{ debtor_debts?.debtor?.customer?.customer_name }}</div>
                    <div>Sales No: {{ debtor?.batch_code }}</div>
                    <div>Sold By: {{ debtor?.user?.fullname }}</div>

                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>
                    <hr class="solid_line" />

                    <div v-for="item in sales_array" :key="item.id" class="mt-2">
                        <div>{{ item.batch_code }}</div>
                        <div>{{ item?.product_name }}</div>
                        <div v-if="item.variant">
                            <div class="d-flex justify-content-between">
                                <div>{{ `${item.quantity}` + ' ' + `${item?.variant?.variant_name + '(s)'}` + ' x ' +
                                    `${$filters.formatMoney(item?.variant?.variant_selling_price,
                                        company?.currency?.symbol)}`
                                }}</div>
                                <div>{{ $filters.formatMoney(item?.total, company?.currency?.symbol) }}</div>
                            </div>

                        </div>

                        <div v-else>
                            <div class="d-flex justify-content-between">
                                <div>{{ `${item?.quantity + ' ' + item?.variant_name + ' x ' +
                                parseFloat(item?.selling_price
                                ).toFixed(2)}`}}</div>
                                <div>{{
                                    $filters.formatMoney(item?.quantity * item?.selling_price,
                                        company?.currency?.symbol)
                                }}
                                </div>
                            </div>
                        </div>
                        <hr class="solid_line" />
                    </div>



                    <div class="printdottedborder mt-3"></div>
                    <div class="printdottedborder mt-1 mb-3"></div>

                    <div class="row mt-3">
                        <div class="col-md-4"></div>
                        <div class="col-md-8">

                            <div class=" d-flex justify-content-between font-weight-bold">
                                <div>Sub-total</div>
                                <span>
                                    {{ $filters.formatMoney(subTotal, company?.currency?.symbol) }}
                                </span>
                            </div>

                            <div class="d-flex justify-content-between">
                                <div>Tax</div>
                                <div>{{ $filters.formatMoney(taxTotal, company?.currency?.symbol) }}</div>
                            </div>

                            <div class="d-flex justify-content-between">
                                <div>Discount</div>
                                <div>{{ $filters.formatMoney(discountTotal, company?.currency?.symbol) }}</div>

                            </div>
                            
                            <div class="d-flex justify-content-between" style="font-weight:500">
                                <div>Total Amount</div>
                                <span>
                                    {{ $filters.formatMoney(sales?.total_amount, company?.currency?.symbol) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between mt-3">
                        <div>Amount Paid</div>
                        <div>{{ $filters.formatMoney(sales?.amount_paid, company?.currency?.symbol) }}</div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div>Payment</div>
                        <div style="text-transform:capitalize">{{ sales?.payment_type }}</div>
                    </div>

                    <div class="d-flex justify-content-between"
                        v-if="sales?.payment_type == 'cash' || sales?.payment_type == 'card'">
                        <div>Change</div>
                        <div class="">
                            {{ $filters.formatMoney(sales?.change, company?.currency?.symbol) }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>Balance</div>
                        <div class="" v-if="sales?.balance !== null">
                            {{ $filters.formatMoney(sales?.balance, company?.currency?.symbol) }}
                        </div>
                        <div class="" v-else>
                            {{ $filters.formatMoney(0, company?.currency?.symbol) }}
                        </div>
                    </div>

                    <div>Powered by BMAC POS</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { reusableMixin } from "../../../lib/reusableMixin";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            router: useRouter(),
            debtor_debts: null,
            grand_total: 0,
            tax_amount: 0,
            debtor: [],
            debtor__array: [],
            store: useStore(),
            salesId: null,
            sales_items: [],
            sales: null,
            sales_array: [],

            payment_type: null,
            amount: null,
            balance: "0.00",
            def_balance: "0.00",
            crudLoading: false,
            loading: true
        };
    },

    methods: {
        createPDF(batch_code) {
            var pdf = new jsPDF({
                unit: "pt",
                orientation: "l",
                lineHeight: 1.2,
            });

            var element = document.getElementById("pdf");
            var width = element.style.width;
            var height = element.style.height;
            html2canvas(element).then((canvas) => {
                var image = canvas.toDataURL("image/png");
                pdf.addImage(image, "JPEG", 0, 0, width, height);
                pdf.save(batch_code + ".pdf");
            });
        },
        getDebtor() {
            this.loading = true
            axios
                .get(`/debtors/id/${this.$route.params.id}`)
                .then((response) => {
                    this.loading = false
                    this.debtor_debts = response.data.data;
                    this.debtor = response.data.data.debtor;

                    this.sales = this.debtor_debts?.sales;
                    this.sales_items = this.debtor_debts?.sales?.sales_item;
                    this.sales_items.forEach((s) => {
                        this.sales_array.push({
                            product_name: s?.product?.product_name,
                            selling_price: s?.selling_price,
                            variant_name: s?.variant?.variant_name ?? s?.product?.unit_of_measurement,
                            quantity: s?.quantity,
                            tax: parseFloat(s.tax_amount),
                            discount: s?.discount,
                            total: parseFloat(s?.amount_paid)//s?.selling_price * s?.quantity,
                        });
                    });

                    this.def_balance = this.debtor_debts?.total_amount - this.debtor_debts?.amount_paid;
                    this.balance = this.def_balance
                })
                .catch(() => {
                    this.loading = true

                    this.toast.error("Oops,Something went wrong");
                });
        },
        handleAmount() {
            let balance =
                this.debtor_debts?.total_amount - this.debtor_debts?.amount_paid;
            this.balance = (balance - this.amount).toFixed(2);
            if (Math.sign(this.balance) == -1) {
                this.amount = this.debtor_debts?.balance;
                this.balance = (0).toFixed(2);
            }
        },
        makePayment() {
            if (this.payment_type == null) {
                this.toast.error("Select payment type first");
                return;
            }
            this.crudLoading = true;
            axios
                .post("/debtors/pay-debt", {
                    amount: this.amount,
                    customer_id: this.debtor?.customer_id,
                    debt_id: this.debtor_debts?.id,

                })
                .then((response) => {
                    this.crudLoading = false;
                    this.$router.push({ name: "Debtor" });
                    this.toast.success(response.data.message);
                })
                .catch((error) => {
                    this.crudLoading = false;
                    this.toast.error(error.response.data.message[0] ?? 'Something went wrong!');
                });
        },
    },
    computed: {
        totalQty() {
            let qty = this.sales_items.reduce((a, b) => {
                return a + b.unit_quantity
            }, 0);
            return qty
        },

        loggedInUser() {
            return this.store.state.getLoggedInUser;
        },
        grandTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + b.total + b.tax - b.discount).toFixed(2);
            }, 0);
        },
        subTotal() {
            return this.sales_array.reduce((a, b) => {
                return a + b.total;
            }, 0);
        },
        discountTotal() {
            return this.sales_array.reduce((a, b) => {
                return (parseFloat(a) + parseFloat(b.discount)).toFixed(2);
            }, 0);
        },
        taxTotal() {
            return this.sales_array.reduce((a, b) => {
                return a + b.tax//parseFloat(a) + parseFloat(b.tax).toFixed(2);
            }, 0);
        },
    },

    created() {
        this.getDebtor();
        this.getCompany();
    },
};
</script>

  
<style>

</style>