<template>
    <div>
    <AdminHeader />
    
    <div class="tab-content mb-3" id="myTabContent">
        <div class="tab-pane mt-5 fade show active bg-white rounded-lg px-3 py-3" id="company" role="tabpanel" aria-labelledby="company-tab">
            <div class="d-flex justify-content-between">
                <div>
                    <h4>Create New User</h4>
                    <div class="text-muted" style="font-size: 10px;">Create new Employee</div>
                </div>
            </div>
            <div class="row mx-2 mt-2">
                <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                    <li class="text-danger">{{ errors }}</li>
                </ul>
            </div>
            <form @submit.prevent="createUser" class="mt-4">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="mb-2">First Name <span class="text-danger">*</span> </label>
                        <input v-model="first_name" type="text" class="form-control" placeholder="Enter first name">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="mb-2">Last Name <span class="text-danger">*</span> </label>
                        <input v-model="last_name" type="text" class="form-control" placeholder="Enter last name">
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="form-group col-md-4">
                        <label class="mb-2">Phone Number <span class="text-danger">*</span></label>
                        <input v-model="phone_no" type="text" class="form-control" placeholder="Enter phone number">
                    </div>
                    <div class="form-group col-md-4">
                        <label class="mb-2">Email <span class="text-danger">*</span> </label>
                        <input v-model="email" type="text" class="form-control" placeholder="Enter email address">
                    </div>
                    <!-- <div class="form-group col-md-4">
                        <label class="mb-2">Password</label>
                        <input v-model="password" type="text" class="form-control" placeholder="Enter phone number">
                    </div> -->
                    <div class="form-group col-md-4">
                        <label class="mb-2">Role <span class="text-danger">*</span> </label>
                        <select class="form-select" v-model="role_id" @change="getSelectedRole()" id="selectedRole">
                            <option value="" disabled>Choose Role</option>
                            <option v-for="theroles in roles" :key="theroles.id" :value="theroles.id">{{ theroles.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="form-group col-md-12">
                        <label class="mb-2">Assigned Store <span class="text-danger">*</span> </label>
                        <!-- <select v-model="store" id="inputState" class="form-select">
                            <option value="" disabled>Choose Store</option>
                            <option v-for="thestores in stores" :key="thestores.id" :value="thestores.id">{{ thestores.store_name }}</option>
                        </select> -->
                        <Multiselect v-model="store" label="name" :options="store_arr" placeholder="Select store" mode="tags" :searchable="true" :create-option="true" :close-on-select="false" />
                    </div>
                </div>
                <div class="fw-bold my-4" style="font-size:21px">Select Permissions</div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="my-2 row">
                            <div class="col-8 fw-bold">SALES</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="sales_module" name="sales_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_sales" name="view_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Make Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_sales" name="create_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Reverse Sales</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_sales" name="reverse_sales" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Selling Price</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_price_change" name="create_price_change" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Discount</div>
                            <div class="form-check form-switch col-2">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_discount" name="create_discount" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">SETTINGS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="settings_module" name="settings_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Inventory Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="inventory_category" name="inventory_category" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_inventory_category" name="view_inventory_category" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_inventory_category" name="create_inventory_category" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Edit Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_inventory_category" name="edit_inventory_category" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Delete Category</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_inventory_category" name="delete_inventory_category" :disabled="is_influencer">
                            </div>
                        </div>
    
    
                        <!-- Subcategory -->
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Inventory Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="inventory_subcategory" name="inventory_subcategory" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_inventory_subcategory" name="view_inventory_subcategory" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_inventory_subcategory" name="create_inventory_subcategory" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Edit Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_inventory_subcategory" name="edit_inventory_subcategory" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Delete Subcategory</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_inventory_subcategory" name="delete_inventory_subcategory" :disabled="is_influencer">
                            </div>
                        </div>
                        <!-- End subcategory -->
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="customers" name="customers" @change="handleChange($event)"  :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">View Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_customers" name="view_customers"  :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_customers" name="create_customers"  :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Edit Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_customers" name="edit_customers"  :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Delete Customers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_customers" name="delete_customers" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="suppliers" name="suppliers" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">View Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_suppliers" name="view_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_suppliers" name="create_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_suppliers" name="edit_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Suppliers</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_suppliers" name="delete_suppliers" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="taxes" name="taxes" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">View Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_taxes" name="view_taxes" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_taxes" name="create_taxes" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Edit Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_taxes" name="edit_taxes" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Tax</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_taxes" name="delete_taxes" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="payment_type" name="payment_type" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_payment_type" name="view_payment_type" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Change Payment Type</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="change_payment_type" name="change_payment_type" :disabled="is_influencer">
                            </div>
                        </div>
    
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="notification" name="notification" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_notification" name="view_notification" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Change Notification</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="change_notification" name="change_notification" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Change Password</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="change_password" name="change_password"  :disabled="is_influencer">
                            </div>
                        </div>
                   
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Change Email</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="change_email" name="change_email" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">INVENTORY MANAGEMENT</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="inventory_mgt_module" name="inventory_mgt_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="product" name="product" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_product" name="view_product" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_product" name="create_product" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_product" name="edit_product" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_product" name="delete_product" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">ACCOUNTING</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="accounting_module" name="accounting_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Ledger Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_ledger_group" name="view_ledger_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Ledger Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_ledger_group" name="create_ledger_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Ledger Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_ledger_group" name="update_ledger_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Ledger Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_ledger_group" name="delete_ledger_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Ledger</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_ledger" name="view_ledger" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Ledger</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_ledger" name="create_ledger" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Ledger</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_ledger" name="update_ledger" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Ledger</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_ledger" name="delete_ledger" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Transaction</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_transaction" name="view_transaction" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Post Transaction</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="post_transaction" name="post_transaction" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Transaction</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_transaction" name="update_transaction" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Transaction</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_transaction" name="delete_transaction" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Expense</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_expense" name="view_expense" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Expense</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_expense" name="create_expense" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Expense</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_expense" name="update_expense" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Expense</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_expense" name="delete_expense" :disabled="is_influencer">
                            </div>
                        </div>
    
    
                    </div>
    
                    <div class="col-md-6">                    
                        
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="stock_adjustment" name="stock_adjustment" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_stock_adjustment" name="view_stock_adjustment" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_stock_adjustment" name="create_stock_adjustment" :disabled="is_influencer">
                            </div>
                        </div>
                        <!-- <div class="my-2 row">
                            <div class="col-8">View Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_stock_adjustment" name="view_stock_adjustment">
                            </div>
                        </div> -->
                        <div class="my-2 row">
                            <div class="col-8">Reverse Stock Adjustment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_stock_adjustment" name="reverse_stock_adjustment" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="stock_transfer" name="stock_transfer" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">View Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_stock_transfer" name="view_stock_transfer" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_stock_transfer" name="create_stock_transfer" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Reverse Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_stock_transfer" name="reverse_stock_transfer" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Product Location</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="product_location" name="product_location" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Product Location</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_product_location" name="view_product_location" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Expired Product</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="expired_products" name="expired_products" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Expired Products</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_expired_products" name="view_expired_products" :disabled="is_influencer">
                            </div>
                        </div>
    
                        
                        <!-- <div class="my-2 row">
                            <div class="col-8">Reverse Stock Transfer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_stock_transfer" name="reverse_stock_transfer">
                            </div>
                        </div> -->
                        <div class="my-2 mt-4 row">
                            <div class="col-8 fw-bold">ADMINISTRATION</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="administration_module" name="administration_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Employees</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="employees" name="employees" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Employees</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_administration" name="view_administration" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_employee" name="create_employee" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_employee" name="edit_employee" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Employee</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_employee" name="delete_employee" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 row">
                            <div class="col-8 fw-bold">Store Management</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="store_management" name="store_management" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_store_management" name="view_store_management" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_store_management" name="create_store_management" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Edit Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="edit_store_management" name="edit_store_management" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Store</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_store_management" name="delete_store_management" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="fw-bold col-8">Purchase</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="purchase_module" name="purchase_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_purchase" name="view_purchase" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Create Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_purchase" name="create_purchase" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="my-2 row">
                            <div class="col-8">Reverse Purchase</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="reverse_purchase" name="reverse_purchase" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="fw-bold col-8">DEBTORS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="debtors_module" name="debtors_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Debtors</div>
                            <div class="form-check form-switch col-2  mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_debtors" name="view_debtors" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Record Receipts</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_record_receipts" name="create_record_receipts" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">CREDITORS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="creditors_module" name="creditors_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Creditors</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_creditors" name="view_creditors" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Record Payment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_record_payment" name="create_record_payment" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">REPORTS</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_reports" name="view_reports" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Reports</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_reports" name="view_reports" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">CURRENCY</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="currency_module" name="currency_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Currency</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_currency" name="view_currency" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Currency</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_currency" name="create_currency" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Currency</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_currency" name="update_currency" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">INVOICE</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="invoice_module" name="invoice_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Invoice</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_invoice" name="view_invoice" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Invoice</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_invoice" name="create_invoice" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Invoice</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_invoice" name="update_invoice" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Invoice</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_invoice" name="delete_invoice" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Record Invoice Payment</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="record_invoice_payment" name="record_invoice_payment" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">BILLING</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="billing_module" name="billing_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Billing</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_billing" name="view_billing" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Billing</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_billing" name="create_billing" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Billing</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_billing" name="delete_billing" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Billing Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_billing_group" name="view_billing_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Billing Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_billing_group" name="create_billing_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Billing Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_billing_group" name="update_billing_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Billing Group</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_billing_group" name="delete_billing_group" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Add group Customer</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="add_group_customer" name="add_group_customer" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">BANK</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="bank_module" name="bank_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Bank</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_bank" name="view_bank" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Create Bank</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="create_bank" name="create_bank" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Bank</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_bank" name="update_bank" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Delete Bank</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="delete_bank" name="delete_bank" :disabled="is_influencer">
                            </div>
                        </div>
    
                        <div class="mb-2 mt-4 row">
                            <div class="col-8 fw-bold">PAYMENT GATEWAY</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="gateway_module" name="gateway_module" @change="handleChange($event)" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">View Payment gateway</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="view_payment_gateway" name="view_payment_gateway" :disabled="is_influencer">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-8">Update Payment gateway</div>
                            <div class="form-check form-switch col-2 mt-1">
                                <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 v-model="update_payment_gateway" name="update_payment_gateway" :disabled="is_influencer">
                            </div>
                        </div>
    
                    </div>
                </div>
    
                <div class="mt-4 d-flex justify-content-end">
    
                    <button type="submit" class="btn px-3 me-5 btn-primary text-white" v-if="crudLoading == false">Create User</button>
                    <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white" disabled>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Processing</span>
                        </div>
                    </button>
                    <button type="button" @click="$router.go(-1)" class="btn px-3 exportbtn">Cancel</button>
                </div>
            </form>
        </div>
    </div>
    </div>
    </template>
    
    <style src="@vueform/multiselect/themes/default.css"></style>
    
    <script>
    import {
        ref
    } from '@vue/reactivity';
    import AdminHeader from './administrationheadertab.vue'
    import {
        onMounted
    } from '@vue/runtime-core';
    import axios from 'axios';
    import {
        useToast
    } from 'vue-toastification';
    import {
        useRouter
    } from 'vue-router';
    import Multiselect from '@vueform/multiselect'
    
    export default {
        components: {
            AdminHeader,
            Multiselect
        },
        setup() {
            const first_name = ref('');
            const last_name = ref('');
            const phone_no = ref('');
            const email = ref('');
            const password = ref('');
            const store = ref([]);
            const role_id = ref('')
            const stores = ref({})
            const store_arr = ref([])
            const crudLoading = ref(false)
            const roles = ref('')
            const toast = useToast()
            const validationErrors = ref('')
            const router = useRouter()
            const is_influencer = ref(false)
    
            const sales_module = ref('')
    
            const view_sales = ref('')
            const create_sales = ref('')
            const reverse_sales = ref('')
            const create_price_change = ref('')
            const create_discount = ref('')
    
            const settings_module = ref('')
            const view_settings = ref('')
    
            const inventory_category = ref('')
            const view_inventory_category = ref('')
            const create_inventory_category = ref('')
            const edit_inventory_category = ref('')
            const delete_inventory_category = ref('')
    
            const inventory_subcategory = ref('')
            const view_inventory_subcategory = ref('')
            const create_inventory_subcategory = ref('')
            const edit_inventory_subcategory = ref('')
            const delete_inventory_subcategory = ref('')
    
            const customers = ref('')
            const view_customers = ref('')
            const create_customers = ref('')
            const edit_customers = ref('')
            const delete_customers = ref('')
    
            const suppliers = ref('')
            const view_suppliers = ref('')
            const create_suppliers = ref('')
            const edit_suppliers = ref('')
            const delete_suppliers = ref('')
    
            const taxes = ref('')
            const view_taxes = ref('')
            const create_taxes = ref('')
            const edit_taxes = ref('')
            const delete_taxes = ref('')
    
            const payment_type = ref('')
            const view_payment_type = ref('')
            const change_payment_type = ref('')
    
            const notification = ref('')
            const view_notification = ref('')
            const change_notification = ref('')
    
            const change_email = ref('')
            const change_password = ref('')
    
            const inventory_mgt_module = ref('');
            const product = ref('')
            const view_product = ref('')
            const edit_product = ref('')
            const delete_product = ref('')
            const create_product = ref('')
    
            const stock_adjustment = ref('')
            const view_stock_adjustment = ref('')
            const create_stock_adjustment = ref('')
            const reverse_stock_adjustment = ref('')
    
            const stock_transfer = ref('')
            const view_stock_transfer = ref('')
            const create_stock_transfer = ref('')
            const reverse_stock_transfer = ref('')
    
            const product_location = ref('')
            const expired_products = ref('')
            const view_product_location = ref('')
            const view_expired_products = ref('')
    
            const administration_module = ref('')
            const employees = ref('')
            const view_administration = ref('')
            const create_employee = ref('')
            const edit_employee = ref('')
            const delete_employee = ref('')
    
            const store_management = ref('')
            const view_store_management = ref('')
            const create_store_management = ref('')
            const edit_store_management = ref('')
            const delete_store_management = ref('')
    
            const debtors_module = ref('')
            const view_debtors = ref('')
            const create_record_receipts = ref('')
    
            const creditors_module = ref('')
            const view_creditors = ref('')
    
            const create_record_payment = ref('')
    
            const report_module = ref('')
            const view_reports = ref('')
    
            const purchase_module = ref('')
            const view_purchase = ref('')
            const create_purchase = ref('')
            const reverse_purchase = ref('')
    
            const view_currency = ref('')
            const create_currency = ref('')
            const update_currency = ref('')
            const currency_module = ref('')
    
            const invoice_module = ref('')
            const billing_module = ref('')
            const accounting_module = ref('')
            const bank_module = ref('')
            const gateway_module = ref('')
    
            const view_invoice = ref('')
            const create_invoice = ref('')
            const update_invoice = ref('')
            const delete_invoice = ref('')
            const record_invoice_payment = ref('')
    
            const view_billing = ref('')
            const create_billing = ref('')
            const delete_billing = ref('')
    
            const view_billing_group = ref('')
            const create_billing_group = ref('')
            const update_billing_group = ref('')
            const delete_billing_group = ref('')
            const add_group_customer = ref('')
    
            const view_ledger_group = ref('')
            const create_ledger_group = ref('')
            const update_ledger_group = ref('')
            const delete_ledger_group = ref('')
    
            const view_ledger = ref('')
            const create_ledger = ref('')
            const update_ledger = ref('')
            const delete_ledger = ref('')
    
            const view_transaction = ref('')
            const post_transaction = ref('')
            const update_transaction = ref('')
            const delete_transaction = ref('')
    
            const view_expense = ref('')
            const create_expense = ref('')
            const update_expense = ref('')
            const delete_expense = ref('')
    
            const view_bank = ref('')
            const create_bank = ref('')
            const update_bank = ref('')
            const delete_bank = ref('')
    
            const view_payment_gateway = ref('')
            const update_payment_gateway = ref('')
    
            const permission_status = ref(null)
    
            const handleChange = (evt) => {
                if (evt.target.name == 'sales_module') {
                    view_sales.value = sales_module.value
                    create_sales.value = sales_module.value
                    reverse_sales.value = sales_module.value
                    create_price_change.value = sales_module.value
                    create_discount.value = sales_module.value
    
                }
                if (evt.target.name == 'settings_module') {
                    inventory_category.value = settings_module.value
    
                    inventory_subcategory.value = settings_module.value
    
                    customers.value = settings_module.value
    
                    suppliers.value = settings_module.value
    
                    taxes.value = settings_module.value
    
                    payment_type.value = settings_module.value
    
                    notification.value = settings_module.value
    
                    change_email.value = settings_module.value
                    change_password.value = settings_module.value
                }
    
                if (evt.target.name == 'inventory_category' || evt.target.name == 'settings_module') {
                    view_inventory_category.value = inventory_category.value
                    create_inventory_category.value = inventory_category.value
                    edit_inventory_category.value = inventory_category.value
                    delete_inventory_category.value = inventory_category.value
                }
    
                if (evt.target.name == 'inventory_subcategory' || evt.target.name == 'settings_module') {
                    view_inventory_subcategory.value = inventory_subcategory.value
                    create_inventory_subcategory.value = inventory_subcategory.value
                    edit_inventory_subcategory.value = inventory_subcategory.value
                    delete_inventory_subcategory.value = inventory_subcategory.value
                }
    
                if (evt.target.name == 'customers' || evt.target.name == 'settings_module') {
                    view_customers.value = customers.value
                    create_customers.value = customers.value
                    edit_customers.value = customers.value
                    delete_customers.value = customers.value
                }
    
                if (evt.target.name == 'suppliers' || evt.target.name == 'settings_module') {
                    view_suppliers.value = suppliers.value
                    create_suppliers.value = suppliers.value
                    edit_suppliers.value = suppliers.value
                    delete_suppliers.value = suppliers.value
                }
    
                if (evt.target.name == 'taxes' || evt.target.name == 'settings_module') {
                    view_taxes.value = taxes.value
                    create_taxes.value = taxes.value
                    edit_taxes.value = taxes.value
                    delete_taxes.value = taxes.value
                }
                if (evt.target.name == 'payment_type' || evt.target.name == 'settings_module') {
                    view_payment_type.value = payment_type.value
                    change_payment_type.value = payment_type.value
                }
                if (evt.target.name == 'notification' || evt.target.name == 'settings_module') {
                    view_notification.value = notification.value
                    change_notification.value = notification.value
                }
    
                if (evt.target.name == 'inventory_mgt_module') {
                    product.value = inventory_mgt_module.value
    
                    stock_adjustment.value = inventory_mgt_module.value
    
                    stock_transfer.value = inventory_mgt_module.value
    
                    expired_products.value = inventory_mgt_module.value
                    product_location.value = inventory_mgt_module.value
                }
    
                if (evt.target.name == 'product' || evt.target.name == 'inventory_mgt_module') {
                    view_product.value = product.value
                    create_product.value = product.value
                    edit_product.value = product.value
                    delete_product.value = product.value
                }
                if (evt.target.name == 'stock_adjustment' || evt.target.name == 'inventory_mgt_module') {
                    view_stock_adjustment.value = stock_adjustment.value
                    create_stock_adjustment.value = stock_adjustment.value
                    reverse_stock_adjustment.value = stock_adjustment.value
                }
    
                if (evt.target.name == 'stock_transfer' || evt.target.name == 'inventory_mgt_module') {
                    view_stock_transfer.value = stock_transfer.value
                    create_stock_transfer.value = stock_transfer.value
                    reverse_stock_transfer.value = stock_transfer.value
                }
    
                if (evt.target.name == 'expired_products' || evt.target.name == 'inventory_mgt_module') {
                    view_expired_products.value = expired_products.value
                }
    
                if (evt.target.name == 'product_location' || evt.target.name == 'inventory_mgt_module') {
                    view_product_location.value = product_location.value
                }
    
                if (evt.target.name == 'administration_module') {
                    employees.value = administration_module.value
                    store_management.value = administration_module.value
                    view_store_management.value = administration_module.value
                }
    
                if (evt.target.name == 'employees' || evt.target.name == 'administration_module') {
                    view_administration.value = employees.value
                    create_employee.value = employees.value
                    edit_employee.value = employees.value
                    delete_employee.value = employees.value
    
                }
    
                if (evt.target.name == 'store_management' || evt.target.name == 'administration_module') {
                    view_store_management.value = store_management.value
                    create_store_management.value = store_management.value
                    edit_store_management.value = store_management.value
                    delete_store_management.value = store_management.value
                }
    
                if (evt.target.name == 'debtors_module') {
                    view_debtors.value = debtors_module.value
                    create_record_receipts.value = debtors_module.value
                }
    
                if (evt.target.name == 'creditors_module') {
                    view_creditors.value = creditors_module.value
                    create_record_payment.value = creditors_module.value
                }
    
                if (evt.target.name == 'purchase_module') {
                    view_purchase.value = purchase_module.value
                    create_purchase.value = purchase_module.value
                    reverse_purchase.value = purchase_module.value
                }
    
                if (evt.target.name == 'currency_module') {
                    view_currency.value = currency_module.value
                    create_currency.value = currency_module.value
                    update_currency.value = currency_module.value
                }
    
                if (evt.target.name == 'invoice_module') {
                    view_invoice.value = invoice_module.value
                    create_invoice.value = invoice_module.value
                    update_invoice.value = invoice_module.value
                    delete_invoice.value = invoice_module.value
                    record_invoice_payment.value = invoice_module.value
                }
    
                if (evt.target.name == 'billing_module') {
                    view_billing.value = billing_module.value
                    create_billing.value = billing_module.value
                    delete_billing.value = billing_module.value
                    view_billing_group.value = billing_module.value
                    create_billing_group.value = billing_module.value
                    update_billing_group.value = billing_module.value
                    delete_billing_group.value = billing_module.value
                    add_group_customer.value = billing_module.value
                }
    
                if (evt.target.name == 'accounting_module') {
                    view_ledger_group.value = accounting_module.value
                    create_ledger_group.value = accounting_module.value
                    update_ledger_group.value = accounting_module.value
                    delete_ledger_group.value = accounting_module.value
                    view_ledger.value = accounting_module.value
                    create_ledger.value = accounting_module.value
                    update_ledger.value = accounting_module.value
                    delete_ledger.value = accounting_module.value
    
                    view_transaction.value = accounting_module.value
                    post_transaction.value = accounting_module.value
                    update_transaction.value = accounting_module.value
                    delete_transaction.value = accounting_module.value
    
                    view_expense.value = accounting_module.value
                    create_expense.value = accounting_module.value
                    update_expense.value = accounting_module.value
                    delete_expense.value = accounting_module.value
                }
    
                if (evt.target.name == 'bank_module') {
                    view_bank.value = bank_module.value
                    create_bank.value = bank_module.value
                    update_bank.value = bank_module.value
                    delete_bank.value = bank_module.value
                }
    
                if (evt.target.name == 'gateway_module') {
                    view_payment_gateway.value = gateway_module.value
                    update_payment_gateway.value = gateway_module.value
                }
            }
    
            const getSelectedRole = () =>{
                var e = document.getElementById("selectedRole");
                var selected = e.options[e.selectedIndex].text
                if(selected == "Influencer"){
                    is_influencer.value = true
    
                    // Sales
                    view_sales.value = 1
                    sales_module.value = 1
                    create_sales.value = 0
                    reverse_sales.value = 0
                    create_price_change.value = 0
                    create_discount.value = 0
    
                    // Settings
                    settings_module.value = 1
                    inventory_category.value = 1
                    view_inventory_category.value = 1
                    create_inventory_category.value = 0
                    edit_inventory_category.value = 0
                    delete_inventory_category.value = 0
    
                    inventory_subcategory.value = 1
                    view_inventory_subcategory.value = 1
                    create_inventory_subcategory.value = 0
                    edit_inventory_subcategory.value = 0
                    delete_inventory_subcategory.value = 0
    
                    customers.value = 1
                    view_customers.value = 1
                    create_customers.value = 0
                    edit_customers.value = 0
                    delete_customers.value = 0
                    suppliers.value = 1
                    view_suppliers.value = 1
                    create_suppliers.value = 0
                    edit_suppliers.value = 0
                    delete_suppliers.value = 0
                    taxes.value = 1
                    view_taxes.value = 1
                    create_taxes.value = 0
                    edit_taxes.value = 0
                    delete_taxes.value = 0
                    payment_type.value = 1
                    view_payment_type.value = 1
                    change_payment_type.value = 0
                    notification.value = 1
                    view_notification.value = 1
                    change_notification.value = 0
    
                    // Inventory Module
                    inventory_mgt_module.value = 1
                    product.value = 1
                    view_product.value = 1
                    create_product.value = 0
                    edit_product.value = 0
                    delete_product.value = 0
    
                    stock_adjustment.value = 1
                    view_stock_adjustment.value = 1
                    create_stock_adjustment.value = 0
                    reverse_stock_adjustment.value = 0
    
                    stock_transfer.value = 1
                    view_stock_transfer.value = 1
                    create_stock_transfer.value = 0
                    reverse_stock_transfer.value = 0
    
                    view_product_location.value = 1
                    product_location.value = 1
    
                    expired_products.value = 1
                    view_expired_products.value = 1
    
                    // Administration
                    administration_module.value=1
                    employees.value = 1
                    view_administration.value = 1
                    create_employee.value = 0
                    edit_employee.value = 0
                    delete_employee.value = 0
    
                    store_management.value = 1
                    view_store_management.value = 1
                    create_store_management.value = 0
                    edit_store_management.value = 0
                    delete_store_management.value = 0
    
                    purchase_module.value = 1
                    view_purchase.value = 1
                    create_purchase.value = 0
                    reverse_purchase.value = 0
    
                    debtors_module.value = 1
                    view_debtors.value = 1
                    create_record_receipts.value = 0
    
                    creditors_module.value = 1
                    view_creditors.value = 1
                    create_record_payment.value = 0
    
                    report_module.value = 1
                    view_reports.value = 1
    
                    currency_module.value = 1
                    view_currency.value = 1
                    create_currency.value = 1
                    update_currency.value = 0
    
    
                } else{
                    is_influencer.value = false
                }
    
            }
    
            const getRoles = async () => {
                const response = await axios.get('/admin/role')
                roles.value = response.data.data
            }
            const getStores = async () => {
                const getStores = await axios.get('/admin/store')
                const loadedStore = getStores.data.data
                stores.value = loadedStore
                stores.value.forEach((store) => {
                    store_arr.value.push({
                        value: store.id,
                        name: store.store_name
                    })
                })
            }
    
            const createUser = async () => {
                crudLoading.value = true
                await axios.post('/admin/employee/web', {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    phone_no: phone_no.value,
                    email: email.value,
                    password: password.value,
                    store: store.value,
                    role_id: role_id.value,
    
                    permissions: {
                        sales_module: sales_module.value,
                        view_sales: view_sales.value,
                        create_sales: create_sales.value,
                        reverse_sales: reverse_sales.value,
                        create_price_change: create_price_change.value,
                        create_discount: create_discount.value,
                        settings_module: settings_module.value,
    
                        view_settings: view_settings.value,
                        view_inventory_category: view_inventory_category.value,
                        create_inventory_category: create_inventory_category.value,
                        edit_inventory_category: edit_inventory_category.value,
                        delete_inventory_category: delete_inventory_category.value,
    
                        view_inventory_subcategory: view_inventory_subcategory.value,
                        create_inventory_subcategory: create_inventory_subcategory.value,
                        edit_inventory_subcategory: edit_inventory_subcategory.value,
                        delete_inventory_subcategory: delete_inventory_subcategory.value,
    
                        view_customers: view_customers.value,
                        create_customers: create_customers.value,
                        edit_customers: edit_customers.value,
                        delete_customers: delete_customers.value,
    
                        view_suppliers: view_suppliers.value,
                        create_suppliers: create_suppliers.value,
                        edit_suppliers: edit_suppliers.value,
                        delete_suppliers: delete_suppliers.value,
    
                        view_taxes: view_taxes.value,
                        create_taxes: create_taxes.value,
                        edit_taxes: edit_taxes.value,
                        delete_taxes: delete_taxes.value,
    
                        change_email: change_email.value,
                        change_password: change_password.value,
                        change_payment_type: change_payment_type.value,
                        view_payment_type: view_payment_type.value,
    
                        change_notification: change_notification.value,
                        view_notification: view_notification.value,
    
                        create_product: create_product.value,
    
                        inventory_mgt_module: inventory_mgt_module.value,
                        view_product: view_product.value,
                        edit_product: edit_product.value,
                        delete_product: delete_product.value,
                        view_stock_adjustment: view_stock_adjustment.value,
                        create_stock_adjustment: create_stock_adjustment.value,
                        reverse_stock_adjustment: reverse_stock_adjustment.value,
                        view_stock_transfer: view_stock_transfer.value,
                        create_stock_transfer: create_stock_transfer.value,
                        reverse_stock_transfer: reverse_stock_transfer.value,
    
                        view_product_location: view_product_location.value,
                        view_expired_products: view_expired_products.value,
    
                        administration_module: administration_module.value,
                        view_administration: view_administration.value,
                        create_employee: create_employee.value,
                        edit_employee: edit_employee.value,
                        delete_employee: delete_employee.value,
                        view_store_management: view_store_management.value,
                        create_store_management: create_store_management.value,
                        edit_store_management: edit_store_management.value,
                        delete_store_management: delete_store_management.value,
                        debtors_module: debtors_module.value,
                        view_debtors: view_debtors.value,
                        create_record_receipts: create_record_receipts.value,
                        view_creditors: view_creditors.value,
                        creditors_module: creditors_module.value,
                        create_record_payment: create_record_payment.value,
                        view_reports: view_reports.value,
    
                        view_purchase: view_purchase.value,
                        purchase_module: purchase_module.value,
                        create_purchase: create_purchase.value,
                        reverse_purchase:reverse_purchase.value,
                        report_module: view_reports.value,
    
                        currency_module: currency_module.value,
                        view_currency: view_currency.value,
                        create_currency: create_currency.value,
                        update_currency: update_currency.value,
    
                        invoice_module: invoice_module.value,
                        billing_module: billing_module.value,
                        accounting_module: accounting_module.value,
                        bank_module: bank_module.value,
                        gateway_module: gateway_module.value,
    
                        view_invoice: view_invoice.value,
                        create_invoice: create_invoice.value,
                        update_invoice: update_invoice.value,
                        delete_invoice: delete_invoice.value,
                        record_invoice_payment: record_invoice_payment.value,
    
                        view_billing: view_billing.value,
                        create_billing: create_billing.value,
                        delete_billing: delete_billing.value,
    
                        view_billing_group: view_billing_group.value,
                        create_billing_group: create_billing_group.value,
                        update_billing_group: update_billing_group.value,
                        delete_billing_group: delete_billing_group.value,
                        add_group_customer: add_group_customer.value,
    
                        view_ledger_group: view_ledger_group.value,
                        create_ledger_group: create_ledger_group.value,
                        update_ledger_group: update_ledger_group.value,
                        delete_ledger_group: delete_ledger_group.value,
    
                        view_ledger: view_ledger.value,
                        create_ledger: create_ledger.value,
                        update_ledger: update_ledger.value,
                        delete_ledger: delete_ledger.value,
    
                        view_transaction: view_transaction.value,
                        post_transaction: post_transaction.value,
                        update_transaction: update_transaction.value,
                        delete_transaction: delete_transaction.value,
    
                        view_expense: view_expense.value,
                        create_expense: create_expense.value,
                        update_expense: update_expense.value,
                        delete_expense: delete_expense.value,
    
                        view_bank: view_bank.value,
                        create_bank: create_bank.value,
                        update_bank: update_bank.value,
                        delete_bank: delete_bank.value,
    
                        view_payment_gateway: view_payment_gateway.value,
                        update_payment_gateway: update_payment_gateway.value
    
                    },
                }).then(() => {
                    crudLoading.value = false
                    router.push('/manage-user')
                    toast.success('User created successfully')
                    validationErrors.value = '';
                }).catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning('Your inputs are not valid, kindly recheck');
                        validationErrors.value = error.response.data.message
                    }
                    if (error.response.status == 500 || error.response.status == 400) {
                        toast.error('An error occured please try again');
                    }
                    if (error?.response?.status == 403) {
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                })
            }
    
            onMounted(async () => {
                getRoles()
                // get all stores
                getStores()
    
                // get all roles
                // const getRoles = await axios.get('/admin/role')
                // const loadedRoles = getRoles.data.data
                // roles.value = loadedRoles
            })
    
            return {
                first_name,
                last_name,
                phone_no,
                email,
                password,
                store,
                role_id,
                stores,
                roles,
                validationErrors,
                is_influencer,
                getSelectedRole,
                createUser,
                getRoles,
                getStores,
                store_arr,
                crudLoading,
                handleChange,
                // togglePermission,
                sales_module,
                view_sales,
                create_sales,
                settings_module,
                reverse_sales,
                create_price_change,
                create_discount,
                view_settings,
                inventory_category,
                view_inventory_category,
                create_inventory_category,
                edit_inventory_category,
                delete_inventory_category,
    
                inventory_subcategory,
                view_inventory_subcategory,
                create_inventory_subcategory,
                edit_inventory_subcategory,
                delete_inventory_subcategory,
    
                customers,
                view_customers,
                create_customers,
                edit_customers,
                delete_customers,
    
                suppliers,
                view_suppliers,
                create_suppliers,
                edit_suppliers,
                delete_suppliers,
    
                taxes,
                view_taxes,
                create_taxes,
                edit_taxes,
                delete_taxes,
    
                payment_type,
                view_payment_type,
                change_payment_type,
    
                notification,
                view_notification,
                change_notification,
    
                change_email,
                change_password,
    
                inventory_mgt_module,
                product,
                view_product,
                edit_product,
                delete_product,
                create_product,
    
                stock_adjustment,
                view_stock_adjustment,
                create_stock_adjustment,
                reverse_stock_adjustment,
                
                stock_transfer,
                view_stock_transfer,
                create_stock_transfer,
                reverse_stock_transfer,
    
                view_product_location,
                product_location,
                view_expired_products,
                expired_products,
                administration_module,
                employees,
                view_administration,
                create_employee,
                edit_employee,
                delete_employee,
    
                store_management,
                view_store_management,
                create_store_management,
                edit_store_management,
                delete_store_management,
    
                debtors_module,
                view_debtors,
                create_record_receipts,
                creditors_module,
                view_creditors,
                create_record_payment,
                report_module,
                view_reports,
                purchase_module,
                view_purchase,
                create_purchase,
                reverse_purchase,
                permission_status,
                view_currency,
                create_currency,
                update_currency,
                currency_module,
    
                invoice_module,
                billing_module,
                accounting_module,
                bank_module,
                gateway_module,
    
                view_invoice,
                create_invoice,
                update_invoice,
                delete_invoice,
                record_invoice_payment,
    
                view_billing,
                create_billing,
                delete_billing,
    
                view_billing_group,
                create_billing_group,
                update_billing_group,
                delete_billing_group,
                add_group_customer,
    
                view_ledger_group,
                create_ledger_group,
                update_ledger_group,
                delete_ledger_group,
                
                view_ledger,
                create_ledger,
                update_ledger,
                delete_ledger,
    
                view_transaction,
                post_transaction,
                update_transaction,
                delete_transaction,
    
                view_expense,
                create_expense,
                update_expense,
                delete_expense,
    
                view_bank,
                create_bank,
                update_bank,
                delete_bank,
    
                view_payment_gateway,
                update_payment_gateway
    
            }
        }
    }
    </script>
    
    <style scoped>
    .form-switch .form-check-input {
        height: 24px !important;
        width: 48px !important;
        margin-top: -4px;
    }
    
    .form-switch .form-check-input:focus {
        border-color: rgba(0, 0, 0, 0.25);
        outline: 0;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
    }
    
    .form-switch .form-check-input:checked {
        background-color: #004AAD;
        border-color: #004AAD;
        border: none;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
    }
    
    @media only screen and (max-width: 768px) {
        .resposivePerm {
            display: flex !important;
            justify-content: space-between !important;
        }
    }
    </style>
    