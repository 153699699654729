<template>
  <ReportHeaderTab />

  <div>
    <div class="tab-pane fade show active" id="allproduct" role="tabpanel" aria-labelledby="allproduct-tab">
      <div class="mt-3 font-weight-bold gridtop">
        <div class="mt-3 font-weight-bold gridtop">
          <div class="d-flex justify-content-between gridtop mb-2">
            <div class="mt-2 fw-bold" style="font-size: 20px">
              <router-link to="/customer-report">
                <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
              </router-link>
              {{ details[0]?.customer?.customer_name }}
            </div>





            <div class="d-flex alluserdebtorstop topuserdebt">
              <div class="d-flex dateinputs alluserdebtorstop mt-1">
                <div class="mb-0 me-1" style="width: 214px">
                  <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                    style="background: none; border: none" />
                </div>
                <div class="mb-0 me-2" style="width: 214px">
                  <Datepicker v-model="tableData.end_date" :minDate="tableData.start_date" :enableTimePicker="false"
                    class="form-control" style="background: none; border: none" />
                </div>
                <div class="me-2 salessubmitdate mt-1">
                  <button type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white mt-1"
                    v-if="crudLoading == false" @click="fetchData()">Submit</button>
                  <button v-if="crudLoading == true" class="btn btn-primary text-white mt-1" type="button"
                    @click="fetchData()" disabled>
                    <span class="">Processing...</span>
                  </button>
                </div>
              </div>
              <div class="d-flex alluserdebtorstop mt-1">
                <div class="input-group mb-0 me-2" style="width: 214px">
                  <div class="d-flex align-items-center small pl-2 me-2" style="margin-top: 2px">
                    <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                    <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px"
                      v-model="query" />
                  </div>
                </div>
                 <div class="">
                  <div class="dropdown">
                    <button class="btn exportbtn me-2 mt-2 dropdown-toggle" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                      Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <a @click="drawPdf(print_content, headerArrayColumn, 'l', details[0]?.customer?.customer_name + '-report', 'Customer Sales Detail')"
                          class="dropdown-item" href="#">PDF</a>
                      </li>
                      <li>
                        <a @click="reusableMixinToCSV(details[0]?.customer?.customer_name + '-report.csv')"
                          class="dropdown-item" href="#">CSV</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
















            <!--  -->
          </div>
        </div>
      </div>
      <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
        <div v-if="loading == true" class="text-center py-5">
          <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="text-center py-5" v-if="details.length == 0 && loading == false">
          <div>
            <img src="../../private-assets/images/emptypng.png" alt="" />
          </div>
          <div class="fw-bold mt-3">Your Customer sales list is empty!</div>
        </div>
        <div v-if="loading == false && details.length > 0">
          <table class="table table-sm-responsive">
            <thead class="thead-white" style="border-style: hidden !important">
              <tr class="pb-5">
                <th class="font-weight-bold heading">Sales Number</th>
                <th class="font-weight-bold heading">Date</th>
                <th class="font-weight-bold heading">Time</th>
                <th class="font-weight-bold heading">Total({{ company?.currency?.symbol }})</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" v-for="sale in details" :key="sale.id">
                <td class="text-secondary py-3 row1">
                  <router-link :to="'/sales-receipt-credit/' + sale.id" class="text-primary">
                    {{ sale?.batch_code }}
                  </router-link>
                </td>
                <td class="text-secondary py-3 row1">{{ dateTime(sale?.date) }}</td>
                <td class="text-secondary py-3 row1">{{ timeConvert(sale?.time) }}</td>
                <td class="text-secondary py-3 row1">{{ $filters.formatMoney(sale?.total_amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
      @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>
  </div>
</template>


<script>
import axios from "axios";
import { useToast } from "vue-toastification";

import { reusableMixin } from "../../../lib/reusableMixin";

import { useStore } from "vuex";
import ReportHeaderTab from "./components/reportheadertab.vue";
export default {
  components: {
    ReportHeaderTab,
  },
  mixins: [reusableMixin],
  data() {
    let sortOrders = {};
    let columns = [
      {
        width: "16%",
        label: "Name",
        name: "name",
        sortable: false,
      },
      {
        width: "15%",
        label: "Sortcode",
        name: "sortcode",
        sortable: false,
      },
      {
        width: "15%",
        label: "Phonecode",
        name: "phonecode",
        sortable: false,
      },
      {
        width: "5%",
        label: "Status",
        name: "status",
        sortable: false,
      },
      {
        width: "5%",
        label: "Actions",
        name: "actions",
        sortable: false,
      },
    ];

    // Show date from the past 6 months
    var current_date = new Date();
    let current_date_to_date = current_date.setMonth(
      current_date.getMonth() - 6
    );
    current_date_to_date = new Date(current_date_to_date);

    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      print_content: [],
      headerArrayColumn: [
        "S/N",
        "Sales Number",
        "Date",
        "Time",
        "Total",
      ],
      details: [],
      getStore: ["All"],
      paymentTypes: [],
      loading: false,
      query: null,

      selectedModel: [],
      allSelected: false,
      columns: columns,
      sortKey: "id",
      sortOrders: sortOrders,
      tableData: {
        store: "All",
        start_date: current_date_to_date,
        end_date: new Date(),
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "desc",
        id: "",
        status: [0, 1, 2, 3],
        query: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      toast: useToast(),
      currentID: null,
      store: useStore(),
      crudLoading: false,
      total_transaction: 0,
      total_amount: 0,

    };
  },
  methods: {
    grandTotal(item) {
      this.total_transaction = item.reduce(function (sum, t) {
        return sum + t.transactions
      }, 0);

      this.total_amount = item.reduce(function (sum, t) {
        return sum + t.total_amt
      }, 0);
    },

    allStore() {
      axios
        .get("/admin/store")
        .then((response) => {
          this.stores = response.data.data;
          this.stores.forEach((store) => {
            this.getStore.push({
              value: store.store_name,
              name: store.store_name,
            });
          });
        })
        .catch(() => {
          this.toast.error("Whoops, Something went wrong");
        });
    },
    dataToPrint(details) {
      details.map((sales, idx) => {
        let result = {
          sn: idx + 1,
          batch_code: sales.batch_code,
          date: sales.date,
          time: sales.time,
          total: sales.total_amount,
        };
        this.print_content.push(result);
      });
    },

    selectAll() {
      if (this.allSelected) {
        const selected = this.details.map((u) => u.id);
        this.selectedModel = selected;
      } else {
        this.selectedModel = [];
      }
    },

    fetchData(url = "/report/customer-report/" + this.$route.params.customerId) {

      this.loading = true;
      this.crudLoading = true;
      this.tableData.query = this.query;
      this.tableData.store = this.$route.query.store;

      if (this.crudLoading && this.query) {
        this.crudLoading = false;
      }

      this.tableData.draw++;
      axios
        .get(url, {
          params: this.tableData,
        })
        .then((response) => {
          this.print_content = [];
          if (!response.data.status == 200) {
            this.loading = false;
            return (this.errorMessage = "Could not fetch data");
          }
          this.loading = false;
          this.crudLoading = false;
          let data = response.data;
          this.details = data.data.data;
          this.grandTotal(this.details)
          this.dataToPrint(this.details);
          this.configPagination(data.data);
        })
        .catch((error) => {
          this.crudLoading = false;
          this.errorMessagem = error;
          this.loading = false;
        });
    },

    timeConvert(time) {
      // Check correct time format and split into components
      if (time) {
        time = time
          .toString()
          .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
          // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
      }
      return time;
    },

    computed: {
      permission() {
        return this.store.state.getCurrentUserPermission;
      },
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
  },

  watch: {
    query() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.allStore();
    this.getCompany();
  },
};
</script>
    

<style scoped>
.debitbtn {
  background-color: #ffe5e5;
  color: #de1b00;
}

@media (max-width: 767px) {
  .alluserdebtorstop {
    flex-direction: column;
  }

  .salessubmitdate {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .toSNserdebt {
    flex-direction: column;
  }

  .salessubmitdate {
    margin-left: 13px;
  }
}
</style>
