<template>
<SettingHeaderTab />

<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
        <div class="mt-2" style="font-size:24px"> Currency List</div>
        <div class="d-flex gridtop">
            <div class="d-flex">
                <div class="d-flex align-items-center small pl-2 me-2 mt-1" styfle="margin-top:2px">
                    <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute px-2" />
                    <input type="text" class="form-control " placeholder="Search currencies" style="padding-left: 38px;" v-model="query" />
                </div>

            </div>
            <div class="d-flex">

                <div>
                    <button class="btn btn-primary text-white mt-2" data-bs-toggle="modal" data-bs-target="#createCurrencyModal">Create Currency</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3 cardpadding" style="overflow-x: auto;">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="allCurrencies.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
            <div class="fw-bold mt-3">Your currency bucket is empty!</div>
        </div>

        <div class="mb-5 cardpadding">
            <div class="table-responsive">
                <table v-if="allCurrencies.length > 0 && loading == false" class="table v-middle">
                    <thead style="border-style: hidden !important;">
                        <tr class="bg-white">
                            <th scope="col"><input type="checkbox"></th>
                            <th scope="col">Name</th>
                            <th scope="col">Code</th>
                            <th scope="col">Symbol</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(currency, index) in allCurrencies" :key="index">
                            <td class="py-3"><input type="checkbox"></td>
                            <td class="py-3">{{ currency.name }}</td>
                            <td class="py-3">{{ currency.code }}</td>
                            <td class="py-3">{{ currency.symbol }}</td>
                            <td class="py-3">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" :true-value=1 :false-value=0 :value="currency.id" v-model="statuses[index]" @change="updateCurrencyStatus(currency.id, $event)">
                                </div>
                            </td>
                            <td class="py-3">
                                <div class="" style="font-size: 13px;">
                                    <font-awesome-icon type="button" prefix="fal" icon="pen" class="me-2" style="font-size: 15px;" @click="getOneModal(currency)" data-bs-toggle="modal" data-bs-target="#editCurrencyModal" />
                                    <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#deleteCuurencyModal" icon="trash" style="font-size: 15px;" @click="getOneModal(currency)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteCuurencyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteCuurencyModal">Delete Currency</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img src="../../../assets/images/Frame.png" style="font-size: 70px;" alt="trash">
                </div>
                <div class="fw-bold text-center mt-3 mb-2" style="font-size:20px">
                    Delete
                </div>
                <div class="text-center">
                    Are you sure you want to delete this currency?
                </div>
            </div>
            <!-- <div class="modal-footer">
          <button @click.prevent="deleteTax" type="button" class="btn btn-danger" data-bs-dismiss="modal">Confirm</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
        </div> -->
            <div class="mt-3 mb-5 text-center">
                <button @click.prevent="deleteCurrency" type="button" class="btn btn-success me-3" style="padding-left:2.5rem; padding-right:2.5rem" data-bs-dismiss="modal">Yes</button>
                <button type="button" class="btn" style="background: #DFEAFD; padding-left:2.5rem; padding-right:2.5rem" data-bs-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="editCurrencyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editCurrencyModal">Edit Currency</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="updateCurrency">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Currency Name</label>
                            <input type="text" class="form-control" placeholder="Enter tax name" v-model="name">
                        </div>
                        <div class="col-md-6">
                            <label>Currency Code</label>
                            <input type="text" class="form-control" placeholder="Enter percentage" v-model="code">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Currency Symbol</label>
                            <input type="text" class="form-control" placeholder="Enter percentage" v-model="symbol">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="tax_name < 1 && tax_percentage < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="createCurrencyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createCurrencyModal">Create Currency</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createCurrency">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Currency Name</label>
                            <input type="text" class="form-control" placeholder="Enter currency name" v-model="cname">
                        </div>
                        <div class="col-md-6">
                            <label>Currency Code</label>
                            <input type="text" class="form-control" placeholder="Enter code" v-model="ccode">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Currency Symbol</label>
                            <input type="text" class="form-control" placeholder="Enter symbol" v-model="csymbol">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="(cname < 1) && (ccode < 1) && (csymbol < 1)" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">Create</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
// import { ref } from '@vue/reactivity';
import SettingHeaderTab from './components/settingheadertab.vue';
import axios from 'axios';
import { useToast} from 'vue-toastification';

export default {
    components: {
        SettingHeaderTab
    },

    mounted(){
        this.getCurrencies();
    },

    data() {
        return {
            allCurrencies: [],
            currency_id: '',
            status: '',
            statuses: [],
            loading: false,
            query: '',
            code: '',
            name: '',
            symbol: '',
            ccode: '',
            cname: '',
            csymbol: '',
            validationErrors: [],
            crudLoading: false,
            toast: useToast(),
        };
    },
       
    methods: {
        async getCurrencies(){
            this.loading = true
            await axios.get('settings/currency', { query: this.query }).then((response) => {                
                this.allCurrencies = response.data.data.data;
                this.allCurrencies.forEach((c, index) => {
                    this.statuses[index] = c.is_active
                })
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.toast.error(error.response.data.message);
            });
        },

        getOneModal(currency){
            this.currency_id = currency.id
            this.name = currency.name
            this.code = currency.code
            this.symbol = currency.symbol
        },

        async updateCurrencyStatus(id, evt){

            await axios.put('settings/currency/set-active/' + id, {
                status: evt.target.checked
            }).then((res) => {
                this.toast.success(res.data.message);
                this.getCurrencies()
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.warning('There are errors in your input');
                }
                if (error.response.status == 500) {
                    this.toast.error('An error occured please try again');
                }
            })
        },

        async createCurrency(){
            this.loading = true
            await axios.post('/settings/currency', {
                name: this.cname,
                code: this.ccode,
                symbol: this.csymbol

            }).then(() => {
                this.crudLoading = true
                this.name = ''
                this.code = ''
                this.symbol = ''
                this.toast.success('Created currency successfull')
                this.getCurrencies()
                this.loading = false

            }).catch((error) => {
                this.crudLoading = false

                if (error.response.status == 422) {
                    this.toast.warning('There are errors in your input');
                    this.validationErrors.value = error.response.data.message
                }
                if (error.response.status == 500) {
                    this.toast.error('An error occured please try again');
                }
                this.loading = false

            })
        },

        async updateCurrency(){
            this.crudLoading = true
            await axios.put(`${'/settings/currency/update/'}`, {
                id: this.currency_id,
                name: this.name,
                code: this.code,
                symbol: this.symbol

            }).then(() => {
                this.toast.success('Created tax successfull')
                this.getCurrencies()
                this.crudLoading = false

            }).catch((error) => {
                this.crudLoading = false

                if (error.response.status == 422) {
                    this.toast.warning('There are errors in your input');
                    this.validationErrors.value = error.response.data.message
                }
                if (error.response.status == 500) {
                    this.toast.error('An error occured please try again');
                }
                
                if (error.response.status == 400) {
                    this.toast.error("Unfortunately this currency can not be edited")
                } 
                this.crudLoading = true

            })
        },

        async deleteCurrency(){
            await axios.delete(`/settings/currency/${this.currency_id}`).then(() => {
                this.toast.success('Currency deleted successfully')
                this.getCurrencies()
            }).catch((error) => {
                if (error.response.status == 400) {
                    this.toast.error(error.response.data.message)
                } else {
                    this.toast.error('Currency could not be deleted')
                }
            })

        }

        // watch(query, () => {
        //     getCurrencies()
        // });
        
    }
}
</script>

<style>

</style>
