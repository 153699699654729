<template>
  <div>
    <div class="mt-2" style="font-size: 20px; color: #35413B; font-weight: 700">Subscription Plan</div>

    <div class="card makecardresponsive rounded-lg mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
      <div v-if="loading == true" class="text-center py-5">
        <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="text-center py-5" v-if="subscriptions.length == 0 && loading == false">
        <div>
          <img src="../../private-assets/images/emptypng.png" alt="" />
        </div>
        <div class="fw-bold mt-3">
          Your subscriptions list is empty!
        </div>
      </div>

      <div v-if="loading == false && subscriptions.length > 0">
        <div class="">
          <table class="table table-sm-responsive">
            <thead class="thead-white" style="border-style: hidden !important;">
              <tr class="pb-5">
                <th class="fw-bold" style="font-size: 14px">Invoice ID</th>
                <th class="fw-bold" style="font-size: 14px">Package Purchased</th>
                <th class="fw-bold" style="font-size: 14px">Amount</th>
                <th class="fw-bold" style="font-size: 14px">Maximum User</th>
                <th class="fw-bold" style="font-size: 14px">Maximum Location</th>
                <th class="fw-bold" style="font-size: 14px">Date Purchased</th>
                <th class="fw-bold" style="font-size: 14px">Expiry Date</th>
                <th class="fw-bold" style="font-size: 14px">Status</th>

              </tr>
            </thead>

            <tbody>
              <tr class="" v-for="sub in subscriptions" :key="sub.id">
                <td class="text-secondary">{{sub?.invoice_number}}</td>
                <td class="text-secondary">{{ sub?.user?.trial_subscription == '14 days trial' ?
                sub?.user?.trial_subscription : sub?.plan_detail?.category?.name}}</td>
                <td class="text-secondary" v-if="!sub?.user?.trial_subscription">{{
                  $filters.formatMoney(sub?.plan_detail?.amount, currentUserCurrency?.symbol)
                }} per
                  {{ sub?.plan_detail?.type == 'Monthly' ? 'month' : sub?.plan_detail?.type == 'Quarterly' ? '3 months' :
                sub?.plan_detail?.type == 'Half Year' ? 'six months' : sub?.plan_detail?.type == 'Yearly' ? 'year' :
                  '' }}
                </td>
                <td v-else>
                  {{ sub?.user?.trial_subscription }}
                </td>
                <td class="text-secondary">{{ sub?.plan_detail?.max_employee }} Users</td>
                <td class="text-secondary">{{ sub?.plan_detail?.max_location }}</td>

                <td class="text-secondary">{{ dateTime(sub?.subscription_date) }}</td>
                <td class="text-secondary">{{ dateTime(sub?.expiry_date) }}</td>
                <td class="text-secondary">
                  <span class="badge bg-success" style="width:60px" v-if="sub?.status == 1 || sub?.status == 0">Active</span>
                  <span class="badge bg-danger" style="width:60px" v-else>In Active</span>
                </td>

                <td class="text-secondary" @click="toggleBar">
                  <!-- <img src="../../private-assets/images/pick.webp" style="margin-top:-1rem; cursor:pointer" alt=""> -->

                  <div class="dropdown p-0">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false" style="background-color:#fff;padding:2px">
                      Select
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><router-link :to="'/subscription/payment-invoice/' + sub?.id"
                          class="dropdown-item">Print</router-link></li>
                      <li><a class="dropdown-item" href="#" @click="currentSub('renew', sub)"
                          :class="sub?.status == 1 && !sub?.user?.trial_subscription ? 'disabled' : ''">Renew</a></li>
                      <li><a class="dropdown-item" href="#" @click="currentSub('upgrade', sub)">Upgrade Plan</a></li>


                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="mt-5">
          <div>You currently have {{ subscriptions.length }}/{{ users.length }} users on your account</div>
        </div>

        <div style="background: #DAE7FC;" class="py-4 px-2">
          <div class="d-flex justify-content-between bottomcontent">
            <div>Our Support Lines: {{details.tel}},{{details.tel2}} </div>
            <div>Email: {{ details.email }}</div>
          </div>
        </div>
      </div>
    </div>

    <Alert :modalId="'singleModal'" @alertModal="handleAction()"
      :modalText="'Are you sure you want to renew this subscription'" :action="'alertModal'"
      title="Subscription Renewal"></Alert>
  </div>
</template>

<script>
import Alert from "../../../components/AlertModal.vue";

import { ref } from '@vue/reactivity';
import { onClickOutside } from "@vueuse/core";
import { onMounted } from "@vue/runtime-core";
import { reusableMixin } from '../../../lib/reusableMixin';
import { useToast } from "vue-toastification";
import axios from "axios";
import {
  useRouter
} from "vue-router";

export default {
  components: {
    Alert

  },
  mixins: [reusableMixin],
  setup() {
    const showToggle = ref(false);
    const targetToggle = ref(null);
    const subscriptions = ref([])
    const toast = useToast();
    const loading = ref(false)
    const currentID = ref(null)
    const currentAction = ref(null)
    const users = ref([])
    const router = useRouter();
    const super_user = ref([]);
    const details = ref([]);

    const currentSub = (action, sub) => {
      currentID.value = sub.id
      currentAction.value = action

      if (currentAction.value == 'upgrade') {
        router.push('/subscribe/' + currentID.value + '?query=upgrade')
      } else if (currentAction.value == 'renew') {
        router.push('/subscription-payment/' + sub.plan_details_id + '?query=renew')
      }
    }
    const handleAction = () => {
      if (currentAction.value == 'renew') {
        axios.get("/subscription/user/renew/" + currentID.value)
          .then((response) => {
            activeSubscription()
            toast.success(response.data.message);
          }).catch((error) => {
            toast.error(error.response.data.message);

          })
      }
    }

    const toggleBar = () => {
      showToggle.value = !showToggle.value;
    };

    onClickOutside(targetToggle, () => {
      showToggle.value = false;
    });

    const activeSubscription = () => {
      loading.value = true
      axios.get("/subscription/user")
        .then((response) => {
          loading.value = false
          subscriptions.value = response.data.data
          // console.log(response.data.data, 'hh')
          allUsers()
          superUser()
          pageData()
        }).catch(() => {
          loading.value = false
        })
    }

    const pageData = () => {
        axios.get("/admin/fetchProjectSetup").then((response) => {
            details.value = response.data.data;
        })
    }

    const allUsers = () => {
      axios.get("/user/all")
        .then((response) => {
          loading.value = false
          users.value = response.data.data.data
        }).catch(() => {
        })
    }


    const superUser = () => {
      axios.get("/user/account")
        .then((response) => {
          loading.value = false
          super_user.value = response.data.data.data
        }).catch(() => {
        })
    }

    onMounted(() => activeSubscription());


    return { pageData, showToggle, targetToggle, toggleBar, activeSubscription, subscriptions, handleAction, loading, currentAction, currentID, currentSub, users, super_user, details }
  }
}
</script>

<style scoped>
.toggle {
  position: absolute;
}

.innertoggle {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  /* border-bottom: 1px solid gray; */
  width: 100% !important;
}

.innertoggle:hover {
  background: #E3FFF1;
  width: 100%;
}

.hometabletoggle {
  background: white;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  padding-top: 3px;
  width: 10rem;
}

@media (max-width: 767px) {
  .bottomcontent {
    flex-direction: column;
    width: 100%;
  }

  .makecardresponsive {
    width: 100%;
  }
}
</style>