<template>
 
<div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">

    <!-- Preload -->
    <!-- <div id="loading">
        <div class="load-circle"><span class="one"></span></div>
    </div> -->
    <!-- End Preload -->
    
    <Navbar />

    <!-- Main -->
    <main>
        <!-- Home Banner -->
        <section class="section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 m-15px-tb">
                        <h1 class="dark-color h1 font-w-400 m-15px-b">Welcome to <span class="theme-color">InventFlux</span>. Inventory Manager.</h1>
                        <p class="font-1 font-w-400 m-20px-b">With InventFlux, retailers, wholesalers and distributors can manage their inventory in a single place at any time and at anywhere</p>
                        <div class="p-10px-t">
                            <router-link class="m-btn m-btn-theme m-btn-radius" to="/auth/register">Get Started <i class="fas fa-arrow-right d-none d-md-inline m-10px-l"></i></router-link>&nbsp;
                            <a class="m-btn m-btn-theme-light m-btn-radius" href="#">Documentation</a>
                        </div>
                    </div>
                    <div class="col-lg-7 m-15px-tb">
                        <img src="static/img/cat-v2-8.svg" title="" alt="">
                    </div>
                </div>
            </div>
        </section>
        <!-- End Home Banner -->

        <!-- Section -->
        <section class="section gray-bg">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-400 dark-color m-10px-b">Do more with InventFlux</h3>
                        <p class="font-1 m-0px">Building a thriving business is hard enough without worrying about tracking your stocks. We are here to ease all your worries by providing the you with the best IMS System</p>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Location Management</h6>
                                <p class="m-15px-b">Whether you're picking, packing, or shipping, InventFlux solutions help you better manage warehouse inventory</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Expiry Tracking</h6>
                                <p class="m-15px-b">Whether you're a construction company or a hospital, there's a constant demand for critical supplies and we help you track</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Product Barcodes</h6>
                                <p class="m-15px-b">Assign a different barcode than your stocking product number for internal organization.</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">User-Definable Categories</h6>
                                <p class="m-15px-b">Search, find, and analyze inventory by using custom categories and sub-categories.</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Cost Tracking</h6>
                                <p class="m-15px-b">All transactions are stored to give you detailed audits on the cost fluctuations of your inventory.</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Track Debtors/Creditors</h6>
                                <p class="m-15px-b">Business owners can find and monitor the customers who bought on credits and are owing them.</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Stock Adjustment</h6>
                                <p class="m-15px-b">Business owners can adjust already existing stocks to match record.</p>    
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 m-15px-tb">
                        <div class="media box-shadow p-20px white-bg hover-top">
                            <div class="avatar-100 border-radius-10 dots-icon">
                                <img src="static/img/cat-v2-i-1.svg" title="" alt=""/>
                                <span class="dots"><i class="dot dot1"></i><i class="dot dot2"></i><i class="dot dot3"></i></span>
                            </div>
                            <div class="media-body p-15px-l">
                                <h6 class="dark-color font-w-500 m-5px-b">Sales Reversal</h6>
                                <p class="m-15px-b">Business owners can can reverse completed sales</p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->

        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 m-15px-tb">
                        <label class="font-small font-w-500 theme-color m-10px-b">We are here to help you</label>
                        <h3 class="dark-color font-w-500">automate your inventory <span class="theme2nd-color">with one simple InventFlux</span></h3>
                        <p class="font-1">Maintain accurate stock counts, reduce errors, and avoid time-consuming manual entry of items, locations, sales, purchases and more.</p>
                          <router-link class="m-btn m-btn-theme flex-shrink-0" to="/auth/register">Get Started</router-link>
                    </div>
                    <div class="col-lg-6">
                        <div class="box-shadow-lg border-top-3 border-color-theme p-25px">
                            <div class="media align-items-center border-bottom-1 border-color-dark-gray p-20px-tb">
                                <div class="icon-40 blue-bg-alt blue-color border-radius-50">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="media-body p-20px-l">
                                    <h6 class="dark-color font-w-500 m-5px-b">Quick, Accurate, Validated.</h6>
                                    <p class="m-0px">saves you alot of time</p>
                                </div>
                            </div>

                            <div class="media align-items-center border-bottom-1 border-color-dark-gray p-20px-tb">
                                <div class="icon-40 green-bg-alt green-color border-radius-50">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="media-body p-20px-l">
                                    <h6 class="dark-color font-w-500 m-5px-b">Guaranteed 100% uptime.</h6>
                                    <p class="m-0px">247 access to your inventory from anywhere</p>
                                </div>
                            </div>

                            <div class="media align-items-center border-bottom-1 border-color-dark-gray p-20px-tb">
                                <div class="icon-40 yellow-bg-alt yellow-color border-radius-50">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="media-body p-20px-l">
                                    <h6 class="dark-color font-w-500 m-5px-b">Lifetime storage.</h6>
                                    <p class="m-0px">Have access to your data forever</p>
                                </div>
                            </div>

                            <div class="media align-items-center p-20px-tb">
                                <div class="icon-40 pink-bg-alt pink-color border-radius-50">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="media-body p-20px-l">
                                    <h6 class="dark-color font-w-500 m-5px-b">Highly Secured</h6>
                                    <p class="m-0px">Your data is safe with us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->

        <!-- Section -->
        <section class="section gray-bg overflow-hidden">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-400 dark-color m-10px-b">Track. Control. Improve.</h3>
                        <p class="font-1 m-0px">Automate processes and sub-processes involved in stock management, get reporting at each stage, stop using spreadsheets, save time and money.</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->

        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row flex-row-reverse align-items-center justify-content-between">
                    <div class="col-lg-5 m-15px-tb">
                        <h3 class="font-w-500 dark-color h2">InventFlux Delivers What Others Can’t</h3>
                        <p class="font-1">Built with knowledge collected from thousands of inventory client use cases. InventFlux helps keep track of processing, receiving, stock locations, moving product, selling as well as packing and shipping.</p>
                        <ul class="list-type-03 m-20px-b">
                            <li><i class="fas fa-check"></i>Automated inventory management.</li>
                            <li><i class="fas fa-check"></i>Minimize handling costs</li>
                            <li><i class="fas fa-check"></i>Enhance cash flow optimization</li>
                        </ul>
                        <router-link class="m-btn m-btn-theme m-btn-radius" to="/auth/register">Get Started with InventFlux</router-link>
                    </div>
                    <div class="col-lg-6 m-15px-tb">
                        <img src="static/img/cat-v2-7.svg" title="" alt="">
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->


        <!-- Section -->
        <section id="feature" class="section">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-600 dark-color m-20px-b p-20px-b theme-after after-50px">Eliminate Manual Inventory Management Pains</h3>
                        <p class="m-0px font-1">Efficiently and effectively track products, sales, purchases, locations and suppliers or customer purchasing data through our all-in-one inventory management software.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 m-15px-tb">
                        <div class="p-50px-tb p-25px-lr box-shadow-hover hover-top hover-rotate white-bg text-center border-radius-5 border-all-1 border-color-gray">
                            <div class="avatar-100 m-25px-b">
                                <img src="static/img/icon1.png" title="" alt="">
                            </div>
                            <h5 class="dark-color h6 font-w-600 m-15px-b">Well Integrated Solution</h5>
                            <p class="m-0px">Our wide range of integrations streamlines accounting by enabling the seamless transfer of information to and from other systems.</p>
                        </div>
                    </div>
                    <div class="col-md-4 m-15px-tb">
                        <div class="p-50px-tb p-25px-lr box-shadow-hover hover-top hover-rotate white-bg text-center border-radius-5 border-all-1 border-color-gray">
                            <div class="avatar-100 m-25px-b">
                                <img src="static/img/icon2.png" title="" alt="">
                            </div>
                            <h5 class="dark-color h6 font-w-600 m-15px-b">Very Compatible</h5>
                            <p class="m-0px">InventFlux collaborates closely with manufacturers of Barcode and RFID technologies to ensure reliable performance across a wide array of scanning equipment.</p>
                        </div>
                    </div>
                    <div class="col-md-4 m-15px-tb">
                        <div class="p-50px-tb p-25px-lr box-shadow-hover hover-top hover-rotate white-bg text-center border-radius-5 border-all-1 border-color-gray">
                            <div class="avatar-100 m-25px-b">
                                <img src="static/img/icon3.png" title="" alt="">
                            </div>
                            <h5 class="dark-color h6 font-w-600 m-15px-b">Highly Scalable</h5>
                            <p class="m-0px">Whether you choose our cloud or your own server, our multi-warehouse, multi-lingual, and multi-currency capabilities provide the flexibility you require.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->




        <!-- Section -->
        <section class="section gray-bg">
            <div class="container">
                <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="h1 font-w-600 dark-color m-20px-b p-20px-b theme-after after-50px">Our Pricing Plans</h3>
                        <p class="m-0px font-1">Let’s Get Started with a fully integrated management system. We have the ideal Package for your Business</p>
                    </div>
                </div>
                <div class="tab-style-1">
                    <ul class="nav nav-fill nav-tabs">
                        <li class="nav-item">
                            <a href="#tab3_sec1" data-toggle="tab" class="active">
                                <div class="icon"><i class="icon-chat"></i></div>
                                <span>Monthly</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec2" data-toggle="tab">
                                <div class="icon"><i class="icon-tools"></i></div>
                                <span>Quarterly</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec3" data-toggle="tab">
                                <div class="icon"><i class="icon-megaphone"></i></div>
                                <span>Half Year</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#tab3_sec4" data-toggle="tab">
                                <div class="icon"><i class="icon-heart"></i></div>
                                <span>Yearly</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="tab3_sec1" class="tab-pane fade in active show">
                          <div class="row align-items-center">
                              <div v-for="monthly in monthly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec2" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in quarterly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec3" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in halfyear_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div id="tab3_sec4" class="tab-pane fade in">
                          <div class="row align-items-center">
                              <div v-for="monthly in yearly_plans" :key="monthly.id" class="col-lg-4 m-15px-tb">
                                  <div class="price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray">
                                      <div class="pt-head d-flex align-items-center justify-content-between">
                                          <div class="pth-left">
                                              <h4 class="dark-color">{{ monthly.name }}</h4>
                                              <!-- <label>10 Lines Min lines min</label> -->
                                          </div>
                                          <div class="pth-right">
                                              <h5 class="theme2nd-color"><span>₦</span>{{monthly.amount.toLocaleString()}}</h5>
                                              <label>/{{monthly.type}}</label>
                                          </div>
                                      </div>
                                      <div class="pt-body">
                                          <ul class="list-type-01">
                                              <li v-for="(details, index) in JSON.parse(monthly.descriptions)" :key="index" style="font-size: 14px"><i class="fas fa-check"></i> {{details}}</li>
                                          </ul>
                                      </div>
                                      <div class="pt-footer">
                                          <a class="m-btn m-btn-theme2nd m-btn-radius" href="#">GET STARTED</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->


        
        <!-- Section -->
        <section class="section effect-section p-0px-t gray-bg">
            <div class="effect effect-bg-40 bottom theme3rd-bg"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6">
                        <div class="box-shadow-lg white-bg p-40px md-p-25px border-radius-10 border-top-3 border-color-theme">
                            <h3 class="h4 font-w-500 dark-color m-30px-b">See InventFlux in Action</h3>
                            <form class="rd-mailform" @submit.prevent="sendEnquiry">
                                <div class="form-group">
                                    <input type="text" name="name" v-model="enquiry_name" placeholder="Name" required class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="email" name="email" v-model="enquiry_email" placeholder="Email" required class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" name="phone_number" v-model="enquiry_tel" placeholder="Phone Number" required class="form-control">
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" name="message" v-model="enquiry_message" rows="5" placeholder="Your Message" required></textarea>
                                </div>
                                <div class="form-action">
                                    <button v-if="loading == false" class="m-btn w-100 m-btn-theme m-btn-radius" type="submit">Request a Free Demo</button>
                                    <button v-if="loading == true" class="m-btn w-100 m-btn-theme m-btn-radius" disabled>
                                      <div class="spinner-grow text-info" role="status">
                                        <span class="visually-hidden">Processing</span>
                                      </div>
                                    </button>
                                    <div class="snackbars" id="form-output-global"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->
    </main>
    <!-- End Main -->

    <Footer />

      </div>


</template>

<script>
import Navbar from "../components/NavbarHome";
import Footer from "../components/footer";
import axios from "axios";
import { reusableMixin } from '../lib/reusableMixin';

import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
// import paystack from "vue3-paystack";
import { useFlutterwave } from "flutterwave-vue3"

export default {
  components: {
    Navbar,
    Footer,
    // paystack
  },
  mixins: [reusableMixin],
  data() {
    const router = useRouter();
    const toast = useToast();
    return {
      monthly_plans: [],
      quarterly_plans: [],
      halfyear_plans: [],
      yearly_plans: [],
      enquiry_name: '',
      enquiry_email: '',
      enquiry_tel: '',
      enquiry_message: '',
      details: [],
      loading: false,
      router: router,
      toast: toast,
      amount: 0,
      token: localStorage?.token,
      payment: false,
      publicKey:'pk_live_7505355c505d780e487e23eb2ae2911b8a42f09e',
      user: localStorage.vuex !== undefined && JSON.parse(localStorage?.vuex),
      planData: {
        type: 'Monthly',
        category: 'IMS',
        plan: null,
        plan_details_id: null,
        amount: 0,
        user_sub_id: null
      }

    }
  },
  computed: {
    reference(){
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }
  },
  methods: {
    onSuccessfulPayment(res) {
      this.$refs.closeModalRef.click();
      axios.post("/subscription/buy", {
        amount: this.amount/100,
        reference_number: res.reference,
        plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
        type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
        payment_type: 'paystack'

      }).then((response) => {
        this.toast.success(response.data.message);
        this.$refs.closeModalRef.click();
        setTimeout(() => {
          window.location.replace("/subscription-plan");
        }, 1000)
      })
      .catch ((err)=> {
        this.toast.error('error');
        console.log(err)
      })
    },

    onCancelledPayment() {
      console.log('cancel')
    },

    setAmount (amount) {
      if(this.token === undefined) {
        this.toast.error('signin to buy plan');
        this.router.push('/auth/login')
      }else {
        this.checkIfEligible(amount);
      }
      this.amount = amount * 100;
      this.payment = false;
    },

    flutterPayment() {
      useFlutterwave({
          amount: this.amount/100,
          callback(data) {
            let toast = useToast();
            let payment_data = {
              plan_details_id: this.plans.filter(plan=> plan.amount === this.amount/100)[0].id,
              type: this.plans.filter(plan=> plan.amount === this.amount/100)[0].type,
              customer: data.customer,
              reference_number:data.flw_ref,
              meta:data,
              payment_type: 'Flutterwave'
            }
            this.$refs.closeModalRef.click();

            axios.post('/subscription/buy', payment_data).then((response) => {
              toast.success(response.data.message);
              setTimeout(() => {
                window.location.replace("/subscription-plan");
              }, 1000)
            }).catch((error) => {
              this.toast.error(error.response.data.message);
            })

          },
          country: "NG",
          currency: this.user.getLoggedInUser?.code,
          customer: { email: this.user.getLoggedInUser.email, name: this.user.getLoggedInUser.fullname, phone_number: this.user.getLoggedInUser.company.phone },
          customizations: { description: "", logo: "", title: "BMAC" },
          meta: {
            user_id: this.user.getLoggedInUser.id,
            // token: 'FLW-' + this.reference
          },
          onclose() {
            console.log('close it here')
          },
          payment_options: "card",
          public_key: "FLWPUBK-9dbf5b16745932868693fead47ef7100-X",
          redirect_url: undefined,
          tx_ref: "tx_ref_" + Math.random().toString(36).substring(1, 100)
        })
    },

    checkIfEligible(amount) {
        this.payment = true;
      axios.post('/subscription/check-subscription', {
        plan_details_id: this.plans.filter(plan=> plan.amount === amount)[0].id,
      }).then((res) => {
        this.$refs.payRef.click();
        console.log(res);
      }).catch((error) => {
        this.payment = false;
        console.log(error)
        this.toast.error(error.response.data.message);
      })
    },

    async sendEnquiry() {
      this.loading = true;
      await axios
        .post("/send-enquiry", {
          name: this.enquiry_name,
          email: this.enquiry_email,
          message: this.enquiry_message,
          phone_number: this.enquiry_tel,
        })
        .then((res) => {
          this.loading = false;
          this.enquiry_name = '';
          this.enquiry_email = '';
          this.enquiry_message = '';
          this.enquiry_tel = '';
          this.toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response.status === 422 || error.response.status === 400) {
            this.toast.error(error.response.data.message);
          } else if (error.response.status === 500) {
            this.toast.error("An error occured please try again");
          } else if (error.response.status === 404) {
            this.toast.error("the API route does not exist!");
          } else {
            this.toast.error("An error occured please try again");
          }
          this.loading = false;
        });
    },


    getPricingPlans() {
      axios.get("/subscription/pricing-plans").then((response) => {
        this.monthly_plans = response.data.data.monthly
        this.quarterly_plans = response.data.data.quarterly
        this.halfyear_plans = response.data.data.halfyear
        this.yearly_plans = response.data.data.yearly
      })
    },

    scrollTop(){
        window.scroll({
            top: 0,
            behavior: 'smooth',
        })
    }



  },

  mounted() {
    this.superUser()
    this.getPricingPlans()
    this.scrollTop();
  },
  mountBefore() {

  }
}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>