<template>
<InventoryHeaderTab />

<div class="px-2">
    <div class="mt-2 pl-3 font-weight-bold bulkheader" style="font-size:24px"> Bulk Product Upload</div>
</div>
<div class="d-flex justify-content-center" style="overflow-x:auto;">
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3">
        <div class="mt-5">1. All imports must be uploaded using the correct template file.</div>
        <div class="mt-3">
            <button v-if="downloading == false" class="btn btn-primary w-100 py-3 font-weight-bold" @click="downloadFile('/inventory/product/download/upload', 'Product_Upload_Template.xlsx')">Download Template</button>
            <button v-if="downloading == true" type="submit" class="btn btn-primary btn-lg btn-block w-100 rounded-3">
                Downloading...
            </button>

        </div>

        <section class="mt-5">
            <form>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="preview-zone hidden">
                                    <div class="box box-solid">
                                        <div class="box-body"></div>
                                    </div>
                                </div>
                                <div class="dropzone-wrapper">
                                    <div class="dropzone-desc">
                                        <font-awesome-icon icon="sticky-note" class="dragfileicon text-primary" />
                                        <p class="fw-bold mt-2 dragtext">Drag your file here or <span class="text-primary">browse</span></p>
                                        <p>{{import_file_name}}</p>

                                    </div>
                                    <!-- <input type="file" name="file_import" class="dropzone90" ref="import_file" @change="onFileChange"> -->
                                    <input type="file" name="filename" class="custom-file-input dropzone" id="inputFileUpload" v-on:change="onFileChange">
                                </div>
                                <span align="center" v-if="validationErrors">
                                    <p class="form-text text-danger" v-for="(error, index) in validationErrors" :key="index">
                                        {{ error[0]}}
                                    </p>
                                </span>

                                <p class="form-text text-danger" align="center" v-if="dateFormatError != ''">
                                    {{ dateFormatError }}
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="mt-2 text-center">
                        <button v-if="loading == false" type="submit" class="btn btn-primary btn-lg btn-block w-75 rounded-3" @click="importproducts($event)">Upload</button>
                        <button v-if="loading == true" type="submit" class="btn btn-primary btn-lg btn-block w-100 rounded-3">
                            <div class="spinner-grow text-info" role="status">
                                <span class="visually-hidden">Processing</span>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </section>

        <div class="mt-4">
            <div class="mb-2">Download the Sample Template <a href="#" @click="downloadFile('/inventory/product/download/sample', 'Product_Sample_Template.xlsx')"> Here</a></div>
            <div class="font-weight-bold">Instructions</div>
            <div class="mb-2">Below are a few instructions to help you in filling the spreadsheet</div>
            <div class="mt-1 d-flex">
                <span class="me-2">1.</span>
                <div><span class="fw-bold">Download a template of the spreadsheet</span> using the 'Download Template' button to upload products using excel.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">2.</span>
                <div><span class="fw-bold">Product Name:</span> Name of the inventory. It is compulsory</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">3.</span>
                <div><span class="fw-bold">Barcode:</span> Barcode for inventory. Ensure that the Barcode column sheet is on <span class="fw-bold">“TEXT”</span>. It is not mandatory.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">4.</span>
                <div><span class="fw-bold">Unit of Measurement:</span> Item Unit of Measure. It is a compulsory filled. e.g Pcs, Carton, Dozen, Kg etc</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">5.</span>
                <div><span class="fw-bold">Category:</span> Category name for the inventory. Not mandatory but if not filled sales by category report will be blank. The system creates the inventory category if the category is not existing</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">6.</span>
                <div><span class="fw-bold">Cost Price:</span> Inventory cost price. Fill it with zero if you don't need the profitability report and profit analysis.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">7.</span>
                <div><span class="fw-bold">Selling Price:</span> Inventory Selling Price. Inventory Selling Price. Fill it with zero if you don't need the profitability report and profit analysis.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">8.</span>
                <div><span class="fw-bold">Store Name:</span> Ensure you enter the exact Location Name (i.e. case sensitive) as it was created on the system which can be found in <span class="fw-bold">Manage Stores</span> under <span class="fw-bold">Administration Module</span>. It is compulsory</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-2">9.</span>
                <div><span class="fw-bold">Quantity:</span> Inventory Quantity. Fill it with zero if you don't want to input any quantity for the inventory</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">10.</span>
                <div><span class="fw-bold">Best Before: </span>Where applicable, Best before date should be in the format DD/MM/YY. Ensure the Excel spreadsheet format is on <b>"TEXT"</b> </div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">11.</span>
                <div><span class="fw-bold">Tax:</span>Ensure you enter the exact tax Name (i.e. case sensitive) as it was created on the system which can be found in <span class="fw-bold">settings</span> -> <span class="fw-bold">Tax</span>. (The names should be separated with a comma where there are more than one tax name) and ensure that the Tax column sheet is on <span class="fw-bold">“TEXT”</span>.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">12.</span>
                <div><span class="fw-bold">Warning Quantity:</span><span>Enter the quantity you want the system to notify you when the inventory falls below or equals that quantity. Fill it with zero if you don’t want to receive notifications</span>.</div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">13.</span>
                <div><span class="fw-bold">Variant Name:</span><span>Helps in the creation of names for various fractions of inventory that are likely to be sold, such as pieces, cartons, crates, and so on. (The names should be separated with a comma where there are more than one) and ensure that the variant Name column sheet is on <span class="fw-bold">“TEXT”</span>.</span></div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">14.</span>
                <div><span class="fw-bold">Variant Qty:</span><span>This represents the numbers of units/pieces in the variant name created in 12 above. Ensure you maintain the order used while entering the variant name. (The units should be separated with a comma where there are more than one) and ensure that the variant Qty column sheet is on <span class="fw-bold">“TEXT”</span>.</span></div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">15.</span>
                <div><span class="fw-bold">Variant Price:</span><span>This represents the price of the units/pieces in the variant name created in 13 above. They are also expected to take the order used in 12&13 above. (The price should be separated with a comma where there are more than one) and ensure that the variant Qty column sheet is on <span class="fw-bold">“TEXT”</span>.</span></div>
            </div>

            <div class="mt-1 d-flex">
                <span class="me-1">16.</span>
                <div><span>Save the completed template anywhere of your choice on your computer. Select the saved template and click on the “Upload products” button to finish up the upload.</span></div>
            </div>

        </div>
        <div class="mt-3">
            <div class="">Note:</div>
            <div class="ms-4">
                <div class="d-flex">
                    <font-awesome-icon class="bottom-bulk" prefix="fas" icon="circle" />
                    <div class="ms-2">Ensure the Quantity unit is saved in pieces if you are using the variant</div>
                </div>
                <div class="d-flex mt-1">
                    <font-awesome-icon class="bottom-bulk" prefix="fas" icon="circle" />
                    <div class="ms-2">Ensure the store name has been created on the application before uploading</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import InventoryHeaderTab from './component/inventoryheadertab.vue'
import axios from 'axios'
import {
    useToast
} from 'vue-toastification'
import {
    useRouter
} from 'vue-router';

import {
    ref
} from '@vue/reactivity';

import { useReusable} from "../../../lib/reusable";

export default {
    components: {
        InventoryHeaderTab
    },
    setup() {
        const {downloading, downloadFile} = useReusable()
        const import_file = ref('')
        const loading = ref(false)
        const toast = useToast()
        const router = useRouter()
        const validationErrors = ref([]);
        const dateFormatError = ref('');

        const import_file_name = ref('')

        const downloadSample = async () => {
            downloading.value = true
            axios({
                url: '/inventory/product/download/template',
                method: "GET",
                responseType: "blob", // important
            }).then((response) => {
                downloading.value = false

                // Service that handles ajax call
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Product_Upload_Template.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(()=>{
                  downloading.value = false
            });

        }

        const onFileChange = (e) => {
            import_file.value = e.target.files[0]
            import_file_name.value = import_file.value.name
        }

        const importproducts = () => {
            validationErrors.value = []
            dateFormatError.value = []
            loading.value = true
            let formData = new FormData()
            formData.append("import_file", import_file.value)
             axios.post('/inventory/product/import', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data' 
                },
            }).then((response) => {
                    toast.success(response.data.message)
                    router.push('/all-products')
            

                loading.value = false
            }).catch((error) => {
                loading.value = false
                if (error?.response?.status == 422) {
                    validationErrors.value = error.response.data.errors
                    toast.warning(error.response.data.message);
                }
                if (error?.response?.status == 500) {
                    toast.error('An error occured please try again');
                }
                if (error?.response?.status == 400) {
                    dateFormatError.value = error.response.data.message
                    toast.error("There is an error in your input");
                }
                if (error.message == 'Network Error') {
                    import_file_name.value = null
                    toast.error("Upload file changed, upload again");
                }
                loading.value = false

            })
        }
        return {
            loading,
            downloading,
            validationErrors,
            import_file,
            onFileChange,
            importproducts,
            downloadSample,
            import_file_name,
            downloadFile,
            dateFormatError
        }
    }
}
</script>

<style>
.bottom-bulk {
  height: 9px;
  margin-top: 7px;
}

@media (max-width: 767px) {
  .dragfileicon {
    font-size: 30px;
  }

  .dragtext {
    font-size: 14px;
  }
}
</style>