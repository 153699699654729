<template>
    <div>
    <div class="row" id="error_message">
        <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
            <li class="text-danger" style="list-style: none">
                <small>{{ errors }}</small>
            </li>
        </ul>
    </div>

    <form @submit.prevent="createProducts" class="mt-4">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="inputEmail4">Bar Code</label>
                <input v-model="bar_code" type="text" class="form-control" placeholder="Input bar code" />
                <font-awesome-icon icon="barcode" class="scan" />
                <!-- <i class="mdi mdi-barcode-scan iconify scan"></i> -->
            </div>
            <div class="form-group col-md-4">
                <label>Product Name <span class="text-danger">*</span> </label>
                <input v-model="product_name" type="text" class="form-control" placeholder="Input product name" />
            </div>

            <div class="form-group col-md-4">
                <label>Unit of Measurement <span class="text-danger">*</span> </label>
                <Multiselect v-model="unit_of_measurement" :options="UOM" label="name"
                    placeholder="Select unit of measurement" :searchable="true" :create-option="false"
                    :close-on-select="true" />
            </div>
        </div>
        <div class="row mt-3">

            <div class="form-group col-md-6">
                <label>Cost Price</label>
                <input v-model="cost_price" type="text" class="form-control" placeholder="Input cost price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
            </div>
            <div class="form-group col-md-6">
                <label>Mark Up (%)</label>
                <input type="number" min=0 class="form-control" v-model="markup" @keyup="handleInput" />
                <span class="text-danger"><small>{{ markup_error }}</small></span>
            </div>
        </div>
        <div class="row mt-3">

            <div class="form-group col-md-4">
                <label for="inputState">Selling Price</label>
                <input v-model="selling_price" type="text" class="form-control" placeholder="Input selling price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    :disabled="isDisable" />
            </div>

            <div class="form-group col-md-4">
                <label for="">Category</label>
                <select id="" class="form-select" v-model="category" @change="categorySub($event)">
                    <option value="" disabled>Choose category</option>
                    <option v-for="catego in allCategories" :key="catego.id" :value="catego.id">
                        {{ catego.category_name }}
                    </option>
                </select>
            </div>

            <div class="form-group col-md-4">
                <label for="">Subcategory</label>
                <select id="" class="form-select" v-model="subcategory">
                    <!-- <option disabled>Choose Subcategory</option> -->
                    <option v-if="dropDownLoading" selected disabled>Loading...</option>
                    <option v-for="subcat in allSubcategories" :key="subcat.id" :value="subcat.id">
                        {{ subcat.name }}
                    </option>
                </select>
            </div>

        </div>
        <div class="row mt-3">
            <div class="form-group col-md-6">
                <label>Quantity</label>
                <input type="text" value="0" min=0 disabled class="form-control" placeholder="input quantity" />
                <div style="font-size: 13px">
                    You have to purchase or upload a product to update the quantity
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Warning Quantity</label>
                <input v-model="warning_quantity" type="number" class="form-control" min=0
                    placeholder="input warning quantity" onkeypress="return /^[0-9]+$/i.test(event.key)" />
                <span class="text-danger"><small>{{ warningQty_error }}</small></span>

            </div>
        </div>
        <div class="mt-3">
            <div class="fw-bold" style="font-size: 15px">
                APPLY TAX TO THIS PRODUCT
            </div>
            <div class="mt-3">
                <span v-for="(tax, index) in taxes" :key="tax.id">
                    <input class="form-check-input" type="checkbox" :id="tax.id" :true-value="tax.id"
                        :false-value="null" :value="tax.id" v-model="tax_id[index]" name="profileImg">
                    <label style="margin-right:20px !important;margin-left:5px" class="form-check-label"
                        for="flexCheckChecked">
                        {{ tax.tax_name }} ({{ tax.tax_percentage }}%)
                    </label>
                </span>
            </div>
            <div class="mt-3 mb-2 fw-bold" style="font-size: 15px">
                PRICE VARIANT
            </div>
            <div style="font-size: 15px">
                Add variant if this product is sold in multiple versions e.g pieces,
                dozens, cartons. If you want to make use of variant, ensure the
                quantity is saved in pieces.
            </div>
            <div v-for="(vary, index) in variant" :key="index.id" class="row mt-3">
                <div class="form-group col-md-3">
                    <label for="inputEmail4">Variant Name</label>
                    <input v-model="vary.variant_name" type="text" class="form-control"
                        placeholder="Input variant code" />
                </div>
                <div class="form-group col-md-3">
                    <label>Variant Price</label>
                    <input v-model="vary.variant_selling_price" type="text" class="form-control"
                        placeholder="Input variant price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </div>
                <div class="form-group col-md-3">
                    <label>Variant Quantity</label>
                    <input v-model="vary.variant_quantity" type="text" class="form-control"
                        placeholder="Input variant quantity" onkeypress="return /^[0-9]+$/i.test(event.key)" />
                </div>
                <div class="col-md-3 deletebtn">
                    <button @click.prevent="removeVariant(index.id)" class="btn btn-danger text-white fw-bold px-4">
                        X
                    </button>
                </div>
            </div>
            <div class="form-group col-md-4 mt-3">
                <button @click.prevent="addVariant" class="btn variantproduct">
                    Add Variant
                </button>
            </div>
        </div>

        <div class="mt-4 d-flex justify-content-end">
            <button type="submit" class="btn px-5 me-5 btn-primary text-white"
                v-if="crudLoading == false">Save</button>
            <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white" disabled>
                <span class="visually-">Processing</span>
            </button>
            <button type="button" @click="$router.go(-1)" class="btn px-5 exportbtn"
                v-if="$router.currentRoute.value.name == 'All Products'">Cancel</button>
            <button type="button" @click="$emit('modalToggle')" class="btn px-5 exportbtn" v-else>Cancel</button>

        </div>
    </form>

    </div>
</template>

<script>
import {
    ref
} from "@vue/reactivity";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";
import {
    onMounted
} from "@vue/runtime-core";
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        Multiselect
    },

    setup(props, { emit }) {
        const product_name = ref("");
        const cost_price = ref(0);
        const selling_price = ref(0);
        const category = ref("");
        const subcategory = ref("");
        const bar_code = ref("");
        const unit_of_measurement = ref("");
        const markup = ref("");
        const warning_quantity = ref("");
        const variant_name = ref("");
        const variant_selling_price = ref("");
        const variant_quantity = ref("");
        const image = ref(null);
        const tax_id = ref([])
        const store = ref('')
        const variant = ref([{
            variant_name: "",
            variant_selling_price: "",
            variant_quantity: ""
        },]);
        const taxes = ref([])
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);
        const allCategories = ref([]);
        const allSubcategories = ref([]);

        const stores = ref([])
        const store_arr = ref([])
        const UOM = ref([])
        const isDisable = ref(false)
        const markup_error = ref("")
        const warningQty_error = ref("")
        const crudLoading = ref(false)
        const dropDownLoading = ref(false)
        // const getProducts = ref([])
        const product_results = ref([])


        const clearFields = () => {
            product_name.value = null
            cost_price.value = null
            selling_price.value = null
            category.value = null
            subcategory.value = null
            unit_of_measurement.value = null

        }
        const handleInput = () => {
            if (Math.sign(markup.value) == -1) {
                markup_error.value = "Markup value must not be lesser than zero"
            } else {
                markup_error.value = ""
            }
            if (Math.sign(warning_quantity.value) == -1) {
                warningQty_error.value = "Warning quantity value must not be lesser than zero"
            } else {
                warningQty_error.value = ""
            }
            if (cost_price.value && markup.value) {
                selling_price.value = ((parseInt(markup.value) / 100) * parseInt(cost_price.value)) + parseInt(cost_price.value)
            } else {
                isDisable.value = false
            }
        }

        const onFileSelected = (event) => {
            let file = event.target.files[0];
            if (file.size > 5048770) {
                toast.warning("Image too large", {
                    timeout: 10000,
                })
                return;
            } else {
                let reader = new FileReader();
                reader.onload = (event) => {
                    image.value = event.target.result;
                };
                reader.readAsDataURL(file);
            }
        }

        const getStores = async () => {
            const getStores = await axios.get('/admin/store')
            const loadedStore = getStores.data.data
            stores.value = loadedStore
            stores.value.forEach((store) => {
                store_arr.value.push(store.store_name)
            })
        }

        const addVariant = () => {
            variant.value.push({
                variant_name: "",
                variant_selling_price: "",
                variant_quantity: "",
            });
        };

        const removeVariant = (index) => {
            variant.value.splice(index, 1);
        };


        const createProducts = async () => {
            crudLoading.value = true
            let currentRoute = router.currentRoute.value.name
            await axios
                .post("/inventory/product/save", {
                    image: image.value,
                    product_name: product_name.value,
                    cost_price: cost_price.value,
                    selling_price: selling_price.value,
                    category: category.value,
                    subcategory: subcategory.value,
                    bar_code: bar_code.value,
                    unit_of_measurement: unit_of_measurement.value,
                    markup: markup.value,
                    warning_quantity: warning_quantity.value,
                    // warning_quantity: warning_quantity.value,
                    variant: variant.value,
                    variant_name: variant_name.value,
                    variant_selling_price: variant_selling_price.value,
                    variant_quantity: variant_quantity.value,
                    tax_id: tax_id.value.filter(x => x !== null) ?? null,
                    store: store.value
                })
                .then((res) => {
                    emit('modalToggle')
                    crudLoading.value = false
                    if (currentRoute == 'Create Product') {
                        router.push("/all-products");
                    } else {
                        emit('saveProduct')
                    }
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;                        
                        document.getElementById('error_message').scrollIntoView()
                    }
                    if (error.response.status == 403) {
                        toast.error(error.response.data.message);
                    }

                    if (error.response.status == 400) {
                        validationErrors.value = error.response.data.message;
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                }).finally(() => {
                    if (router.currentRoute.value.name == 'All Products'); else clearFields();
                })
        };

        const categorySub = async (event) => {
            dropDownLoading.value = true
            let category_id = event.target.value
            await axios
                .post("/settings/category/subcategory", {
                    category_id: category_id,
                })
                .then((res) => {
                    // allSubcategories.value = res.data.data
                    allSubcategories.value = res.data.data.length < 1 ? [{ value: '', name: 'No subcategory' }] : res.data.data
                    dropDownLoading.value = false
                })
                .catch(() => {
                    dropDownLoading.value = true
                    toast.error("An error occured please try again");
                });
        };


        const getUnitofMeasurement = async () => {
            await axios
                .get("/inventory/product/uom/unit-of-measurement")
                .then((res) => {
                    let uom = res.data.data
                    uom.forEach((u) => {
                        UOM.value.push({ value: u.id, name: u.name })
                    })
                })
                .catch(() => {
                    toast.error("An error occured please try again");
                });
        }


        onMounted(async () => {
            getStores()
            getUnitofMeasurement()
            // allProducts()
            const result = await axios.get('settings/tax')
            taxes.value = result.data.data

            const response = await axios.get("/settings/category/all");
            allCategories.value = response.data.data;

            // const resp = await axios.get("/settings/subcategory/all");
            // allSubcategories.value = resp.data.data;
        });

        return {
            // allProducts,
            clearFields,
            product_results,
            dropDownLoading,
            categorySub,
            product_name,
            cost_price,
            selling_price,
            category,
            subcategory,
            bar_code,
            unit_of_measurement,
            markup,
            warning_quantity,
            variant,
            createProducts,
            addVariant,
            variant_name,
            variant_selling_price,
            variant_quantity,
            image,
            tax_id,
            taxes,
            removeVariant,
            validationErrors,
            allCategories,
            allSubcategories,
            getStores,
            stores,
            store,
            store_arr,
            onFileSelected,
            getUnitofMeasurement,
            UOM,
            handleInput,
            isDisable,
            markup_error,
            warningQty_error,
            crudLoading
        };

    },
    // emits:["saveProduct"],

}
</script>