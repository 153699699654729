<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- Page Title -->
        <section class="section bg-center bg-cover effect-section" style="background-image: url(static/img/bg-1.jpg);">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">Contact Us</h2>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li class="active">Contact Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->

        <!-- Section -->
        <section class="section gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 col-lg-8 m-15px-tb">
                        <div class="white-bg p-30px box-shadow h-100">
                            <h5 class="font-w-600 m-20px-b dark-color">Send A Message</h5>
                            <form class="rd-mailform" @submit.prevent="sendEnquiry">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input v-model="enquiry_name" type="text" name="name" placeholder="Name" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input v-model="enquiry_email" type="email" name="email" placeholder="Email Address" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input v-model="enquiry_tel" type="text" name="phone_number" placeholder="Phone Number" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea class="form-control" v-model="enquiry_message" name="message" rows="5" placeholder="Your Message" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-action">
                                            <button v-if="loading == false" class="m-btn m-btn-theme m-btn-radius" type="submit">Get In touch</button>
                                            <button v-if="loading == true" class="m-btn m-btn-theme m-btn-radius" disabled>
                                            <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Processing</span>
                                            </div>
                                            </button>
                                            <div class="snackbars" id="form-output-global"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-5 col-lg-4 m-15px-tb">
                        <div class="border-all-10 box-shadow border-color-white p-60px-tb p-20px-lr theme-bg">
                            <h5 class="h6 white-color font-w-600 m-5px-b">Our Address Info</h5>
                            <p class="white-color-light m-40px-b font-small">301 The Greenhouse London, E2 8DY UK</p>
                            <h5 class="h6 white-color font-w-600 m-5px-b">Our Contact Info</h5>
                            <p class="white-color-light m-40px-b font-small">Please connect with us at<br><a class="white-color-light" href="mailto:support@domain.com">support@domain.com</a></p>
                            <h5 class="h6 white-color font-w-600 m-5px-b">Our Contact Info</h5>
                            <p class="white-color-light m-0px-b font-small"><a class="white-color-light" href="tel:820-885-3321">820-885-3321</a></p>
                        </div>
                    </div>
                    <div class="col-12 m-30px-t">
                        <div class="p-15px white-bg box-shadow">
                            <div class="embed-responsive embed-responsive-21by9">
                                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1520156366883" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->
    </main>
    <!-- End Main -->

    <Footer />

</div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";
import { useToast } from "vue-toastification";


export default{
    components: {
        Navbar,
        Footer,
    },
    data() {

        const toast = useToast();

        return {
            policyData: {},
            loading: false,
            toast: toast,
            enquiry_name: '',
            enquiry_email: '',
            enquiry_tel: '',
            enquiry_message: '',
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        pageData() {
            axios.get("/admin/fetchProjectSetup").then((response) => {
                this.policyData = response.data.data;
                this.loading = false;
            })
        },

        async sendEnquiry() {
            this.loading = true;
            await axios
            .post("/send-enquiry", {
            name: this.enquiry_name,
            email: this.enquiry_email,
            message: this.enquiry_message,
            phone_number: this.enquiry_tel,
            })
            .then((res) => {
            this.loading = false;
            this.enquiry_name = '';
            this.enquiry_email = '';
            this.enquiry_message = '';
            this.enquiry_tel = '';
            this.toast.success(res.data.message);
            })
            .catch((error) => {
            if (error.response.status === 422 || error.response.status === 400) {
                this.toast.error(error.response.data.message);
            } else if (error.response.status === 500) {
                this.toast.error("An error occured please try again");
            } else if (error.response.status === 404) {
                this.toast.error("the API route does not exist!");
            } else {
                this.toast.error("An error occured please try again");
            }
            this.loading = false;
            });
        },
    }

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>