<template>
  <div class="mt-3 gridtop" style="overflow-x:hidden">
    <div class="d-flex onmobile justify-content-between">
      <div class="mt-1 fw-bold" style="font-size:20px">
        <span style="cursor:pointer" @click="$router.go(-1)"><font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" /></span> 
        {{sales?.batch_code}}
      </div>
      <!-- <PrintButtonHeader :title="'Invoice-'+sales?.batch_code"/> -->

      <PrintButtonHeader :title="'Invoice-'+sales?.batch_code" :urlA4="'/sales-receipt/'" :url58mm="'/print58mm-sales-receipt/'" :url80mm="'/print80mm-sales-receipt/'" :currentUrl="'View Sales Receipt'" :data="sales" :type="'Sales Receipt'" :loading="loading"/>


    </div>  
    <div style="overflow-x:hidden">
      <div class="d-flex salescardcontainer">
        <div class="card rounded-lg mt-3 mb-3 px-4 py-3 w-75 salescard cardprintsales print-sales-shadow" style="overflow-x: auto;">
          <!-- <div v-if="loading == true" class="text-center" style="margin:200px">
              <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
              </div>
          </div> -->
          <!-- <SkeletonLoader v-if="loading"></SkeletonLoader> -->
          <Invoice :sales_items="sales_items" :sales_array="sales_array" :sales="sales" :loading="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
    useToast
} from "vue-toastification";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import { useStore } from "vuex";
import Invoice from './component/invoice'
import axios from 'axios';


export default {
    components:{
      Invoice
    },
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            sales: null,
            grand_total:0,
            tax_amount:0,
            sales_items: [],
            sales_array:[],
            store : useStore(),
            loading:true

        }
    },
    methods: {
        getSale() {
          this.loading = true
            axios.get(`/inventory/sales/${this.$route.params.id}`).then((response) => {
              this.loading = false

                this.sales = response.data.data
                this.sales_items = response.data.data.sales_item
                this.sales_items.forEach((s) => {
                    // let tax = s?.product?.tax.reduce(function (sum, tax) {
                    //     return parseFloat(sum) + (s?.selling_price * parseFloat(tax?.tax_percentage) / 100)
                    // }, 0)
                    this.sales_array.push({
                        "product_name": s?.product?.product_name,
                        "selling_price": s?.selling_price,
                        "variant_name": s?.variant?.variant_name ?? s?.product?.uom?.name,
                        "quantity": s?.quantity,
                        "tax": parseFloat(s.tax_amount),
                        "discount": s?.discount,
                        "total": parseFloat(s?.amount_paid)//(s?.selling_price * s?.quantity)
                    })
                })

            }).catch(() => {
              this.loading = true
                this.toast.error("Oops, Something went wrong")
            })
        },
        createPDF(batch_code) {
            var pdf = new jsPDF({
                unit: 'pt',
                orientation: 'l',
                lineHeight: 1.2
            });

            var element = document.getElementById('pdf');
            var width = element.style.width;
            var height = element.style.height;
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'JPEG', 0, 0, width, height);
                pdf.save(batch_code + '.pdf');
            });
        },
    },

    created() {
        this.getSale()
    }

}
</script>


<style scoped>
.subtotal {
  margin-right:99px;
}

.taxmargin {
  margin-right: 150px;
}

.totalmargin {
  margin-right: 134px;
}

.salesbottommargin {
  margin-right: 74px !important;
}

.salesbottommarginpaid {
  margin-right: 32px !important;
}

.salesbottommarginbal {
  margin-right: 46px !important;
}

.salescardcontainer {
  justify-content: center;
}

@media (max-width: 767px) {
  .salescard {
    width: 100% !important;
  }

  .onmobile {
    flex-direction: column !important;
  }

  .salescardcontainer {
    display: block !important;
  }

  .invoicelogo{
    width: 50px;
    height: 50px;
  }
}
</style>