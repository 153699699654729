<template>
<SettingHeaderTab />
<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between headercreate">
        <div class="" style="font-size: 20px">List of Subcategory</div>
        <div class="d-md-flex">
            <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:1px">
                <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute px-2" />
                <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
            </div>
            <div class="ml-3 me-2 my-md-0 my-3">
                <button data-bs-toggle="modal" data-bs-target="#createSubcategoryModal" type="button" class="btn btn-primary text-white" :class="permission.create_inventory_category == 1 ? '' : 'disabled'">
                    <span class="ms-2">Create Subcategory</span>
                </button>
            </div>
            <div>
                <button class="btn btn-danger text-white" data-bs-toggle="modal" data-bs-target="#bulkModal"  :class="permission.delete_inventory_category == 1 && selectedModel.length  > 0 ? '' : 'disabled'">Bulk Delete</button>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3" style="overflow: auto">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>

        <div class="text-center py-5" v-else-if="subcategories.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">Your subcategory bucket is empty!</div>
            <!-- <div>Create store</div> -->
        </div>

        <table class="table table-sm-responsive" v-if="loading == false && subcategories.length > 0">
            <thead class="thead-white" style="border-style: hidden !important">
                <tr class="pb-5 text-left">
                    <th>
                        <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" />
                    </th>
                    <th class="font-weight-bold">Lists of Subcategory</th>
                    <th class="font-weight-bold">Category</th>
                    <th class="font-weight-bold">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="subcategory in subcategories" :key="subcategory.id" class="text-left">
                    <td>
                        <input type="checkbox" name="" id="" v-model="selectedModel" :value="subcategory.id" />
                    </td>
                    <td>{{ subcategory.name }}</td>
                    <td>{{ subcategory?.category?.category_name }}</td>
                    <td style="font-size: 13px">
                        <font-awesome-icon @click="getOneModal(subcategory)" :data-bs-toggle="permission.edit_inventory_category==1 ? 'modal' : ''" data-bs-target="#editCategoryModal" type="button" prefix="fal" icon="pen" class="mx-2" style="font-size: 15px" :style="permission.edit_inventory_category==1 ? {} : {'color':'#DCDCDC !important', 'cursor':'default'}" />
                        <font-awesome-icon @click="getOneModal(subcategory)" :data-bs-toggle="permission.delete_inventory_category==1 ? 'modal' : ''" data-bs-target="#singleModal" type="button" icon="trash" style="font-size: 15px" :style="permission.delete_inventory_category==1 ? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="createSubcategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createSubcategoryModal">
                    Create Inventory Subcategory
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createSubcategory">
                <div class="modal-body">
                    <div class="row">
                        <ul class="col-md-12" v-for="errors in validationErrors" :key="errors.id">
                            <li class="text-danger" style="list-style: none">
                                <small>{{ errors }}</small>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <label>Category <span class="text-danger">*</span> </label>
                        <Multiselect v-model="category_id" label="name" :options="categories" placeholder="Select Category" :searchable="true" :create-option="false" :close-on-select="true" />
                    </div>
                    <div class="mt-3">
                        <div>
                            <label>Subcategory <span class="text-danger">*</span> </label>
                            <input v-model="name" type="text" class="form-control" placeholder="Enter subcategory name" />
                        </div>
                    </div>
                    <div class="modalfooter mt-3 d-flex justify-content-end">
                        <button :disabled="name < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">
                            Create
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="editCategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editCategoryModal">
                    Edit Inventory Subcategory
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="updateSubcategory">
                <div class="modal-body">
                    <div class="row">
                        <ul class="col-md-12" v-for="errors in validationErrors" :key="errors.id">
                            <li class="text-danger" style="list-style: none">
                                <small>{{ errors }}</small>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <label>Category <span class="text-danger">*</span> </label>
                        <Multiselect v-model="category_id" label="name" :options="categories" placeholder="Select Category" :searchable="true" :create-option="false" :close-on-select="true" />
                    </div>
                    <div class="mt-3">
                        <div>
                            <label>Subcategory <span class="text-danger">*</span> </label>
                            <input v-model="name" type="text" class="form-control" placeholder="Enter subcategory name" />
                        </div>
                    </div>
                    <div class="modalfooter mt-3 d-flex justify-content-end">
                        <button :disabled="name < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary">
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleSubcategoryDeleteButton" :modalText="'Are you sure you want to delete this subcategory'" :action="'deleteSingle'"></SingleDeleteModal>

<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkSubcategoryDeleteButton" :modalText="'Are you sure you want to delete this subcategories'"></BulkDeleteModal>
</template>

<script>
import {
computed,
    ref
} from "@vue/reactivity";
import SettingHeaderTab from "./components/settingheadertab.vue";
import axios from "axios";
import {
    onMounted
} from "@vue/runtime-core";
import {
    useToast
} from "vue-toastification";
import {
    watch
} from "vue";
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';
import { useStore } from "vuex";
import Multiselect from '@vueform/multiselect'

// import { Modal } from 'bootstrap'
export default {
    components: {
        SettingHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal,
        Multiselect
    },
    setup() {
        const store = useStore()
        const subcategories = ref("");
        const categories = ref("");
        const category_id = ref("");

        const loading = ref(false);
        const toggleCreateModal = ref("");
        const toast = useToast();
        const name = ref("");
        const validationErrors = ref("");
        const subcategory_id = ref("");
        const selectedModel = ref([]);
        const allSelected = ref(false);
        const query = ref(null);
        const permission_status = ref(null)
        const selectAll = async () => {
            if (allSelected.value) {
                const selected = subcategories.value.map((u) => u.id);
                selectedModel.value = selected;
            } else {
                selectedModel.value = [];
            }
        };

        const getOneModal = (subcategory) => {
            name.value = subcategory.name;
            category_id.value = subcategory.category_id;
            subcategory_id.value = subcategory.id;
        };

        const callSingleSubcategoryDeleteButton = async () => {
            await axios.delete(`/settings/subcategory/${subcategory_id.value}`);
            name.value = ''
            category_id.value = '';
            getAllSubcategories();
            toast.success("Subcategory deleted successfully");
        };

        const callBulkSubcategoryDeleteButton = async () => {
            await axios.post(`/settings/subcategory/delete-bulk`, {
                subcategories: selectedModel.value,
            }).then((response) => {
                getAllSubcategories();
                toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    toast.error(error.response.data.message[0]);
                } else {
                    toast.error(error.response.data.message);
                }

            })
        }

        const createSubcategory = async () => {
            loading.value = true;

            await axios
                .post("/settings/subcategory", {
                    category_id:category_id.value,
                    name: name.value,
                })
                .then(() => {
                    toast.success("Created subcategory successfull");
                    name.value = "";
                    getAllSubcategories();
                    loading.value = false;
                    category_id.value = ""
                    name.value=""
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        toast.warning(error.response.data.message[0]);
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }

                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                    loading.value = false;
                });
        };

        const updateSubcategory = async () => {
            loading.value = true;
            await axios
                .put(`${"/settings/subcategory/" + subcategory_id.value}`, {
                    category_id:category_id.value,
                    name: name.value,
                })
                .then(() => {
                    toast.success("Created subcategory successfull");
                    name.value = "";
                    getAllSubcategories();
                    loading.value = false;
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        toast.warning(error.response.data.message[0]);
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }

                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                    loading.value = false;
                });
        };

        const getAllSubcategories = async () => {
            loading.value = true;

            await axios.get("settings/subcategory", {
                params: {
                    query: query.value,
                },
            }).then((response)=>{
                subcategories.value = response.data.data;
                loading.value = false;
            }).catch((error)=>{
                permission_status.value = error?.response?.status
                toast.error(error.response.data.message, {
                    timeout: 10000,
                });
                loading.value = false;
            })            
        };

        const getAllCategories = async () => {
            await axios.get("settings/category/all").then((response)=>{
                let cat = response.data.data;

                categories.value = []
                cat.forEach((category) => {
                    categories.value.push({
                    value: category.id,
                    name: category.category_name
                })
            })
            
            }).catch((error)=>{
                permission_status.value = error?.response?.status
                toast.error(error.response.data.message, {
                    timeout: 10000,
                });
            })            
        };
        const permission = computed(() => store.state.getCurrentUserPermission);

        watch(query, () => {
            getAllSubcategories();
        });

        onMounted(async () => {
            getAllSubcategories()
            getAllCategories()
        });

        return {
            category_id,
            permission,
            subcategories,
            categories,
            getAllCategories,
            getAllSubcategories,
            loading,
            toggleCreateModal,
            createSubcategory,
            updateSubcategory,
            name,
            validationErrors,
            getOneModal,
            callSingleSubcategoryDeleteButton,
            selectAll,
            selectedModel,
            allSelected,
            callBulkSubcategoryDeleteButton,
            query,
            permission_status

        };
    },
};
</script>

<style></style>
