<template>
    <div class="mt-3 gridtop">
        <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto;">
            <div>
                <h4>New Invoice</h4>
                <div class="text-muted" style="font-size: 10px;">Create New Invoice</div>
            </div>
            <div class="row mx-2 mt-2">
                <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                    <li class="text-danger">{{ errors }}</li>
                </ul>
            </div>
            <div v-if="loading == true" class="text-center py-5 loader-spinner">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <form action="" class="mt-4">
                <div class="row px-1">
                    <div class="mb-0 me-1 col-md-3 form-group">
                        <label>Invoice Number <span class="text-danger">*</span> </label>
                        <input v-model="invoice_no" class="form-control" style="height: 40px"/>
                    </div>
                    <div class="mb-0 me-1 col-md-3 dateinputs salesdatetop form-group">
                        <label class="datelabel">Invoice Date <span class="text-danger">*</span> </label>
                        <Datepicker v-model="date" :enableTimePicker="true" class="form-control salesdate"
                            style="background:none; border:none; margin-top: 32px;" />
                    </div>
                    <div class="form-group col-md-3">
                        <label>Customer <span class="text-danger">*</span></label>
                        <Multiselect v-model="customer" label="name" :options="customer_arr"
                            placeholder="Select customer" :searchable="true" :create-option="true"
                            :close-on-select="true" />
                        <!-- <small class="text-muted">Search customer</small> -->
                    </div>
                    <div class="form-group col-md-3">
                        <label>Currency <span class="text-danger">*</span></label>
                        <Multiselect v-model="currency" label="name" :options="currency_arr"
                            placeholder="Select currency" :searchable="true" :create-option="true"
                            :close-on-select="true" />
                        <!-- <small class="text-muted">Search currency</small> -->
                    </div>
                </div>
            </form>
            <div class="overflow-auto" style="overflow:auto; max-height:350px;"> <!--display: flex;flex-direction: column-reverse;">-->
                <div class="text-danger mt-1"></div>
                <table class="table table-sm-responsive">
                    <thead class="thead-light">
                        <tr class="">
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;width:20%;">Product</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Available Qty</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Variant</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Quantity</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Price</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Discount (N)</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Tax(₦)</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;">Amount</th>
                            <th class="text-secondary font-weight-bold" style="font-size: 14px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in product" :key="index.id">
                            <td style="width: 150px">
                                <Multiselect v-model="p.product_id" label="name" :options="getProducts"
                                    :canClear="false" :searchable="true" :create-option="false" :close-on-select="true"
                                    @select="handleChange(p.product_id,index)" />
                                <!-- <small class="text-danger"></small> -->
                            </td>

                            <td><input disabled type="text" class="form-control" v-model="p.available_qty" /></td>
                            <td v-if="p.variant.length > 0">
                                <select class="form-select" v-model="p.product_variant_id"
                                    @change="handleVariant(p,index,p.product_variant_id)">
                                    <option disabled>Select variant</option>
                                    <option v-for="variant in p.variant" :key="variant.id" :value="variant.id">
                                        {{ variant.variant_name }}
                                    </option>
                                </select>
                            </td>
                            <td v-else>
                                <input type="text" class="form-control" min=0 :value="p.unit_of_measurement" disabled />
                            </td>
                            <td><input type="number" class="form-control" min=1 v-model="p.quantity" 
                                    :disabled="p.disable_field" @keyup="calculateAmount(p)" onkeypress="return /^[0-9]+$/i.test(event.key)" required/></td>
                            <td><input type="number" class="form-control" min=0 step="any" v-model="p.selling_price"
                                    :disabled="(p.disable_field) || (permission.create_price_change !== 1)"
                                    @keyup="calculateAmount(p)" required /></td>
                            <td><input type="number" class="form-control" min=0 step="any" v-model="p.discount"
                                    :disabled="p.disable_field || (permission.create_discount !== 1)"
                                    @keyup="calculateAmount(p)" required></td>
                            <td><input type="number" class="form-control" v-model="p.tax_amount"
                                    @keyup="calculateAmount(p)" disabled></td>
                            <td><input type="number" class="form-control" min=0 step="any" v-model="p.amount"
                                    @keyup="calculateTotal(p)" :disabled="p.disable_field" required /></td>
                            <td>
                                <button class="btn btn-danger text-white fw-bold"
                                    @click.prevent="removeProduct(index, calculateTotal)" type="button">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group d-flex justify-content-end pe-4">
                <button class="btn" style="background-color: #DAE7FC" type="button" @click="addProduct">Add Product</button>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <div class="d-flex justify-content-between gridtop">
                    <div class="me-3">
                        <span class="fw-bold me-3">Total Amount</span>
                        <span>₦ {{form.total_cost}}</span>
                    </div>
                    <div class="me-3">
                        <span class="fw-bold me-3">Total Discount</span>
                        <!-- <span>₦ {{ form.total_discount}}</span> -->
                        <span>{{ $filters.formatMoney(form.total_discount, currentUserCurrency?.symbol)}}</span>

                    </div>
                    <div class="me-3">
                        <span class="fw-bold me-3">Total VAT</span>
                        <span>{{ $filters.formatMoney(form.total_tax, currentUserCurrency?.symbol)}}</span>

                    </div>
                    <div class="text-primary">
                        <span class="fw-bold h5 me-2" style="color: #051B35">Grand Total</span>
                        <span style="color: #051B35">{{ $filters.formatMoney(form.grand_total, currentUserCurrency?.symbol)}}</span>

                    </div>
                </div>
            </div>

            <form action="" class="mt-5" @submit.prevent="makeSale">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="inputEmail4">Select Payment Type</label>

                        <select v-model="form.payment_type" id="inputState" class="form-select"
                            @change="handlePaymentType()">
                            <option :value="pay.value" v-for="pay in paymentTypes" :key="pay.id">{{pay.name}}</option>
                        </select>
                        <small class="text-danger">{{split_payment_error}}</small>
                    </div>
                    <div class="form-group col-md-4"
                        v-show="form.payment_type == 'cash' || form.payment_type == 'card' || form.payment_type == 'part'">
                        <label>Paid Amount</label>
                        <input type="number" min=0 step="any" class="form-control" v-model="form.paid_amount"
                            :disabled="paid_disabled" @keyup="handlePaidInput" />
                        <span class="text-danger"><small>{{paid_amount_error}}</small></span>
                    </div>
                    <div class="form-group col-md-4"
                        v-show="form.payment_type == 'cash' || form.payment_type == 'card'">
                        <label>Change</label>
                        <input type="number" min=0 step="any" class="form-control" disabled v-model="form.change" />
                    </div>
                    <div class="form-group col-md-4"
                        v-show=" form.payment_type == 'credit' || form.payment_type == 'part'">
                        <label>Balance</label>
                        <input type="text" class="form-control" v-model="form.balance" disabled />
                    </div>
                    <div class="form-group col-md-4" v-show="form.payment_type == 'split'">
                        <label>Cash</label>
                        <input type="number" class="form-control" step="any" name="cash" v-model="form.cash"
                            :disabled="cash_disabled" @keyup="handleSplitpayment($event)" />
                        <span class="text-danger"><small> {{split_cash_error}}</small></span>
                    </div>
                    <div class="form-group col-md-4" min=0 step="any" v-show="form.payment_type == 'split'">
                        <label>Card</label>
                        <input type="number" min=0 step="any" class="form-control" name="card" v-model="form.card"
                            :disabled="card_disabled" @keyup="handleSplitpayment($event)" />
                        <span class="text-danger"><small> {{split_card_error}}</small></span>

                    </div>
                </div>

                <div class="mt-4 d-flex justify-content-end">
                    <button type="submit" class="btn px-5 me-5 btn-primary text-white"
                        v-if="crudLoading == false">Make Sales</button>
                    <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white"
                        disabled>
                        Processing...
                    </button>
                    <button type="button" @click="$router.go(-1)" class="btn px-5 exportbtn">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>
   
<script>
import {
    ref
} from "@vue/reactivity";
import {
    onMounted
} from "@vue/runtime-core";
import axios from "axios";
import {
    useReusable
} from "../../../lib/reusable";
import Multiselect from '@vueform/multiselect'
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";

// import jsPDF from "jspdf";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import "jspdf-autotable";

export default {
    components: {
        Multiselect
    },
    mixins: [reusableMixin],
    setup() {
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);

        // const getStore = ref([])
        const store_id = ref(null)
        const customer_arr = ref([])
        const currency_arr = ref([])
        const customer = ref(null)
        const currency = ref(null)
        // const getProducts = ref([])
        const product = ref([{
            tax_amount: "",
            product_id: "",
            available_qty: 0,
            selling_price: 0,
            cost_price: 0,
            discount: (0).toFixed(2),
            tax_pecentage: (0).toFixed(2),
            variant: [],
            tax:[],
            product_variant_id: "",
            quantity: 1,
            amount: null,
            sales_value: 0,
            cost_value: 0,
            disable_field: true

        },{
            tax_amount: "",
            product_id: "",
            available_qty: 0,
            selling_price: 0,
            cost_price: 0,
            discount: (0).toFixed(2),
            tax_pecentage: (0).toFixed(2),
            variant: [],
            tax:[],
            product_variant_id: "",
            quantity: 1,
            amount: null,
            sales_value: 0,
            cost_value: 0,
            disable_field: true

        },{
            tax_amount: "",
            product_id: "",
            available_qty: 0,
            selling_price: 0,
            cost_price: 0,
            discount: (0).toFixed(2),
            tax_pecentage: (0).toFixed(2),
            variant: [],
            tax:[],
            product_variant_id: "",
            quantity: 1,
            amount: null,
            sales_value: 0,
            cost_value: 0,
            disable_field: true

        },{
            tax_amount: "",
            product_id: "",
            available_qty: 0,
            selling_price: 0,
            cost_price: 0,
            discount: (0).toFixed(2),
            tax_pecentage: (0).toFixed(2),
            variant: [],
            tax:[],
            product_variant_id: "",
            quantity: 1,
            amount: null,
            sales_value: 0,
            cost_value: 0,
            disable_field: true

        },{
            tax_amount: "",
            product_id: "",
            available_qty: 0,
            selling_price: 0,
            cost_price: 0,
            discount: (0).toFixed(2),
            tax_pecentage: (0).toFixed(2),
            variant: [],
            tax:[],
            product_variant_id: "",
            quantity: 1,
            amount: null,
            sales_value: 0,
            cost_value: 0,
            disable_field: true

        }])
        const date = ref(new Date())

        const form = ref({
            total_discount: (0).toFixed(2),
            total_tax: (0).toFixed(2),
            payment_type: 'cash',
            total_cost: (0).toFixed(2),
            grand_total: (0).toFixed(2),
            paid_amount: null,
            change: null,
            balance: null,
            cash: (0).toFixed(2),
            card: (0).toFixed(2),
        })

        const paid_disabled = ref(true)
        const cash_disabled = ref(true)
        const card_disabled = ref(true)
        const paid_amount_error = ref('')
        const split_payment_error = ref('')
        const taxes = ref([])
        const crudLoading = ref(false)
        const loading = ref(false)
        const getProducts = ref([]);

        const {
            removeProduct,
            permission,
            paymentTypes,
            allPaymentTypes,
            getStore,
            allStore,

        } = useReusable(product)

        const clearFields = (product) => {
            product.tax_amount = "",
                product.product_id = "",
                product.available_qty = 0,
                product.selling_price = 0,
                product.discount = (0).toFixed(2),
                product.tax_pecentage = (0).toFixed(2),
                product.variant = [],
                product.product_variant_id = "",
                product.unit_of_measurement = "",
                product.sales_value = 0,
                product.cost_value = 0,
                product.quantity = 1,
                product.amount = null,
                product.disable_field = true

            form.value.total_discount = (0).toFixed(2)
            form.value.total_tax = (0).toFixed(2)
            form.value.payment_type = 'cash'
            form.value.total_cost = (0).toFixed(2)
            form.value.grand_total = (0).toFixed(2)
            form.value.paid_amount = null
            form.value.change = null
            form.value.balance = null
            form.value.cash = (0).toFixed(2)
            form.value.card = (0).toFixed(2)
        }

        const getProductInStore = async (storeId) => {
            getProducts.value = []
            const getProduct = await axios.get(`${'/admin/store/product-in-store/' + storeId}`)
            let products = getProduct?.data?.data?.data

            products.forEach((product) => {
                getProducts.value.push({
                    value: product.id,
                    name: product.bar_code ? product.product_name + '<span style="visibility: hidden; "> - ' + product?.bar_code + '</span>' :  product.product_name
                })
            })
            // Set avalaible quantity value back to zero
            product.value.forEach((p) => {
                p.available_qty = 0
                clearFields(p)
            })
        }

        const handleSplitpayment = (event) => {
            let name = event.target.name
            let cash = form.value.cash
            let card = form.value.card
            if (cash == '') {
                cash = 0
            }
            if (card == '') {
                card = 0
            }
            if (name == 'cash') {
                form.value.card = parseFloat(form.value.grand_total - cash).toFixed(2)
                if (Math.sign(form.value.card) == -1) {
                    form.value.card  = parseFloat(0).toFixed(2)
                    form.value.cash  = parseFloat(form.value.grand_total).toFixed(2)
                }
            } else if (name == 'card') {
                form.value.cash = parseFloat(form.value.grand_total - card).toFixed(2)
                if (Math.sign(form.value.cash) == -1) {
                    form.value.cash  = parseFloat(0).toFixed(2)
                    form.value.card  = parseFloat(form.value.grand_total).toFixed(2)
                }
            }
        }

        const handlePaymentType = () => {
            let paymentType = form.value.payment_type
            if (paymentType == 'credit') {
                form.value.balance = form.value.grand_total
                form.value.cash = null
                form.value.card = null
                form.value.change = null
                form.value.paid_amount = null
            } else if (paymentType == 'part') {
                form.value.change = null
                form.value.cash = null
                form.value.card = null
                form.value.balance = form.value.total_cost
                form.value.paid_amount = (0).toFixed(2)
                if (form.value.paid_amount < form.value.grand_total) {
                    form.value.balance = (form.value.grand_total - form.value.paid_amount).toFixed(2)
                }
            } else if (paymentType == 'split') {
                form.value.balance = null
                form.value.change = null
                form.value.paid_amount = null
            } else if (paymentType == 'part') {
                form.value.cash = null
                form.value.card = null
                form.value.change = null
            } else if ((paymentType == 'cash') || (paymentType == 'card')) {
                form.value.cash = null
                form.value.card = null
                form.value.balance = null
                form.value.paid_amount = form.value.grand_total
                form.value.change = (0).toFixed(2)

            }

        }

        const calculateAmount = (p) => {
            // Add discount
            let amt = parseFloat((p.selling_price * p.quantity) - p.discount)
            // let tax_pecentage = (amt * p.tax_pecentage / 100) + amt
            form.value.grand_total = amt + p.tax_pecentage //grand total

            // Variant value
            if (p.product_variant_id) {
                p.amount = (p.selling_price * p.quantity).toFixed(2)
            }else{
                p.amount = parseFloat(p.selling_price * p.quantity).toFixed(2)
            }

            if (Math.sign(p.amount) == -1) {
                p.amount = (0).toFixed(2)
            }

            if (Math.sign(p.quantity) == -1) {
                p.quantity = Math.abs(p.quantity)
            }

            if (Math.sign(p.discount) == -1) {
                p.discount = (0).toFixed(2)
            }

            if (isNaN(p.amount)) {
                p.amount = '?'
            }
            if (p.quantity > 0 || p.quantity == '') {
                calculateTotal(p)
            }
        }

        const calculateTotal = (p) => {

            // update tax
            calculateTax(p)

            // get total discount
            let totl_discount = product.value.reduce((a, b) => {
                let sum = a + parseFloat(b.discount);
                if (isNaN(sum) == true) {
                    sum = a
                }
                return sum
            }, 0);
            form.value.total_discount = (totl_discount).toFixed(2)

            // Get total cost
            let total = product.value.reduce((a, b) => {
                let sum = a + parseFloat(b.amount);
                if (isNaN(sum) == true) {
                    sum = a
                }
                return sum
            }, 0);
            form.value.total_cost = (total).toFixed(2)
            // get total tax
            let totl_tax = product.value.reduce((a, b) => {
                let sum = a + parseFloat(b.tax_amount);
                if (isNaN(sum) == true) {
                    sum = a
                }
                return sum
            }, 0);
            form.value.total_tax = totl_tax.toFixed(2)//parseFloat((form.value.total_cost) * (totl_tax / 100)).toFixed(2)

            // Get total grand
            form.value.grand_total = ((parseFloat(form.value.total_tax) + parseFloat(form.value.total_cost)) - parseFloat(form.value.total_discount)).toFixed(2) //grand total
            //get paid amount and enable if not empty
            form.value.paid_amount = form.value.grand_total
            if (form.value.paid_amount == '?') {
                paid_disabled.value = true
                card_disabled.value = true
                cash_disabled.value = true
            } else {
                paid_disabled.value = false
                card_disabled.value = false
                cash_disabled.value = false
            }

            // Get change value
            if (form.value.payment_type == 'cash' || form.value.payment_type == 'card') {
                form.value.change = (form.value.paid_amount - form.value.grand_total).toFixed(2)
            }


            // Prefill Balance
            if (form.value.payment_type == 'credit') {
                form.value.balance = form.value.grand_total
            }
            // cost value
            if (p.product_variant_id) {

                let variant = p.variant.find(({
                    id
                }) => id == p.product_variant_id)
                p.cost_value = (p.cost_price * p.quantity * variant.variant_quantity).toFixed(2)
                p.sales_value = parseFloat(p.amount).toFixed(2)
            } else {
                p.sales_value = parseFloat(p.amount).toFixed(2)
                p.cost_value = (parseFloat(p.cost_price) * p.quantity).toFixed(2)
            }
        }

        const handlePaidInput = async () => {
            if (form.value.payment_type == 'part') {
                if (form.value.paid_amount > form.value.grand_total) {
                    form.value.paid_amount = form.value.grand_total
                    form.value.balance = '0.00'
                    paid_amount_error.value = "Paid amount must not be greater than total amount"
                    setTimeout(() => {
                        paid_amount_error.value = ""
                    }, 1000);
                } else {
                    form.value.balance = (form.value.grand_total - form.value.paid_amount).toFixed(2)
                }
            } else if (form.value.payment_type == 'cash' || form.value.payment_type == 'card') {
                // Get change value
                if (form.value.paid_amount > form.value.grand_total) {
                    form.value.change = (form.value.paid_amount - form.value.grand_total).toFixed(2)
                } else {
                    form.value.change = null
                }
            }
        }

        const addProduct = () => {
            product.value.push({
                tax_amount: "",
                product_id: "",
                available_qty: 0,
                cost_price: 0,
                discount: (0).toFixed(2),
                tax_pecentage: (0).toFixed(2),
                selling_price: 0,
                variant: [],
                tax:[],
                product_variant_id: "",
                quantity: 1,
                amount: null,
                sales_value: 0,
                cost_value: 0,
                disable_field: true
            });

        };

        const allcustomers = async () => {
            await axios.get(`${'/settings/customers/all'}`)
                .then((response) => {
                    let customers = response?.data?.data
                    customers.forEach((customer) => {
                        customer_arr.value.push({
                            value: customer.id,
                            name: customer.customer_name
                        })
                    })
                }).catch(() => {

                })
        }

        const allCurrency = async () => {
            await axios.get(`${'/settings/currency'}`)
                .then((response) => {
                    let currencies = response?.data?.data?.data
                    console.log('++++++++', currencies);
                    currencies.forEach((currency) => {
                        currency_arr.value.push({
                            value: currency.id,
                            name: currency.name+' - '+currency.symbol
                        })
                    })
                }).catch(() => {

                })
        }

        const handleChange = async (id, index) => {
            const getProduct = await axios.get(`/inventory/product/${id}?store_id=${store_id.value}`)
            let single_product = getProduct?.data?.data
            // set variant value
            let variant = single_product.variants
            // set selling price value
            let selling_price = single_product?.selling_price
            // set cost price value
            let cost_price = single_product?.cost_price
            // set total cost value
            let total_seliing = single_product?.selling_price
            // set available quantity value
            let available_qty = 0

            // Check if product already selected
            let occurences = product.value.filter((v) => (v.product_id == single_product.id))
            if (occurences.length > 1) {
                product.value[index].disable_field = true
                product.value[index].product_id = ""
                product.value[index].selling_price = (0).toFixed(2)
                product.value[index].tax_pecentage = (0).toFixed(2)
                product.value[index].sales_value = (0).toFixed(2)
                product.value[index].cost_value = (0).toFixed(2)
                product.value[index].discount = (0).toFixed(2)
                product.value[index].tax_amount = (0).toFixed(2)
                product.value[index].quantity = null

                product.value[index].amount = (0).toFixed(2)
                form.value.total_cost = (0).toFixed(2)
                form.value.grand_total = (0).toFixed(2)
                form.value.total_tax = (0).toFixed(2)
                form.value.total_discount = (0).toFixed(2)


                toast.warning("Product already selected");
                calculateTotal(product.value[index])
                return
            }

            let empty_product = product.value.filter((v) => (v.product_id == ""))
            if(empty_product.length == 0){
                addProduct()
            }
            if (variant.length > 0) {
                // set cost value
                selling_price = 0
                // set total cost value
                total_seliing = 0
            }

            // Sum tax
            if (single_product?.store_product != null) {
                available_qty = single_product?.store_qty
            } else {
                toast.warning('This product is not in the selected store')
            }

            product.value[index].selling_price = parseFloat(selling_price).toFixed(2)
            product.value[index].cost_price = parseFloat(cost_price).toFixed(2)
            product.value[index].available_qty = available_qty
            product.value[index].best_before = single_product.best_before
            product.value[index].amount = parseFloat(total_seliing * product.value[index].quantity).toFixed(2)
            product.value[index].variant = variant
            product.value[index].tax = single_product?.tax
            product.value[index].disable_field = ((permission.create_price_change == 1) || (permission.create_discount == 1)) ?? false
            product.value[index].unit_of_measurement = single_product?.unit_of_measurement
            product.value[index].product_variant_id = ''

            calculateTotal(product.value[index])
        }

        const calculateTax = (cur_product) =>{
            let tax = cur_product?.tax?.reduce(function (sum, tax) {
                return parseFloat(sum) + parseFloat(tax.tax_percentage);
            }, 0);
            cur_product.tax_amount = (parseFloat(cur_product?.amount) * parseFloat(tax / 100)).toFixed(2)
            let amt = parseFloat((cur_product?.amount * cur_product?.quantity) - cur_product?.discount)
            cur_product.tax_pecentage = (amt * tax / 100)
        }



        const makeSale = async () => {
            crudLoading.value = true
            let valid_products  = removeEmptyObjects(product.value)
            let cash = form.value.cash
            let card = form.value.card
            let sum_cash_card_value = parseFloat(cash) + parseFloat(card)
            if (form.value.payment_type == 'split') {
                if (sum_cash_card_value != parseFloat(form.value.grand_total)) {
                    crudLoading.value = false
                    toast.error("Cash and card value must be equal to grand total")
                    return
                }
            }
            await axios.post("/inventory/sales", {
                // total_discount: form.value.total_discount,
                // total_tax: form.value.total_tax,
                selling_price: form.value.selling_price,
                payment_type: form.value.payment_type,
                // total_cost: form.value.total_cost,
                total_amount: form.value.grand_total,
                amount_paid: form.value.paid_amount,
                change: form.value.change,
                balance: form.value.balance,
                cash: form.value.cash,
                card: form.value.card,
                store_id: store_id.value,
                time: date.value.toLocaleTimeString(),
                date: date.value.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }).split("/").reverse().join("/"),
                customer_id: customer.value,
                sales_item: valid_products
            }).then((res) => {
                console.log('wwwwwwww', res.data.data.id);
                    crudLoading.value = false
                    router.push({
                        path: "/sales-receipt/"+res.data.data.id,
                    });
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false

                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 400) {
                        validationErrors.value = error.response.data.message;
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                });
        }


        const handleVariant = (p, index, vId) => {
            if (p.variant.length > 0) {
                let variant = p.variant.find(({
                    id
                }) => id == vId)
                product.value[index].cost_price = parseFloat(p.cost_price).toFixed(2)
                product.value[index].selling_price = parseFloat(variant.variant_selling_price).toFixed(2)
                product.value[index].amount = parseFloat(variant.variant_selling_price * product.value[index].quantity).toFixed(2)
                product.value[index].tax_amount = parseFloat(p?.selling_price * (p?.tax_pecentage / 100)).toFixed(2)

                // update taxs
                calculateTax(p)
            }

            calculateTotal(p)
        }

        const removeEmptyObjects = (array) => {
            const newArray = [];
            array.forEach(element => {
                if (element.product_id !== "") {
                    newArray.push(element);
                }
            });

            return newArray;
        }

        const defaultStore = async () => {
            // loading.value =  true
            await axios.get(`${'/admin/store/default-store'}`)
                .then((response) => {
                    store_id.value = response?.data?.data?.id
                    getProductInStore(store_id.value)
                    loading.value =  false
                }).catch(() => {
                    loading.value =  false
                })
        }


        const defaultCustomer = async () => {
            await axios.get(`${'/settings/customers/default-customer'}`)
                .then((response) => {
                    customer.value = response?.data?.data.id
                }).catch(() => {
                })
        }

        onMounted(async () => {
            allcustomers()
            allCurrency()
            // allProducts()
            allStore()
            allPaymentTypes()
            setTimeout(() => {
                defaultStore()
                defaultCustomer()

            }, 500);

        });
        return {
            loading,
            store_id,
            defaultCustomer,
            defaultStore,
            removeEmptyObjects,
            getProductInStore,
            removeProduct,
            permission,
            getStore,
            handleChange,
            product,
            getProducts,
            customer_arr,
            currency_arr,
            customer,
            currency,
            allcustomers,
            allCurrency,
            addProduct,
            calculateTotal,
            form,
            date,
            calculateAmount,
            paid_disabled,
            cash_disabled,
            card_disabled,
            paid_amount_error,
            split_payment_error,

            validationErrors,

            handlePaidInput,
            handlePaymentType,
            handleSplitpayment,
            makeSale,
            paymentTypes,
            allPaymentTypes,
            taxes,
            handleVariant,
            crudLoading,

        };
    },
};
</script>

<style>
.loader-spinner{
  width: 120px;
  height: 120px;
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  position:fixed;
  z-index:99999

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
<style scoped>
@media (max-width: 767px) {
    .makesalebtn {
        margin-right: 0px !important;
    }
}
</style>
   