<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
       <!-- Page Title -->
       <section class="section bg-center bg-cover effect-section" style="background-image: url(static/img/bg-1.jpg);">
            <div class="mask theme-bg opacity-8"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">Frequently Asked Questions</h2>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li class="active">Frequently Asked Questions</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->

        <!-- Section -->
        <section class="section gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 ml-lg-auto"></div>
                    <div class="col-lg-8 ml-lg-auto">
                        <h3 id="basics" class="dark-color font-w-600 m-20px-b">Our Basic Helps!</h3>
                        <div v-if="details.length" class="accordion" id="accordionExample">
                            <div v-for="faq in details.slice(0,1)" :key="faq.id" class="accordion-item">
                                <h2 class="accordion-header" :id="'headingOne'+faq.id">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+faq.id" aria-expanded="true" :aria-controls="'collapseOne'+faq.id">
                                        {{faq.question}}
                                    </button>
                                </h2>
                                <div :id="'collapseOne'+faq.id" class="accordion-collapse collapse show" :aria-labelledby="'headingOne'+faq.id" data-bs-parent="#accordionExample">
                                    <div class="accordion-body" v-html="faq.answer"></div>
                                </div>
                            </div>
                            <div v-for="faq in details.slice(1)" :key="faq.id" class="accordion-item">
                                <h2 class="accordion-header" :id="'headingOne'+faq.id">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+faq.id" aria-expanded="false" :aria-controls="'collapseOne'+faq.id">
                                        {{faq.question}}
                                    </button>
                                </h2>
                                <div :id="'collapseOne'+faq.id" class="accordion-collapse collapse" :aria-labelledby="'headingOne'+faq.id" data-bs-parent="#accordionExample">
                                    <div class="accordion-body" v-html="faq.answer"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <h3 id="basics" class="dark-color font-w-600 m-20px-b">Our Basic Helps!</h3>
                        <div class="accordion accordion-05 m-40px-b">
                            <div class="acco-group white-bg" v-for="faq in details" :key="faq.id">
                                <a href="#" class="acco-heading">{{faq.question}}</a>
                                <div class="acco-des" v-html="faq.answer"></div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-lg-2 ml-lg-auto"></div>
                </div>
            </div>
        </section>
        <!-- End section -->

        <!-- section -->
        <section class="section">
            <div class="container">
                <div class="row section-title md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-7">
                        <h3 class="h1 font-w-600 dark-color m-10px-b">Need personal assistance?</h3>
                        <div>If you can't find the answers you're looking for in our FAQs, please use any of the channels below to contact us, and rest assured we will respond promptly.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4 m-15px-tb">
                        <div class="card white-bg">
                            <div class="card-body p-25px">
                                <h4 class="dark-color font-w-600 h5 m-15px-b">
                                    Ask on Whatsapp
                                </h4>
                                <p>You can send your question via WhatsApp, and one of our team members will respond to you.</p>
                                <a class="m-btn-wide m-btn m-btn-radius m-btn-theme" href="https://wa.me/+2348093607355" target="_blank">Chat us</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 m-15px-tb">
                        <div class="card theme-bg" style="background-color: #004aad">
                            <div class="card-body p-25px">
                                <h4 class="white-color font-w-600 h5 m-15px-b">
                                    We can help!
                                </h4>
                                <p class="white-color-light">You can easily contact us with your request, and we will respond as soon as possible.  </p>
                                <router-link class="m-btn-wide m-btn m-btn-radius m-btn-t-white" to="/contact">Submit Request</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 m-15px-tb">
                        <div class="card white-bg">
                            <div class="card-body p-25px">
                                <h4 class="dark-color font-w-600 h5 m-15px-b">
                                    Visit Our Blog
                                </h4>
                                <p>You'll find answers to nearly all your questions about InventFlux in our blog posts.</p>
                                <router-link class="m-btn-wide m-btn m-btn-radius m-btn-theme" to="/blog">Visit Now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End section -->
    </main>
    <!-- End Main -->

    <Footer />

</div>
</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";


export default{
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            details: {},
            loading: true
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        async pageData() {
            await axios.get("/admin/active-faq").then((response) => {
                this.details = response.data.data;
                this.loading = false;
            })
        },
    }

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>