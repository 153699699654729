<template>
    <InventoryHeaderTab />
    <div>
        <div class="my-3 px-3 font-weight-bold ">
            <!-- <div class="mt-2" style="font-size: 20px">List of Stock Transfer</div> -->
            <div class="mt-2" style="font-size: 20px">List of Stock Transfer</div>

            <div class="d-flex justify-content-between gridtop mb-2">
                <div class="d-flex dateinputs allsalestop">
                    <div class="mb-0 me-1" style="width: 170px">
                        <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control"
                            style="background: none; border: none" />
                    </div>
                    <div class="mb-0 me-2" style="width: 170px">
                        <Datepicker v-model="tableData.end_date" :minDate="tableData.start_date"
                            :enableTimePicker="false" class="form-control" style="background: none; border: none" />
                    </div>
                    <div class="salessubmitdate">
                        <button type="submit" data-bs-dismiss="modal"
                            class="btn btn-primary text-white mt-2" v-if="crudLoading == false"
                            @click="fetchData()">Submit</button>
                        <button v-if="crudLoading == true" class="btn btn-primary text-white mt-2"
                            type="button" @click="fetchData()" disabled>
                            <span class="">Processing...</span>
                        </button>
                    </div>
                </div>

                <div class="d-flex gridtop">
                    <div class="d-flex me-2">
                        <div class="d-flex align-items-center small pl-2 me-2">
                            <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                            <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px; max-width:170px;margin-top:5px"
                                v-model="query" />
                        </div>
                    </div>
                    <div class="d-flex mt-1">
                        <div class="dropdown">
                            <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                                Export
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a @click="drawPdf(print_content, headerArrayColumn, 'l', 'Stock transfer', 'Stock Transfer')"
                                        class="dropdown-item" href="#">PDF</a>
                                </li>
                                <li>
                                    <a @click="reusableMixinToCSV('stocktransfers.csv')" class="dropdown-item"
                                        href="#">CSV</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-1">
                            <router-link to="/create-stock-transfer" class="btn text-white btn-primary"
                                :class="permission.create_stock_transfer == 1 ? '' : 'disabled'">
                                <span class="ms-2">Create Stock Transfer</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div class="text-center py-5" v-if="details.length == 0 && loading == false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your stock transfer list is empty!</div>
            </div>
            <div v-if="loading == true" class="text-center py-5">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="card-toolbar" v-if="loading == false && details.length > 0">
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()"
                            class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
            </div>
            <div class="" v-if="loading == false && details.length > 0">
                <table class="table table-sm-responsive" v-if="details.length > 0 && loading == false">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5 text-left">
                            <th class="font-weight-bold heading">Stock Number</th>
                            <th class="font-weight-bold heading">Date</th>
                            <th class="font-weight-bold heading" style="width:12%">Time</th>

                            <th class="font-weight-bold heading">Source Store</th>
                            <th class="font-weight-bold heading">Destination Store</th>
                            <th class="font-weight-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 15px">
                        <tr class="text-left" v-for="stock_issue in details" :key="stock_issue.id">
                            <td class="text-secondary py-3 row1">{{ stock_issue?.batch_code }}</td>
                            <td class="text-secondary py-3 row1">{{ stock_issue?.date }}</td>
                            <td class="text-secondary py-3 row1">
                                {{ timeConvert(stock_issue?.time) }}
                            </td>
                            <td class="text-secondary py-3 row1">{{ stock_issue?.store?.store_name }}</td>
                            <td class="text-secondary py-3 row1">{{ stock_issue?.destination?.store_name }}</td>
                            <td class="py-3">
                                <div class="dropdown p-0">
                                    <button class="btn btn-default dropdown-toggle" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                        style="background-color:#fff;padding:2px">
                                        Select
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <router-link class="dropdown-item"
                                                :to="'/view-stock-transfer/' + stock_issue.id" :class="permission.view_stock_transfer == 0 ? 'disabled':''">View</router-link>
                                        </li>
                                        <li><a class="dropdown-item" href="#">Print</a></li>
                                        <li><a class="dropdown-item" href="#" :data-bs-toggle="permission.reverse_stock_transfer == 1 ? 'modal':''"
                                                data-bs-target="#singleModal" :class="permission.reverse_stock_transfer == 1 ? '':'disabled'"
                                                @click="getCurrentId(stock_issue.id)">Reverse</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
        <Alert :modalId="'singleModal'" @alertModal="handleAction()"
            :modalText="'Are you sure you want to reverse this stock transfer'" :action="'alertModal'"
            title="Reverse Stock Transfer"></Alert>
        <pagination style="float: right" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)"
            @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0"></pagination>

    </div>
</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";

import axios from "axios";
import {
    useToast
} from "vue-toastification";

import jsPDF from "jspdf";
import {
    reusableMixin
} from "../../../lib/reusableMixin";
import "jspdf-autotable";
import {
    useStore
} from "vuex";
import Alert from "../../../components/AlertModal.vue";

export default {
    components: {
        InventoryHeaderTab,
        Alert
    },
    mixins: [reusableMixin],
    data() {
        let sortOrders = {};
        let columns = [{
            width: "16%",
            label: "Name",
            name: "name",
            sortable: false,
        },
        {
            width: "15%",
            label: "Sortcode",
            name: "sortcode",
            sortable: false,
        },
        {
            width: "15%",
            label: "Phonecode",
            name: "phonecode",
            sortable: false,
        },
        {
            width: "5%",
            label: "Status",
            name: "status",
            sortable: false,
        },
        {
            width: "5%",
            label: "Actions",
            name: "actions",
            sortable: false,
        },
        ];

        // Show date from the past 6 months
        var current_date = new Date();
        let current_date_to_date = current_date.setMonth(current_date.getMonth() - 6);
        current_date_to_date = new Date(current_date_to_date)


        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            headerArrayColumn: ['S/N', 'Stock Number', 'Date', 'Source Store', 'Destination Store'],
            details: [],
            loading: false,
            query: null,

            selectedModel: [],
            allSelected: false,
            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                start_date: current_date_to_date,
                end_date: new Date(),
                draw: 0,
                length: 10,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            currentID: null,
            store: useStore(),
            crudLoading: false,
            print_content: [],
            currentId: null


        }
    },
    methods: {
        getCurrentId(id) {
            this.currentId = id
        },
        dataToPrint(details) {
            details.map((issue, idx) => {
                let result = {
                    sn: idx + 1,
                    batch_code: issue.batch_code,
                    date: issue.date,
                    source: issue.store?.store_name,
                    destination: issue?.destination.store_name,
                };
                this.print_content.push(result)
            });
        },
        handleAction() {
            this.loading = true
            axios.put(`/inventory/issue-stock/reverse/${this.currentId}`).then((response) => {
                if (response.status == 200) {
                    this.toast.success(response.data.message)
                    this.fetchData()
                }
            }).catch((error) => {
                this.toast.error(error?.response?.data?.message ?? 'Whoops, Something went wrong')
            })


        },
        exportToPdf() {
            const doc = new jsPDF();
            doc.autoTable({
                html: ".table"
            });
            doc.save("stocktransfers.pdf");
        },

        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },

        fetchData(url = '/inventory/issue-stock/web') {
            this.loading = true;
            this.tableData.query = this.query
            this.crudLoading = true;

            if (this.crudLoading && this.query) {
                this.crudLoading = false
            }

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                this.print_content = []
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.dataToPrint(this.details)
                this.crudLoading = false
                this.configPagination(data.data);
            }).catch(error => {
                this.errorMessagem = error
                this.loading = false;
                this.crudLoading = false
            });
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },
    },

    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        }
    },
    watch: {
        query() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
