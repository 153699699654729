// import print from "print-js";
import moment from 'moment';
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import axios from "axios";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

const reusableMixin = {
    data() {
        return {
            stores: [],
            getStore: ["All"],
            paymentTypes: ["All"],
            toast: useToast(),
            store: useStore(),
            super_user:null,
            tableData:{
                store:null,
            },
            company: null

        }
    },
    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission;
        },
        currentUser() {
            return this.store.state.getLoggedInUser;
        },
        currentUserCurrency() {
            return this.store.state.getLoggedInUser?.business?.currency;
        },
    },
    methods: {
        superUser () {
            axios.get("/user/account")
            .then((response) => {
              this.super_user = response.data.data.data
            }).catch(()=>{
            })
          },
        allPaymentTypes() {
            axios
                .get("settings/payment_type")
                .then((response) => {
                    let res = response.data.data;
                    this.paymentTypes = [{ value: "All", name: "All Payment Types" }];
                    res.forEach((p) => {
                        this.paymentTypes.push({
                            value: p.payment_type,
                            name: p.name,
                        });
                    });
                })
                .catch(() => { });
        },

        async getCompany(){
            await axios.get('/settings/company').then((response) => {
              this.company = response.data.data.data.company;
            })
        },

        allStore() {
            axios
                .get("/admin/store/user-stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.getStore = [{ value: "All", name: "All Stores" }];
                    this.stores.forEach((store) => {
                        this.getStore.push({
                            value: store.store_name,
                            name: store.store_name,
                        });
                    });
                    this.defaultStore()
                })
                .catch(() => {
                    this.toast.error("Whoops, Something went wrong");
                });
        },
        defaultStore(){
            axios.get(`${'/admin/store/default-store'}`)
                .then((response) => {
                    this.tableData.store = response?.data?.data?.store_name
                    // getProductInStore(store_id.value)
                }).catch(() => {
                })
        },
        reusableMixinToPrinter() {
            window.print();
        },
        reusableMixinToCSV(filename) {
            var csv = [];
            var rows = document.querySelectorAll(".table tr");

            for (var i = 0; i < rows.length; i++) {
                var row = [],
                    cols = rows[i].querySelectorAll(".row1, .heading");

                for (var j = 0; j < cols.length; j++) {
                    // var input = cols[j].firstElementChild
                    row.push(cols[j].innerText[0]=='0' ? ("'" + cols[j].innerText.replaceAll(',', '')) : cols[j].innerText.replaceAll(',', ''));
                    // if (input === null || input === undefined) {
                    //     row.push(cols[j].innerText.replaceAll(',', ''));
                    // } else {
                    //     row.push(input.value);
                    // }
                }
                csv.push(row.join(","));
            }
            // Download CSV file
            this.downloadCSV(csv.join("\n"), `${filename+'-'+this.formatDateMMDDYY()}.csv`);
        },
        downloadCSV(csv, filename) {
            let csvFile;
            let downloadLink;

            // CSV file
            csvFile = new Blob([decodeURIComponent('%ef%bb%bf'), csv], {
                type: 'text/csv;charset=utf-8'
            });

            // Download link
            downloadLink = document.createElement("a");

            // File name
            downloadLink.download = filename;

            // Create a link to the file
            downloadLink.href = URL.createObjectURL(csvFile);

            // Hide download link
            downloadLink.style.display = "none";
            downloadLink.target = "_blank";

            // Add the link to DOM
            document.body.appendChild(downloadLink);

            // Click download link
            downloadLink.click();
        },
        dateTime(value, type = "") {
            if (type == 'short') {
                return moment(value).format("Do MMM YYYY");
            } else if (type == 'days ago') {
                return moment(value).fromNow()
            }
            return moment(value).format("Do MMMM YYYY");
        },

        formatDateMMDDYY() {
            return (
                new Date().toLocaleString().split(/\D/).slice(0, 3).map(num => num.padStart(2, "0")).join("/")
            )
        },

        drawPdf(dataToBeUsedArray, headerArrayColumn, docOrientation, tableName, dataName, width = "", footerRowArray = []) {
            let pdfInfoRowArray = []

            //transform data structured as required by jspdf
            dataToBeUsedArray.forEach(i => {
                pdfInfoRowArray.push(Object.values(i));
            });
            let pdf;
            if (width) {
                pdf = new jsPDF({
                    format: width,
                    unit: "mm",
                    orientation: docOrientation,
                });
            } else {
                pdf = new jsPDF({
                    format: 'a4',
                    unit: "mm",
                    orientation: docOrientation,
                });
            }

            pdf.setFont("Helvetica");
            // pdf.setFontType("bold");
            pdf.text(105, 10, "InventFlux", null, null, "center");
            pdf.setFont("Helvetica");
            pdf.text(10, 20, `${dataName} List`);
            // pdf.setFontType("normal");
            pdf.setFontSize("10");
            pdf.text(210, 20, `Generated at ${new Date()?.toISOString()}`);

            //draw the pdf table
            pdf.autoTable(headerArrayColumn, pdfInfoRowArray, {
                foot: footerRowArray.length > 0 ? [footerRowArray] : undefined,
                startY: 25,
                startX: 5,
                //set table cell background color
                createdCell: function (cell) {
                    if (cell?.styles?.fillColor) {
                        return cell.styles.fillColor = "whitesmoke";
                    }
                },
                //set table header background color
                headStyles: { fillColor: ['#051B35', 95, 240] },
                footStyles: { fillColor: ['#051B35', 95, 240] },
                // styles: {
                //     fillColor: "green"
                // },
            });
            //save pdf file according to specified name and current date
            pdf.save(`${tableName}-${this.formatDateMMDDYY()}.pdf`);
        },
        timeConvert(time) {
            // Check correct time format and split into components
            if (time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) { // If time format correct
                    time = time.slice(1); // Remove full string match value
                    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
                    time[0] = +time[0] % 12 || 12; // Adjust hours
                }
                return time.join(''); // return adjusted time or original string
            }
            return time
        },

        printPage(width, fontsize = "", title = "") {

            var name = `${title}-${this.formatDateMMDDYY()}`
            var mywindow = window.open('', 'PRINT', 'height=500,width=700');

            mywindow.document.write(`<html><head> <style>
            
                @page { size: ${width}, }
                @media print {
                    body {
                        font-size: ${fontsize};
                        margin: 20mm 8mm 20mm 8mm;  
                    }
                }
               
                .line{
                    display: block; margin-before: 0.5em; margin-after: 0.5em; margin-start: auto; margin-end: auto; overflow: hidden; border-style: inset; border-width: 1px;
                }
                .text-center{
                    text-align:center;
                }
                .text-secondary {
                    color: rgba(108,117,125,1)!important;
                }

                table {
                    caption-side: bottom;
                    border-collapse: collapse
                }
                .fw-bold {
                    font-weight: 700 !important
                }
                .mt-1 {
                    margin-top: .25rem !important
                }
                
                .table {
                    width: 100%;
                    margin-bottom: 1rem;
                    color: #212529;
                    // vertical-align: top;
                    border-color: #dee2e6
                }

       
                .center{
                    text-align:left
                }
                .table>:not(caption)>*>* {
                    padding: .5rem .5rem;
                    background-color: var(--bs-table-bg);
                    border-bottom-width: 1px;
                    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
                }
                
                .table>tbody {
                    vertical-align: inherit
                }
                
                .table>thead {
                    vertical-align: bottom
                }
                
                .table>:not(:last-child)>:last-child>* {
                    border-bottom-color: currentColor
                }
                

                .bg-light {
                    background-color: #f8f9fa !important
                }
                
                .printdottedborder {
                    border: 1px dotted black;
                    }
                    .justify-content-between {
                        justify-content: space-between !important
                    }
                    .d-flex {
                        display: flex !important
                    }
                    .justify-content-end {
                        justify-content: flex-end !important
                    }
                    .mt-4 {
                        margin-top: 1.5rem !important
                    }
                    .mt-3 {
                        margin-top: 1rem !important
                    }
                    .cardpadding {
                        padding-left: 0.1rem !important;
                        padding-right: 0.1rem !important;
                    }
                    .mb-3 {
                        margin-bottom: 1rem !important
                    }
                    .px-4 {
                        padding-right: 1rem !important;
                        padding-left: 1rem !important
                    }
                    .py-3 {
                        padding-top: 1rem !important;
                        padding-bottom: 1rem !important
                    }
                    .fw-bold {
                        font-weight: 700 !important
                    }
                    .me-4 {
                        margin-right: 1.5rem !important
                    }
            </style><title>${name}</title>`);
            mywindow.document.write('</head><body>');
            mywindow.document.write(document.getElementById('printMe').innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true
        },

        createPDF(batch_code, is_adjustment) {

            // var data = document.getElementById('printMe');  
            // html2canvas(data).then(canvas => {  
            // // Few necessary setting options  

            // const contentDataURL = canvas.toDataURL('image/png')  
            // var imgWidth = 210; 
            // var pageHeight = 295;  
            // var imgHeight = canvas.height * imgWidth / canvas.width;
            // var heightLeft = imgHeight;

            // var doc = new jsPDF('p', 'mm');
            // var position = 0;

            // doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

            // heightLeft -= pageHeight;

            // while (heightLeft >= 0) {
            //     position = heightLeft - imgHeight;
            //     doc.addPage();
            //     doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            //     heightLeft -= pageHeight;
            // }
            // doc.save(`${batch_code}${is_adjustment}_${this.formatDateMMDDYY()}.pdf`);

            // });  

            var pdf = new jsPDF({
                unit: 'pt',
                orientation:is_adjustment == true ? 'l': 'p',
                lineHeight: 1.2,
                margin: 100 
            });

            var element = document.getElementById('printMe');
            // var width = element.style.width;
            // var height = element.style.height;
            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'PNG', 0, 0, width, height);
                pdf.save(`${batch_code}_${this.formatDateMMDDYY()}.pdf`);
            });
        },

    }
};

export {
    reusableMixin
};