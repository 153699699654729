<template>
    <div class="d-flex gridtop">
        <div class="me-2">
            <button class="btn px-5" style="background:white; border:1px solid gray;" @click="createPDF(title, ($router.currentRoute.value.name == 'View Stock Adjustment') || ($router.currentRoute.value.name == 'View Stock Transfer'))">Download as PDF</button>
        </div>
        <!-- <div class="d-flex">
       

            <button @click="get58mm()" style="border:none;padding:0px" :disabled="loading">
                <button class="btn px-5 me-2" :style="[$router.currentRoute.value.name == 'Print 58mm '+type ? {'background':'#A020F0', 'border':'1px solid gray','color':'white'}:{'background':'white', 'border':'1px solid gray'}]" @click="$router.currentRoute.value.name == 'Print 58mm '+type ? printPage('58mm', '16px', ''):''">
                    <svg v-if="$router.currentRoute.value.name == 'Print 58mm '+type" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                        <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 17.179688 4.257812 L 15.378906 4.257812 L 15.378906 0 L 4.621094 0 L 4.621094 4.257812 L 2.820312 4.257812 C 1.261719 4.257812 0 5.570312 0 7.1875 L 0 12.238281 C 0 13.855469 1.261719 15.167969 2.820312 15.167969 L 4.1875 15.167969 L 4.1875 20 L 15.8125 20 L 15.8125 15.167969 L 17.179688 15.167969 C 18.738281 15.167969 20 13.855469 20 12.238281 L 20 7.1875 C 20 5.570312 18.738281 4.257812 17.179688 4.257812 Z M 5.472656 0.851562 L 14.527344 0.851562 L 14.527344 4.257812 L 5.472656 4.257812 Z M 14.960938 19.148438 L 5.039062 19.148438 L 5.039062 11.847656 L 14.960938 11.847656 Z M 19.148438 12.238281 C 19.148438 13.382812 18.265625 14.320312 17.179688 14.320312 L 15.8125 14.320312 L 15.8125 10.992188 L 4.1875 10.992188 L 4.1875 14.320312 L 2.820312 14.320312 C 1.734375 14.320312 0.851562 13.382812 0.851562 12.238281 L 0.851562 7.1875 C 0.851562 6.039062 1.734375 5.105469 2.820312 5.105469 L 17.179688 5.105469 C 18.265625 5.105469 19.148438 6.039062 19.148438 7.1875 Z M 19.148438 12.238281 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 13.792969 L 12.898438 13.792969 L 12.898438 14.648438 L 7.101562 14.648438 Z M 7.101562 13.792969 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 16.144531 L 12.898438 16.144531 L 12.898438 16.992188 L 7.101562 16.992188 Z M 7.101562 16.144531 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 14.953125 7.074219 L 16.222656 7.074219 L 16.222656 7.929688 L 14.953125 7.929688 Z M 14.953125 7.074219 "/>
                        </g>
                        </svg>
                          {{$router.currentRoute.value.name == 'Print 58mm '+type ? 'Print 58mm' : '58mm Paper'}} 
                </button>
            </button>
        </div> -->
        <div class="d-flex">
            <button @click="get80mm()" style="border:none;padding:0px" :disabled="loading">
                <button class="btn px-5 me-2" :style="[$router.currentRoute.value.name == 'Print 80mm ' +type ? {'background':'#A020F0', 'border':'1px solid gray','color':'white'}:{'background':'white', 'border':'1px solid gray'}]" @click="$router.currentRoute.value.name == 'Print 80mm ' +type ? printPage('80mm', '16px', title):''">
                    <svg v-if="$router.currentRoute.value.name == 'Print 80mm '+type" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                        <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 17.179688 4.257812 L 15.378906 4.257812 L 15.378906 0 L 4.621094 0 L 4.621094 4.257812 L 2.820312 4.257812 C 1.261719 4.257812 0 5.570312 0 7.1875 L 0 12.238281 C 0 13.855469 1.261719 15.167969 2.820312 15.167969 L 4.1875 15.167969 L 4.1875 20 L 15.8125 20 L 15.8125 15.167969 L 17.179688 15.167969 C 18.738281 15.167969 20 13.855469 20 12.238281 L 20 7.1875 C 20 5.570312 18.738281 4.257812 17.179688 4.257812 Z M 5.472656 0.851562 L 14.527344 0.851562 L 14.527344 4.257812 L 5.472656 4.257812 Z M 14.960938 19.148438 L 5.039062 19.148438 L 5.039062 11.847656 L 14.960938 11.847656 Z M 19.148438 12.238281 C 19.148438 13.382812 18.265625 14.320312 17.179688 14.320312 L 15.8125 14.320312 L 15.8125 10.992188 L 4.1875 10.992188 L 4.1875 14.320312 L 2.820312 14.320312 C 1.734375 14.320312 0.851562 13.382812 0.851562 12.238281 L 0.851562 7.1875 C 0.851562 6.039062 1.734375 5.105469 2.820312 5.105469 L 17.179688 5.105469 C 18.265625 5.105469 19.148438 6.039062 19.148438 7.1875 Z M 19.148438 12.238281 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 13.792969 L 12.898438 13.792969 L 12.898438 14.648438 L 7.101562 14.648438 Z M 7.101562 13.792969 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 16.144531 L 12.898438 16.144531 L 12.898438 16.992188 L 7.101562 16.992188 Z M 7.101562 16.144531 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 14.953125 7.074219 L 16.222656 7.074219 L 16.222656 7.929688 L 14.953125 7.929688 Z M 14.953125 7.074219 "/>
                        </g>
                        </svg>
                     {{$router.currentRoute.value.name == 'Print 80mm ' +type ? 'Print 80mm' : '80mm Paper'}} 
                </button>
            </button>
        </div>
        <div class="d-flex">
            <button @click="getA4()" style="border:none;padding:0px" :disabled="loading">
                <button class="btn px-5 me-2" :style="[$router.currentRoute.value.name == 'View '+type ? {'background':'#A020F0', 'border':'1px solid gray','color':'white'}:{'background':'white', 'border':'1px solid gray'}]" @click="$router.currentRoute.value.name == 'View '+type ? printPage('80mm', '16px', title):''">
                    
                    <svg v-if="$router.currentRoute.value.name == 'View '+type" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                        <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 17.179688 4.257812 L 15.378906 4.257812 L 15.378906 0 L 4.621094 0 L 4.621094 4.257812 L 2.820312 4.257812 C 1.261719 4.257812 0 5.570312 0 7.1875 L 0 12.238281 C 0 13.855469 1.261719 15.167969 2.820312 15.167969 L 4.1875 15.167969 L 4.1875 20 L 15.8125 20 L 15.8125 15.167969 L 17.179688 15.167969 C 18.738281 15.167969 20 13.855469 20 12.238281 L 20 7.1875 C 20 5.570312 18.738281 4.257812 17.179688 4.257812 Z M 5.472656 0.851562 L 14.527344 0.851562 L 14.527344 4.257812 L 5.472656 4.257812 Z M 14.960938 19.148438 L 5.039062 19.148438 L 5.039062 11.847656 L 14.960938 11.847656 Z M 19.148438 12.238281 C 19.148438 13.382812 18.265625 14.320312 17.179688 14.320312 L 15.8125 14.320312 L 15.8125 10.992188 L 4.1875 10.992188 L 4.1875 14.320312 L 2.820312 14.320312 C 1.734375 14.320312 0.851562 13.382812 0.851562 12.238281 L 0.851562 7.1875 C 0.851562 6.039062 1.734375 5.105469 2.820312 5.105469 L 17.179688 5.105469 C 18.265625 5.105469 19.148438 6.039062 19.148438 7.1875 Z M 19.148438 12.238281 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 13.792969 L 12.898438 13.792969 L 12.898438 14.648438 L 7.101562 14.648438 Z M 7.101562 13.792969 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 7.101562 16.144531 L 12.898438 16.144531 L 12.898438 16.992188 L 7.101562 16.992188 Z M 7.101562 16.144531 "/>
                        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(255%,255%,255%);fill-opacity:1;" d="M 14.953125 7.074219 L 16.222656 7.074219 L 16.222656 7.929688 L 14.953125 7.929688 Z M 14.953125 7.074219 "/>
                        </g>
                    </svg> {{$router.currentRoute.value.name == 'View '+type ? ' Print A4' : 'A4 Paper'}} 
                </button>
            </button>
        </div>


       
    </div>
</template>

<script>
import { reusableMixin } from '../lib/reusableMixin'


export default {
    mixins: [reusableMixin],
    props:{
        title:String,
        urlA4:String,
        url58mm:String,
        url80mm:String,
        currentUrl:String,
        type:String,
        data:Object,
        loading:Boolean
    },
    data(){
        return{

        }
    },
    methods:{
        get58mm(){
            if(this.$router.currentRoute.value.name == 'Print 58mm '+this.type){
                this.$router.push("#")
            }else{
                this.$router.push(this.url58mm+this.data?.id)
            }
        },

        get80mm(){
            if(this.$router.currentRoute.value.name == 'Print 80mm '+this.type){
                this.$router.push("#")
            }else{
                this.$router.push(this.url80mm+this.data?.id)
            }
        },
        getA4(){
            if(this.$router.currentRoute.value.name == 'View '+this.type){
                this.$router.push("#")
            }else{
                this.$router.push(this.urlA4+this.data?.id)
            }
        },
        
        
    }

}
</script>
    