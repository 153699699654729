<template>
<SettingHeaderTab />

<div>
    <div class="mt-3">

        <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
            <div class="mt-2" style="font-size:24px"> Notification</div>
            <div class="d-flex gridtop">
                <div class="d-flex">
                </div>
                <!-- <div class="d-flex">
                    <div>
                        <button class="btn allbuttonsclr text-white mt-2" data-bs-toggle="modal" data-bs-target="#createNotification">Create Notification</button>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="bg-white rounded-lg mt-3 mb-3 px-4 py-3" style="overflow-x: auto">
            <div v-if="loading == true" class="text-center py-5">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="text-center py-5" v-if="permission_status == 403">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
            </div>
            <div class="text-center py-5" v-else-if="notifications.length == 0 && loading == false">
                <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
                <div class="fw-bold mt-3">Your notification bucket is empty!</div>
            </div>
            <div v-if="notifications.length > 0 && loading == false">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="fw-bold">Notification Details</th>
                            <th class="fw-bold"></th>
                            <th class="fw-bold"></th>
                            <!-- <th class="fw-bold">Action</th> -->

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(notification, index) in notifications" :key="notification.id">
                            <td>
                                <font-awesome-icon class="img-icon me-3" icon="volume-down" />
                                {{notification.tag}}
                            </td>

                            <td></td>
                            <td>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" :true-value='1' :false-value='0' :value="notification.id" v-model="email_statuses[index]" @change="updateEmailStatus(notification.id, $event)">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNotification" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createNotification">Create Notification</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createNotification">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Tag</label>
                            <input type="text" class="form-control" placeholder="Enter tag name" v-model="tag" name="tag">
                        </div>
                        <div class="col-md-6">
                            <label>Value</label>
                            <input type="number" class="form-control" min="0" max="1" placeholder="0 or 1" v-model="value" name="value">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="tag < 1 && value < 2" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">Create</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="editNotification" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editNotification">Edit Notification</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="editNotification">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Tag</label>
                            <input type="text" class="form-control" placeholder="Enter tag name" v-model="tag" name="tag">
                        </div>
                        <div class="col-md-6">
                            <label>Value</label>
                            <input type="number" class="form-control" min="0" max="1" placeholder="0 or 1" v-model="value" name="value">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="tag < 1 && value < 2" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import SettingHeaderTab from './components/settingheadertab.vue'
import {
    ref
} from '@vue/reactivity'
import axios from 'axios'
import {
    useToast
} from 'vue-toastification'
import {
    onMounted
} from '@vue/runtime-core'
export default {
    components: {
        SettingHeaderTab
    },
    setup() {
        const tag = ref('')
        const value = ref('')
        const toast = useToast()
        const loading = ref(false)

        const crudLoading = ref(false)
        const validationErrors = ref([])
        const notifications = ref([])
        const notification_id = ref([])

        const email_statuses = ref([])

        const whatsapp_statuses = ref([])
        const permission_status = ref(null)

        const getOneModal = (notification) => {
            notification_id.value = notification.id
            tag.value = notification.tag
            value.value = notification.value
        }

        const updateWhatsappStatus = async (id, evt) => {

            await axios.put('settings/notification/change-whatsapp-status/' + id, {
                value: evt.target.checked
            }).then((res) => {
                toast.success(res.data.message);
            }).catch((error) => {
                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
            })
        }
        const updateEmailStatus = async (id, evt) => {

            await axios.put('settings/notification/change-email-status/' + id, {
                value: evt.target.checked
            }).then((res) => {
                toast.success(res.data.message);
            }).catch((error) => {
                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
            })
        }
        const getAllNotifications = async () => {
            loading.value = true

            await axios.get('settings/set-notification')
            .then((response) => {
                notifications.value = response.data.data
                notifications.value.forEach((p, index) => {
                    email_statuses.value[index] = p.email
                    whatsapp_statuses.value[index] = p.whatsapp

                })
                loading.value = false
            }).catch((error) => {
                loading.value = false
                if (error?.response?.status == 403) {
                    permission_status.value = error?.response?.status
                    toast.error(error.response.data.message);
                }
            })
        }

        const createNotification = async () => {
            loading.value = true
            await axios.post('/settings/set-notification',
                [{
                    tag: tag.value,
                    value: value.value
                }]
            ).then(() => {
                crudLoading.value = true

                tag.value = ''
                value.value = ''
                toast.success('Created tax successfull')
                getAllNotifications()
                loading.value = false

            }).catch((error) => {
                crudLoading.value = false

                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                    validationErrors.value = error.response.data.message
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
                loading.value = false

            })
        }

        const editNotification = async () => {
            loading.value = true
            await axios.put('/settings/set-notification/' + notification_id.value,
                [{
                    tag: tag.value,
                    value: value.value
                }]
            ).then(() => {
                crudLoading.value = true

                tag.value = ''
                value.value = ''
                toast.success('Created tax successfull')
                getAllNotifications()
                loading.value = false

            }).catch((error) => {
                crudLoading.value = false

                if (error.response.status == 422) {
                    toast.warning('There are errors in your input');
                    validationErrors.value = error.response.data.message
                }
                if (error.response.status == 500) {
                    toast.error('An error occured please try again');
                }
                loading.value = false

            })
        }

        onMounted(() => getAllNotifications())

        return {
            createNotification,
            editNotification,
            getAllNotifications,
            updateEmailStatus,
            updateWhatsappStatus,
            notifications,
            notification_id,
            getOneModal,
            tag,
            value,
            email_statuses,
            whatsapp_statuses,
            loading,
            crudLoading,
            validationErrors,
            permission_status
        }
    }
}
</script>

<style scoped>
.form-switch .form-check-input {
    height: 24px !important;
    width: 48px !important;
    margin-top: -4px;
}

.form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}

.form-switch .form-check-input:checked {
    background-color: #004AAD;
    border-color: #004AAD;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}
</style>
