<template>
<div>
    <div class="tab-pane fade show active" id="allproduct" role="tabpanel" aria-labelledby="allproduct-tab">
        <div class="mt-3 font-weight-bold gridtop">
            <div class="mt-3 font-weight-bold gridtop">
                <div class="d-flex justify-content-between gridtop mb-2">
                    <div class="mt-2 fw-bold" style="font-size: 20px">
                        <router-link to="/debtor">
                            <font-awesome-icon class="arrowradius me-3" prefix="far" icon="angle-left" />
                        </router-link>
                        {{details[0]?.debtor?.customer?.customer_name}}
                    </div>
                    <div class="d-flex alluserdebtorstop topuserdebt">
                        <div class="d-flex dateinputs alluserdebtorstop mt-1">
                            <div class="mb-0 me-1" style="width: 214px">
                                <Datepicker v-model="tableData.start_date" :enableTimePicker="false" class="form-control" style="background: none; border: none" />
                            </div>
                            <div class="mb-0 me-2" style="width: 214px">
                                <Datepicker v-model="tableData.end_date" :minDate="tableData.start_date" :enableTimePicker="false" class="form-control" style="background: none; border: none" />
                            </div>
                            <div class="me-2 salessubmitdate mt-1">
                                <button type="submit" data-bs-dismiss="modal" class="btn btn-success allbuttonsclr text-white mt-1" v-if="crudLoading == false" @click="fetchData()">Submit</button>
                                <button v-if="crudLoading== true" class="btn btn-success allbuttonsclr text-white mt-1" type="button" @click="fetchData()" disabled>
                                    <span class="">Processing...</span>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex alluserdebtorstop mt-1">
                            <div class="input-group mb-0 me-2" style="width: 214px">
                                <div class="d-flex align-items-center small pl-2 me-2" style="margin-top: 2px">
                                    <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute ps-3" />
                                    <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
                                </div>
                            </div>
                            <div class="mt-1">
                                <div class="dropdown">
                                    <button class="btn exportbtn me-2 mt-1 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../../private-assets/images/Vector.png" class="me-2" alt="" />
                                        Export
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a @click="drawPdf(print_content, headerArrayColumn,'l',details[0]?.debtor?.customer?.customer_name,details[0]?.debtor?.customer?.customer_name)" class="dropdown-item" href="#">PDF</a>
                                        </li>
                                        <li>
                                            <a @click="reusableMixinToCSV('User-debtors.csv')" class="dropdown-item" href="#">CSV</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card thecard rounded-lg mt-3 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
            <div class="text-center py-5" v-if="details.length == 0 && loading == false">
                <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
                <div class="fw-bold mt-3">Your debtor list is empty!</div>
            </div>
            <div v-if="loading == true" class="text-center py-5">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="" v-if="loading == false && details.length > 0">
                <table class="table table-sm-responsive">
                    <thead class="thead-white" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="font-weight-bold heading">Sales Number</th>
                            <th class="font-weight-bold heading">Date</th>
                            <th class="font-weight-bold heading">Total({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold heading">Amount Received({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold heading">Balance({{ company?.currency?.symbol }})</th>
                            <th class="font-weight-bold">Status</th>
                            <th class="font-weight-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="debt in details" :key="debt.id">
                            <td class="text-secondary py-3 row1">{{debt?.sales?.batch_code}}</td>
                            <td class="text-secondary py-3 row1">{{dateTime(debt?.last_payment)}}</td>

                            <td class="text-secondary py-3 row1">{{ $filters.formatMoney(debt?.total_amount)  }} </td>

                            <td class="text-secondary py-3 row1">{{ $filters.formatMoney(debt?.amount_paid)  }} </td>

                            <td class="text-secondary py-3 row1">{{ $filters.formatMoney(debt?.amount_owned)  }} </td>
                            <td class="text-danger py-3">
                                <button class="btn btn-sm btn-outline-danger debitbtn px-2">
                                    {{ dateTime(debt?.last_payment, 'days ago') }}
                                </button>
                            </td>
                            <td class="py-3">
                                <div class="">
                                    <router-link class="dropdown-item" :to="'/debtor-receipt/'+debt?.id">
                                        <font-awesome-icon icon="eye" style="font-size: 15px" />
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    useToast
} from 'vue-toastification';

import {
    reusableMixin
} from "../../../lib/reusableMixin";

export default {
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        // Show date from the past 6 months
        var current_date = new Date();
        let current_date_to_date = current_date.setMonth(current_date.getMonth() - 6);
        current_date_to_date = new Date(current_date_to_date)

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn: ['S/N', 'Customer Name', 'Total Amount Due', 'Total Amount Paid', 'Total Balance'],
            errorMessage: null,
            details: [],
            loading: false,
            query: null,
            customer_id: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                start_date: current_date_to_date,
                end_date: new Date(),
                draw: 0,
                length: 5,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            permission_status: null,
            crudLoading: null

        }
    },

    methods: {
        dataToPrint(details) {
            details.map((debt, idx) => {
                let result = {
                    sn: idx + 1,
                    batch_code: debt?.sales?.batch_code,
                    date: this.dateTime(debt?.created_at),
                    amount_paid: debt?.amount_paid,
                    amount_owned: debt?.amount_owned,

                };
                this.print_content.push(result)
            });
        },

        fetchData(url = '/debtors/customer/' + this.$route.params.id) {
            this.loading = true;
            this.tableData.query = this.query
            this.crudLoading = true

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                this.crudLoading = false
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;
                let data = response.data;
                this.details = data.data.data;
                this.dataToPrint(this.details)
                this.configPagination(data.data);
            }).catch(error => {
                this.crudLoading = false
                this.errorMessage = error
                this.loading = false;
                if (error?.response?.status == 403) {
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            });
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

    },

    watch: {
        query() {
            this.fetchData()
        }
    },
    mounted() {
        this.fetchData();
        this.getCompany();

    },

}
</script>

<style scoped>
.debitbtn {
    background-color: #ffe5e5;
    color: #de1b00;
}

@media (max-width: 767px) {
    .alluserdebtorstop {
        flex-direction: column;
    }

    .salessubmitdate {
        margin-left: 14px;
    }

    .thecard {
        width: 21rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .topuserdebt {
        flex-direction: column;
    }

    .salessubmitdate {
        margin-left: 13px;
    }
}
</style>
