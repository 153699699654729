<template>
    <div data-spy="scroll" data-target="#navbar-collapse-toggle" data-offset="98">
    <Navbar/>

    <!-- Main -->
    <main>
        <!-- Page Title -->
        <section class="section bg-center bg-cover bg-fixed effect-section" style="background-image: url(static/img/banner-bg-5.jpg);">
            <div class="mask theme-bg opacity-9"></div>
            <div class="container">
                <div class="row justify-content-center p-50px-t">
                    <div class="col-lg-8 text-center">
                        <h2 class="white-color font-w-600 display-4 m-20px-b">Privacy policy</h2>
                        <ol class="breadcrumb white justify-content-center">
                            <li><router-link to="/home">Home</router-link></li>
                            <li class="active">Privacy policy</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Page Title -->

        <!-- Section -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body" v-html="details.ims_privacy_policy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Section -->
    </main>
    <!-- End Main -->

    <Footer />

</div>

</template>
<script>
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import axios from "axios";

export default{
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            details: {},
            loading: true
        }
    },
    mounted() {
        this.scrollTop();
        this.pageData();
    },
    methods: {
        scrollTop(){
            window.scroll({
                top: 0,
                behavior: 'smooth',
            })
        },
        pageData() {
            axios.get("/admin/fetchProjectSetup").then((response) => {
                this.details = response.data.data;
                this.loading = false;
            })
        }

    }

}
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>