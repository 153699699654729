<template>
<SettingHeaderTab />

<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between gridtop">
        <div class="mt-2" style="font-size:24px"> Supplier List</div>
        <div class="d-flex gridtop">
            <div class="d-flex">
                <div class="d-flex align-items-center small pl-2 me-2 mt-1" styfle="margin-top:2px">
                    <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute px-2" />
                    <input type="text" class="form-control " placeholder="Search suppliers" style="padding-left: 38px;" v-model="query" />
                </div>
                <div class="dropdown">
                    <button class="btn exportbtn me-2 mt-2 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../private-assets/images/Vector.png" class="me-2" alt=""> Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <a @click="drawPdf(print_content, headerArrayColumn,'p','Suppliers','Supplier')" class="dropdown-item" href="#">PDF</a>
                        <li><a @click="reusableMixinToCSV('suppliers.csv')" class="dropdown-item" href="#">CSV</a></li>
                    </ul>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <router-link to="bulk-suppliers-upload"  class="btn btn-primary mt-2 me-2" :class="permission.create_suppliers == 1 ? '' : 'disabled'">Bulk Upload</router-link>
                </div>
                <div>
                    <router-link to="create-supplier" class="btn btn-primary text-white mt-2 me-2" :class="permission.create_suppliers == 1 ? '' : 'disabled'">Create Supplier </router-link>
                </div>
                <div>
                    <button class="btn btn-danger text-white mt-2" data-bs-toggle="modal" data-bs-target="#bulkModal" :class="permission.delete_suppliers == 1 && selectedModel.length  > 0 ? '' : 'disabled'">Bulk Delete</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3 cardpadding" style="overflow-x: auto;">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>
        <div class="text-center py-5" v-else-if="details.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt=""></div>
            <div class="fw-bold mt-3">Your suppliers bucket is empty!</div>
        </div>

        <div class="mb-5 cardpadding">
            <div class="card-toolbar" v-if="loading == false && details.length > 0">
                <!--begin::Toolbar-->
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                    <div class="d-flex align-items-center py-1" style="margin-right: 10px">
                        <select v-model="tableData.length" @change="fetchData()" class="form-select form-select-solid fw-bolder">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option :value="pagination.total">All</option>
                        </select>
                    </div>
                    <!--begin::Actions-->

                </div>
                <!--end::Toolbar-->
            </div>
            <div class="table-responsive">
                <table v-if="details.length > 0 && loading == false" class="table v-middle">
                    <thead style="border-style: hidden !important;">
                        <tr class="bg-white">
                            <th scope="col"> <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" />
                            </th>
                            <th scope="col" class="heading">Supplier Name</th>
                            <th scope="col" class="heading">Email</th>
                            <th scope="col" class="heading">Phone Number</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="supplier in details" :key="supplier.id">
                            <td><input type="checkbox" name="" id="" v-model="selectedModel" :value="supplier.id" /></td>
                            <td class="py-3 row1">{{ supplier.supplier_name }}</td>
                            <td class="py-3 row1">{{ supplier.email }}</td>
                            <td class="py-3 row1">{{ supplier.phone_no }}</td>
                            <td class="py-3">
                                <div class="" style="font-size: 13px;">
                                    <router-Link :to="permission.edit_suppliers==1 ? {name:'Edit Supplier', params:{ id:supplier.id}} : '#'">
                                        <font-awesome-icon prefix="fal" icon="pen" class="me-2" style="font-size: 15px;" :style="permission.edit_suppliers==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                    </router-Link>
                                    <font-awesome-icon type="button" :data-bs-toggle="permission.edit_suppliers==1 ? 'modal' :''" data-bs-target="#singleModal" icon="trash" style="font-size: 15px;" @click="getOneModal(supplier)" :style="permission.delete_suppliers==1? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination style="float:right;" :pagination="pagination" @prev="fetchData(pagination.prevPageUrl)" @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
            </pagination>
        </div>
    </div>
</div>
<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleSupplierDeleteButton" :modalText="'Are you sure you want to delete this supplier'" :action="'deleteSingle'"></SingleDeleteModal>
<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkDeletSuppliereButton" :modalText="'Are you sure you want to delete this suppliers'"></BulkDeleteModal>
</template>

<script>
import SettingHeaderTab from './components/settingheadertab.vue';
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';
import axios from 'axios';
import {
    useToast
} from 'vue-toastification';

import {
    reusableMixin
} from "../../../lib/reusableMixin";
import { useStore } from 'vuex';

export default {
    components: {
        SettingHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal,
    },
    mixins: [reusableMixin],

    data() {
        let sortOrders = {};

        let columns = [{
                width: '16%',
                label: 'Name',
                name: 'name',
                sortable: false
            },
            {
                width: '15%',
                label: 'Sortcode',
                name: 'sortcode',
                sortable: false
            },
            {
                width: '15%',
                label: 'Phonecode',
                name: 'phonecode',
                sortable: false
            },
            {
                width: '5%',
                label: 'Status',
                name: 'status',
                sortable: false
            },
            {
                width: '5%',
                label: 'Actions',
                name: 'actions',
                sortable: false
            },

        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            print_content: [],
            headerArrayColumn:['S/N','Supplier Name', 'Email', 'Phone Number'],
            errorMessage: null,
            getCustomers: '',
            details: [],
            loading: false,
            query: null,
            supplier_id: null,

            selectedModel: [],
            allSelected: false,

            columns: columns,
            sortKey: 'id',
            sortOrders: sortOrders,
            tableData: {
                draw: 0,
                length: 5,
                search: '',
                column: 0,
                dir: 'desc',
                id: '',
                status: [0, 1, 2, 3],
                query: ''
            },
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            toast: useToast(),
            permission_status:null,
            store:useStore()

        }
    },

    methods: {
        dataToPrint(details){
            this.print_content = []
            details.map((supplier, idx) => {
                let result = {
                    sn: idx + 1,
                    supplier_name: supplier.supplier_name,
                    email: supplier.email,
                    phone_number: supplier.phone_no,
                };
                this.print_content.push(result)
            });
        },
        getOneModal(supplier) {
            this.supplier_id = supplier.id
        },
        selectAll() {
            if (this.allSelected) {
                const selected = this.details.map((u) => u.id);
                this.selectedModel = selected;
            } else {
                this.selectedModel = [];
            }
        },
        fetchData(url = '/settings/suppliers') {
            this.loading = true;
            this.tableData.query = this.query

            this.tableData.draw++;
            axios.get(url, {
                params: this.tableData
            }).then(response => {
                if (!response.data.status == 200) {
                    this.loading = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.loading = false;

                let data = response.data;
                this.details = data.data.suppliers.data;
                this.dataToPrint(data.data.suppliers2)
                this.configPagination(data.data.suppliers);


            }).catch(error => {
                this.errorMessage = error
                this.loading = false;
                if(error?.response?.status == 403){
                    this.permission_status = error?.response?.status
                    this.toast.error(error.response.data.message);
                }
            });
        },

        callSingleSupplierDeleteButton() {
            axios.delete(`/settings/supplier/${this.supplier_id}`).then(() => {
                this.toast.success('Supplier deleted successfully')
                this.fetchData()
            }).catch((error) => {
                if(error?.response?.status == 403){
                    this.toast.error(error.response.data.message);
                    return
                }
                this.toast.success('Whoops, Something went wrong')
            })

        },

        callBulkDeletSuppliereButton() {
            axios.post(`/settings/supplier/delete-bulk`, {
                suppliers: this.selectedModel,
            }).then((response) => {
                this.fetchData();
                this.toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    this.toast.error(error.response.data.message[0]);
                } else {
                    this.toast.error(error.response.data.message);
                }

            })
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

    },

    computed: {
        permission() {
            return this.store.state.getCurrentUserPermission
        }
    },
    watch: {
        query() {
            this.fetchData()
        }
    },
    created() {
        this.fetchData();

    },

}
</script>
