<template>
<SettingHeaderTab />
<div>
    <div class="mt-3 px-3 font-weight-bold d-flex justify-content-between headercreate">
        <div class="" style="font-size: 20px">List of Category</div>
        <div class="d-flex">
            <div class="d-flex align-items-center small pl-2 me-2" styfle="margin-top:1px">
                <font-awesome-icon prefix="far" icon="search" class="text-muted position-absolute px-2" />
                <input type="text" class="form-control" placeholder="Search" style="padding-left: 38px" v-model="query" />
            </div>
            <div class="ml-3 me-2">
                <button data-bs-toggle="modal" data-bs-target="#createCategoryModal" type="button" class="btn btn-primary text-white" :class="permission.create_inventory_category == 1 ? '' : 'disabled'">
                    <span class="ms-2">Create Category</span>
                </button>
            </div>
            <div>
                <button class="btn btn-danger text-white" data-bs-toggle="modal" data-bs-target="#bulkModal"  :class="permission.delete_inventory_category == 1 && selectedModel.length  > 0 ? '' : 'disabled'">Bulk Delete</button>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg mt-3 mb-3 px-5 py-3">
        <div v-if="loading == true" class="text-center py-5">
            <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="text-center py-5" v-if="permission_status == 403">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">You don't have permission to access this resource!</div>
        </div>

        <div class="text-center py-5" v-else-if="categories.length == 0 && loading == false">
            <div><img src="../../private-assets/images/emptypng.png" alt="" /></div>
            <div class="fw-bold mt-3">Your category bucket is empty!</div>
            <!-- <div>Create store</div> -->
        </div>

        <table class="table table-sm-responsive" v-if="loading == false && categories.length > 0">
            <thead class="thead-white" style="border-style: hidden !important">
                <tr class="pb-5 text-left">
                    <th>
                        <input type="checkbox" name="" id="" v-model="allSelected" @change="selectAll" />
                    </th>
                    <th class="font-weight-bold">Lists of Category</th>
                    <th class="font-weight-bold">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in categories" :key="category.id" class="text-left">
                    <td>
                        <input type="checkbox" name="" id="" v-model="selectedModel" :value="category.id" />
                    </td>
                    <td>{{ category.category_name }}</td>
                    <td style="font-size: 13px">
                        <font-awesome-icon @click="getOneModal(category)" :data-bs-toggle="permission.edit_inventory_category==1 ? 'modal' : ''" data-bs-target="#editCategoryModal" type="button" prefix="fal" icon="pen" class="mx-2" style="font-size: 15px" :style="permission.edit_inventory_category==1 ? {} : {'color':'#DCDCDC !important', 'cursor':'default'}" />
                        <font-awesome-icon @click="getOneModal(category)" :data-bs-toggle="permission.delete_inventory_category==1 ? 'modal' : ''" data-bs-target="#singleModal" type="button" icon="trash" style="font-size: 15px" :style="permission.delete_inventory_category==1 ? {} : {'color':'#DCDCDC !important', 'cursor':'default'}"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="createCategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createCategoryModal">
                    Create Inventory Category
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createCategory">
                <div class="modal-body">
                    <div>
                        <div>
                            <input v-model="category_name" type="text" class="form-control" placeholder="Enter category name" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="category_name < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary text-white">
                            Create
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="editCategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editCategoryModal">
                    Edit Inventory Category
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="updateCategory">
                <div class="modal-body">
                    <div>
                        <div>
                            <input v-model="category_name" type="text" class="form-control" placeholder="Enter category name" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="category_name < 1" type="submit" data-bs-dismiss="modal" class="btn btn-primary">
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<SingleDeleteModal :modalId="'singleModal'" @deleteSingle="callSingleCategoryDeleteButton" :modalText="'Are you sure you want to delete this category, <strong> all the subcategories assigned to it will be deleted</strong>'" :action="'deleteSingle'"></SingleDeleteModal>

<BulkDeleteModal :modalId="'bulkModal'" @deleteBulk="callBulkCategoryDeleteButton" :modalText="'<div >Are you sure you want to delete this categories, <strong> all the subcategories assigned to it will be deleted</strong>'"></BulkDeleteModal>
</template>

<script>
import {
computed,
    ref
} from "@vue/reactivity";
import SettingHeaderTab from "./components/settingheadertab.vue";
import axios from "axios";
import {
    onMounted
} from "@vue/runtime-core";
import {
    useToast
} from "vue-toastification";
import {
    watch
} from "vue";
import SingleDeleteModal from '../../../components/ActionModal.vue';
import BulkDeleteModal from '../../../components/bulkDeleteModal.vue';
import { useStore } from "vuex";

// import { Modal } from 'bootstrap'
export default {
    components: {
        SettingHeaderTab,
        SingleDeleteModal,
        BulkDeleteModal
    },
    setup() {
        const store = useStore()
        const categories = ref("");
        const loading = ref(false);
        const toggleCreateModal = ref("");
        const toast = useToast();
        const category_name = ref("");
        const validationErrors = ref("");
        const category_id = ref("");
        const selectedModel = ref([]);
        const allSelected = ref(false);
        const query = ref(null);
        const permission_status = ref(null)
        const selectAll = async () => {
            if (allSelected.value) {
                const selected = categories.value.map((u) => u.id);
                selectedModel.value = selected;
            } else {
                selectedModel.value = [];
            }
        };

        const getOneModal = (category) => {
            category_name.value = category.category_name;
            category_id.value = category.id;
        };

        const callSingleCategoryDeleteButton = async () => {
            await axios.delete(`/settings/category/${category_id.value}`);
            category_name.value = ''
            category_id.value = '';

            getAllCategories();
            toast.success("Category deleted successfully");
        };

        const callBulkCategoryDeleteButton = async () => {
            await axios.post(`/settings/category/delete-bulk`, {
                categories: selectedModel.value,
            }).then((response) => {
                category_name.value = ''
                category_id.value = '';
                allSelected.value = false
                getAllCategories();
                toast.success(response.data.message);

            }).catch((error) => {
                if (error.response.status == 422) {
                    toast.error(error.response.data.message[0]);
                } else {
                    toast.error(error.response.data.message);
                }

            })
        }

        const createCategory = async () => {
            loading.value = true;

            await axios
                .post("/settings/category", {
                    category_name: category_name.value,
                })
                .then(() => {
                    toast.success("Created category successfull");
                    category_name.value = ''
                    category_id.value = '';
                    getAllCategories();
                    loading.value = false;
                })
                .catch((error) => {
                    category_name.value = ''
                    category_id.value = '';
                    if (error.response.status == 422) {
                        toast.warning(error.response.data.message[0]);
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }

                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                    loading.value = false;
                });
        };

        const updateCategory = async () => {
            loading.value = true;
            await axios
                .put(`${"/settings/category/" + category_id.value}`, {
                    category_name: category_name.value,
                })
                .then(() => {
                    toast.success("Created category successfull");
                    category_name.value = "";
                    getAllCategories();
                    loading.value = false;
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        toast.warning(error.response.data.message[0]);
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }

                    if(error?.response?.status == 403){
                        permission_status.value = error?.response?.status
                        toast.error(error.response.data.message);
                    }
                    loading.value = false;
                });
        };

        const getAllCategories = async () => {
            loading.value = true;

            await axios.get("settings/category", {
                params: {
                    query: query.value,
                },
            }).then((response)=>{
                categories.value = response.data.data;
                loading.value = false;
            }).catch((error)=>{
                permission_status.value = error?.response?.status
                toast.error(error.response.data.message, {
                    timeout: 10000,
                });
                loading.value = false;
            })

            
        };
        const permission = computed(() => store.state.getCurrentUserPermission);

        watch(query, () => {
            getAllCategories();
        });

        onMounted(() => getAllCategories());

        return {
            permission,
            categories,
            getAllCategories,
            loading,
            toggleCreateModal,
            createCategory,
            updateCategory,
            category_name,
            validationErrors,
            getOneModal,
            callSingleCategoryDeleteButton,
            selectAll,
            selectedModel,
            allSelected,
            callBulkCategoryDeleteButton,
            query,
            permission_status

        };
    },
};
</script>

<style></style>
