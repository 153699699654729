<template>
  <div class="container-fluid bg-white">
    <ul class="nav nav-tabs thetoppills py-2">
      <li class="nav-item" role="presentation" v-if="permission.inventory_mgt_module == 1 && permission.view_product == 1">
        <router-link
          to="/all-products"
          active-class="pillactive"
          class="nav-link makeGreen"
          :class="
            $router.currentRoute.value.name == 'Create Product' ||
            $router.currentRoute.value.name == 'Edit Product' ||
            $router.currentRoute.value.name == 'View Product' ||
            $router.currentRoute.value.name == 'Bulk Product Upload'
              ? 'pillactive'
              : ''
          "
        >
          <!-- <font-awesome-icon prefix="far" icon="shopping-bag" class="pillicons" />  -->
          Product
        </router-link>
      </li>
      <li class="nav-item" role="presentation" v-if="permission.inventory_mgt_module == 1 && permission.view_product_location == 1">
        <router-link
          to="/all-product-location"
          active-class="pillactive"
          class="nav-link makeGreen"
          v-if="!freemium"
        >
          <!-- <font-awesome-icon icon="map-marker-alt" class="pillicons" /> -->
          Product Location
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Product Location</span>
      </li>
      <li class="nav-item" role="presentation" v-if="permission.inventory_mgt_module == 1 && permission.view_stock_adjustment == 1">
        <router-link
          to="/all-stock-adjustment"
          active-class="pillactive"
          class="nav-link makeGreen"
          :class="
            $router.currentRoute.value.name == 'Create Stock Adjustment' ||
            $router.currentRoute.value.name == 'View Stock Adjustment' ||
            $router.currentRoute.value.name == 'Print Stock Adjustment' ||
            $router.currentRoute.value.name == 'Print 80mm Stock Adjustment' || 
            $router.currentRoute.value.name == 'Print 58mm Stock Adjustment' 


              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          <!-- <font-awesome-icon icon="shopping-cart" class="pillicons" /> -->
          Stock Adjustment
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Stock Adjustment</span>
      </li>
      <li class="nav-item" role="presentation" v-if="permission.inventory_mgt_module == 1 && permission.view_stock_transfer == 1">
        <router-link
          class="nav-link makeGreen"
          active-class="pillactive"
          to="/all-stock-transfer"
          :class="
            $router.currentRoute.value.name == 'Create Stock Transfer' ||
            $router.currentRoute.value.name == 'View Stock Transfer' ||
            $router.currentRoute.value.name == 'Print Stock Transfer' ||
            $router.currentRoute.value.name == 'Print 80mm Stock Transfer' ||
            $router.currentRoute.value.name == 'Print 58mm Stock Transfer'
              ? 'pillactive'
              : ''
          "
          v-if="!freemium"
        >
          <!-- <font-awesome-icon icon="sort" class="pillicons" /> -->
          Stock Transfer
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Stock Transfer</span>
      </li>
      <li class="nav-item" role="presentation" v-if="permission.inventory_mgt_module == 1 && permission.view_expired_products == 1">
        <router-link
          class="nav-link makeGreen"
          active-class="pillactive"
          to="/expired-products"
          v-if="!freemium"
        >
          <!-- <font-awesome-icon icon="exclamation-triangle" class="pillicons" /> -->
          Expired Product
        </router-link>
        <span v-if="freemium" @click="freemiumPrompt" class="nav-link makeGreen">Expired Product</span>
      </li>
    </ul>
  </div>



  
</template>

<script>
import { computed } from "@vue/runtime-core";
import axios from "axios";
import { useStore } from "vuex";
import {
    useToast
} from "vue-toastification";

export default {
  setup() {
    const store = useStore();
    const permission = computed(() => store.state.getCurrentUserPermission);
    return { permission };
  },
  data () {
    return {
      freemium: true,
      toast: useToast(),
    } 
  },
  methods: {
    activeSubscription() {
      // loading.value = true
      axios.get("/subscription/user")
        .then((response) => {
          // loading.value = false
          // subscriptions.value = response.data.data
          // // console.log(response.data.data, 'hh')
          // allUsers()
          // superUser()
          if(response.data.data.filter(data => data.status == 1 || data.status == 0).length == 0) {
            this.freemium = true;
          }else {
            this.freemium = false;
          }
        }).catch(() => {
          // loading.value = false
        })
    },

    freemiumPrompt () {
      this.toast.error('subscribe or upgrade to use this functionality')
    }
  },
  mounted() {
      this.activeSubscription();
  },

};
</script>

<style>
  .thetoppills > li > span {
      color: #a0aec0;
      cursor: pointer;
  }
</style>
