<template>
  <InventoryHeaderTab />
  <div class="mt-5 bg-white rounded-lg px-3 py-3">
    <div v-if="loading == true" class="text-center py-3">
      <div class="spinner-border text-primary " role="status" style="width: 9rem; height: 9rem;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="loading == false">
      <div class="d-flex justify-content-between">
        <div>
          <h4>Product Info</h4>
          <div class="text-muted" style="font-size: 10px;">Details of product</div>
        </div>
        <div class="editcompany" style="font-size: 14px;">
          <router-link :to="`/edit-product/${getParamID}`">
            <font-awesome-icon class="me-2" icon="edit" style="font-size: 14px;" />
            <span>Edit Product</span>
          </router-link>
        </div>
      </div>

      <div class="mt-3 d-flex">
        <div class="me-5 phototext">PHOTO</div>
        <div v-if="theproduct?.product?.image"><img :src="host.hostName+theproduct?.product?.image" alt="" class="logoproductsnippet" style="height: 65px;width:65px"></div>
        <div v-else><img src="../../private-assets/images/user.png" alt="" class="logoproductsnippet" style="height: 65px;width:65px"></div>

      </div>


      
      <div class="mt-4">
        <div class="row">
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">BARCODE</div>
            <div class="mt-2 mb-3">{{ theproduct.bar_code }}</div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">PRODUCT NAME</div>
            <div class="mt-2 mb-3">{{ theproduct.product_name }}</div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">COST PRICE</div>
            <div class="mt-2 mb-3">{{ theproduct.cost_price }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">MARK UP</div>
            <div class="mt-2 mb-3">{{ theproduct.markup }}%</div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">SELLING PRICE</div>
            <div class="mt-2 mb-3">{{ theproduct.bar_code }}</div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">City</div>
            <div class="mt-2 mb-3">{{theproduct?.store?.city?.name}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">UNIT OF MEASUREMENT</div>
            <div class="mt-2 mb-3">{{ theproduct.unit_of_measurement }}</div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">MIN. WARNING QUANTITY</div>
            <div class="mt-2 mb-3">{{ theproduct.warning_quantity }}</div>
          </div>
          <!-- <div class="col-md-4">
            <div class="font-weight-bold text-muted" style="font-size: 14px;">MAX. WARNING QUANTITY</div>
            <div v-if="theproduct.product.max_warning_quantity == null" class="mt-2 mb-3">--</div>
            <div v-if="theproduct.product.max_warning_quantity !== null" class="mt-2 mb-3">{{ theproduct.product.max_warning_quantity }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3 bg-white rounded-lg px-3 py-3">
    <div class="d-flex justify-content-between">
      <div>
        <h4>Variant</h4>
        <div class="text-muted" style="font-size: 10px;">Details of product variants</div>
      </div>
      <div class="editcompany" style="font-size: 14px;">
        <router-link :to="`/edit-product/${theproduct.id}`">
          <font-awesome-icon class="me-2" icon="edit" style="font-size: 14px;" />
          <span>Edit variant</span>
        </router-link>
      </div>
    </div>
    <div class="row mt-4"  v-for="variant in thevariants" :key="variant.id">
      <div class="col-md-4">
        <div class="font-weight-bold text-muted" style="font-size: 14px;">VARIANT NAME</div>
        <div class="mt-3">{{ variant.variant_name }}</div>
      </div>
      <div class="col-md-4">
        <div class="font-weight-bold text-muted" style="font-size: 14px;">VARIANT PRICE</div>
        <div class="mt-3">{{ variant.variant_selling_price }}</div>
      </div>
      <div class="col-md-4">
        <div class="font-weight-bold text-muted" style="font-size: 14px;">VARIANT QUANTITY</div>
        <div class="mt-3">{{ variant.variant_quantity }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import InventoryHeaderTab from './component/inventoryheadertab.vue'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import host from "../../../main"

export default {
  components: { InventoryHeaderTab },
  setup(){
    const { params } = useRoute();
    const theproduct = ref('')
    const thevariants = ref([])
    const loading = ref(false)
    const getParamID = ref('')

    const getTheParamID = async () => { getParamID.value = params.id }

    const GetProducts  = async () => {
      loading.value = true

      const getProduct = await axios.get(`/inventory/product/${params.id}`)
      theproduct.value = getProduct.data.data;
      thevariants.value = theproduct.value?.variants

      loading.value = false
    }

    onMounted(GetProducts(), getTheParamID())

    return { theproduct, thevariants, loading, getTheParamID, getParamID, GetProducts,host }
  }
}
</script>

<style scoped>
.phototext {
  margin-top: 26px;
}
</style>