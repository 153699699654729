<template>
  <!-- Header -->
  <header class="header-nav header-white">
        <div class="fixed-header-bar">
            <div class="container container-large">
                <div class="navbar navbar-default navbar-expand-lg main-navbar">
                    <div class="navbar-brand">
                        <router-link v-if="details.logo" to="/home" title="InventFlux" class="logo">
                            <img :src="url.hostName+'images/logo/'+details.logo2" style="max-height: 50px;" class="light-logo" alt="InventFlux" title="">
                            <img :src="url.hostName+'images/logo/'+details.logo" style="max-height: 50px;" class="dark-logo" alt="InventFlux" title="">
                        </router-link>
                        <router-link v-else to="/home" title="InventFlux" class="logo">
                            <img src="/static/img/logo2.png" style="max-height: 50px;" class="light-logo" alt="InventFlux" title="">
                            <img src="/static/img/logo.png" style="max-height: 50px;" class="dark-logo" alt="InventFlux" title="">
                        </router-link>
                    </div>
                    <div class="navbar-collapse justify-content-end collapse" id="navbar-collapse-toggle">
                        <ul class="nav navbar-nav m-auto">
                            <li><router-link class="nav-link" to="/home">Home</router-link></li>
                            <li><router-link class="nav-link" to="/about">About</router-link></li>
                            <li><router-link class="nav-link" to="/faq">FAQ</router-link></li>
                            <li><router-link class="nav-link" to="/pricing">Pricing</router-link></li>
                            <li><router-link class="nav-link" to="/blog">Blog</router-link></li>
                            <li><router-link class="nav-link" to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                    <div class="extra-menu d-flex align-items-center">
                      <div v-if="isLogin" class="d-none d-md-block h-btn m-35px-l">
                            <router-link class="m-btn m-btn-theme2nd m-btn-radius" to="/">Account</router-link>&nbsp;
                            <button v-if="logoutLoading == false" class="m-btn m-btn-theme2nd m-btn-radius" @click="logout">Logout</button>
                            <button v-if="logoutLoading == true" class="m-btn m-btn-theme2nd m-btn-radius">
                              <div class="spinner-grow text-info" role="status">
                                  <span class="visually-hidden">Processing</span>
                              </div>
                            </button>
                        </div>
                        <div v-else class="d-none d-md-block h-btn m-35px-l">
                            <router-link class="m-btn m-btn-theme2nd m-btn-radius" to="/auth/login">Login</router-link>&nbsp;
                            <router-link class="m-btn m-btn-theme2nd m-btn-radius" to="/auth/register">Register</router-link>
                        </div>
                        <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle" aria-expanded="false">
                            <span class="icon-bar"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Header End -->
</template>


<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import hostName from '../main';

export default {
  data () {
    const toast = useToast();
    return {
      token: localStorage?.token,
      isLogin: localStorage.getItem("token"),
      logoutLoading: false,
      toast: toast,
      url: hostName,
      details: {},
    }
  },

  mounted() {
    this.pageData();
  },

  methods: {
    logout() {
        this.logoutLoading = true;
        axios.get("/logout").then((response) => {
            if(response.status ==200){
                this.logoutLoading = false;
                localStorage.clear();
                window.location.href = "/auth/login";
                // this.route.push('/auth/login')
            }                
            
        }).catch((error)=>{
            this.logoutLoading = false;
            this.toast.error(error?.response?.data?.message)
        })
        
    },

    pageData() {
        axios.get("/admin/fetchProjectSetup").then((response) => {
            this.details = response.data.data;
        })
    },

  }
};
</script>

<style scoped>
a {
    text-decoration: none;
    /* color: #b61c1c; */
}
</style>
