import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/homepage";
import Privacy from '../pages/privacypage';
import Terms from '../pages/termspage';
import About from '../pages/about';
import Contact from '../pages/contact';
import Faq from '../pages/faq';
import Blog from '../pages/blog';
import BlogSearch from '../pages/blogSearch';
import BlogByCategory from '../pages/blogByCategory';
import BlogDetail from '../pages/blogdetail';
import Pricing from '../pages/pricingpage';
import Login from "../authpages/login.vue";
import Register from "../authpages/register.vue";
import VerifyEmail from "../authpages/verify-email.vue";
import SendEmailVericationLink from "../authpages/send-verification-link.vue";

import ChangePassword from "../authpages/change-password.vue";
import ResetPassword from "../authpages/reset-password.vue";
// private section
import Private from "../private/private.vue";
import DashboardHome from "../private/dashboard/home.vue";
// administration
import AdministrationManageStore from "../private/dashboard/admninistration/index.vue";
import AdminCreateStore from "../private/dashboard/admninistration/create-store.vue";
import AdminManageUser from "../private/dashboard/admninistration/manage-user.vue";
import AdminCreateUser from "../private/dashboard/admninistration/create-new-user.vue";
import AdminEditStore from "../private/dashboard/admninistration/edit-store.vue";
import AdminEditUser from "../private/dashboard/admninistration/edit-user.vue";
// creditor
import CreditorIndex from "../private/dashboard/creditor/creditor-index.vue";
import CreditorReceipt from "../private/dashboard/creditor/creditors-receipt.vue";
import PrintCreditor from "../private/dashboard/creditor/print-creditor-receipt.vue";
import getUserCredit from "../private/dashboard/creditor/user-creditor.vue";
// debitor
import DebitorIndex from "../private/dashboard/debitor/index.vue";
import DebitorReceipt from "../private/dashboard/debitor/debitor-receipt.vue";
import PrintDebitorReceipt from "../private/dashboard/debitor/print-debtor-receipt.vue";

import getUserDebit from "../private/dashboard/debitor/get-user-debit.vue";
// inventorymgt
import AllProduct from "../private/dashboard/inventorymgt/allproducts.vue";
import AllProductLocation from "../private/dashboard/inventorymgt/allproductlocation.vue";
import AllStockAdjustment from "../private/dashboard/inventorymgt/allstockadjustment.vue";
import AllStockTransfer from "../private/dashboard/inventorymgt/allstocktransfer.vue";
import CreateProduct from "../private/dashboard/inventorymgt/createproduct.vue";
import CreateStockTransfer from "../private/dashboard/inventorymgt/createstocktransfer.vue";
import CreateStockAdjustment from "../private/dashboard/inventorymgt/createstockadjustment.vue";
import EditProduct from "../private/dashboard/inventorymgt/editproduct.vue";
import ViewProduct from "../private/dashboard/inventorymgt/view-product.vue";

import PrintStockAdjustment from "../private/dashboard/inventorymgt/print-stock-adjustment.vue";
// import Print58mmStockAdjustment from "../private/dashboard/inventorymgt/print58mm-stock-adjustment.vue";

import PrintStockTransfer from "../private/dashboard/inventorymgt/print-stock-transfer.vue";
import ViewStockAdjustment from "../private/dashboard/inventorymgt/view-stock-adjustment.vue";
import ViewStockTransfer from "../private/dashboard/inventorymgt/view-stock-transfer.vue";
// import Print80mmStockTransfer from "../private/dashboard/inventorymgt/print80mm-stock-transfer.vue";
// import Print58mmStockTransfer from "../private/dashboard/inventorymgt/print58mm-stock-transfer.vue";

import BulkProductUpload from "../private/dashboard/inventorymgt/bulk-product-upload.vue";
import ExpiredProducts from "../private/dashboard/inventorymgt/Expired-product.vue";
// setting
import SettingInfo from "../private/dashboard/setting/settingindex.vue";
import ChangeUserPassword from "../private/dashboard/setting/change-password.vue";
import ChangeUserEmail from "../private/dashboard/setting/change-email.vue";
import AllCustomers from "../private/dashboard/setting/allcustomers.vue";
import AllPayments from "../private/dashboard/setting/allpayments.vue";
import AllSuppliers from "../private/dashboard/setting/allsuppliers.vue";
import InventoryCategory from "../private/dashboard/setting/inventorycategory.vue";
import InventorySubcategory from "../private/dashboard/setting/inventorysubcategory.vue";

import Notification from "../private/dashboard/setting/Notification.vue";
import Taxes from "../private/dashboard/setting/taxes.vue";
import BulkCustomerUpload from "../private/dashboard/setting/bulk-customers-upload.vue";
import BulkSuppliersUpload from "../private/dashboard/setting/bulk-suppliers-upload.vue";
import CreateCustomer from "../private/dashboard/setting/create-customer.vue";
import EditCustomer from "../private/dashboard/setting/edit-customer.vue";

// Currency
import AllCurrencies from "../private/dashboard/setting/all-currencies.vue";

import EditSupplier from "../private/dashboard/setting/edit-supplier.vue";
import CreateSupplier from "../private/dashboard/setting/create-supplier.vue";

import EditCompanyInfo from "../private/dashboard/setting/edit-company-info.vue";
// purchase
import AllPurchase from "../private/dashboard/purchase/all-purchases.vue";
import MakePurchase from "../private/dashboard/purchase/make-purchase.vue";
import PrintPurchase from "../private/dashboard/purchase/print-purchase-receipt.vue";
import PurchaseReceipt from "../private/dashboard/purchase/purchase-receipt.vue";
import ViewPurchase from "../private/dashboard/purchase/view-purchase.vue";

// report
import AllReports from "../private/dashboard/reports/all-reports.vue";
import Performance from "../private/dashboard/reports/performance.vue";
import ReportAdjustment from "../private/dashboard/reports/report-adjustment.vue";
import ReportBin from "../private/dashboard/reports/reportbin.vue";
import ViewStockAdjustmentReport from "../private/dashboard/reports/view-stock-adjustment-report.vue";
import ReportReceipt from "../private/dashboard/reports/report-receipt.vue";
import CustomerReport from "../private/dashboard/reports/customer-report";
import UserCustomerReport from "../private/dashboard/reports/user-customer-report";
import CustomerInvoice from "../private/dashboard/reports/customer-invoice";
import SalesReport from "../private/dashboard/reports/sales-report";
import SalesInvoice from "../private/dashboard/reports/sales-invoice";
import ProfitabilityReport from "../private/dashboard/reports/profitability-report";
import PurchaseReport from "../private/dashboard/reports/purchase-report";
import PurchaseReportInvoice from "../private/dashboard/reports/purchase-report-invoice";
import StockTransferReport from "../private/dashboard/reports/stock-transfer-report";
import StockTransferDetailsReport from "../private/dashboard/reports/stock-transfer-details-report";
import StockAdjustmentReport from "../private/dashboard/reports/stock-adjustment-report";
import StockAdjustmentDetailsReport from "../private/dashboard/reports/stock-adjustment-details-report";
import SalesByCategory from "../private/dashboard/reports/salesbycategory";
import AuditTrail from "../private/dashboard/reports/audit-trail";
import InventoryStatusReport from "../private/dashboard/reports/inventory-status-report.vue";

// sales
import AllSales from "../private/dashboard/sales/allsales.vue";
import MakeCredit from "../private/dashboard/sales/make-credit-sales.vue";
import MakeSales from "../private/dashboard/sales/make-sales.vue";

import PrintSalesReceipt from "../private/dashboard/sales/print-sales-receipt.vue";
// import Print58mmSalesReceipt from "../private/dashboard/sales/print58mm-sales-receipt.vue";


import SalesReceiptCash from "../private/dashboard/sales/sales-receipt-cash.vue";
// import SalesReceiptCredit from "../private/dashboard/sales/sales-receipt-credit.vue";
import ViewSale from "../private/dashboard/sales/view-sales.vue";

// subscription
import SubscriptionIndex from '../private/dashboard/subscription/subscription.vue';
import subscriptionPlan from '../private/dashboard/subscription/subscriptionplan.vue'
import subscriptionPayment from '../private/dashboard/subscription/payment.vue'
import subscriptionPlanInvoice from '../private/dashboard/subscription/invoice.vue'

// invoice
import Invoices from "../private/dashboard/invoice/invoices.vue";
import CreateInvoice from "../private/dashboard/invoice/create-invoice.vue";
import InvoiceDetail from "../private/dashboard/invoice/component/invoice.vue";


export const isLoggedIn = () => {
  return localStorage.getItem("token");
};

// export const isLoggedIn2 = () => {
//   const now = new Date();
//   // conso
//   // return localStorage.getItem("token");
// };


const meta =  {
    requiresSubscription: true,
    isAuthenticated:true 
}
const meta2 =  {
  requiresSubscription: false,
  isAuthenticated:true 
}

// Pricing

const routes = [
  { path: "/home", component: Home },
  { path: "/privacy-policy", component: Privacy},
  { path: "/terms-and-conditions", component: Terms},
  { path: "/pricing", component: Pricing},
  { path: "/contact", component: Contact},
  { path: "/about", component: About},
  { path: "/faq", component: Faq},
  { path: "/blog", component: Blog},
  { path: "/search/blog", component: BlogSearch},
  { path: "/blog/category/:id", component: BlogByCategory},
  { path: "/blog/:id", component: BlogDetail},
  { path: "/auth/login", component: Login },
  { path: "/auth/register", component: Register },
  { path: "/auth/change-password/:token", component: ChangePassword },
  { path: "/auth/reset-password", component: ResetPassword },
  { path: "/auth/verify/account/:token", component: VerifyEmail },
  { path: "/auth/send-email-verifcation", component: SendEmailVericationLink },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: Home,
    meta: {
        title: 'Error',
    },
  },
  
  {
    path: "/",
    component: Private,
    beforeEnter(to, from, next) {
      if (isLoggedIn()) {
        next();
      } else {
        next("/home");
      }
    },
    
    children: [
      { path: "/", component: DashboardHome, name: "Dashboard"
      
    },
      {
        path: "/manage-store",
        component: AdministrationManageStore,
        name: "Manage Store",
        meta
      },
      {
        path: "/create-store",
        component: AdminCreateStore,
        name: "Create Store",
        meta
      },
      { path: "/manage-user", component: AdminManageUser, name: "Manage User",meta },
      { path: "/create-user", component: AdminCreateUser, name: "Create User",meta },
      {
        path: "/edit-store/:id",
        component: AdminEditStore,
        name: "Edit Store",
        meta
      },
      {
        path: "/edit-user/:id",
        component: AdminEditUser,
        name: "Edit User",
        meta
      },

      { path: "/creditor", component: CreditorIndex, name: "Creditor",meta },
      {
        path: "/creditor-receipt/:id",
        component: CreditorReceipt,
        name: "View Creditor Receipt",
        meta
      },

      {
        path: "/print58mm-creditor-receipt/:id",
        component: PrintCreditor,
        name: "Print 58mm Creditor Receipt",
        meta
      },
      {
        path: "/print80mm-creditor-receipt/:id",
        component: PrintCreditor,
        name: "Print 80mm Creditor Receipt",
        meta
      },

      { path: "/user-credit/:supplierId", component: getUserCredit, name: "User Credit",meta },

      { path: "/debtor", component: DebitorIndex, name: "Debtor",meta },
      {
        path: "/debtor-receipt/:id",
        component: DebitorReceipt,
        name: "View Debtor Receipt",
        meta
      },

      {
        path: "/print80mm-debtor-receipt/:id",
        component: PrintDebitorReceipt,
        name: "Print 80mm Debtor Receipt",
        meta
      },

      {
        path: "/print58mm-debtor-receipt/:id",
        component: PrintDebitorReceipt,
        name: "Print 58mm Debtor Receipt",
        meta
      },

      { path: "/user-debtor/:id", component: getUserDebit, name: "User Debtor",meta },

      // Currency

      {
        path: "/all-currencies",
        component: AllCurrencies,
        name: "All Currency",
        meta
      },

      {
        path: "/edit-company-info",
        component: EditCompanyInfo,
        name: "Edit Company Info",
        meta
      },
      { path: "/all-products", component: AllProduct, name: "All Products",meta2 },
      {
        path: "/all-product-location",
        component: AllProductLocation,
        name: "Product Location",
        meta
      },
      {
        path: "/all-stock-transfer",
        component: AllStockTransfer,
        name: "Stock Transfer",
        meta
      },
      {
        path: "/all-stock-adjustment",
        component: AllStockAdjustment,
        name: "Stock Adjustment",
        meta
      },
      {
        path: "/create-product",
        component: CreateProduct,
        name: "Create Product",
        meta2
      },
      {
        path: "/create-stock-transfer",
        component: CreateStockTransfer,
        name: "Create Stock Transfer",
        meta
      },
      {
        path: "/create-stock-adjustment",
        component: CreateStockAdjustment,
        name: "Create Stock Adjustment",
        meta
      },
      {
        path: "/edit-product/:id",
        component: EditProduct,
        name: "Edit Product",
        meta
      },
      {
        path: "/view-product/:id",
        component: ViewProduct,
        name: "View Product",
        meta
      },

      {
        path: "/print80mm-stock-adjustment/:id",
        component: PrintStockAdjustment,
        name: "Print 80mm Stock Adjustment",
        meta
      },

      {
        path: "/print58mm-stock-adjustment/:id",
        component: PrintStockAdjustment,
        name: "Print 58mm Stock Adjustment",
        meta
      },

  
      {
        path: "/view-stock-transfer/:id",
        component: ViewStockTransfer,
        name: "View Stock Transfer",
        meta
      },
      {
        path: "/print80mm-stock-transfer/:id",
        component: PrintStockTransfer,
        name: "Print 80mm Stock Transfer",
        meta
      },
      {
        path: "/print58mm-stock-transfer/:id",
        component: PrintStockTransfer,
        name: "Print 58mm Stock Transfer",
        meta
      },
      
      {
        path: "/view-stock-adjustment/:id",
        component: ViewStockAdjustment,
        name: "View Stock Adjustment",
        meta
      },
      {
        path: "/bulk-product-upload",
        component: BulkProductUpload,
        name: "Bulk Product Upload",
        meta
      },
      {
        path: "/expired-products",
        component: ExpiredProducts,
        name: "Expired Products",
        meta
      },
      { path: "/company-info", component: SettingInfo, name: "Company Info" },
      {
        path: "/change-user-password",
        component: ChangeUserPassword,
        name: "Change Password",
      },
      {
        path: "/change-user-email",
        component: ChangeUserEmail,
        name: "Change Email",
      },
      {
        path: "/all-customers",
        component: AllCustomers,
        name: "All Customers",
      
      },

      { path: "/all-payments", component: AllPayments, name: "All Payments",meta2 },
      {
        path: "/all-suppliers",
        component: AllSuppliers,
        name: "All Suppliers",
        
      },
      {
        path: "/inventory-category",
        component: InventoryCategory,
        name: "Inventory Category",
        
      },

      {
        path: "/inventory-subcategory",
        component: InventorySubcategory,
        name: "Inventory Subcategory",
        
      },
      { path: "/notifications", component: Notification, name: "Notification", },
      { path: "/tax", component: Taxes, name: "Tax", },
      {
        path: "/bulk-customers-upload",
        component: BulkCustomerUpload,
        name: "Bulk Customer Upload",
        
      },
      {
        path: "/bulk-suppliers-upload",
        component: BulkSuppliersUpload,
        name: "Bulk Supplier Upload",
        
      },
      {
        path: "/create-customer",
        component: CreateCustomer,
        name: "Create Customer",
        
      },
      {
        path: "/edit-customer/:id",
        component: EditCustomer,
        name: "Edit Customer",
        
      },

      {
        path: "/edit-supplier/:id",
        component: EditSupplier,
        name: "Edit Supplier",
        
      },
      {
        path: "/create-supplier",
        component: CreateSupplier,
        name: "Create Supplier",
        
      },

      {
        path: "/edit-company-info",
        component: EditCompanyInfo,
        name: "Edit Company Info",
        
      },

      { path: "/all-purchases", component: AllPurchase, name: "All Purchase",meta2 },
      {
        path: "/make-purchase",
        component: MakePurchase,
        name: "Make Purchase",
        meta
      },

      {
        path: "/purchase-receipt/:id",
        component: PurchaseReceipt,
        name: "View Purchase Receipt",
        meta
      },

      {
        path: "/print58mm-purchase-receipt/:id",
        component: PrintPurchase,
        name: "Print 58mm Purchase Receipt",
        meta
      },
      {
        path: "/print80mm-purchase-receipt/:id",
        component: PrintPurchase,
        name: "Print 80mm Purchase Receipt",
        meta
      },


      {
        path: "/view-purchase/:id",
        component: ViewPurchase,
        name: "Purchase View",
        meta
      },

      { path: "/all-reports", component: AllReports, name: "All Reports", },
      { path: "/performance", component: Performance, name: "Performance", },
      {
        path: "/report-adjustment",
        component: ReportAdjustment,
        name: "Report Adjustment",
        
      },
      { path: "/report-bin", component: ReportBin, name: "Report Bin", },
      {
        path: "/view-report-stock-adjustment",
        component: ViewStockAdjustmentReport,
        name: "View Stock Report",
        
      },
      {
        path: "/report-receipt",
        component: ReportReceipt,
        name: "Report Receipt",
        
      },
      {
        path: "/customer-report",
        component: CustomerReport,
        name: "Customer Report",
        
      },
      {
        path: "/user-customer-report/:customerId",
        component: UserCustomerReport,
        name: "User Customer Report",
        
      },
      { path: "/sales-report", component: SalesReport, name: "Sales Report", },
      {
        path: "/sales-invoice",
        component: SalesInvoice,
        name: "Sales Invoice",
        
      },
      {
        path: "/profitability-report",
        component: ProfitabilityReport,
        name: "Profitability Report",
        
      },
      {
        path: "/purchase-report",
        component: PurchaseReport,
        name: "Purchase Report",
        
      },
      {
        path: "/purchase-report-invoice",
        component: PurchaseReportInvoice,
        name: "Purchase Report Invoice",
        
      },
      {
        path: "/stock-transfer-report",
        component: StockTransferReport,
        name: "Stock Transfer Report",
        
      },
      {
        path: "/stock-transfer-details",
        component: StockTransferDetailsReport,
        name: "Stock Transfer Report Details",
        
      },
      {
        path: "/stock-adjustment-report",
        component: StockAdjustmentReport,
        name: "Stock Adjustment Report",
        
      },
      {
        path: "/stock-adjustment-details",
        component: StockAdjustmentDetailsReport,
        name: "Stock Adjustment Report Details",
        
      },
      {
        path: "/sales-by-category",
        component: SalesByCategory,
        name: "Sales By Category",
        
      },
      { 
        path: "/audit-trail", 
        component: AuditTrail, 
        name: "Audit Trail",
      },
      { 
        path: "/inventory-status-report", 
        component: InventoryStatusReport, 
        name: "Inventory Status Report",
      },

      {
        path: "/customer-invoice",
        component: CustomerInvoice,
        name: "Customer Invoice",
        meta
      },

      { path: "/all-sales", component: AllSales, name: "All Sales",meta2 },
      {
        path: "/make-credit-sales",
        component: MakeCredit,
        name: "Make Credit Sales",
        meta
      },
      { path: "/make-sales", component: MakeSales, name: "Make Sales",meta},
      {
        path: "/sales-receipt/:id",
        component: SalesReceiptCash,
        name: "View Sales Receipt",
        meta
      },
      {
        path: "/view-sales/:id",
        component: ViewSale,
        name: "View Sales",
        meta
      },

      {
        path: "/print58mm-sales-receipt/:id",
        component: PrintSalesReceipt,
        name: "Print 58mm Sales Receipt",
        meta
      },
      {
        path: "/print80mm-sales-receipt/:id",
        component: PrintSalesReceipt,
        name: "Print 80mm Sales Receipt",
        meta
      },
      
      {
        path: "/sales-receipt-credit/:id",
        component: SalesReceiptCash,
        name: "Sales Receipt Credit",
        meta
      },
      {
        path: "/subscribe/:id?",
        component: SubscriptionIndex,
        name: "Subscribe",
        
      },
      {
        path: "/subscription-plan",
        component: subscriptionPlan,
        name: "Subscription Plan",
      },
      {
        path: "/subscription/payment-invoice/:id",
        component: subscriptionPlanInvoice,
        name: "Subscription Payment Invoice",
      },

      {
        path: "/subscription-payment/:id",
        component: subscriptionPayment,
        name: "Subscription Payment",
      },

      //invoice
      { path: "/invoice-history", component: Invoices, name: "Invoices",meta2 },
      { path: "/create-invoice", component: CreateInvoice, name: "Create Invoice",meta},
      {
        path: "/invoice/:id",
        component: InvoiceDetail,
        name: "Invoice Detail",
        meta
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
