<template>
    <div id="printMe">
        <div>
            <div class="d-flex justify-content-between mb-5">
                <div class="fw-bold" style="font-size: 45px">Invoice</div>
                <!-- <div><img style="max-width:100px;" class="invoicelogo" :src="host+loggedInUser?.business?.company_image" /></div> -->
            </div>

            <div class="d-flex justify-content-between mb-5">
                <div>
                    <div class="fw-bold">{{ loggedInUser?.business?.company_name }}</div>
                    <div class="">{{ loggedInUser?.business?.address }}</div>
                    <div class="mt-1">{{ loggedInUser?.business?.phone }}</div>
                </div>
                <div>
                    <div class="fw-bold">Supplier</div>
                    <div class="fw-bold" style="text-transform: uppercase">
                        {{ purchase?.supplier?.supplier_name }}
                    </div>
                    <div class="">{{ purchase?.supplier?.address }}</div>
                    <div class="">{{ purchase?.supplier?.email }}</div>
                </div>
            </div>

            <table class="table table-sm-responsive">
                <thead class="thead" style="border-style: hidden !important">
                    <tr class="center">
                        <th class="text-secondary font-weight-bold" style="width: 30%; font-size: 14px">
                            purchase Date
                        </th>
                        <th class="text-secondary font-weight-bold" style="width: 20%; font-size: 14px">
                            purchase No
                        </th>
                        <th class="text-secondary font-weight-bold" style="width: 25%; font-size: 14px">
                            Store
                        </th>
                        <th class="text-secondary font-weight-bold" style="width: 15%; font-size: 14px">
                            Purchased By
                        </th>
                    </tr>
                </thead>
                <tbody style="border-style: hidden !important">
                    <tr class="center">
                        <td class="">{{ dateTime(purchase?.date) }}</td>
                        <td class="">{{ purchase?.batch_code }}</td>
                        <td class="">{{ purchase?.store?.store_name }}</td>
                        <td class="">{{ purchase?.user?.fullname }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="table-scroll">
                <table class="table table-sm-responsive">
                    <thead class="thead bg-light" style="border-style: hidden !important">
                        <tr class="center">
                            <th class="text-secondary font-weight-bold" style="width: 20%; font-size: 14px;width:20%">
                                PRODUCT
                            </th>
                            <th class="text-secondary font-weight-bold" style="width: 15%; font-size: 14px">
                                VARIANT
                            </th>
                            <th class="text-secondary font-weight-bold" style="width: 15%; font-size: 14px">
                                QTY
                            </th>
                            <th class="text-secondary font-weight-bold" style="width: 15%; font-size: 14px">
                                PRICE({{ company?.currency?.symbol }})
                            </th>
                            <th class="text-secondary font-weight-bold" style="width: 10%; font-size: 14px">
                                AMOUNT({{ company?.currency?.symbol }})
                            </th>
                        </tr>
                    </thead>
                    <tbody style="border-style: hidden !important" v-if="!loading">
                        <tr class="center" v-for="p in purchase_array" :key="p.id">
                            <td class="">{{ p?.product_name }}</td>
                            <td class="">{{ p?.variant_name }}</td>
                            <td class="">{{ p?.quantity }}</td>
                            <td class="">
                                {{ $filters.formatMoney(p?.cost_price)  }}
                            </td>
                            <td class="">
                                {{ $filters.formatMoney(p?.total)  }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center" style="margin: 50px" v-if="loading">
                    <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem"></div>
                </div>
            </div>

            <div class="topd d-flex justify-content-end" v-if="!loading">
                <div style="width: 300px">
                    <div class="d-flex justify-content-between">
                        <div class="fw-bold subtotal">TOTAL</div>
                        <div class="leftsub">
                            {{ $filters.formatMoney(total, company?.currency?.symbol)  }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!loading">
                <hr class="firsthr" />

                <div class="fw-bold">Payment Information</div>

                <div>
                    <div class="d-flex justify-content-between">
                        <div>Payment Type</div>
                        <div class="fw-bold" style="text-transform: capitalize">
                            {{ paymentType }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div>Paid Amount</div>
                        <div class="fw-bold" v-if="purchase?.paid_amount">
                            {{ $filters.formatMoney(purchase?.paid_amount, company?.currency?.symbol)  }}

                        </div>
                        <div class="fw-bold" v-else-if="creditor_credits?.amount_paid">
                            {{ $filters.formatMoney(creditor_credits?.amount_paid, company?.currency?.symbol)  }}

                        </div>
                        <div class="fw-bold" v-else>
                            {{ $filters.formatMoney(0, company?.currency?.symbol)  }}
                        </div>
                    </div>
                    <div></div>
                    <div class="d-flex justify-content-between mt-1" v-if="purchase?.payment_type == 'credit'">
                        <div>Balance</div>
                        <div class="fw-bold" v-if="purchase?.balance !== null ||creditor_credits?.total_balance !== null">
                            {{ $filters.formatMoney(creditor_credits?.total_balance ?? purchase?.balance , company?.currency?.symbol)  }}

                        </div>
                        <div class="fw-bold" v-else>N/A</div>
                    </div>

                    <div class="mt-1" v-else-if="purchase?.payment_type == 'split'">
                        <div class="d-flex justify-content-between mt-1">
                            <div>Cash</div>
                            <div class="fw-bold" v-if="purchase?.cash !== null">
                                ₦{{ purchase?.cash }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-1">
                            <div>Card</div>
                            <div class="fw-bold" v-if="purchase?.card !== null">
                                ₦{{ purchase?.card }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1" v-else-if="purchase?.payment_type == 'part'">
                        <div>Balance</div>
                        <div class="fw-bold" v-if="
                            purchase?.balance !== null ||
                            creditor_credits?.total_balance !== null
                        ">
                            {{ $filters.formatMoney(creditor_credits?.total_balance ?? purchase?.balance , company?.currency?.symbol)  }}

                        </div>
                        <div class="fw-bold" v-else>N/A</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { reusableMixin } from "../../../../lib/reusableMixin";
import { useStore } from "vuex";
import host from "../../../../main";

export default {
    props: {
        creditor_credits: Object,
        purchase_items: Array,
        purchase_array: Array,
        purchase: Array,
        loading: Boolean,
    },
    mixins: [reusableMixin],
    data() {
        return {
            toast: useToast(),
            grand_total: 0,
            tax_amount: 0,
            store: useStore(),
        };
    },

    computed: {
        host() {
            return host.hostName;
        },
        paymentType() {
            if (this.purchase?.payment_type == "part" || this.purchase?.payment_type == "credit") {
                if (
                    this.creditor_credits?.total_balance == 0 ||
                    this.purchase?.balance == 0
                ) {
                    return "Paid";
                }
            }
            return this.purchase?.payment_type == 'part' ? 'Part Payment' : this.purchase?.payment_type;
        },

        loggedInUser() {
            return this.store.state.getLoggedInUser;
        },
        total() {
            return this.purchase_array.reduce((a, b) => {
                return a + b.total;
            }, 0);
        },
    },

    created() {
        this.getCompany();
    }
};
</script>

<style>
.table-scroll {
    overflow-x: auto;
}
</style>
    