<template>
    <InventoryHeaderTab />
    <div class="card thecard rounded-lg mt-5 mb-3 px-4 py-3 cardpadding" style="overflow-x: auto">
        <h4>Stock Transfer</h4>
        <div class="text-muted" style="font-size: 10px">Create Stock Transfer</div>
        <div class="row">
            <ul class="col-md-4" v-for="errors in validationErrors" :key="errors.id">
                <li class="text-danger" style="list-style: none">
                    <small>{{ errors }}</small>
                </li>
            </ul>
        </div>
        <form class="mt-4" @submit.prevent="transferStock">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Date</label>
                    <Datepicker v-model="date" :enableTimePicker="true" class=" "
                        style="background: none; border: none"></Datepicker>
                </div>
                <div class="form-group col-md-4">
                    <label>Source Store</label>
                    <Multiselect v-model="store_id" label="name" :options="getStore" placeholder="Select store"
                        :searchable="true" :create-option="false" :close-on-select="true"
                        @select="getProductInStore(store_id)" />
                </div>
                <div class="form-group col-md-4">
                    <label>Destinaton store</label>
                    <Multiselect v-model="destination_id" label="name" :options="getStore" placeholder="Select store"
                        :searchable="true" :create-option="false" :close-on-select="true" />
                </div>
            </div>
            <div class="mt-3 overflow-auto" style="max-height:350px">

                <table class="table table-sm-responsive mt-4">
                    <thead class="thead-light" style="border-style: hidden !important">
                        <tr class="pb-5">
                            <th class="fw-bold" style="font-size: 14px">Product</th>
                            <th class="fw-bold" style="font-size: 14px">Available Quantity</th>
                            <th class="fw-bold" style="font-size: 14px">Variant/Unit of Measurement</th>
                            <th class="fw-bold" style="font-size: 14px">Quantity</th>
                            <th class="fw-bold" style="font-size: 14px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in product" :key="index.id">
                            <td style="width:30%">
                                <Multiselect v-model="p.product_id" label="name" :options="getProducts"
                                    :canClear="false" :searchable="true" :create-option="false" :close-on-select="true"
                                    @select="handleChange(p.product_id, index)" />
                            </td>
                            <td>
                                <input disabled type="text" class="form-control" v-model="p.available_qty" />
                            </td>
                            <td v-if="p.variant.length > 0">
                                <select class="form-select" v-model="p.product_variant_id">
                                    <option disabled>Select variant</option>
                                    <option v-for="variant in p.variant" :key="variant.id" :value="variant.id">
                                        {{ variant.variant_name }}
                                    </option>
                                </select>
                            </td>
                            <td v-else>
                                <input type="text" class="form-control" min=0 :value="p.unit_of_measurement" disabled />
                            </td>
                            <td><input type="text" class="form-control" min=0 v-model="p.quantity" onkeypress="return /^[0-9]+$/i.test(event.key)" @keyup="validateInput(p)" /></td>
                            <td>
                                <button class="btn btn-danger text-white fw-bold" type="button"
                                    @click="removeProduct(index)">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end mt-2">
                <button type="button" class="btn" style="background-color: #DAE7FC" @click="addProduct">Add Product</button>
            </div>

            <div class="mt-5 d-flex justify-content-end">
                <button type="submit" class="btn px-5 me-5 btn-primary text-white"
                    v-if="crudLoading == false">Transfer Stock</button>
                <button v-if="crudLoading == true" type="submit" class="btn px-5 me-5 btn-primary text-white"
                    disabled>
                    <span class="visually-">Processing</span>
                </button>
                <button type="button" @click="$router.go(-1)" class="btn px-5 exportbtn">Cancel</button>

            </div>
        </form>
    </div>
</template>

<script>
import InventoryHeaderTab from "./component/inventoryheadertab.vue";
import {
    ref
} from "@vue/reactivity";
import {
    onMounted
} from "@vue/runtime-core";
import axios from "axios";
import {
    useToast
} from "vue-toastification";
import {
    useRouter
} from "vue-router";
import {
    useReusable
} from "../../../lib/reusable";
import Multiselect from '@vueform/multiselect'

export default {
    components: {
        InventoryHeaderTab,
        Multiselect
    },
    setup() {
        const toast = useToast();
        const router = useRouter();
        const validationErrors = ref([]);
        const getVariants = ref("");

        const date = ref(new Date());
        const store_id = ref(null)
        const destination_id = ref(null)
        const getProducts = ref([])
        const product = ref([{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            total_price: 0
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            total_price: 0
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            total_price: 0
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            total_price: 0
        },{
            product_id: "",
            available_qty: 0,
            cost_price: "",
            variant: [],
            product_variant_id: "",
            quantity: "",
            total_price: 0
        }]);
        const crudLoading = ref(false)
        const {
            removeProduct,
            getStore,
            allStore,
            validateInput
        } = useReusable(product)

        const addProduct = () => {
            product.value.push({
                product_id: "",
                available_qty: 0,
                variant: [],
                product_variant_id: "",
                quantity: "",

            });
        };

        const clearFields = (product) => {
            product.product_id = "",
                product.available_qty = 0,
                product.cost_price = "",
                product.selling_price = "",
                product.variant = [],
                product.product_variant_id = "",
                product.quantity = "",
                product.reason = "",
                product.adjustment_type = "",
                product.total_price = 0
        }

        const getProductInStore = async (storeId) => {
            const getProduct = await axios.get(`${'/admin/store/product-in-store/' + storeId}`)
            let products = getProduct?.data?.data?.data

            // clear old  products
            getProducts.value = []
            products.forEach((product) => {
                getProducts.value.push({
                    value: product.id,
                    name: product.bar_code ? product.product_name + '<span style="visibility: hidden; "> - ' + product?.bar_code + '</span>' : product.product_name
                })
            })
            // Set avalaible quantity value back to zero
            product.value.forEach((p) => {
                p.available_qty = 0
                clearFields(p)
            })
        }
        const transferStock = async () => {
            crudLoading.value = true
            let valid_products = removeEmptyObjects(product.value)
            if (store_id.value == null) {
                crudLoading.value = false
                toast.error("Source store must not be empty");
                return
            }

            if (destination_id.value == null) {
                crudLoading.value = false
                toast.error("Destination store must not be empty");
                return
            }


            if (store_id.value == destination_id.value) {
                crudLoading.value = false
                toast.error("Source and destination store must not be thesame");
                return
            }

            await axios.post("/inventory/issue-stock", {
                store_id: store_id.value,
                destination_id: destination_id.value,
                time: date.value.toLocaleTimeString(),
                date: date.value.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }).split("/").reverse().join("/"),

                issue_stock_item: valid_products
            })
                .then((res) => {
                    crudLoading.value = false
                    router.push("/all-stock-transfer");
                    toast.success(res.data.message);
                })
                .catch((error) => {
                    crudLoading.value = false
                    if (error.response.status == 422) {
                        toast.warning("There are errors in your input");
                        validationErrors.value = error.response.data.message;
                    }
                    if (error.response.status == 403) {
                        toast.error(error.response.data.message);
                    }
                    if (error.response.status == 400) {
                        validationErrors.value = error.response.data.message;
                        toast.error("This is a bad request");
                    }
                    if (error.response.status == 500) {
                        toast.error("An error occured please try again");
                    }
                });
        }

        const removeEmptyObjects = (array) => {
            const newArray = [];
            array.forEach(element => {
                if (element.product_id !== "") {
                    newArray.push(element);
                }
            });

            return newArray;
        }
        const handleChange = async (product_id, index) => {
            let id = product_id
            const getProduct = await axios.get(`/inventory/product/${id}?store_id=${store_id.value}`)
            let single_product = getProduct?.data?.data

            // set variant value
            let variant = single_product.variants
            // set cost value
            let cost = single_product?.cost_price
            // set total cost value
            let total_cost = single_product?.cost_price


            // Check if product already selected
            let occurences = product.value.filter((v) => (v.product_id == single_product.id))

            if (occurences.length > 1) {
                product.value[index].product_id = ""
                product.value[index].cost_price = parseFloat(0).toFixed(2)
                product.value[index].new_cost_price = 0
                product.value[index].selling_price = (0).toFixed(2)
                product.value[index].available_qty = 0
                product.value[index].total_price = parseFloat(0).toFixed(2)
                product.value[index].product_variant_id = ''
                product.value[index].quantity = 0
                toast.warning("Product already selected");

                return
            }

            let empty_product = product.value.filter((v) => (v.product_id == ""))
            if(empty_product.length == 0){
                addProduct()
            }

            // set available quantity value
            let available_qty = single_product?.store_qty
            product.value[index].cost_price = cost
            product.value[index].available_qty = available_qty
            product.value[index].total_price = total_cost
            product.value[index].variant = variant
            product.value[index].unit_of_measurement = single_product?.unit_of_measurement
            product.value[index].product_variant_id = ''

        }

        const getVariantCost = (p, evt) => {
            let variant_selling_price = p.variant.find(({
                id
            }) => id == evt.target.value).variant_selling_price
            p.total_price = parseInt(variant_selling_price) + parseInt(p.total_price)
        }


        onMounted(async () => {
            allStore()

        });

        return {
            allStore,
            getStore,
            getProducts,
            getVariants,
            date,
            product,
            addProduct,
            removeProduct,
            transferStock,
            validationErrors,
            store_id,
            getVariantCost,
            handleChange,
            getProductInStore,
            destination_id,
            validateInput,
            crudLoading
        };
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .thecard {
        width: 21rem;
    }
}
</style>
